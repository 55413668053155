import {
  TableCell,
  TableRow,
  Checkbox,
  Grid,
  Link,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Route } from "react-router-dom";
import CustomButton from '../CustomButton';

function EmgVolunteerRow({ labelId, row, isItemSelected, inviteMode, addEmgVolunteer, removeEmgVolunteer, completeEmgVolunteer, disbleAddCompleInvite, handleClick }) {

  return (
    <Route
      render={({ history }) => (
        <TableRow
          hover
          // onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          {inviteMode && <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(event) => handleClick(event, row.id)}
            />
          </TableCell>}
          <TableCell>{row.registrationNo}</TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.contributeDistrict}</TableCell>
          <TableCell>{row.contributeDivisionalSecretariat}</TableCell>
          <TableCell>{row.contributegnDivision}</TableCell>
          {!inviteMode && <TableCell>{row.status}</TableCell>}
          {!inviteMode && <TableCell><CustomButton
            color="secondary"
            label={row.status == "INVITED" ? "Add" : "Remove"}
            onClick={row.status == "INVITED" ? () => { addEmgVolunteer(row.registrationNo, row.id) } : () => { removeEmgVolunteer(row.registrationNo, row.id) }}
            disabled={((row.status == "COMPLETED") || disbleAddCompleInvite)}
            margin={6} /><CustomButton
            color="secondary"
            label="Complete"
            onClick={() => { completeEmgVolunteer(row.registrationNo, row.id) }}
            disabled={(!(row.status == "ACCPETED") || disbleAddCompleInvite)}
            margin={6} /></TableCell>}

          <TableCell>
            <Tooltip title="Show More" placement="top">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  history.push("/volunteer/" + row.id.toString());
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
    />
  );
}

export default EmgVolunteerRow;
