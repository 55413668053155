import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  defualtOnlineCarousalHeading, defualtRegularCarousalHeading, volunteerHomePageRegularOpportunityImageLink, volunteerHomePageOnlineOpportunityImageLink
  , defaultLearnmore, defaultShare, defaultItems
  , volunteerHomePageOnlineOpportunityCardContent, volunteerHomePageOnlineOpportunityCardContentTopic, volunteerHomePageOnlineOpportunityImageTopic, opportunityLimit
  // ,volunteerHomePageRegularOpportunityCardContent,volunteerHomePageRegularOpportunityCardContentTopic,
  // volunteerHomePageRegularOpportunityImageTopic
} from './Constants';
import { useTranslation } from "react-i18next";
import { fetchHomeOpportunities } from '../../functions/opportunity/fetchOpportunities';
import CustomCarousal from '../../components/Carousal';
import CustomButton from '../../components/CustomButton';
import Alert from '@material-ui/lab/Alert';
import { Link, useHistory } from 'react-router-dom';
import { ButtonGroup, Grid, Button, Paper } from '@material-ui/core';
import { onClickCard } from '../../functions/opportunity/onClickFunctions'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import LoadingPage from '../../components/LoadingPage';
import MessageCard from '../../components/MessageCard';
import { check } from '../../helper/route_helper';
import {FULLY_COMPLETED_VOLUNTEER} from "../../containers/Router/Constants";

export default function VolunteerHomePage() {

  const [details, setDetails] = useState({ error: false, items: [] });
  const [isLoading, setLoading] = useState(true)
  const [profileIncmoplete, setProfileIncomplete] = useState(false);
  const history = useHistory();
  const registrationNo = useSelector(state => state.auth.registrationNo);
  const { t } = useTranslation();
  const access = useSelector(state => state.auth.access)

  useEffect(async () => {
    setProfileIncomplete(localStorage.getItem("PROFILE_INCOMPLETE"));
    setLoading(true)
    let mounted = true;
    async function fetchData() {
      let result = await fetchHomeOpportunities(opportunityLimit);
      
      if (mounted) {
        setDetails(result)
        setLoading(false)
      }
      
    }
    await fetchData();
    return () => mounted = false;
  }, [])

  
  const handleProfileClick = () => {
    history.push("/profile")
    setProfileIncomplete(false);
    localStorage.removeItem("PROFILE_INCOMPLETE");
  }
  return (
    <React.Fragment>
      {!isLoading ?
        details.error ?
          <MessageCard message={details.message} />
          :
          <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              {
                registrationNo &&
                <Box m={1}>
                  <Chip
                    avatar={<Avatar>R</Avatar>}
                    label={t('Volunteer.Registration No') + ": " + registrationNo}
                    color="primary"
                    variant="outlined"
                  />
                </Box>
              }
            </Grid>
            {check(FULLY_COMPLETED_VOLUNTEER, access, "") &&
            (
              <Grid container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: 40 }}>
              <Grid item>
                <CustomButton label={t('Volunteer.View My Activities')} component={Link} to="/opportunities/myOpportunities" color="secondary" />
              </Grid>
              <Grid item>
                <CustomButton label={t('Volunteer.View All Opportunities')} component={Link} to="/viewAllOpportunities" color="secondary" />
              </Grid>
            </Grid>
            )}
            
            {profileIncmoplete &&
              <div>
                <Alert variant="filled" severity="warning"
                  action={
                    <Button color="inherit" size="small" onClick={handleProfileClick}>
                      {t('Volunteer.View profile')}
                    </Button>
                  }
                >
                  {t('Volunteer.Compete your volunteer profile to apply for opportunities')}
                </Alert>
              </div>
            }
            <Paper style={{ padding: '18px 36px', marginTop: 12, marginBottom: 40 }}>
              <CustomCarousal
                onClickLearnMore={defaultLearnmore}
                onClickShare={defaultShare}
                header={defualtOnlineCarousalHeading}
                items={details.onlineItems}
                defualtImageLink={volunteerHomePageOnlineOpportunityImageLink}
                onClickCard={onClickCard}
              />
              <CustomCarousal
                onClickLearnMore={defaultLearnmore}
                onClickShare={defaultShare}
                header={defualtRegularCarousalHeading}
                items={details.regularItems}
                defualtImageLink={volunteerHomePageRegularOpportunityImageLink}
                onClickCard={onClickCard}
              />
            </Paper>
          </div>
        :
        <LoadingPage />
      }
    </React.Fragment>
  )
}