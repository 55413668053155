import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { updatePartnerUrl } from '../../../containers/Admin/Partner/Constants';

export const submitUpdatePartner = async (details) => {
    const saveUrl = serverHost + ":" + serverPort + updatePartnerUrl
    try {
        const response = await axios.put(saveUrl, { ...details }, { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }
    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }
};

