import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import Slide from '@material-ui/core/Slide';
import {Typography} from "@material-ui/core"
import {SDGS, FUNCTIONS, CATOGARIES} from "./static.js"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    card: {
        backgroundColor: "#daeeff",
        color: "#0c5aad",
        display: "grid",
        gridTemplateColumns: "90px 3px 1fr",
        gridTemplateRows: "1fr 2.9fr 3px 45px 3px 45px",
        gridTemplateAreas: `"title title title"
                            "description description description"
                            "line1 line1 line1"
                            "time line2 matter"
                            "time line2 line3"
                            "time line2 location"`,
        gap: "0px 0px",
        overflow: "hidden",
        boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.4)",
        borderRadius: "1px",
        cursor: "pointer"
    },
    header: {
        gridArea: "title",
        alignSelf: "stretch",
        padding: "10px",
        textOverflow: "ellipsis",

        backgroundColor: (props) => props.color,
        color: "white",
        textTransform: "uppercase",
        fontSize: "26px",
        fontWeight: "bold",
    },
    description: {
        gridArea: "description",
        alignSelf: "center",
        padding: "7px 10px",
    },
    time: {
        gridArea: "time",
        alignSelf: "center",
        justifySelf: "center",
        textAlign: "center"
    },
    matter: {
        gridArea: "matter",
        padding: "7px 10px",
        alignSelf: "center",
        fontWeight: "500",
        fontSize: "13px",
    },
    location: {
        gridArea: "location",
        padding: "7px 10px",
        alignSelf: "center",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    line: {
        display: "block",
        backgroundColor: "white"
    },

    mouseOver: {
        gridArea: "4 / 1 / 7 / 4",
        backgroundColor: "#d66",
        alignSelf: "stretch",
        zIndex: 10,
        padding: "10px"
    }
}))

var fixedTwoDigits = new Intl.NumberFormat('en-US', { 
    minimumIntegerDigits: 2, 
    minimumFractionDigits: 0 
});

function fixDigits(val, digits = 2) {
    return fixedTwoDigits.format(val)
}

export default function OpportunityCard(
    {
        title,
        header,
        vio,
        duration,
        functions,
        location,
        sdg,
        className,
    }
) {
    const [hover, setHover] = useState(false)
    const {t} = useTranslation()
    const cls = useStyles({color: CATOGARIES[header - 1].color, hover })

    return (
        <dev className={className}>
            <dev className={cls.card}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >

                {/* Grid areas */}
                <div className={cls.header}>
                    <Typography
                        variant="h6"
                        style={{ fontWeight: "700", color: "white", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                    >
                        {t(`Categories.${CATOGARIES[header - 1].text}`)}
                    </Typography>
                </div>
                <div className={cls.description}>
                    <Typography
                        variant="h6"
                        style={{ fontSize: "15px", fontWeight: "bold", color: "#083c76" }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: "600",
                            //textTransform: "uppercase"
                        }}
                    >
                        {vio}
                    </Typography>
                </div>


                <div className={cls.time}>
                    <Typography
                        variant="h6"
                        style={{ color: "#083c76", fontSize: "22px", fontWeight: "bold" }}
                    >
                        {fixDigits(duration)}
                    </Typography>
                    <Typography
                        variant="p"
                        style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            letterSpacing: "0",
                        }}
                    >
                        {t('VolunteeringOpportunities.hoursWeek')}
                            </Typography>
                </div>
                <div className={cls.matter}>
                    <Typography
                        variant="p"
                    >
                        {functions.map(i => t(`CategoriesVO.${FUNCTIONS[i - 1]}`)).join(", ")}
                    </Typography>
                </div>
                <div className={cls.location}>{location}</div>

                {/* Gap lines */}
                <div className={cls.line} style={{ gridArea: "line1" }} />
                <div className={cls.line} style={{ gridArea: "line2" }} />
                <div className={cls.line} style={{ gridArea: "line3" }} />

                {/* on hover */}
                <Slide direction="up" mountOnEnter unmountOnExit in={hover}>
                    <div className={cls.mouseOver} style={{backgroundColor: SDGS[sdg-1].color}}>
                        <img src={SDGS[sdg-1].image} height={'100%'}/>
                    </div>
                </Slide>
            </dev>
        </dev>
    )
}
