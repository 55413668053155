
import sdg1 from "../../images/SDG_01.png";
import sdg2 from "../../images/SDG_02.png";
import sdg3 from "../../images/SDG_03.png";
import sdg4 from "../../images/SDG_04.png";
import sdg5 from "../../images/SDG_05.png";
import sdg6 from "../../images/SDG_06.png";
import sdg7 from "../../images/SDG_07.png";
import sdg8 from "../../images/SDG_08.png";
import sdg9 from "../../images/SDG_09.png";
import sdg10 from "../../images/SDG_10.png";
import sdg11 from "../../images/SDG_11.png";
import sdg12 from "../../images/SDG_12.png";
import sdg13 from "../../images/SDG_13.png";
import sdg14 from "../../images/SDG_14.png";
import sdg15 from "../../images/SDG_15.png";
import sdg16 from "../../images/SDG_16.png";
import sdg17 from "../../images/SDG_17.png";

// SDG Images
export const SDGS = [
    {
        color: '#ea1b2d',
        image: sdg1
    },
    {
        color: '#d19e28',
        image: sdg2
    },
    {
        color: '#269a45',
        image: sdg3
    },
    {
        color: '#c11e31',
        image: sdg4
    },
    {
        color: '#ef4129',
        image: sdg5
    },
    {
        color: '#00add8',
        image: sdg6
    },
    {
        color: '#fbb611',
        image: sdg7
    },
    {
        color: '#8e1737',
        image: sdg8
    },
    {
        color: '#f26d22',
        image: sdg9
    },
    {
        color: '#df1382',
        image: sdg10
    },
    {
        color: '#f99d22',
        image: sdg11
    },
    {
        color: '#cc8b27',
        image: sdg12
    },
    {
        color: '#47773d',
        image: sdg13
    },
    {
        color: '#007cba',
        image: sdg14
    },
    {
        color: '#3eae48',
        image: sdg15
    },
    {
        color: '#01548a',
        image: sdg16
    },
    {
        color: '#1a3666',
        image: sdg17
    },
]
export const CATOGARIES = [
  {
    text: 'Fundraising and Administration',
    color: "#1b262c"
  },
  {
    text: 'Environmental',
    color: "#84142d"
  },
  {
    text: 'Children and Young People',
    color: "#2b2e4a"
  },
  {
    text: 'Elderly and disabled',
    color: "#301b3f"
  },
  {
    text: 'Homeless',
    color: "#59405c"
  },
  {
    text: 'Trustee',
    color: "#272121"
  },
  {
    text: 'International',
    color: "#44000d"
  },
  {
    text: 'Research',
    color: "#382039"
  },
  {
    text: 'Refugees/migrants',
    color: "#053f5e"
  },
  {
    text: 'Human Rights',
    color: "#393e46"
  },
  {
    text: 'Education',
    color: "#00454a"
  },
];


 
export const FUNCTIONS = [
  {
    opport: 'Translation',
  },
  {
    opport: 'Project Development and management',
  },
  {
    opport: 'Writing and Editing',
  },
  {
    opport: 'Art and Design',
  },
  {
    opport: 'Research',
  },
  {
    opport: 'Outreach and advocacy',
  },
  {
    opport: 'Technology development',
  },
  {
    opport: 'Teaching and Training',
  },
  {
    opport: 'Administration',
  },
  {
    opport: 'Event Organization',
  },
  {
    opport: 'Volunteer management',
  },
  {
    opport: 'Leadership and strategy',
  },
  {
    opport: 'Community organizing',
  },
  {
    opport: 'Healthcare services',
  },
  {
    opport: 'Video editing, graphic designi',
  },
  {
    opport: ' Graphic Designing ',
  },
  {
    opport: 'Compering',
  },
  {
    opport: 'Pyschical Activities',
  },
].map(opp => opp.opport);



