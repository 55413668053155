import {axiosClient, resolver} from "../client.js"


export default {
    getOpportunities(query) {
        return resolver(
            axiosClient.get('/api/opportunity/getOpportunities', {params: query})
        )
    }
}
