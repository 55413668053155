import axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { submitVIOStatusURL } from "../../../containers/VIOApproval/Constants";

export const submitVIOStatus = async (id, approve) => {
  const registerUrl = serverHost + ":" + serverPort + submitVIOStatusURL;
  try {
    const body = { id: id, approve: approve };
    const response = await axios.post(
      registerUrl,
      { id, approve },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    );
    if (response.status === 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.response ? err.response.data.message : "Error",
      error: true,
    };
    return result;
  }
};
