import {
    ADDDATE,DELETEDATE,CLEARALL
} from '../actiontypes';

const INITIAL_STATE = {

	dates :[]
}

export default function calenderReducer(state = INITIAL_STATE, { type, payload }) {
	switch (type) {

		case ADDDATE:
            state.dates.push(payload.day)
            return { ...state, dates:state.dates}
        case DELETEDATE:
            const index = state.dates.indexOf(payload.day);
            if (index > -1) {
                state.dates.splice(index, 1);
            }
            return { ...state, dates:state.dates}
        case CLEARALL:
            return { ...state, dates:[]}
		default:
			return state
	}
}
