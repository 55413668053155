import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { serverHost, serverPort } from "../../Constant";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function EditAdminPermissionsModal({ row }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const permissionList = [
    "VIEW_PROFILE_STATUS",
    "CHECK_REFEREES",
    "VERIFY_VIOS",
    "APPROVE_OPPORTUNITIES",
    "CONFIRM_VOLUNTEERS",
    "VIEW_REPORTS",
  ];
  const [state, setState] = React.useState({
    VIEW_PROFILE_STATUS: false,
    CHECK_REFEREES: false,
    VERIFY_VIOS: false,
    APPROVE_OPPORTUNITIES: false,
    CONFIRM_VOLUNTEERS: false,
    VIEW_REPORTS: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const test = () => {
    row.adminPermissions.map((permission) => {
      
    });
  };

  useEffect(() => {
    row.adminPermissions.map((permission) => {
      setState({ ...state, [permission]: true });
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        react-transition-group
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <FormGroup>
                <Box p={3}>
                  <span style={{ padding: 20 }}>
                    Update Admin Permissions of {row.name}
                  </span>
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="VIEW_PROFILE_STATUS"
                      onChange={handleChange}
                      color="primary"
                      value="VIEW_PROFILE_STATUS"
                      checked={state.VIEW_PROFILE_STATUS}
                    />
                  }
                  label="View Profile Status"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="CHECK_REFEREES"
                      onChange={handleChange}
                      color="primary"
                      value="CHECK_REFEREES"
                      checked={state.CHECK_REFEREES}
                    />
                  }
                  label="Check Referees"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="VERIFY_VIOS"
                      onChange={handleChange}
                      color="primary"
                      value="VERIFY_VIOS"
                      checked={state.VERIFY_VIOS}
                    />
                  }
                  label="Verify VIOs"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="APPROVE_OPPORTUNITIES"
                      onChange={handleChange}
                      color="primary"
                      value="APPROVE_OPPORTUNITIES"
                      checked={state.APPROVE_OPPORTUNITIES}
                    />
                  }
                  label="Approve Opportunities"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="CONFIRM_VOLUNTEERS"
                      onChange={handleChange}
                      color="primary"
                      value="CONFIRM_VOLUNTEERS"
                      checked={state.CONFIRM_VOLUNTEERS}
                    />
                  }
                  label="Confirm Volunteers"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="VIEW_REPORTS"
                      onChange={handleChange}
                      color="primary"
                      value="VIEW_REPORTS"
                      checked={state.VIEW_REPORTS}
                    />
                  }
                  label="View Reports"
                />
              </FormGroup>
            </Grid>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => test()}
            >
              Update
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default EditAdminPermissionsModal;
