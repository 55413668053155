import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

function PDFFooter() {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="caption" display="block" gutterBottom align="center">
        {t('Reports.This is an automated report generated by the Volunteer Management System')}
      </Typography>
    </div>
  );
}

export default PDFFooter;
