export const dropdownDetails = [
    {
        label:"Time Basis",
        name:"Time Basis",
        defaultType:"Daily",
        list:[
            {
                value:"Daily"
            },
            {
                value:"Weekly"
            },
            {
                value:"Monthly"
            },
            {
                value:"Quarterly"
            },
            {
                value:"Bi-Annual"
            },
            {
                value:"Daily"
            }
        ]
    },
    {
        label:"Type",
        name:"Type",
        defaultType:"Online opprtunity",
        list:[
            {
                value:"Online opprtunity"
            },
            {
                value:"Regular Opportunity"
            },
            {
                value:"Volunteer Involving rganization"
            }
        ]
    }
]


export const displayDetails = [
    {
        title:"Daily Volunteers registrations",
        date:new Date().toString(),
        list:[
            {

                title:"Registered volunteers",
                value:"147",
                needDropDown:false
            },
            {
                title:"Local "+"82"+" Overseas "+"18",
                value:"",
                needDropDown:true,
                dropdownItems:[{
                    label:"Registration countries",
                    name: "Registration countries",
                    list:[
                        {
                            value:"Australia - 76"
                        },
                        {
                            value:"India - 96"
                        }
                    ]
                }]
            },
            {
                title:"Keysector",
                value:"11",
                needDropDown:true,
                dropdownItems:[{
                    label:"Keysector",
                    name: "Keysector",
                    list:[
                        {
                            value:"Key1 - 76"
                        },
                        {
                            value:"Key2 - 96"
                        }
                    ]
                }]
            },
            {
                title:"Districs",
                value:"11",
                needDropDown:true,
                dropdownItems:[{
                    label:"Districs",
                    name: "Districs",
                    list:[
                        {
                            value:"Colombo - 76"
                        },
                        {
                            value:"Galle - 96"
                        }
                    ]
                }]
            },
            {
                title:"Gender",
                value:"50%M 50%F",
                needDropDown:false
            }
        ]
    }
]

export const dailyReportConst = {
        numVolunteers: 'Registered volunteers',
        numVolunteersByNationality: 'By nationality',
        numVolunteersByKeySectors: 'By key sectors',
        numVoluteersByDistricts: 'By districts',
        numVolunteersByGender: 'By gender',
    }




export const getReportUrl = "/getReportDetails";
export const getReport = {
    DAILY: {
        URL:'/api/report/General/daily',
        BKEY: 'selectedDate',
    },
    QUARTERLY: {
        URL:'/api/report/General/quartlyChoice',
        BKEY: ['choice', 'selectedYear'],
    },
    ANNUALLY: {
        URL: '/api/report/General/yearChoice',
        BKEY: 'selectedYear',
    }

}

export const getOnlineReport = {
    DAILY: {
        URL:'/api/report/Online/daily',
        BKEY: 'selectedDate',
    },
    QUARTERLY: {
        URL:'/api/report/Online/quartlyChoice',
        BKEY: ['choice', 'selectedYear'],
    },
    ANNUALLY: {
        URL: '/api/report/Online/yearChoice',
        BKEY: 'selectedYear',
    }

}