import React from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import NonFormalVOProcess from '../../components/VIO/NonFormalVOProcess';
import { submitUpdateNonFormalVO } from '../../functions/VIO/NonFormalVolunteerOppertunity/submitUpdateNonFormalVO';
import { fetchNonFormalVolunteerOppertunity } from '../../functions/VIO/NonFormalVolunteerOppertunity/fetchNonFormalVolunteerOppertunity';
import { fetchNonFormalVOFormData } from '../../functions/VIO/NonFormalVolunteerOppertunity/fetchNonFormalVOFormData';
import MessageCard from "../../components/MessageCard";
import { Paper } from "@material-ui/core";
import FormLanguageAlert from "../../components/FormLanguageAlert";

export default function NonFormalVO({ match }) {

  const id = match.params.id;
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const [fetchErrorFormData, setFetchErrorFormData] = React.useState(false);
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {

    let mounted = true;

    let resultFormData = await fetchNonFormalVOFormData();

    if (mounted) {
      if (resultFormData.error) {
        setFetchErrorFormData(true);
        setErrorMsg("Error")
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFormDisplayData(resultFormData.item);
        setFetchErrorFormData(false);
      }
    }

    let result = await fetchNonFormalVolunteerOppertunity(id);
    
    if (mounted) {
      if (result.error) {
        setError(true);
        setErrorMsg("Error")
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFetchDetails({ ...result.item });
        setError(false);
      }
    }

    return () => mounted = false;
  }, [])

  const updateNonFormalVOFunc = async (voDetails) => {

    var formData = { ...voDetails }
    delete formData.history

    let result = await submitUpdateNonFormalVO(id, formData);

    if (result.error) {
      setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Updated!")
      setPushError(!pushError)
    }
  }
  return (
    <React.Fragment>
      <FormLanguageAlert/>
      {(fetchDetails && formDisplayData) ?
        (!error && !fetchErrorFormData) ?
          <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
            <NonFormalVOProcess
              onSubmit={updateNonFormalVOFunc}
              fetchDetails={fetchDetails}
              isEdit={true}
              formDisplayData={formDisplayData}
            />
          </Paper>
          : <MessageCard />
        : ""
      }
    </React.Fragment>
  );
}