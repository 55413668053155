import React from "react";
import { useEffect } from "react";
import LoadingPage from "../../components/LoadingPage";
import MessageCard from "../../components/MessageCard";
import { useSnackbar } from "notistack";
import { fetchVOFormDataFunc } from "../../functions/VIO/VolunteerOppertunity/fetchVOFormData";
import EVOProcess from '../../components/EmergencyUser/EVOProcess';
import { Paper } from "@material-ui/core";
import {submitCreateEVOFunc} from '../../functions/EmergencyUser/EVO/submitCreateEVO';

export default function CreateEVOPage() {
 
  const [errorMsg, setErrorMsg] = React.useState("");
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const [fetchErrorFormData, setFetchErrorFormData] = React.useState(false);
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {

    let mounted = true;
    let resultFormData = await fetchVOFormDataFunc();

    if (mounted) {
      if (resultFormData.error) {
        setFetchErrorFormData(true);
        setErrorMsg("Error")
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFormDisplayData(resultFormData.item);
        setFetchErrorFormData(false);
      }
    }
    return () => mounted = false;
  }, [])

  const createEVOFunc = async (voDetails) => {

    var formData = { ...voDetails }
    delete formData.history

    let result = await submitCreateEVOFunc(formData);
    if (result.error) {
      setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Created!")
      setPushError(!pushError)
      voDetails.history.push("/emg-VolunteerOpportunity/" + result.item.id)
    }
  }

  return (
    <React.Fragment>
    {
      (formDisplayData) ?
        (!fetchErrorFormData) ?
          <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40, marginBottom: 40 }}>
            <EVOProcess
              onSubmit={createEVOFunc}
              formDisplayData={formDisplayData}
            />
          </Paper>
          : <MessageCard message="Error" />
        : ""
    }
  </React.Fragment>
  );
}
