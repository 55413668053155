import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import ProfileComplete from "../../components/VolunteerProfile/ProfileComplete";
import { fetchVolunteerProfile } from '../../functions/Volunteer/fetchVolunteerProfile';
import { saveVolunteerProfile } from '../../functions/Volunteer/saveVolunteerProfile';
import { fetchVolunteerProfileFormData } from '../../functions/Volunteer/fetchVolunteerProfileFormData';
import MessageCard from "../../components/MessageCard";
import LoadingPage from "../../components/LoadingPage";
import { Paper } from "@material-ui/core";
import { tokenKey } from "../../Constant";
import * as AuthActions from "../../actions/AuthActions";
// import { fetchVolunteerProfileFormData } from "./Constants";
import FormLanguageAlert from "../../components/FormLanguageAlert";

export default function ProfilePage() {

  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [fetchError, setFetchError] = React.useState(false);
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const [fetchErrorFormData, setFetchErrorFormData] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {

    let mounted = true;

    let resultFormData = await fetchVolunteerProfileFormData();

    if (mounted) {
      if (resultFormData.error) {
        setFetchErrorFormData(true);
        resultFormData.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(resultFormData.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFormDisplayData(resultFormData.item);
        setFetchErrorFormData(false);
      }
    }

    let result = await fetchVolunteerProfile();

    if (mounted) {
      if (result.error) {
        setFetchError(true);
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)  
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFetchDetails(result.item);
        setFetchError(false);
      }
    }
    return () => mounted = false;
  }, [fetchError])

const resetToken = async (token) => await dispatch(AuthActions.resetToken(token));

const saveProfile = async (profileDetails) => {
  localStorage.removeItem("PROFILE_INCOMPLETE");
  var formData = { ...profileDetails }
  delete formData.history
  let result = await saveVolunteerProfile({ ...formData });

  if (result.error) {
    result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
    setIsErrorVariant(true)
    setPushError(!pushError)
  }
  else {
    localStorage.setItem(tokenKey,result.token);
    await resetToken(result.token);
    
    setErrorMsg("Successfully Saved!")
    setIsErrorVariant(false)
    setPushError(!pushError)
  }
}

  return (
    <React.Fragment>
      <FormLanguageAlert/>
      {(fetchDetails && formDisplayData) ?
        (!fetchError && !fetchErrorFormData) ?
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
            <ProfileComplete
              onSubmit={saveProfile}
              fetchDetails={fetchDetails}
              formDisplayData={formDisplayData}
            />
          </Paper>
          : <MessageCard />
        : <LoadingPage />
      }

    </React.Fragment>

  );
}