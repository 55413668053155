export const subvioCreateUrl = "/api/vio/createSubVio/";

export const subvioViewUrl = "/api/viewSubvio";

export const addVolunteerUrl = "/api/subvio/addVolunteer";

export const removeVolunteerUrl = "/api/subvio/removeVolunteer"

export const subviosViewUrl = "/api/subvio/viewSubvios"

export const removeSubvioUrl = "/api/subvio/delete"
export const assignSubVioUrl = '/api/org/subvio/assign';
export const unassignSubVioUrl = '/api/org/subvio/unassign';
export const getOrgVolunteersUrl = '/api/vio/get-org-volunteers-subvio'
export const subVioPublicProfileUrl = '/api/vio/public-view';

export const volunteerHeader = "Volunteer Details"