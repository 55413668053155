import { Button, Card, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { forgotPasswordRequest } from "../utils/forgot_password";
import { Alert } from "@material-ui/lab";
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
    email: yup.string().required('required').email("invalid email address"),
});


export default function RequestEmailForm() {

    const { register, errors, handleSubmit } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange'
        }
    );

    const userId = useSelector(state => state.auth.id)
    const [status, setStatus] = useState('')
    const {t} = useTranslation();

    const onSubmit = async (data) => {
        
        const [code] = await forgotPasswordRequest(data.email)
        
        if (code === 200) setStatus('SUCCESS')
        else setStatus('FAILED')
    }

    return (
        <Paper style={{ padding: '18px 36px', maxWidth: 480, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justify="center">
                    <Grid item style={{ padding: '20px 0' }}>
                        <Typography variant="h5" color="primary" align="center">{t("ForgetPassword.topic")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            label={t("ForgetPassword.enterEmail")}
                            size="small"
                            id="outlined-margin-normal"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            error={errors.email}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                        {t("ForgetPassword.confirmEmail")}
                        </Button>
                    </ Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        {status !== '' && <Alert severity="success">
                        {t("ForgetPassword.confirmMessage")}
                        </Alert>}
                    </ Grid>
                </Grid>
            </form>
        </Paper>
    );
}

