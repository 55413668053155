import {
  Collapse,
  TableCell,
  TableRow, TextField,
} from "@material-ui/core";
import React from "react";
import { green, red } from '@material-ui/core/colors';
import Button from "@material-ui/core/Button"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { submitComplaintStatus } from "../../../functions/Admin/Complaint/submitComplaintStatus";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";


const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: red,
  },
});


function ComplaintRow({ row, labelId,classes,  isItemSelected, isComplaintResolved,showAlertResolved ,isError}) {
  const [open, setOpen] = React.useState(false);
  const [resolve, setResolve] = React.useState('');

  const resolvedHandler = async () => {
    const res = await submitComplaintStatus(row.id, resolve);
    showAlertResolved();
    if (res.error === false){
      isComplaintResolved();
    }else {
      isError();
    }
  }
  const moreDetails = {
    state: row.state,
    description: row.description,
    userType: row.userType === "VIO" ? "VIO": "VOLUNTEER",
    email: row.user.email,
    createdDate: row.createdAt.substring(0,10),
    createdTime: row.createdAt.substring(11,19)
  }
  const moreDetailsSubject = {
    state: "Complaint State",
    description: "Description",
    userType: "Type of the user",
    email: "Email",
    createdDate: "Created Date",
    createdTime: "Created Time"
  }

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={labelId}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {row.userRegistrationNo}
        </TableCell>
        <TableCell align="left">
          {row.userName}
        </TableCell>
        <TableCell align="left">{row.title}</TableCell>
        { row.state !== "RESOLVED" &&
          <TableCell align='center'>
            <TextField id={row.id} name="resolve" value={resolve} onChange={(event)=> setResolve(event.target.value)} label="Action Taken"/>
          </TableCell>}
        { row.state !== "RESOLVED" &&
        <TableCell align='center'>
          <Button onClick={resolvedHandler} variant="contained" size="small" color="primary" style={{color: 'white', marginRight: '5px'}} textPrimary='white' className={classes.margin}>
            RESOLVE
          </Button>
        </TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details about the complaint
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{moreDetailsSubject.email}</TableCell>
                    <TableCell>{moreDetailsSubject.userType}</TableCell>
                    <TableCell>{moreDetailsSubject.state}</TableCell>
                    <TableCell>{moreDetailsSubject.description}</TableCell>
                    <TableCell>{moreDetailsSubject.createdDate}</TableCell>
                    <TableCell>{moreDetailsSubject.createdTime}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{moreDetails.email}</TableCell>
                    <TableCell>{moreDetails.userType}</TableCell>
                    <TableCell>{moreDetails.state}</TableCell>
                    <TableCell>{moreDetails.description}</TableCell>
                    <TableCell>{moreDetails.createdDate}</TableCell>
                    <TableCell>{moreDetails.createdTime}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ComplaintRow;
