import axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../../Constant";

const GETALLNEWS = `/api/news/admin/list`;
const UPDATENEWS = `/api/news/admin/archive`;
const UPDATEMESSAGE = `/api/news/admin`;
const CREATENEW = `/api/news/admin/create`;

export const fetchNews = async () => {
    const url = `${serverHost}:${serverPort}${GETALLNEWS}`;
    try{
        const response = await axios.get(url,
            {
                headers: {
                  token: localStorage.getItem(tokenKey),
                }
            }
            );
        if(response.status === 200) {
            return {
                item: response.data,
                error: false,
            }
        }
        else {
            return {
                item: response.data,
                error: true,
            }
        }
    } 
    catch(err) {
        return {
            item: err.message,
            error: true,
        }
    }
}

export const updateNews = async (id) => {
    const url = `${serverHost}:${serverPort}${UPDATENEWS}/${id}`;
    try{
        const response = await axios.put(url,null,
            {
                headers: {
                    token: localStorage.getItem(tokenKey),
                },
            }
        );
        
        if( response.status === 200) {
            return {
                item: response.data,
                error: false,
            }
        }
        else {
            return {
                item: null,
                error: true,
            }
        }
    } catch (err) {
        return {
            item: err.message,
            error: true,
        }
    }
}

export const updateMessageRequest = async (id, body) => {
    const url = `${serverHost}:${serverPort}${UPDATEMESSAGE}/${id}`;
    try{
        const response = await axios.put(url,body,
            {
                headers: {
                    token: localStorage.getItem(tokenKey),
                },
            }
        );
        
        if( response.status === 200) {
            return {
                item: response.data,
                error: false,
            }
        }
        else {
            return {
                item: null,
                error: true,
            }
        }
    } catch (err) {
        return {
            item: err.message,
            error: true,
        }
    }
}

export const createNewReqeust = async (body) => {
    const url = `${serverHost}:${serverPort}${CREATENEW}`;
    try{
        const response = await axios.post(url,body,
            {
                headers: {
                    token: localStorage.getItem(tokenKey),
                },
            }
        );
        
        if( response.status === 200) {
            return {
                item: response.data,
                error: false,
            }
        }
        else {
            return {
                item: null,
                error: true,
            }
        }
    } catch (err) {
        return {
            item: err.message,
            error: true,
        }
    }
}