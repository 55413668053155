import {
    ADD_REFEREE,
    ALL_ADMINS,
    CORE_ADMIN,
    CREATE_NON_FORMAL_VO,
    CREATE_SUB_VIOS,
    CREATE_VO,
    EMERGENCY_USER,
    ORG_VOLUNTEERS,
    PUBLIC,
    SUB_VIO_LIST,
    VIO_HOME,
    VIO_PROFILE,
    VIO_REPORTS,
    VOLUNTEER_HOME,
    VOLUNTEER_ORGANIZATIONS,
    VOLUNTEER_PROFILE,
    VOLUNTEER_REPORTS
} from "../containers/Router/Constants";

const menu = [{
        name: "Volunteer Register*",
        url: "/register",
    },
    {
        name: "Volunteer Profile",
        url: "/profile",
    },
    {
        name: "VIO Profile*",
        url: "/vioprofile",
    },
    {
        name: "VIO Approve Volunteers",
        url: "/vio/volunteerOpportunity",
    },
    {
        name: "Add Referee*",
        url: "/referee",
    },
    {
        name: "Referee Evaluate",
        url: "/refereeFeedback/1",
    },
    {
        name: "Verify Email*",
        url: "/verifyEmail/16678",
    },
    {
        name: "Vio Verify Email*",
        url: "/verifyVioEmail/:1",
    },
    {
        name: "Verify phone*",
        url: "/verifyPhone/aaa",
    },
    {
        name: "VIO Home*",
        url: "/vioHome",
    },
    {
        name: "Create Volunteer Opportunity*",
        url: "/createVO",
    },
    {
        name: "Volunteer Opportunity",
        url: "/viewOpportunity/1",
    },
    {
        name: "Volunteer Home*",
        url: "/volunteerHome",
    },
    {
        name: "My Activities*",
        url: "/opportunities/myOpportunities",
    },
    {
        name: "All Opportunities*",
        url: "/viewAllOpportunities",
    },
    {
        name: "Volunteer Agreements",
        url: "/volunteerAgreement/1/1/1",
    },
    {
        name: "Create SubVio*",
        url: "/createSubVio",
    },
    {
        name: "Vio Agreements",
        url: "/vioAgreement/1/1/1",
    },
    {
        name: "Volunteer Feedback",
        url: "/volunteerFeedback/1/1/1",
    },
    {
        name: "Vio Feedback",
        url: "/vioFeedback/1/1/1",
    },
    {
        name: "Admin Profile",
        url: "/admin/profile",
    },
    {
        name: "Admin Invite",
        url: "/admin/invite",
    },
    {
        name: "Admin List",
        url: "/admin/adminlist",
    },
    {
        name: "VIO Approval",
        url: "/admin/VIOApproval",
    },
    {
        name: "Admin CHangePW",
        url: "/admin/changepw",
    },
    {
        name: "Admin View Opportunities",
        url: "/admin/viewAllOpportunities",
    },
    {
        name: "Admin Approve Volunteers",
        url: "/admin/volunteerOpportunity",
    },
    {
        name: "View Sub Vios",
        url: "/viewSubVios",
    },
    {
        name: "View Sub Vio",
        url: "/viewSubVio/1",
    },
    {
        name: "View Organizational Volunteers",
        url: "/viewOrganizationalVolunteers",
    },
];

export const menus = (t, {refereeFilled}) => ({
    volunteers: [{
            name: t("MenuItems.volunteerProfile"),
            url: "/profile",
            access: VOLUNTEER_PROFILE
        },
        {
            name: refereeFilled ? t("Refree.View Referee") : t("MenuItems.addReferee"),
            url: refereeFilled ? "/viewReferee" : "/referee",
            access: refereeFilled ? VOLUNTEER_HOME : ADD_REFEREE
        },
        {
            name: t("MenuItems.volunteerHome"),
            url: "/volunteerHome",
            access: VOLUNTEER_HOME
        },
        {
            name: t("MenuItems.volunteerOrgs"),
            url: "/org-vios",
            access: VOLUNTEER_ORGANIZATIONS
        },{
            name: t("Reports.Reports"),
            url: "/volunteerReports",
            access: VOLUNTEER_REPORTS
        },
        {
            name: t("MenuItems.settings"),
            url: "/settings",
            access: PUBLIC
        },
        {
          name: t("MenuItems.complaint"),
          url: "/submitComplaint",
          access: VOLUNTEER_HOME
        },
    ],
    vio: [{
            name: t("MenuItems.vioProfile"),
            url: "/vioprofile",
            access: VIO_PROFILE
        },
        {
            name: t("MenuItems.vioHome"),
            url: "/vioHome",
            access: VIO_HOME
        },
        {
            name: t("MenuItems.createOpportunity"),
            url: "/createVO",
            access: CREATE_VO
        },
        {
            name: t("MenuItems.createNonFormlOpportunity"),
            url: "/createNonFormalVO",
            access: CREATE_NON_FORMAL_VO
        },
        {
            name: t("MenuItems.createSubVio"),
            url: "/createSubVio",
            access: CREATE_SUB_VIOS
        },
        {
            name: t("MenuItems.volunteers"),
            url: "/org-volunteers",
            access: ORG_VOLUNTEERS
        },
        {
            name: t("MenuItems.viewSubvios"),
            url: "/subVIOList",
            access: SUB_VIO_LIST
        },
        {
            name: t("MenuItems.viewNonFormalVolunteers"),
            url: "/nonFormalVOList",
            access: SUB_VIO_LIST
        },
        {
            name: t("Reports.Reports"),
            url: "/vioReports",
            access: VIO_REPORTS
        },
        {
            name: t("MenuItems.settings"),
            url: "/settings",
            access: PUBLIC
        },
        {
          name: t("MenuItems.complaint"),
          url: "/submitComplaint",
          access: VIO_HOME
        },
    ],
    admin: [
        {
            name: "Dashboard",
            url: "/admin/dashboard",
            access: ALL_ADMINS
        },
        {
            name: "Profile",
            url: "/admin/profile",
            access: ALL_ADMINS
        },
        {
            name: "Invite Admin",
            url: "/admin/invite",
            access: CORE_ADMIN
        },
        {
            name: "View Admins",
            url: "/admin/adminlist",
            access: CORE_ADMIN
        },
        {
            name: "View VIOs",
            url: "/admin/VIOlist",
            access: ALL_ADMINS
        },
        {
            name: "View Volunteers",
            url: "/admin/volunteerList",
            access: ALL_ADMINS
        },
        {
            name: "Approve VIO",
            url: "/admin/VIOApproval",
            access: ALL_ADMINS
        },
        {
            name: "Approve Non Formal Volunteer Opportunity",
            url: "/admin/NonFormalVOApproval",
            access: ALL_ADMINS
        },
        {
            name: "News",
            url: "/admin/news",
            access: ALL_ADMINS
        },
        {
            name: "View Reports",
            url: "/admin/reports",
            access: ALL_ADMINS
        },
        {
            name: "View Opportunities",
            url: "/admin/viewAllOpportunities",
            access: ALL_ADMINS
        },
        {
            name: "Approve Volunteers",
            url: "/admin/volunteerOpportunity",
            access: ALL_ADMINS
        },
        {
            name: "Create Emergency User",
            url: "/admin/createemguser",
            access: ALL_ADMINS
        },
        {
            name: "View Emergency Users",
            url: "/admin/emguserslist",
            access: ALL_ADMINS
        },
        {
            name: "Volunteer Story",
            url: "/admin/volunteerStory",
            access: ALL_ADMINS
        },
        {
            name: "Partners",
            url: "/admin/partners",
            access: ALL_ADMINS
        },
        {
            name: "Settings",
            url: "/settings",
            access: PUBLIC
        },
        {
            name: "Complaints",
            url: "/complaintList",
            access: ALL_ADMINS
        },
    ],
    emegencyUser: [{
            name: t("MenuItems.emergencyVolunteerHome"),
            url: "/emguserHome",
            access: EMERGENCY_USER
        },
        {
            name: t("MenuItems.emgCreateOpportunity"),
            url: "/createEmergencyVO",
            access: EMERGENCY_USER
        },
        {
            name: t("MenuItems.settings"),
            url: "/settings",
            access: EMERGENCY_USER
        },
    ],
});

export default menu;
