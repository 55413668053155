import {
    ONSEARCH
} from '../actiontypes';

const INITIAL_STATE = {

    search :"",
    type:"PENDING"
}

export default function OportunitySearchbarReducer(state = INITIAL_STATE, { type, payload }) {
	switch (type) {

		case ONSEARCH:
            return { ...state, search:payload.search?payload.search:state.search, type:payload.type? payload.type : state.type}
		default:
			return state
	}
}
