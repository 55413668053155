import {serverHost,serverPort,tokenKey} from "../../Constant";
import axios from 'axios';
import {viewAllUrl,removeUrl,addVolunteerUrl} from '../../containers/OrgVolunteers/Constants';



export const addOrgVolunteer = async (email) => {
    
    const fetchUrl = serverHost+":"+serverPort+addVolunteerUrl;
    try{
        const response = await axios.post(fetchUrl,{
            email
        },
        {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            } 
        });
        
        if (response.status == 200) {
            
            
                const result =  {
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                item:response.message,
                error:true
            }
            
            return result;
        }
    }
        
    catch(err){
        
        const result = {
            item:err.message,
            error:true
        }
        
        return result;
    } 
}


  export const fetchOrgVolunteers = async (searchValue) => {
    
    const fetchUrl = serverHost+":"+serverPort+viewAllUrl;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            },
            params: { 
                searchValue:searchValue
            } 
        });
        
        if (response.status == 200) {
            
            
                const result =  {
                    volunteers:response.data,
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                item:response.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            item:err.message,
            error:true
        }
        
        return result;
    } 
  
  
  };



  export const removeOrgVolunteer = async (volunteerId) => {
    
    const deleteVolunteerUrl = serverHost+":"+serverPort+removeUrl;
    try{
        const response = await axios.delete(deleteVolunteerUrl,
        {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            },
            params:{volunteerId} 
        });
        
        if (response.status == 200) {
            
            
                const result =  {
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                item:response.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            item:err.message,
            error:true
        }
        
        return result;
    } 
  
  
  };

  