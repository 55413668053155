import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../Constant";
import { fetchVolunteerReportsUrl } from '../../containers/Reports/Constants';


export const fetchVolunteerReports = async (reportType, reportBody) => {

    var body = {}
    switch (reportType) {
        case "DAILY":
            body = {
                type: "range",
                startDate: reportBody[0],
                endDate: reportBody[1]
            }
            break
        case 'QUARTERLY':
            body = {
                type: "quarterly",
                year: reportBody[1],
                quarter: reportBody[0]
            }
            break;
        case 'ANNUALLY':
            body = {
                type: "annually",
                year: reportBody
            }
            break;
        default:
            break
    }
    const fetchUrl = serverHost + ":" + serverPort + fetchVolunteerReportsUrl;
    try {
        const response = await axios.post(fetchUrl, body,
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },

            });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

