export const editFormat = (dataArray, indexArray,fieldName) => {
    if(indexArray==null)return []
    var ob1 = {}
    dataArray.forEach(({id, ...other}) => {
        (indexArray.includes(id.toString()) || indexArray.includes(id)) ? ob1[other[fieldName]] = true : ob1[other[fieldName]] = false
    })
    return ob1
}


export const editFormat2 = (dataArray, indexArray) => {
    if(indexArray==null)return []
    var ob1 = {}
    dataArray.forEach((data, ind) => {
        indexArray.includes(data.toString()) ? ob1[data] = true : ob1[data] = false
    })
    return ob1
}