export const CORE_ADMIN_BIT = 0;
export const GENERAL_ADMIN_BIT = 1;
export const EMERGENCY_LOGIN = 2;

export const GENERAL_VOLUNTEER_BIT = 3;
export const ORG_VOLUNTEER_BIT = 4;
export const SCL_VOLUNTEER_BIT = 5;

export const MOB_VIO_BIT = 6;
export const NMO_VIO_BIT = 7;

export const MOB_SVBVIO_BIT = 8;
export const NMO_SVBVIO_BIT = 9;

export const LOG_OUT_BIT = 10;

export const ADM_NOR_STA_BIT = 11;

export const EMERGENCY_LOGIN_STATUS = 12;

export const VOLUNTEER_MOB_UNVERIFIED_BIT = 13;
export const VOLUNTEER_BIO_INCOMPLETE_BIT = 14;
export const VOLUNTEER_REFEREE_UNVERIFIED_STATUS = 15;
export const VOLUNTEER_FULLY_COMPLETED_BIT = 16;

export const VIO_EMAIL_VERIFIED_BIT = 17;
export const VIO_PROFILE_COMPLETED_BIT = 18;
export const VIO_APPPROVED_COM_BIT = 19;

export const LOG_OUT_STA_BIT = 20;

/*

{
  'all': true // Allow all users
  'type': type bit should equal 1
  'and': All bits inside this should 1
  'or': one of bit should 1
  'not': should zero
}

*/

export const EMERGENCY_USER = {
    'type': [
        EMERGENCY_LOGIN,
        EMERGENCY_LOGIN_STATUS
    ]
}

const REFEREE_ADD = {
    'type': [
        GENERAL_VOLUNTEER_BIT,
        ORG_VOLUNTEER_BIT,
        SCL_VOLUNTEER_BIT
    ],
    'and': [
        VOLUNTEER_REFEREE_UNVERIFIED_STATUS
    ]
}

const APPROVED_VIO_AND_SUBVIO = {
    'type': [
        MOB_VIO_BIT,
        NMO_VIO_BIT,
        MOB_SVBVIO_BIT,
        NMO_SVBVIO_BIT
    ],
    'and': [
        VIO_APPPROVED_COM_BIT
    ]
};

const VIO_AND_SUBVIO = {
    'type': [
        MOB_VIO_BIT,
        NMO_VIO_BIT,
        MOB_SVBVIO_BIT,
        NMO_SVBVIO_BIT
    ]
};
const VIO_AND_SUBVIO_AND_VOLUNTEER = {
  'type': [
    MOB_VIO_BIT,
    NMO_VIO_BIT,
    MOB_SVBVIO_BIT,
    NMO_SVBVIO_BIT,
    GENERAL_VOLUNTEER_BIT,
    ORG_VOLUNTEER_BIT,
    SCL_VOLUNTEER_BIT
  ]
};

export const APPROVED_VIO = {
    'type': [
        MOB_VIO_BIT,
        NMO_VIO_BIT
    ],
    'and': [
        VIO_APPPROVED_COM_BIT
    ]
};

export const FULLY_COMPLETED_VOLUNTEER = {
    'type': [
        GENERAL_VOLUNTEER_BIT,
        ORG_VOLUNTEER_BIT,
        SCL_VOLUNTEER_BIT
    ],
    'and': [
        VOLUNTEER_FULLY_COMPLETED_BIT
    ]
}

const VOLUNTEER = {
    'type': [
        GENERAL_VOLUNTEER_BIT,
        ORG_VOLUNTEER_BIT,
        SCL_VOLUNTEER_BIT
    ]
};

export const LOGGED_OUT = {
    'and': [
        LOG_OUT_BIT,
        LOG_OUT_STA_BIT
    ]
};

export const ALL_ADMINS = {
    'type': [
        CORE_ADMIN_BIT,
        GENERAL_ADMIN_BIT
    ]
};

export const CORE_ADMIN = {
    'type': [
        CORE_ADMIN_BIT
    ]
};

export const ADD_REFEREE = REFEREE_ADD;

export const VOLUNTEER_HOME = VOLUNTEER;

export const USER_OPPORTUNITIES = FULLY_COMPLETED_VOLUNTEER;

export const VIO_AGREEMENT = FULLY_COMPLETED_VOLUNTEER;

export const VOLUNTEER_PROFILE = VOLUNTEER;

export const VIO_FEEDBACK = FULLY_COMPLETED_VOLUNTEER;

export const VOLUNTEER_ORGANIZATIONS = FULLY_COMPLETED_VOLUNTEER;

export const VOLUNTEER_REPORTS = FULLY_COMPLETED_VOLUNTEER;

export const ORGANIZATIONAL_VIOS = FULLY_COMPLETED_VOLUNTEER;

export const ORGANIZATIONAL_VOLUNTEERS = APPROVED_VIO_AND_SUBVIO;

export const VOLUNTEER_AGREEMENT = APPROVED_VIO_AND_SUBVIO;

export const VOLUNTEER_FEEDBACK = APPROVED_VIO_AND_SUBVIO;

export const CREATE_VO = APPROVED_VIO_AND_SUBVIO;

export const CREATE_NON_FORMAL_VO = APPROVED_VIO_AND_SUBVIO;

export const EDIT_VO = APPROVED_VIO_AND_SUBVIO;

export const EDIT_NON_FORMAL_VO = APPROVED_VIO_AND_SUBVIO;

export const CHANGE_VO_STATUS = APPROVED_VIO_AND_SUBVIO;

export const CREATE_SUB_VIOS = APPROVED_VIO;

export const VIEW_SUB_VIOS = APPROVED_VIO;

export const VIEW_SUB_VIO = APPROVED_VIO;

export const VIEW_ORG_VOLS = APPROVED_VIO_AND_SUBVIO;

export const VIO_HOME = VIO_AND_SUBVIO;

export const COMPLAINT = VIO_AND_SUBVIO_AND_VOLUNTEER;

export const SUB_VIO_LIST = APPROVED_VIO;

export const VIO_REPORTS = APPROVED_VIO;

export const VIO_PROFILE = VIO_AND_SUBVIO;

export const ORG_VOLUNTEERS = APPROVED_VIO_AND_SUBVIO;

export const SHARE_OPPORTUNITIES = APPROVED_VIO_AND_SUBVIO;

export const ACCEPT_SHARE_OPPORTUNITIES = APPROVED_VIO_AND_SUBVIO;

export const PUBLIC = {
    'all': true
}

export const LOGGED_USER = {
    'not': [
        LOG_OUT_BIT,
        LOG_OUT_STA_BIT
    ]
}
