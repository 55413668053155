
import { serverHost, serverPort, tokenKey } from "../../Constant";
import axios from 'axios';
import { assignSubVioUrl, unassignSubVioUrl, getOrgVolunteersUrl } from '../../containers/subvio/Constants';


export const changeSubVioFunc = async (volunteerId, subVioId, assigned) => {

    const updateUrl = serverHost + ":" + serverPort + (assigned ? assignSubVioUrl : unassignSubVioUrl);

    try {
        const response = await axios.post(updateUrl,
            {
                volunteerId: volunteerId,
                subVioId: subVioId
            },
            { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};

export const getOrgVolunteers = async (subVioId) => {

    const fetchUrl = serverHost + ":" + serverPort + getOrgVolunteersUrl + '/' + subVioId.toString();

    try {
        const response = await axios.get(fetchUrl,

            { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};