import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

function MainTitle({children}) {
    return (
        <Typography
            variant="h6"
            color="inherit"
            gutterBottom
            style={{
                fontWeight: "bold",
                backgroundColor: "#14334F",
                color: "white",
                padding: 10,
                textTransform: "uppercase",
            }}
        >
            {children}
        </Typography>
    );
}

function Content({children}) {
    return (
        <Box
            m={1}
            style={{
                padding: "10px 30px",
                color: "#14334F",
                fontFamily: "500",
                fontSize: "16px",
                lineHeight: "1.6",
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
}

function TextContent({children}) {
    return (
        <Box
            m={1}
            style={{
                padding: "10px 30px",
                textIndent: "30px",
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="p"
                        color="inherit"
                        gutterBottom
                        style={{color: "#14334F", fontweight: "500"}}
                    >
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

function SubSection({title, children}) {
    return (
        <div style={{paddingLeft: "60px", marginTop: "20px"}}>
            <Typography variant="h6" style={{fontSize: "16px", fontWeight: "bold"}}>
                {title}
            </Typography>
            {children}
        </div>
    );
}

function Section_TheProcess({t}) {
    return (
        <Grid
            style={{
                backgroundColor: "#E1EBFE",
                marginBottom: "30px",
                paddingBottom: "10px",
            }}
        >
            <MainTitle>{t("EngageWith.processTitle")}</MainTitle>

            <Content>
                {t("EngageWith.processHeaderDescription")}
                <SubSection title={t("EngageWith.volunteer")}>
                    <ul>
                        <li>{t("EngageWith.volunteerDesc1")}</li>
                        <li>
                            {t("EngageWith.volunteerDesc2")}
                        </li>
                        <li>
                            {t("EngageWith.volunteerDesc3")}
                        </li>
                        <li>{t("EngageWith.volunteerDesc4")}</li>
                        <li>
                            {t("EngageWith.volunteerDesc5")}
                        </li>
                        <li>
                            {t("EngageWith.volunteerDesc6")}
                        </li>
                        <li>
                            {t("EngageWith.volunteerDesc7")}
                        </li>
                    </ul>
                </SubSection>
                <SubSection title={t("EngageWith.vio")}>
                    <ul>
                        <li>{t("EngageWith.vioDesc1")}</li>
                        <li>{t("EngageWith.vioDesc2")}</li>
                        <li>{t("EngageWith.vioDesc3")}</li>
                        <li>{t("EngageWith.vioDesc4")}</li>
                        <li>{t("EngageWith.vioDesc5")}</li>
                        <li>{t("EngageWith.vioDesc6")}</li>
                    </ul>
                </SubSection>
                <SubSection title={t("EngageWith.posting")}>
                    <ul>
                        <li>{t("EngageWith.postingDesc1")}</li>
                        <li>{t("EngageWith.postingDesc2")}</li>
                        <li>{t("EngageWith.postingDesc3")}</li>
                        <li>{t("EngageWith.postingDesc4")}</li>
                        <li>{t("EngageWith.postingDesc5")}</li>
                        <li>{t("EngageWith.postingDesc6")}</li>
                        <li>{t("EngageWith.postingDesc7")}</li>
                        <li>{t("EngageWith.postingDesc8")}</li>
                        <li>{t("EngageWith.postingDesc9")}</li>
                    </ul>
                </SubSection>
                <SubSection title={t("EngageWith.sectionProcess")}>
                    <ul>
                        <li>{t("EngageWith.selectionDesc1")}</li>
                        <li>{t("EngageWith.selectionDesc2")}</li>
                        <li>{t("EngageWith.selectionDesc3")}</li>
                        <li>{t("EngageWith.selectionDesc4")}</li>
                        <li>{t("EngageWith.selectionDesc5")}</li>
                        <li>{t("EngageWith.selectionDesc6")}</li>
                        <li>{t("EngageWith.selectionDesc7")}</li>
                        <li>{t("EngageWith.selectionDesc8")}</li>
                    </ul>
                </SubSection>
                <SubSection title={t("EngageWith.contributing")}>
                    <ul>
                        <li>{t("EngageWith.contributionDesc1")}</li>
                        <li>{t("EngageWith.contributionDesc2")}</li>
                        <li>{t("EngageWith.contributionDesc3")}</li>
                        <li>{t("EngageWith.contributionDesc4")}</li>
                    </ul>
                </SubSection>
            </Content>
        </Grid>
    );
}

function ContactUs() {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Grid
                item
                xs={12}
                style={{
                    padding: "40px",
                    textAlign: "center",
                    backgroundColor: "#1d4971",
                    color: "white",
                    marginBottom: "50px",
                }}
            >
                <Typography variant="h4" style={{fontWeight: "600"}}>{t("EngageWith.mainTitle")}</Typography>
                <Typography variant="subtitle2">Home / Engage With Us</Typography>
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{marginBottom: 20, color: "#14334F"}}
            >
                <Grid item style={{maxWidth: "80%"}}>
                    <Section_TheProcess t={t}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ContactUs;
