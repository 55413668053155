import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import api from "../../../api";
import {readKeyGenerator, todayPieces, SP} from "../../../utils";

const bg1 = "#e1ebff";
const bg2 = "#e8f3fe"

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: bg1,
        margin: "50px 0",
        padding: "40px",
        width: "100%",
        color: theme.palette.primary.main
    },
    title: {
        textTransform: "uppercase",
        textAlign: "center",
        fontWeight: "600",
        margin: "0 0 20px 0"
    },
    subtitle: {
        textTransform: "uppercase",
        textAlign: "center",
        fontWeight: "600",
        margin: "20px 0 20px 0"
    },
    cardContainer: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    card: {
        backgroundColor: bg2,
        padding: "20px",
        margin: "10px 0"
    },
    cardTitle: {
        display: "block",
        fontSize: "20px",
        fontWeight: 500
    },
    cardSubtitle: {
        display: "block",
    },
    cardValue: {
        display: "block",
        fontSize: "20px",
        fontWeight: 500,
        width: "100%",
        textAlign: "right"
    },

}))

function StatCard(props) {
    const classes = useStyles()
    return (
        <Grid item xs={12} sm={5} className={classes.card}>
            <Typography variant="h4" className={classes.cardTitle}>{props.title}</Typography>
            <Typography variant="body1" className={classes.cardSubtitle}>{props.subtitle}</Typography>
            <Typography variant="h5" className={classes.cardValue}>{props.value}</Typography>
        </Grid>
    )
}

function StatCardLinear(props) {
    const classes = useStyles()
    return (
        <Grid
            item
            container
            xs={12}
            justify="space-between"
            className={classes.card}
        >
            <Grid
                item
                container
                xs={8}
                justify="space-between"
                className={classes.cardTitle}
            >
                <Typography variant="h6">{props.label}</Typography>
            </Grid>
            <Grid
                item
                xs={4}
                container
                justify="space-between"
                className={classes.cardValue}
            >
                <Typography variant="h6">{props.value}</Typography>
            </Grid>
        </Grid>
    )
}

function AdminDashboard() {
    // const {t} = useTranslation()
    const classes = useStyles()

    const [dashboardData, setDashboardData] = useState({})

    useEffect(() => {
        (async () => {
            const [status, data] = await api.report.self.admin()
            if (status === 200) {
                setDashboardData(data)
            }
        })()
    }, [])

    const today_statement = `on ${todayPieces.date} ${todayPieces.monthName} ${todayPieces.year}`
    const rk = readKeyGenerator(dashboardData)
    const rk_pending = readKeyGenerator(dashboardData.pending)
    const rk_hours = readKeyGenerator(dashboardData.volunteerHours)
    const rk_sdg = readKeyGenerator(dashboardData.volunteerHours && dashboardData.volunteerHours.sdgs)

    return (
        <React.Fragment>
            <Grid
                item
                container
                justify="space-around"
                xs={12}
                className={classes.mainContainer}
            >
                {/*     Title     */}
                <Grid
                    item
                    xs={12}
                >
                    <Typography variant="h4" className={classes.title}>NVS Administrator Dashboard</Typography>
                </Grid>

                {/*     Cards     */}
                <Grid
                    item
                    container
                    xs={12}
                    justify="space-between"
                    className={classes.cardContainer}
                >
                    <StatCard
                        title="Volunteer Registrations"
                        subtitle={today_statement}
                        value={rk('numVolunteersRegisteredByDay')}
                    />
                    <StatCard
                        title="Volunteer Registrations"
                        subtitle="Total"
                        value={rk('numVolunteersRegistered')}
                    />
                    <StatCard
                        title="VIO Registrations"
                        subtitle={today_statement}
                        value={rk('numViosRegisteredByDay')}
                    />
                    <StatCard
                        title="VIO Registrations"
                        subtitle="Total"
                        value={rk('numViosRegistered')}
                    />
                </Grid>


                <StatCardLinear
                    label="Total number of Volunteers mobilized"
                    value={rk('numVolunteersMobilized')}
                />
                <StatCardLinear
                    label="Total number of Volunteers registered for Emergency Response"
                    value={rk('numEmergencyVolunteer')}
                />
                <StatCardLinear
                    label="Complaints"
                    value={rk('numComplaints')}
                />

                <StatCardLinear
                    label="Pending VIO Approvals"
                    value={rk_pending('pendingVio')}
                />
                <StatCardLinear
                    label="Pending Opportunity Approvals"
                    value={rk_pending('numPendingOpportunities')}
                />
                <StatCardLinear
                    label="Pending Volunteer Sections"
                    value={rk_pending('numOfPendingVolunteer')}
                />
                <StatCardLinear
                    label="Pending Non Formal Volunteer Opportunity Approvals"
                    value={rk_pending('numPendingNonformalOpportunity')}
                />
                <StatCardLinear
                    label="Value of Volunteer Contribution"
                    value={SP.LKR(rk_hours('value'))}
                />


                <Grid
                    item
                    xs={12}
                >
                    <Typography variant="h5" className={classes.subtitle}>Contribution Sustainable Development</Typography>
                </Grid>

                <StatCardLinear
                    label="SDG 01 - No Poverty"
                    value={SP.HOURS(rk_sdg("1"))}
                />
                <StatCardLinear
                    label="SDG 02 - Zero Hunger"
                    value={SP.HOURS(rk_sdg("2"))}
                />
                <StatCardLinear
                    label="SDG 03 - Good Health and Well Being"
                    value={SP.HOURS(rk_sdg("3"))}
                />
                <StatCardLinear
                    label="SDG 04 - Quality Education"
                    value={SP.HOURS(rk_sdg("4"))}
                />
                <StatCardLinear
                    label="SDG 05 - Gender Equality"
                    value={SP.HOURS(rk_sdg("5"))}
                />
                <StatCardLinear
                    label="SDG 06 - Clean Water and Sanitation"
                    value={SP.HOURS(rk_sdg("6"))}
                />
                <StatCardLinear
                    label="SDG 07 - Affordable and Clean Energy"
                    value={SP.HOURS(rk_sdg("7"))}
                />
                <StatCardLinear
                    label="SDG 08 - Decent Work and Economic Growth"
                    value={SP.HOURS(rk_sdg("8"))}
                />
                <StatCardLinear
                    label="SDG 09 - Industry Innovation and Infrastructure"
                    value={SP.HOURS(rk_sdg("9"))}
                />
                <StatCardLinear
                    label="SDG 10 - Reduced Inequalities"
                    value={SP.HOURS(rk_sdg("10"))}
                />
                <StatCardLinear
                    label="SDG 11 - Sustainable Cities and Communities"
                    value={SP.HOURS(rk_sdg("11"))}
                />
                <StatCardLinear
                    label="SDG 12 - Responsible Consumption and Production"
                    value={SP.HOURS(rk_sdg("12"))}
                />
                <StatCardLinear
                    label="SDG 13 - Climate Action"
                    value={SP.HOURS(rk_sdg("13"))}
                />
                <StatCardLinear
                    label="SDG 14 - Life below Water"
                    value={SP.HOURS(rk_sdg("14"))}
                />
                <StatCardLinear
                    label="SDG 15 - Life on Land"
                    value={SP.HOURS(rk_sdg("15"))}
                />
                <StatCardLinear
                    label="SDG 16 -  Peace, Justice and String Institutions"
                    value={SP.HOURS(rk_sdg("16"))}
                />
                <StatCardLinear
                    label="SDG 17 - Partnerships for Goals"
                    value={SP.HOURS(rk_sdg("17"))}
                />
                <StatCardLinear
                    label="TOTAL VOLUNTEER CONTRIBUTION"
                    value={SP.HOURS(rk_hours("total"))}
                />
            </Grid>
        </React.Fragment>
    );
}

export default AdminDashboard;