export const editSubmitFormat = (data,mapArr) => {
    const arr1 = Array.from(Array(Object.values(data).length).keys()).filter((item, index) => !!Object.values(data)[index])
    var arr2 = []
    arr1.forEach((index)=>arr2.push(mapArr[index]))
    return arr2
}

export const editSubmitFormat2 = (data,mapArr) => {
    const arr1 = Array.from(Array(Object.values(data).length).keys()).filter((item, index) => !!Object.values(data)[index])
    var arr2 = []
    arr1.forEach((index)=>arr2.push(mapArr[index].id))
    return arr2
}