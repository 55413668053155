import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ReactHookFormSelect from './ReactHookFormSelect';
import { useForm } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from "./CustomButton";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      marginTop: 12
    },
    button: {
      padding: '15px 20px'
    }
  }));

function DropdownList({menuItemList,isSearch=true,onSearch}) {
    const classes = useStyles();
  const { register, handleSubmit, control } = useForm();
  return (
    <form onSubmit={handleSubmit(onSearch)} >
      <Grid container container direction="row" justify="flex-end">

        {menuItemList.map((item,i)=>(
            <Grid key={i.toString()} item>
                <ReactHookFormSelect
                    className={classes.select}
                    label={item.label}
                    name={item.name}
                    control={control}
                    variant="outlined"
                    defaultValue={item.defaultType}>

                    {item.list.map((element,j)=>(
                            <MenuItem key = {j.toString()} value={element.value}>{element.value}</MenuItem>               
                    ))}

                </ReactHookFormSelect>
            </Grid>


        ))}
        {isSearch?
        <Grid item>
          <CustomButton label="search" type="submit" margin="12px 0" variant="contained" className={classes.button} />
        </Grid>
        :
        ""
}

    </Grid>
    </form>
    )
}

export default DropdownList
