import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import VIORow from "./VIORow";
import { fetchVioListByAdmin } from "../../../functions/Admin/FetchData/fetchData";
import { Grid, InputLabel, Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

function createData(
  id,
  name,
  email,
  status,
  type,
  description,
  cardImage,
  parentVio
) {
  return { id, name, email, status, type, description, cardImage, parentVio };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "vio name", numeric: false, disablePadding: true, label: "VIO Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    handleFilterChange,
    filterType,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#e1ebff",
    padding: 12,
    borderRadius: 4,
    margin: "16px 0",
  },
  table: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 146px)",
      overflowX: "auto",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

//Funcstion Exported
function VIOTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("department");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [rows, setRows] = React.useState([]);
  const [filterType, setFilterType] = React.useState("ALL");

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  useEffect(() => {
    if (filterType == "ALL") {
      setRowsPerPage(5);
    } else {
      setRowsPerPage(rows.length);
    }
  }, [filterType]);

  useEffect(() => {
    const fetchData = async () => {
      let result = await fetchVioListByAdmin();
      let resultList = [];
      result.item.map((result) => {
        // 
        resultList.push(
          createData(
            result.id,
            result.name,
            result.email,
            result.status,
            result.type,
            result.description,
            result.cardImage,
            result.parentVio
          )
        );
      });
      
      setRows(resultList);
    };
    fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Paper
      style={{
        padding: "18px 36px",
        margin: "auto",
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item style={{ padding: "20px 0" }}>
          <Typography variant="h5" align="center" color="primary">
            VIO Profiles
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-end">
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Register Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={filterType}
              variant="outlined"
              onChange={(e) => handleFilterChange(e.target.value)}
              label="Register Status"
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              <MenuItem value={"REGISTERED"}>Registered</MenuItem>
              <MenuItem value={"EMAILVERIFIED"}>Email Verified</MenuItem>
              <MenuItem value={"PROFILECOMPLETED"}>Profile Completed</MenuItem>
              <MenuItem value={"APPROVED"}>Approved</MenuItem>
              <MenuItem value={"REJECTED"}>Rejected</MenuItem>
              <MenuItem value={"TRANSFERRED"}>Transferred</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.paper}>
          <TableContainer className={classes.table} component={Paper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                handleFilterChange={handleFilterChange}
                filterType={filterType}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((row) => {
                    if (filterType == "ALL") {
                      return row;
                    } else if (filterType) {
                      return row.status == filterType;
                    } else {
                      return row;
                    }
                  })
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return <VIORow labelId={labelId} row={row} />;
                  })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </Grid>
    </Paper>
  );
}

export default VIOTable;
