import React from 'react'
import RateComponent from '../../components/RateComponent';
import { Grid } from '@material-ui/core';

function StarFeedback({onChange,value,name,showCount=false,edit=true}) {

    const onRate = (rate)=>{
        onChange(name,rate)
    }


    return (
        <Grid container item md={6} xs={12}>
            <RateComponent
            onChange={onRate}
            value = {value}
            showCount = {showCount}
            edit={edit}
            />
        </Grid>
    )
}

export default StarFeedback
