import { Grid, TextField, Button, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { serverHost } from "../../Constant";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useForm } from "react-hook-form";
import Paper from "@material-ui/core/Paper";
import { Route } from "react-router-dom";

function AdminVerify() {
  const { register, errors, handleSubmit, watch } = useForm({});
  const [state, setState] = useState("");
  let { token } = useParams();
  const newPassword = useRef({});

  const [email, setEmail] = useState("");

  newPassword.current = watch("newPassword", "");

  useEffect(() => {
    const getEmailUrl = serverHost + "/api/admin/verify/" + token;
    Axios.get(getEmailUrl)
      .then((res) => {
        if (res.status == 200) {
          setEmail(res.data.email);

        } else if (res.status == 400) {
          alert("Invalid !!!");

        }
      })
      .catch((err) => {
        alert("Invalid !!!");

      });
  }, []);

  const onSubmit = (data, history) => {
    const adminRegUrl = serverHost + "/api/admin/register";
    Axios.post(adminRegUrl, {
      token: token,
      name: data.name,
      password: data.newPassword,
    })
      .then((res) => {
        if (res.status == 200) {

          alert("Registerer succesfully!");
          history.push("/");
        } else if (res.status == 400) {

          alert("Error !!!");
        }
      })
      .catch((err) => {

        alert("Error !!!");
      });
  };

  return (
    <Route
      render={({ history }) => (
        <Paper style={{ padding: '18px 36px', maxWidth: 420, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
          <form onSubmit={handleSubmit((data, history) => onSubmit(data))}>
            <Grid
              container
              justify="center"
            >
              <Grid item xs={12} style={{ padding: '20px 0' }}>
                <Typography variant="h5" color="primary" align="center">Register as an Admin</Typography>
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  id="outlined-search"
                  label="Name*"
                  type="search"
                  variant="outlined"
                  name="name"
                  inputRef={register({
                    required: "Name is required",
                  })}
                  helperText={
                    <span style={{ color: "red" }}>
                      {errors.name && <p>{errors.name.message}</p>}
                    </span>
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  disabled
                  id="outlined-disabled"
                  label="Email"
                  value={email}
                  variant="outlined"
                  name="email"
                  inputRef={register({
                    required: "Email is empty!",
                  })}
                  helperText={
                    <span style={{ color: "red" }}>
                      {errors.email && <p>{errors.email.message}</p>}
                    </span>
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  type="password"
                  inputRef={register({
                    required: "You must specify a password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                  label="New Password*"
                  id="outlined-search"
                  margin="normal"
                  variant="outlined"
                  helperText={
                    <span style={{ color: "red" }}>
                      {errors.newPassword && (
                        <p>{errors.newPassword.message}</p>
                      )}
                    </span>
                  }
                  name="newPassword"
                />
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  name="password_repeat"
                  type="password"
                  inputRef={register({
                    validate: (value) =>
                      value === newPassword.current ||
                      "The passwords do not match",
                  })}
                  label="Verify New Password*"
                  id="outlined-search"
                  margin="normal"
                  variant="outlined"
                  helperText={
                    <span style={{ color: "red" }}>
                      {errors.password_repeat && (
                        <p>{errors.password_repeat.message}</p>
                      )}
                    </span>
                  }
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Register
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    />
  );
}

export default AdminVerify;
