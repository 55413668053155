import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import PrivateCompanyProfileComplete from "../../components/VIOProfile/PrivateCompany/PrivateCompanyProfileComplete";
import StateInstitution from '../../components/VIOProfile/StateInstitution/StateInstitution';
import StateAcademicInstitution from '../../components/VIOProfile/StateAcademicInstitution/StateAcademicInstitution';
import PrivateAcademicInstitution from '../../components/VIOProfile/PrivateAcademicInstitution/PrivateAcademicInstitution';
import NONPROFIT from '../../components/VIOProfile/NONPROFIT/NONPROFIT';
import School from '../../components/VIOProfile/School/School';
import { fetchVIOProfile } from '../../functions/VIO/VIOProfile/fetchVIOProfile';
import { saveVIOProfile } from '../../functions/VIO/VIOProfile/saveVIOProfile';
import { vioTypes } from "../VIO/FormConstants";
import MessageCard from "../../components/MessageCard";
import LoadingPage from "../../components/LoadingPage";
import { Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { tokenKey } from "../../Constant";
import * as AuthActions from "../../actions/AuthActions";
import { check } from '../../helper/route_helper';
import {APPROVED_VIO} from "../../containers/Router/Constants";
import FormLanguageAlert from "../../components/FormLanguageAlert";

export default function VIOProfilePage() {

  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [fetchError, setFetchError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);
  const access = useSelector(state => state.auth.access)

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  const vioType = useSelector(state => state.auth.type);

  React.useEffect(async () => {

    let mounted = true;

    let result = await fetchVIOProfile();
    result.item.operationCommencedDate = new Date(result.item.operationCommencedDate || new Date());
    console.log(result.item);
    if (mounted) {
      if (result.error) {
        setFetchError(true);
      }
      else {
        setFetchDetails({
          // profileType: "STATEINSTITUTE",
          ...result.item
        });
        setFetchError(false);
      }
    }

    // Until api connect
    // setFetchDetails({
    //   profileType: "STATEINSTITUTE",
    //   profileDetails: {}
    // })
    // setFetchError(false)

    //
    return () => mounted = false;
  }, [fetchError])

  const resetToken = async (token) => await dispatch(AuthActions.resetToken(token));

  const saveProfile = async (profileDetails) => {
    localStorage.removeItem("PROFILE_INCOMPLETE");
    let result = await saveVIOProfile(profileDetails, vioType);
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      localStorage.setItem(tokenKey, result.token);
      await resetToken(result.token);
      check(APPROVED_VIO, access, "") ? setErrorMsg("Successfully Updated!") : setErrorMsg("Successfully Updated!.Please wait until admin approves your profile.")
      setIsErrorVariant(false)
      setPushError(!pushError)
    }
  }

  if (fetchError) return <MessageCard />;
  if (!fetchDetails) return (<LoadingPage />);

  switch (vioType) {
    case "STATEINSTITUTE":
      return (
        <React.Fragment>
          <FormLanguageAlert/>
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
            <FormLanguageAlert/>
            <StateInstitution
              onSubmit={saveProfile}
              fetchDetails={fetchDetails}
              vioType={vioType}
            />
          </Paper>
        </React.Fragment>
      )
    case "PRIVATEORGANIZATION":
      return (
        <React.Fragment>
          <FormLanguageAlert/>
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
            <PrivateCompanyProfileComplete
              onSubmit={saveProfile}
              fetchDetails={fetchDetails}
              vioType={vioType}
            />
          </Paper>
        </React.Fragment>
      )
    case "STATEACADEMIA":
      return (
          <React.Fragment>
            <FormLanguageAlert/>
            <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
              <StateAcademicInstitution
                onSubmit={saveProfile}
                fetchDetails={fetchDetails}
                vioType={vioType}
              />
            </Paper>
          </React.Fragment>
      )
    case "PRIVATEACADEMIA":
      return (
       <React.Fragment>
         <FormLanguageAlert/>
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
            <PrivateAcademicInstitution
              onSubmit={saveProfile}
              fetchDetails={fetchDetails}
              vioType={vioType}
            />
          </Paper>
       </React.Fragment>
      )
    case "NONPROFIT":
      return (
        <React.Fragment>
          <FormLanguageAlert/>
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
            <NONPROFIT
              onSubmit={saveProfile}
              fetchDetails={fetchDetails}
              vioType={vioType}
            />
          </Paper>
        </React.Fragment>
      )
    case "SCHOOL":
      return (
       <React.Fragment>
         <FormLanguageAlert/>
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
            <School
              onSubmit={saveProfile}
              fetchDetails={fetchDetails}
              vioType={vioType}
            />
          </Paper>
       </React.Fragment>
      )
    default:
      return (<MessageCard />);
  }
}
