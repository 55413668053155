import { Checkbox, Divider, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import { Controller, useForm } from "react-hook-form";
import CustomButton from '../../components/CustomButton';
import ReactHookFormSelect from '../../components/ReactHookFormSelect';
import { getVolunteerData, postResult } from '../../functions/Referee/apiCalls';
import { starComponents } from './Constants';
import DetailsSection from './DetailsSection';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StarFeedback from './StarFeedback';
import EndPage from './EndPage';
import { useHistory } from "react-router-dom";
import MessageCard from '../../components/MessageCard';
import { useTranslation } from 'react-i18next';


function RefereeFeedback({ match }) {

    const token = match.params.token;
    const { register, handleSubmit, control, errors } = useForm();
    const [error, setError] = useState(false);
    const [checked, setChecked] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [volunteerDetails, setVolunteerDetails] = useState({});
    const [formEntry, set] = useState({
        accurate: false,
        reliability: 0,
        flexibility: 0,
        professionalism: 0,
        workEthic: 0,
        qualityOfWork: 0,
        timeManagement: 0,
        communicationSkills: 0,
        responsibility: 0,
        teamwork: 0,
        commitment: 0
    });
    const { enqueueSnackbar } = useSnackbar();
    const {t} = useTranslation()

    const onTick = () => {
        setChecked(!checked)
    }

    let history = useHistory();

    const createDate = () => {
        const date = new Date();
        const month = date.getMonth() + 1;
        return date.getFullYear() + "-" + month + "-" + date.getDate();
    }
    const onSubmit = async (data) => {
        
        const response = await postResult({ ...data, ...formEntry }, token)
        if (response.error) {
            const  errorMsg = response.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(response.message)
            enqueueSnackbar(errorMsg, {
                variant: 'error',
                persist: false
            })
        }
        else {
            enqueueSnackbar("Successfully Reviewed!", {
                variant: 'success',
                persist: false
            })
            history.push("/")
        }
    }
    const onChange = async (name, rate) => {
        set({ ...formEntry, [name]: rate })
    }
    useEffect(async () => {
        const setData = async () => {
            const result = await getVolunteerData(token);
            if (result.error) {
                setError(result.error);
                setErrMessage(result.message);
            }
            else {
                setError(result.error);
                setVolunteerDetails(result.volunteer);
                
            }
        }
        await setData();
    }, [])

    return (
        ! error ?
            volunteerDetails.name ?
                    <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
                        <Typography variant="h6" style={{ margin: '18px 0' }}>{t('RefreeFeedback.sentence1')}</Typography>
                        <Grid container spacing={1}>
                            <DetailsSection
                                volunteer={volunteerDetails}
                            />
                        </Grid>
                        <Divider style={{ margin: '20px 0' }} />
                        <form onSubmit={handleSubmit(async (data) => await onSubmit({ ...data }))}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                        {t('RefreeFeedback.sentence2')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <ReactHookFormSelect
                                        label={t('RefreeFeedback.years')}
                                        name="durationYears"
                                        control={control}
                                        variant="outlined"
                                        defaultValue={0}
                                        fullWidth
                                    >
                                        <MenuItem key={0} value={0}>{0}</MenuItem>
                                        <MenuItem key={1} value={1}>{1}</MenuItem>
                                        <MenuItem key={2} value={2}>{2}</MenuItem>
                                        <MenuItem key={3} value={3}>{3}</MenuItem>
                                        <MenuItem key={4} value={4}>{4}</MenuItem>
                                        <MenuItem key={5} value={5}>{5}</MenuItem>
                                        <MenuItem key={"5+"} value={"5+"}>{t("RefreeFeedback.More than 5 years")}</MenuItem>
                                    </ReactHookFormSelect>

                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <ReactHookFormSelect
                                        label={t('RefreeFeedback.months')}
                                        name="durationMonths"
                                        control={control}
                                        variant="outlined"
                                        defaultValue={0}
                                        fullWidth
                                    >
                                        <MenuItem key={0} value={0}>{0}</MenuItem>
                                        <MenuItem key={1} value={1}>{1}</MenuItem>
                                        <MenuItem key={2} value={2}>{2}</MenuItem>
                                        <MenuItem key={3} value={3}>{3}</MenuItem>
                                        <MenuItem key={4} value={4}>{4}</MenuItem>
                                        <MenuItem key={5} value={5}>{5}</MenuItem>
                                        <MenuItem key={6} value={6}>{6}</MenuItem>
                                        <MenuItem key={7} value={7}>{7}</MenuItem>
                                        <MenuItem key={8} value={8}>{8}</MenuItem>
                                        <MenuItem key={9} value={9}>{9}</MenuItem>
                                        <MenuItem key={10} value={10}>{10}</MenuItem>
                                        <MenuItem key={11} value={11}>{11}</MenuItem>

                                    </ReactHookFormSelect>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {t('RefreeFeedback.sentence3')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <ReactHookFormSelect
                                        name="relation"
                                        control={control}
                                        variant="outlined"
                                        defaultValue={"EMPLOYEE"}
                                        fullWidth
                                    >
                                        <MenuItem key={"employer"} value={"EMPLOYER"}>{t('RefreeFeedback.employer')}</MenuItem>
                                        <MenuItem key={"employee"} value={"EMPLOYEE"}>{t('RefreeFeedback.employee')}</MenuItem>
                                        <MenuItem key={"friend"} value={"FRIEND"}>{t('RefreeFeedback.friend')}</MenuItem>
                                        <MenuItem key={"relative"} value={"RELATIVE"}>{t('RefreeFeedback.relative')}</MenuItem>
                                        <MenuItem key={"family"} value={"FAMILY_MEMBER"}>{t('RefreeFeedback.family')}</MenuItem>
                                        <MenuItem key={"student"} value={"STUDENT"}>{t('RefreeFeedback.student')}</MenuItem>

                                    </ReactHookFormSelect>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {t('RefreeFeedback.sentence4')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        as={
                                            <RadioGroup aria-label={t('RefreeFeedback.sentence4')}>
                                                <FormControlLabel
                                                    value={true}
                                                    control={<Radio />}
                                                    checked={formEntry.accurate}
                                                    label={t("Common.yes")}
                                                    onClick={() => { set({ ...formEntry, accurate: true }) }}
                                                />
                                                <FormControlLabel
                                                    value={false}
                                                    control={<Radio />}
                                                    checked={!formEntry.accurate}
                                                    label={t("Common.no")}
                                                    onClick={() => { set({ ...formEntry, accurate: false }) }}
                                                />
                                            </RadioGroup>
                                        }
                                        name="accurate"
                                        control={control}
                                        register={register({ required: true })}
                                        value={formEntry.accurate}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {t('RefreeFeedback.sentence5')}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    {
                                        starComponents.map((item, i) => (
                                            <React.Fragment key={i.toString()}>
                                                <StarFeedback
                                                    key={i.toString()}
                                                    name={item.nameValue}
                                                    value={t(`RefreeFeedback.${item.title}`)}
                                                    onChange={onChange}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {t('RefreeFeedback.sentence6')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        inputRef={register({ required: false })}
                                        name="otherInformation"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={onTick}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    } label={t('RefreeFeedback.sentence7')} />
                                    <Typography variant="body2">
                                        {t('RefreeFeedback.sentence8')}: {createDate()}
                                    </Typography>
                                </Grid>
                                <Grid>
                                </Grid>
                                <div>
                                    <CustomButton label={t("Common.submit")} type={"submit"} disabled={!checked} />
                                </div>
                            </Grid>
                        </form>
                    </Paper>
                :
                ""
                :
            <MessageCard message = {errMessage} />
    )
}

export default RefereeFeedback

