import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { submitCompleteVolunteerUrl } from '../../../containers/EmergencyUser/Constants';

export const submitCompleteVolunteer = async (voID,registrationNo) => {

    const saveUrl = serverHost + ":" + serverPort + submitCompleteVolunteerUrl;
    try {
        const response = await axios.post(saveUrl,
            {
                emergencyOppId:voID,
                registrationNo:registrationNo
            },
            {headers: {'token': localStorage.getItem(tokenKey)}});
  
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

