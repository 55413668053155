import {
  TableCell,
  TableRow,
  Checkbox,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Route } from "react-router-dom";

function VolunteerRow({ labelId, row, isItemSelected }) {
  return (
    <Route
      render={({ history }) => (
        <TableRow
          hover
          //   onClick={(event) => handleClick(event, row.name)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.firstName}
          selected={isItemSelected}
        >
          <TableCell component="th" id={labelId} scope="row" padding="none">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item style={{ marginLeft: 16 }}>{row.firstName + " " + row.lastName}</Grid>
              <Grid item style={{ marginLeft: 10 }}>
                {row.status == "Verified" && (
                  <Tooltip title="Verified" placement="top">
                    <VerifiedUserIcon fontSize="small" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>
            {row.type.charAt(0) +
              row.type
                .toLowerCase()
                .replace(/_/g, " ")
                .slice(1)}
          </TableCell>
          <TableCell>
            {row.registerStatus.charAt(0) +
              row.registerStatus
                .toLowerCase()
                .replace(/_/g, " ")
                .slice(1)}
          </TableCell>
          <TableCell>
            <Tooltip title="Show More" placement="top">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  history.push("/admin/volunteer/" + row.id.toString());
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
    />
  );
}

export default VolunteerRow;
