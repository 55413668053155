import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../Constant";
import { saveVolunteerProfileUrl } from '../../containers/VolunteerProfile/Constants';

export const saveVolunteerProfile = async (profileDetails) => {

    const saveUrl = serverHost + ":" + serverPort + saveVolunteerProfileUrl;
    try {
        const response = await axios.post(saveUrl, { ...profileDetails }, { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status == 200) {
            
            const result = {
                item: response.data,
                token: response.data.token,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

