import React from 'react'
import CustomButton from '../../components/CustomButton'
import { useHistory } from "react-router-dom";
import { tokenKey } from '../../Constant';
import * as AuthActions from "../../actions/AuthActions";
import {useDispatch } from 'react-redux'


function LogoutButton({label}) {

    let history = useHistory();

    const dispatch = useDispatch();
    const clearStore = async() => await dispatch(AuthActions.clearStore());

    const onClick = async() =>{
        localStorage.removeItem(tokenKey);
        await clearStore();
        history.push("/");
    }

    return (
        <CustomButton
            onClick = {onClick}
            label = {label}
        />
    )
}

export default LogoutButton
