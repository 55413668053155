import React from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Paper } from "@material-ui/core";
import MessageCard from '../../../components/MessageCard';
import AddPartner from '../../../components/Admin/Partner/AddPartner';
import { submitAddPartner } from '../../../functions/Admin/Partner/submitAddPartner';

export default function AddPartnerPage() {

  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  const addPartnerFunc = async (details) => {
    var formData = { ...details }
    delete formData.history
    let result = await submitAddPartner(formData);
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Added!")
      setPushError(!pushError)
      details.history.push("/admin/partners")
    }
  }

  return (
    <React.Fragment>
      {
        <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40, marginBottom: 40 }}>
          <AddPartner
            onSubmit={addPartnerFunc}
          />
        </Paper>
      }
    </React.Fragment>

  );
}