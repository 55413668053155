import { Paper, Typography, Button, Grid } from '@material-ui/core'
import { ErrorOutline, CheckCircle } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
function MessageCard({ message, error = true, page404 = false, label, ...props }) {
    const history = useHistory()
    const {t} = useTranslation()

    if (page404) return (
        <div
        style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: '100%',
        height: '90vh',
        top: '5%',
        right: 10,
        zIndex: 1,
        position: 'absolute',
        backgroundImage: `url("/404-image.jpg")`
            }}>
        </div>
    )
    return (
        <Grid container direction="column" alignItems="center" spacing={2} style={{ margin: 'auto', padding: '20px 18px', marginTop: 40 }}>
            <Grid item>
                {error ?
                    <img src="/error.png" style={{ color: 'red', maxWidth: 200, margin: '0 8px' }} /> :
                    <img src="/success.png" style={{ color: 'red', maxWidth: 200, margin: '0 8px' }} />}
            </Grid>
            <Grid item>
                <Typography color="textSecondary" style={{ fontSize: '3rem', paddingLeft: 20, textAlign: 'center' }}>
                    {message ?? t('Common.error')}
                </Typography>
            </Grid>
            <Grid item>
                {error && <Button color="primary" variant="contained" onClick={() => history.goBack()}>{t('Common.goBack')}</Button>}
                {!error && label && <div style={{ direction: 'rtl' }}>
                    <Button {...props}>{label}</Button>
                </div>}
            </Grid>
        </Grid>
    )
}

export default MessageCard
