import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from '@material-ui/core'
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useEffect } from "react";
import IconTextField from '../IconTextField'
import CustomButton from '../CustomButton'
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import ReactHookFormSelect from '../ReactHookFormSelect';
import MenuItem from '@material-ui/core/MenuItem';
import { vioTypes } from '../../containers/VIO/FormConstants';
import {submitVIORegister} from "../../functions/VIO/submitVIORegister";
import {submitVolunteerRegister} from "../../functions/Volunteer/submitVolunteerRegister";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
// const regexPhoneNumber = /^(?:7|0|(?:\+94))[0-9]{9,10}$/

const schema = yup.object().shape({

  name: yup.string().required('Required').max(100, 'Must be at most 100 characters'),
  email: yup.string().email('Email must be a valid email').required('Required'),
  // phone: yup.string().matches(regexPhoneNumber, 'Phone number is not valid').required('Required'),
  password: yup.string().required('Required').min(8, 'Password must be at least 8 characters long'),
  // Retype password should be equal to password
  retypePassword: yup
    .string()
    .oneOf([yup.ref('password'), null, ''], 'Passwords must match')
    .required('Required'),

  type: yup.string().required('Required'),
  // description: yup.string().required('Required').max(300, 'Must be at most 300 characters'),

});

export default function VIOForm({ success, message, t }) {

  const { register, errors, control, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });
  // const onSubmit = data => 

  const [formDisabled, setFormDisabled] = React.useState(false);
  const [VisibilityPw, setVisibilityPw] = React.useState(false);
  const [VisibilityPwRe, setVisibilityPwRe] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  const submitRegisterFunction = async (registerData) => {
      
    let result = await submitVIORegister( {...registerData, description: registerData.name} );
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setErrorMsg("you are successfully registered, please check your inbox or spam folders for the verification email!")
      setIsErrorVariant(false)
      setPushError(!pushError)
      setFormDisabled(true)
    }
  }
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(submitRegisterFunction)}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.orgName")}
                inputRef={register()}
                icon={<AccountCircleIcon />}
                name="name"
                fullWidth
                disabled={formDisabled}
                error={errors.name}
                helperText={errors.name?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.email")}
                inputRef={register()}
                icon={<EmailIcon />}
                name="email"
                fullWidth
                disabled={formDisabled}
                error={errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.password")}
                inputRef={register()}
                type={VisibilityPw? "text":"password"}
                icon={VisibilityPw? <VisibilityOffIcon onClick={()=> setVisibilityPw(false)}/>:<VisibilityIcon onClick={()=> setVisibilityPw(true)}/>}
                name="password"
                fullWidth
                disabled={formDisabled}
                error={errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.retypePassword")}
                inputRef={register()}
                type={VisibilityPwRe? "text":"password"}
                icon={VisibilityPwRe? <VisibilityOffIcon onClick={()=> setVisibilityPwRe(false)}/>:<VisibilityIcon onClick={()=> setVisibilityPwRe(true)}/>}
                name="retypePassword"
                fullWidth
                disabled={formDisabled}
                error={errors.retypePassword}
                helperText={errors.retypePassword?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormSelect
                label={t("Register.vioType")}
                name="type"
                control={control}
                variant="outlined"
                disabled={formDisabled}
                style={{ margin: '12px 0' }}
                defaultValue={vioTypes(t)[0].id}
                fullWidth
              >
                {(vioTypes(t) && (vioTypes(t).map((item, id) => {
                  return (
                    <MenuItem key={id} value={item.id}>{item.name}</MenuItem>
                  );
                })))}
              </ReactHookFormSelect>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <IconTextField*/}
            {/*    placeholder="Why do you select the above type?"*/}
            {/*    // label="Why do you select the above type?"*/}
            {/*    inputRef={register()}*/}
            {/*    multiline*/}
            {/*    rows={5}*/}
            {/*    fullWidth*/}
            {/*    name="description"*/}
            {/*    disabled={formDisabled}*/}
            {/*    error={errors.description}*/}
            {/*    helperText={errors.description?.message}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={6}>
              <CustomButton label={t("Register.registerButton")} type="submit" variant="contained" disabled={formDisabled} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <CustomButton label={t("LoginPage.logInTitle")} component={Link} to="/login" variant="outlined" fullWidth />
            </Grid>
          </Grid>
        </form>
    </React.Fragment>
  );
}
