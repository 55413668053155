import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getNewsfeed } from '../../functions/UserCommon/getNewsfeed';
import { useTranslation } from 'react-i18next';
import NewsBox from './NewsBox';
const useStyles = makeStyles((theme) => ({
    root: {
        width: "60vw",
        [theme.breakpoints.down('sm')]: {
            width: "90vw",
        }
    },
    pos: {
        marginBottom: 12,
    },
}));

export default function Newsfeed() {

    const [news, setNews] = React.useState([]);
    const classes = useStyles();
    const { t } = useTranslation()

    React.useEffect(() => {
        const fetch = async () => {
            const { item, error } = await getNewsfeed();
            if (error) {
                
                return;
            }
            
            setNews(item);
        }
        fetch();
    }, []);

    return (
        <React.Fragment>
            <Grid
                item
                xs={12}
                style={{
                    padding: "40px",
                    textAlign: "center",
                    backgroundColor: "#1d4971",
                    color: "white",
                    marginBottom: "50px",
                }}
            >
                <Typography variant="h4" style={{ fontWeight: "600" }}>
                    {t('Newsfeed.title')}
                </Typography>
                <Typography variant="subtitle2">Home / AboutUs</Typography>
            </Grid>
            <Box py={2}>
                <Grid container>
                    {news.map((row) => {
                        return (
                            <Grid key={row.id} container alignContent="center" alignItems="center" justify="center">
                                <Grid
                                    className={classes.root}
                                    style={{
                                        backgroundColor: "#E1EBFE",
                                        marginBottom: "30px",
                                        paddingBottom: "10px",
                                        lineHeight: 1.6,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        color="inherit"
                                        gutterBottom
                                        style={{
                                            fontWeight: "bold",
                                            backgroundColor: "#14334F",
                                            color: "white",
                                            padding: 10,
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {row.title}
                                    </Typography>
                                    <NewsBox classes={classes} row={row} />
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </React.Fragment>
    );
}