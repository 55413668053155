import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { fetchVolunteerListByAdmin } from "../../../functions/Admin/FetchData/fetchData";
import EmgVolunteerRow from "./EmgVolunteerRow";
import SendIcon from "@material-ui/icons/Send";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import CustomButton from "../../../components/CustomButton";
import { useForm } from "react-hook-form";
import {
  createEmgOpportunity,
  fetchEmgVolunteersList,
} from "../../../functions/EmergencyUser/functions";
import { useHistory } from "react-router";

function createData(
  id,
  email,
  contributeDistrict,
  contributeDivisionalSecretariat,
  contributegnDivision,
  name
) {
  return {
    id,
    email,
    contributeDistrict,
    contributeDivisionalSecretariat,
    contributegnDivision,
    name,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  {
    id: "contribute District",
    numeric: true,
    disablePadding: false,
    label: "Contribute District",
  },
  {
    id: "contribute Divisional Secretariat",
    numeric: true,
    disablePadding: false,
    label: "Contribute Divisional Secretariat",
  },
  {
    id: "contribute gn Division",
    numeric: true,
    disablePadding: false,
    label: "Contribute gn Division",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "center" : "center"}
            // padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, handleOpen } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Emergency Volunteers
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Send">
          <IconButton aria-label="send" onClick={() => handleOpen()}>
            <SendIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Nothing Selected">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EmgVolunteerTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  let history = useHistory();

  useEffect(() => {
    const fetchItems = async () => {
      let result = await fetchEmgVolunteersList();
      let resultList = [];
      result.item.map((result) => {
        // 
        resultList.push(
          createData(
            result.id,
            result.email,
            result.contributeDistrict,
            result.contributeDivisionalSecretariat,
            result.contributegnDivision,
            result.name
          )
        );
      });
      
      setRows(resultList);
    };
    fetchItems();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const onSubmit = async (data) => {
    let result = await createEmgOpportunity(data, selected);
    if (result.error) {
      alert("Error");
    } else {
      alert("Successed");
      history.push("/")
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleOpen={handleOpen}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <EmgVolunteerRow
                      handleClick={handleClick}
                      row={row}
                      isItemSelected={isItemSelected}
                    />
                    // <TableRow
                    //   hover
                    //   onClick={(event) => handleClick(event, row.id)}
                    //   role="checkbox"
                    //   aria-checked={isItemSelected}
                    //   tabIndex={-1}
                    //   key={row.name}
                    //   selected={isItemSelected}
                    // >
                    //   <TableCell padding="checkbox">
                    //     <Checkbox
                    //       checked={isItemSelected}
                    //       inputProps={{ "aria-labelledby": labelId }}
                    //     />
                    //   </TableCell>
                    //   <TableCell
                    //     component="th"
                    //     id={labelId}
                    //     scope="row"
                    //     padding="none"
                    //   >
                    //     {row.firstName}
                    //   </TableCell>
                    //   <TableCell align="right">{row.calories}</TableCell>
                    //   <TableCell align="right">{row.fat}</TableCell>
                    //   <TableCell align="right">{row.carbs}</TableCell>
                    //   <TableCell align="right">{row.protein}</TableCell>
                    // </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Dialog to fill to send msg and email  */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={dialogOpen}
      >
        <DialogTitle id="simple-dialog-title">Fill Below To Send</DialogTitle>
        <DialogContent dividers>
          <form action="" onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="oppName"
                  inputRef={register({
                    required: "Required",
                  })}
                  id="outlined-secondary"
                  label="Name of the opportunity"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.oppName && "Please enter a opportunity name"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="smsMessage"
                  inputRef={register({
                    required: "Required",
                    maxLength: 100,
                  })}
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  label="Message Content"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  helperText={
                    (errors.smsMessage &&
                      errors.smsMessage.type === "required" &&
                      "Please enter a message to send") ||
                    (errors.smsMessage &&
                      errors.smsMessage.type === "maxLength" &&
                      "Maximum text count is 100")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="emailMessage"
                  rowsMax={4}
                  inputRef={register({
                    required: "Required",
                    pattern: {
                      message: "invalid email address",
                    },
                  })}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  label="Email Content"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.emailMessage && "Please enter a content to email"
                  }
                />
              </Grid>

              <CustomButton
                variant="contained"
                type="submit"
                color="primary"
                style={{ width: 180, color: "white" }}
                label="Send"
              >
                Create
              </CustomButton>
              <CustomButton label="Cancel" onClick={handleClose} />
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
