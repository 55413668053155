import { serverHost, serverPort, tokenKey } from "../../Constant";
import axios from 'axios';
import { subvioCreateUrl, subvioViewUrl, subviosViewUrl, addVolunteerUrl, removeVolunteerUrl, removeSubvioUrl } from '../../containers/subvio/Constants';

export const createSubvio = async (email, name, leader, description) => {

    const fetchUrl = serverHost + ":" + serverPort + subvioCreateUrl;
    try {
        const response = await axios.post(fetchUrl,
            { email, name, leader, description },
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                }
            });

        if (response.status == 200) {
            const result = {
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.data.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }
    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }
}


export const fetchSubvio = async (subvioId, searchValue) => {
    const fetchUrl = serverHost + ":" + serverPort + subvioViewUrl;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    tokenKey: localStorage.getItem(tokenKey)
                },
                params: {
                    subvioId,
                    searchValue
                }
            });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};



export const fetchSubvios = async (searchValue) => {
    const fetchUrl = serverHost + ":" + serverPort + subviosViewUrl;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    tokenKey: localStorage.getItem(tokenKey)
                },
                params: {
                    searchValue: searchValue
                }
            });

        if (response.status == 200) {
            const result = {
                subvios: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};


export const addVolunteer = async (email) => {
    const fetchUrl = serverHost + ":" + serverPort + addVolunteerUrl;
    try {
        const response = await axios.post(fetchUrl, {
            email
        },
            {
                headers: {
                    tokenKey: localStorage.getItem(tokenKey)
                }
            });
        if (response.status == 200) {
            const result = {
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};

export const removeSubvio = async (subvioId) => {
    const deleteUrl = serverHost + ":" + serverPort + removeSubvioUrl;

    try {
        const response = await axios.delete(deleteUrl,
            {
                headers: {
                    tokenKey: localStorage.getItem(tokenKey)
                },
                params: { subvioId }
            });
        if (response.status == 200) {
            const result = {
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};

export const removeVolunteer = async (volunteerId) => {
    const fetchUrl = serverHost + ":" + serverPort + removeVolunteerUrl;
    try {
        const response = await axios.delete(fetchUrl,
            {
                headers: {
                    tokenKey: localStorage.getItem(tokenKey)
                },
                params: { volunteerId }
            });
        if (response.status == 200) {

            const result = {
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }
};

