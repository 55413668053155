import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
  },
})

export default function EVOListItem({ voRow }) {
  const classes = useStyles()
  const preventDefault = (event) => event.preventDefault();
  const {t} = useTranslation()
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <Grid item xs={12} sm={6} component={Link} to={`/emg-VolunteerOpportunity/${voRow.id}`} style={{ textDecoration: 'none' }}>
      <Card style={{ padding: 20 }} >
        <Typography variant="h6" color="primary" gutterBottom>
          {voRow.title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">{voRow.gnDivision}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{voRow.divisionalSecretariat}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{voRow.district}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{voRow.startDate} - {voRow.endDate}</Typography>
        <Typography variant="subtitle2" >  {t(`Common.${voRow.status.toLowerCase()}`)} {voRow.type && bull}{voRow.type}</Typography>
      </Card>
    </Grid>
  );
}