import Typography from "@material-ui/core/Typography";
import CustomButton from "../../components/CustomButton";
import {Link, useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React from "react";
import useStyles from "./_styles";
import Paper from "@material-ui/core/Paper";
import aboutUs1 from "../../images/aboutUs-1.png";
import aboutUs3 from "../../images/aboutUs-3.png";
import {useTranslation} from "react-i18next";
import { Box } from "@material-ui/core";
import asset7 from '../../images/Asset7.png';

export default function AboutUs() {
    const classes = useStyles();
    const {t} = useTranslation()

    const history = useHistory();

    function gotoAboutUsPage(history) {
        history.push("/aboutus");
    }

    return (
        <>
            <Grid item className={classes.aboutUsSection}>
                <Grid
                    container
                    style={{display: "flex", justifyContent: "space-around"}}
                    spacing={4}
                >
                    <Grid item xs={12} sm={6} id="regOps">
                        <Paper elevation={3} className={classes.aboutpaper}>
                            <div style={{width: "100%", margin: "10px auto"}}>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                    style={{textAlign: "center", fontWeight: "bold", minHeight: '50px'}}
                                >
                                    {t('LandingPage.aboutUs.onsiteVolunteering')}
                                </Typography>
                            </div>
                            <div style={{display: "flex", justifyContent: 'center'}}>
                                <img src={aboutUs1} width={260} alt="online-onsite-register-image"/>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "15px",
                                    display: "flex",
                                    minHeight: "80px",
                                    justifyContent: "center",
                                    alignItems: "flex-end"
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                    style={{textAlign: "center", minHeight: '50px'}}
                                >
                                    {t('LandingPage.aboutUs.onsiteVolunteeringInstruction')}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <CustomButton
                                    size="small"
                                    className={classes.button}
                                    color="primary"
                                    component={Link}
                                    to="/register?registerAs=volunteer"
                                    label={t('LandingPage.common.registerNow')}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper elevation={3} className={classes.aboutpaper}>
                            <div style={{width: "100%", margin: "10px auto"}}>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                    style={{textAlign: "center", fontWeight: "bold", minHeight: '50px'}}
                                >
                                    {t('LandingPage.aboutUs.requestForVolunteering')}
                                </Typography>
                            </div>
                            <div style={{display: "flex", justifyContent: 'center'}}>
                                <img src={aboutUs3} width={260}/>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "15px",
                                    display: "flex",
                                    minHeight: "80px",
                                    justifyContent: "center",
                                    alignItems: "flex-end"
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                    style={{textAlign: "center", minHeight: '50px'}}
                                >
                                    {t('LandingPage.aboutUs.requestForVolunteeringInstruction')}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <CustomButton
                                    size="small"
                                    className={classes.button}
                                    color="primary"
                                    component={Link}
                                    to="/register?registerAs=vio"
                                    label={t('LandingPage.common.registerNow')}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.aboutUsSection}>
                <Grid
                    container
                    style={{display: "flex", justifyContent: "center"}}
                    spacing={4}
                >
                    <Grid item xs={12} id="aboutUs">
                        <Paper
                            elevation={3}
                            style={{padding: "10px 5%", background: "#e1ebff"}}
                        >
                            <div style={{width: "100%", margin: "5% 0 2%"}}>
                                <Typography
                                    variant="h4"
                                    color="primary"
                                    gutterBottom
                                    style={{textAlign: "center", fontWeight: "bold"}}
                                >
                                    {t('LandingPage.aboutUs.aboutUs')}
                                </Typography>
                            </div>
                            <div style={{width: "100%"}}>
                                {/*<Typography variant="h6" color='primary' gutterBottom style={{textAlign: "justify", fontWeight: 'normal'}} >*/}
                                {/*    The Volunteer Management System /VMS) is a web platform developed to coordinate volunteer activities. The platform enables volunteers and VIOs to identify existing volunteer requirements or opportunities and interact in providing and obtaining voluntary services.*/}
                                {/*</Typography>*/}
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                    style={{textAlign: "justify", fontWeight: "normal"}}
                                >
                                    {t('LandingPage.aboutUs.description')}
                                </Typography>
                            </div>
                            <Grid item xs={12} container alignContent="center" justify="center" alignItems="center">
                                <Box mb={3}>
                                <img src={asset7} alt="img" style={{width:"75vw", maxWidth: "500px"}} />
                                </Box>
                            </Grid>
                            <Grid container style={{marginBottom: "24px", justifyContent: "center"}}>
                                <CustomButton
                                    size="large"
                                    color="primary"
                                    label={t('LandingPage.common.readMore')}
                                    margin="12px 24px 12px 0"
                                    onClick={() => gotoAboutUsPage(history)}
                                />
                                <CustomButton
                                    size="large"
                                    color="primary"
                                    onClick={() =>
                                        window.open("https://www.socialservices.gov.lk/", "_blank")
                                    }
                                    label={t('LandingPage.aboutUs.departmentOfSocialServices')}
                                    margin="12px 12px 12px 0"
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </>

    );
}
