export const volunteerHomePageOnlineOpportunityImageLink = "https://mopportunities.com/wp-content/uploads/2020/03/onlinevolunteering_mopportunities_jpg-895x430.jpg";
export const volunteerHomePageOnlineOpportunityImageTopic = "Online Volunteering Opportunities";
export const volunteerHomePageOnlineOpportunityCardContentTopic = "Online Volunteering Opportunities";
export const volunteerHomePageOnlineOpportunityCardContent = "Lizards are a widespread group of squamate reptiles, with over 6,000 species, rangingacross all continents except Antarctica";
export const defaultLearnmore = () =>{}; 
export const defaultShare = () =>{}; 
export const defualtOnlineCarousalHeading = "Online Volunteering Opportunities";


export const volunteerHomePageRegularOpportunityImageLink = "https://www.unv.org/sites/default/files/styles/threshold-1382/public/UNV_online_volunteering_global_festival_ideas_2017_lizcano.jpg?itok=ZpF8Mp9f";
export const volunteerHomePageRegularOpportunityImageTopic = "Online Volunteering Opportunities";
export const volunteerHomePageRegularOpportunityCardContentTopic = "Online Volunteering Opportunities";
export const volunteerHomePageRegularOpportunityCardContent = "Lizards are a widespread group of squamate reptiles, with over 6,000 species, rangingacross all continents except Antarctica";
export const defualtRegularCarousalHeading = "Regular Volunteering Opportunities";
export const opportunityLimit = 30;

export const onlineItems = {
    oppertunityTitle: "Title",
    id:2,
    voCategory: "Vo Category 2",
    name: "Organization name",
    VODescriptionEnglish: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
};
export const regularItems = {
    id:1,
    voCategory: "Vo Category 2",
    name: "Organization name",
    oppertunityTitle: "online This is the topic of the content",
    VODescriptionEnglish: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
};
export const defaultItems = {onlineItems:[onlineItems,onlineItems,onlineItems,onlineItems,onlineItems,onlineItems,onlineItems,onlineItems,onlineItems,onlineItems,onlineItems],regularItems:[regularItems,regularItems,regularItems]};
export const allOpportunitiesFetchUrl = "/api/opportunity/getOpportunities";
export const homeOpportunitiesFetchUrl = "/api/opportunity/getHomeOpportunities";
export const viewOpportunityViewFetchUrl = "/api/opportunity/get";
export const applyURL = "/api/opportunity/apply"
export const userOpportunitiesFetchUrl = "/api/opportunity/userOpportunities";