import React, { useEffect, useState } from 'react'
import {instruction, maxlength, minlength} from './Constants'
import { Grid, Paper, Typography } from '@material-ui/core';

import { useForm } from "react-hook-form";
import IconTextField from '../../../components/IconTextField';
import CustomButton from '../../../components/CustomButton';

function EnterPhone({ email, onSubmit, error, errMessage, t}) {

    const { register, handleSubmit, errors } = useForm({});

    const onSubmitCode = async (data) => {
        return await onSubmit(email, data.phoneToken);
    }

    return (
        <React.Fragment>
                <Paper style={{ padding: '18px 36px', maxWidth: 800, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
                    <form onSubmit={handleSubmit(onSubmitCode)}>
                        <Grid container justify="center" spacing={2}>
                        <Typography variant="subtitle1" color="primary">{t("VerifyMobile.codeInstruction")}</Typography>
                            <Grid item xs={12}>
                                <IconTextField
                                    inputRef={register({ required: true, maxLength: maxlength, minLength: minlength })}
                                    required
                                    name="phoneToken"
                                    error={!!errors.phoneToken}
                                    helperText={errors.phoneToken && t("VerifyMobile.codeValidation")}
                                />
                            </Grid>
                            {error? 
                                <p style={{color : 'red'}}>{errMessage}</p> : ""}
                            <Grid item xs={6}>
                                <CustomButton
                                    fullWidth
                                    label={t("VerifyMobile.verifyButtonLabel")}
                                    type="submit"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
        </React.Fragment>
    )
}

export default EnterPhone
