import React from "react";
import EmergencyVolunteerOpportunityHome from '../../components/EmergencyUser/EmergencyVolunteerOppertunityHome';
import { useEffect } from "react";
import LoadingPage from "../../components/LoadingPage";
import MessageCard from "../../components/MessageCard";
import { useSnackbar } from "notistack";
import {fetchEmgVolunteers} from '../../functions/EmergencyUser/EmgUserHome/fetchEmgVolunteers';
import {submitInviteVolunteers} from '../../functions/EmergencyUser/EVO/submitInviteVolunteers';
import {submitAddVolunteer} from '../../functions/EmergencyUser/EVO/submitAddVolunteer';
import {fetchEVO} from '../../functions/EmergencyUser/EVO/fetchEVO';
import {submitCompleteEVO} from '../../functions/EmergencyUser/EVO/submitCompleteEVO';
import {submitDeleteEVO} from '../../functions/EmergencyUser/EVO/submitDeleteEVO';
import {submitRemoveVolunteer} from '../../functions/EmergencyUser/EVO/submitRemoveVolunteer';
import {submitCompleteVolunteer} from '../../functions/EmergencyUser/EVO/submitCompleteVolunteer';

export default function EmergencyVolunteerOpportunityPage({ match }) {
  const id = match.params.id;
  // var id='93e34fb8-a288-45be-b1e6-4d47b50be6ee';
  
  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [emgVolunteers,setEmgVolunteers] = React.useState(null);
  const [oppVolunteers, setOppVolunteers] = React.useState(null);
  const [invitedVolunteers, setInvitedVolunteers] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {
    let mounted = true;

    let result = await fetchEVO(id);
    if(mounted){
      if(result.error){

      }
      else{
        setFetchDetails(result.item);
      }
    }

    let resultEmgVol = await fetchEmgVolunteers(id);
    if(mounted){
      if(resultEmgVol.error){

      }
      else{
        setEmgVolunteers(resultEmgVol.item.filter(eVolunteer=>eVolunteer.status=="NEW"))
        setInvitedVolunteers(resultEmgVol.item.filter(eVolunteer=>eVolunteer.status=="INVITED"))
        setOppVolunteers(resultEmgVol.item.filter(eVolunteer=>(eVolunteer.status=="ACCEPTED" || eVolunteer.status=="COMPLETED"|| eVolunteer.status=="INVITED")))
      }
    }
    return () => mounted = false;
  }, [error, reload])

  const completeVO = async (history) => {
    let mounted = true;
    let result = await submitCompleteEVO(id);
    if (mounted) {
      if (result.error) {
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setErrorMsg("Successfully Completed!")
        setIsErrorVariant(false)
        setPushError(!pushError)
        setReload(!reload)
      }
    }
  }

  const deleteVO = async (history) => {
    let mounted = true;
    let result = await submitDeleteEVO(id);
    if (mounted) {
      if (result.error) {
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setErrorMsg("Successfully Deleted!")
        setIsErrorVariant(false)
        setPushError(!pushError)
        history.push("/emguserHome")
      }
    }
  }

  const inviteEmgVolunteers = async (volunteerList) => {
      let result = await submitInviteVolunteers(id, volunteerList);
      if (result.error) {
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setErrorMsg("Successfully Invited!")
        setIsErrorVariant(false)
        setPushError(!pushError)
        // setEmgVolunteers(emgVolunteers.filter(item=> (!volunteerList.includes(item.id))))
        setReload(!reload)
      }
  }

  const addEmgVolunteer = async (registrationNo,volunteerID) => {
    let result = await submitAddVolunteer(id, registrationNo);
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setErrorMsg("Successfully Added!")
      setIsErrorVariant(false)
      setPushError(!pushError)
      var newOppVounteers = [...oppVolunteers]
      var changeIndex = newOppVounteers.findIndex((item)=>item.id==volunteerID)
      newOppVounteers[changeIndex].status="ACCPETED"
      setOppVolunteers(newOppVounteers)
    }
    
}

const removeEmgVolunteer = async(registrationNo,volunteerID)=>{
  let result = await submitRemoveVolunteer(id, registrationNo);
  if (result.error) {
    result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
    setIsErrorVariant(true)
    setPushError(!pushError)
  }
  else {
    setErrorMsg("Successfully Removed!")
    setIsErrorVariant(false)
    setPushError(!pushError)
    var newOppVounteers = [...oppVolunteers]
    var changeIndex = newOppVounteers.findIndex((item)=>item.id==volunteerID)
    newOppVounteers[changeIndex].status="INVITED"
    setOppVolunteers(newOppVounteers)
  }
}

const completeEmgVolunteer = async (registrationNo,volunteerID)=>{
  let result = await submitCompleteVolunteer(id, registrationNo);
  if (result.error) {
    result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
    setIsErrorVariant(true)
    setPushError(!pushError)
  }
  else {
    setErrorMsg("Successfully Completed!")
    setIsErrorVariant(false)
    setPushError(!pushError)
    var newOppVounteers = [...oppVolunteers]
    var changeIndex = newOppVounteers.findIndex((item)=>item.id==volunteerID)
    newOppVounteers[changeIndex].status="COMPLETED"
    setOppVolunteers(newOppVounteers)
  }
}

  return (
    <React.Fragment>
      {
        // !error ?
        // (fetchDetails && formDisplayData) ?
        (emgVolunteers && oppVolunteers && fetchDetails) ?
            <div>
              <EmergencyVolunteerOpportunityHome
                emgVolunteers={emgVolunteers}
                inviteEmgVolunteers={inviteEmgVolunteers}
                invitedVolunteers={invitedVolunteers}
                addEmgVolunteer={addEmgVolunteer}
                removeEmgVolunteer={removeEmgVolunteer}
                completeEmgVolunteer={completeEmgVolunteer}

                oppVolunteers={oppVolunteers}
                fetchDetails={fetchDetails}
               
                id={id}
                completeVO={completeVO}
                deleteVO={deleteVO}
              />
            </div>
            : <LoadingPage />
          // : <MessageCard />
      }

    // </React.Fragment>

  );
}
