import axios from 'axios';
import {serverHost,serverPort,tokenKey} from "../../Constant";

const GETNEWSFEED = `/api/news/home/list`;

export const getNewsfeed = async ()=> {
    const url = `${serverHost}:${serverPort}${GETNEWSFEED}`;

    try{
        const response = await axios.get(url, {
            headers: {
                'token': localStorage.getItem(tokenKey)
            },
        });
    
        if(response.status === 200) {
            return {
                item: response.data,
                error: null,
            }
        }
        else {
            return {
                item: null,
                error: response.data,
            }
        }
    }
    catch(err) {
        return {
            item: null,
            error: err.message,
        }
    }
}