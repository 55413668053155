import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import CustomButton from "../../../components/CustomButton";
import { suspendVolunteer } from "../../../functions/Admin/Volunteers/VolunteerFunctions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class PopUpCalendarByAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.clearDates = this.clearDates.bind(this);
    this.clickOkButton = this.clickOkButton.bind(this);
    this.state = {
      selectedDay: undefined,
      open: false,
      suspendSuccessAlertOpen: false,
      suspendAlertMsg: "",
      suspendAlertState: "",
    };
  }

  handleDayClick(day) {
    this.setState({ selectedDay: day });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  clearDates() {
    this.setState({ selectedDays: [] });
  }

  handleSuspendSuccessAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // if (this.props.userSuspend == "vio") {
    //   this.props.history.push("/admin/violist");
    // } else if (this.props.userSuspend == "vol") {
    //   this.props.history.push("/admin/volunteerList");
    // }
    // window.location.reload(false);
    this.setState({
      suspendSuccessAlertOpen: false,
    });
    this.props.fetchVolSuspendStatusToUpdate();
  };

  async clickOkButton(history) {
    let result = await suspendVolunteer(
      this.props.id,
      this.state.selectedDay.getMonth() +
        1 +
        "-" +
        this.state.selectedDay.getDate() +
        "-" +
        this.state.selectedDay.getFullYear(),
      true
    );
    if (result.error) {
      // alert("Error!");
      // 
      this.setState({
        suspendSuccessAlertOpen: true,
        suspendAlertMsg: "Something Went Wrong!",
        suspendAlertState: "error",
      });
    } else {
      // alert("User suspended!");
      this.setState({
        suspendSuccessAlertOpen: true,
        open: false,
        suspendAlertMsg: "User Suspended SUccessfully!",
        suspendAlertState: "success",
      });
      // if (this.props.userSuspend == "vio") {
      //   history.push("/admin/violist");
      // } else if (this.props.userSuspend == "vol") {
      //   history.push("/admin/volunteerList");
      // }
    }
  }

  render() {
    return (
      <Grid container>
        <CustomButton
          label={this.props.isSuspended ? "unsuspend" : "suspend"}
          color="primary"
          onClick={this.handleOpen}
          type="submit"
          style={{ backgroundColor: "#f52929", color: "white" }}
          variant="contained"
        />
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="simple-dialog-title">Select Days</DialogTitle>
          <DialogContent dividers>
            <DayPicker
              onDayClick={this.handleDayClick}
              selectedDay={this.state.selectedDay}
              disabledDays={{ before: new Date() }}
            />
            {this.state.selectedDay ? (
              <p>You Selected {this.state.selectedDay.toLocaleDateString()}</p>
            ) : (
              <p>Please select a day.</p>
            )}
          </DialogContent>
          <DialogActions>
            <CustomButton
              label="OK"
              onClick={() => {
                if (this.state.selectedDay) {
                  this.clickOkButton(this.props.history);
                }
              }}
            />
            {/* <CustomButton label="Clear Al" onClick={this.clearDates} /> */}
            <CustomButton label="Cancel" onClick={this.handleClose} />
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.suspendSuccessAlertOpen}
          autoHideDuration={1000}
          onClose={this.handleSuspendSuccessAlertClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Alert
            onClose={this.handleSuspendSuccessAlertClose}
            severity={this.state.suspendAlertState}
          >
            {this.state.suspendAlertMsg}
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}
