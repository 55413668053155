import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../Constant";

export const submitComplaint = async (data) => {
    const submitComplaintURL = '/api/complaint/add';
    const complaintUrl = serverHost + ":" + serverPort + submitComplaintURL;
    try {
        const response = await axios.post(complaintUrl,{...data},{headers: {'token': localStorage.getItem(tokenKey)}});
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {

        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

