export function gotoHomeSection(history) {
    const home = document.getElementById("home");
    if (home) {
        window.scrollTo({
            top: home.offsetTop - 150,
            behavior: "smooth",
        });
    } else {
        history.push("/");
    }
}

function gotoViewOpportunitiesSection(history) {
    history.push("/volunteer-opportunities");
}

async function gotoAboutSection(history) {
    const aboutUs = document.getElementById("aboutUs");
    if (aboutUs) {
        window.scrollTo({
            top: aboutUs.offsetTop - 150,
            behavior: "smooth",
        });
    } else {
        history.push("/aboutus");
    }
}

function gotoContactUsPage(history) {
    history.push("/contactus");
}

const getLandingMenu = (history, t) => [
    {
        "name": t("LandingMenu.home"),
        "action": () => gotoHomeSection(history)
    },
    {
        "name": t("LandingMenu.aboutUs"),
        "action": () => gotoAboutSection(history)
    },
    {
        "name": t("LandingMenu.findOpportunities"),
        "action": () => gotoViewOpportunitiesSection(history)
    },
    {
        "name": t("LandingMenu.requestVolunteers"),
        "action": () => history.push("/register")
    },
    {
        "name": t("LandingMenu.contactUs"),
        "action": () => gotoContactUsPage(history)
    }
]
export const getLandingMenuHeader = (history, t, isLogged) => {
    return isLogged ? [
        {
            "name": t("LandingMenu.findOpportunities"),
            "action": () => gotoViewOpportunitiesSection(history)
        },
        {
            "name": t("LandingMenu.contactUs"),
            "action": () => gotoContactUsPage(history)
        }
    ]: [
        {
            "name": t("LandingMenu.findOpportunities"),
            "action": () => gotoViewOpportunitiesSection(history)
        },
        {
            "name": t("LandingMenu.requestVolunteers"),
            "action": () => history.push("/register")
        },
        {
            "name": t("LandingMenu.contactUs"),
            "action": () => gotoContactUsPage(history)
        }
    ]
}

export const getLandingHeaderDropDown = (history, t) => [
    {
        "name": t("LandingMenu.aboutUs"),
        "action": () => gotoAboutSection(history)
    },
    {
        "name": t("LandingMenu.newsfeed"),
        "action": ()=> history.push("/newsfeed")
    },
    {
        "name": t("LandingMenu.engageWithUs"),
        "action": ()=> history.push("/engage-with-us")
    },
]

export default getLandingMenu;

