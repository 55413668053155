import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useEffect } from "react";
import useStyles from "./_styles";
import Divider from "@material-ui/core/Divider";
import Carousel from "react-material-ui-carousel";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LoadingPage from '../../components/LoadingPage';
import { useTranslation } from "react-i18next";

export default function Volunteer({ itemList }) {
    const classes = useStyles();
    const { t } = useTranslation()
    const lg = useMediaQuery('(min-width:1280px)');
    const md = useMediaQuery('(min-width:960px)');

    function chunk(array, size) {
        const chunked_arr = [];
        for (let i = 0; i < array.length; i++) {
            const last = chunked_arr[chunked_arr.length - 1];
            if (!last || last.length === size) {
                chunked_arr.push([array[i]]);
            } else {
                last.push(array[i]);
            }
        }
        return chunked_arr;
    }

    return (
        <Grid item className={classes.volunteerSection}>
            <Divider variant="middle" />
            <Grid container style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                <Grid item xs={12}>
                    <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center" }} >
                        {t('LandingPage.volunteer.title')}
                    </Typography>
                    <Typography variant="h4" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                        {t('LandingPage.volunteer.subtitle')}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
                    <Carousel navButtonsAlwaysInvisible="true" animation="slide" autoPlay={true}>
                        {chunk(itemList, lg ? 3 : md ? 2 : 1).map((items, i) => (
                            <Grid container key={i.toString()} className={(classes.container, classes.parallel)} spacing={3}>
                                {items.map(item => (
                                    items.length === 1 ? <Grid item xs={12} key={item.name}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Avatar alt="Remy Sharp" style={{ width: '250px', height: '250px' }} src={item.imageURL} />
                                        </div>
                                        <div style={{ margin: '5%' }}>
                                            <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'normal' }} >
                                                {item.description}
                                            </Typography>
                                        </div>
                                        <div style={{ margin: '5% auto 0' }}>
                                            <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                                {item.name}
                                            </Typography>
                                        </div>

                                    </Grid> : items.length === 2 ?
                                            <Grid item xs={6} md={6} key={item.name}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Avatar alt="Remy Sharp" style={{ width: '250px', height: '250px' }} src={item.imageURL} />
                                                </div>
                                                <div style={{ margin: '5%' }}>
                                                    <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'normal' }} >
                                                        {item.description}
                                                    </Typography>
                                                </div>
                                                <div style={{ margin: '5% auto 0' }}>
                                                    <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                                        {item.name}
                                                    </Typography>
                                                </div>

                                            </Grid> : <Grid item xs={12} md={6} lg={4} key={item.name}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Avatar alt="Remy Sharp" style={{ width: '250px', height: '250px' }} src={item.imageURL} />
                                                </div>
                                                <div style={{ margin: '5%' }}>
                                                    <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'normal' }} >
                                                        {item.description}
                                                    </Typography>
                                                </div>
                                                <div style={{ margin: '5% auto 0' }}>
                                                    <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                                        {item.name}
                                                    </Typography>
                                                </div>

                                            </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </Grid>
    )
}
