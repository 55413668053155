import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Button, Paper, Grid, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../Constant";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {
    // width: "60vw",
  },
  border: {
    border: "1px solid #c0c0c0",
    padding: 24,
    borderRadius: 4,
    margin: "16px 8px",
  },
}));

function InviteAdmin() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = useState("");
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [inviteFailed, setInviteFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgAllow, setMsgAllow] = useState(false);
  const [adminPermissions, setAdminPermissions] = useState([]);
  const permissionList = [
    "VIEW_PROFILE_STATUS",
    "CHECK_REFEREES",
    "VERIFY_VIOS",
    "APPROVE_OPPORTUNITIES",
    "CONFIRM_VOLUNTEERS",
    "VIEW_REPORTS",
  ];

  const [state, setState] = React.useState({
    VIEW_PROFILE_STATUS: false,
    CHECK_REFEREES: false,
    VERIFY_VIOS: false,
    APPROVE_OPPORTUNITIES: false,
    CONFIRM_VOLUNTEERS: false,
    VIEW_REPORTS: false,
  });

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [alertState, setAlertState] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
    if (alertState == "success") {
      setEmail("");
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleCheckBoxes = () => {
    var list = [];
    permissionList.map((element) => {
      if (state[element]) {
        list.push(element);
        setAdminPermissions(list);
      }
    });
  };

  const sendData = () => {
    const adminInviteUrl = serverHost + "/api/admin/invite";
    if (adminPermissions.length) {
      setMsgAllow(true);
      Axios.post(
        adminInviteUrl,
        {
          email: email,
          adminPermissions: adminPermissions,
        },
        {
          headers: {
            token: localStorage.getItem(tokenKey),
          },
        }
      )
        .then((res) => {
          
          if (res.status == 200) {
            setInviteSuccess(true);
            setInviteFailed(false);
            
            setAlertOpen(true);
            setAlertMsg("Successfully Invited!");
            setAlertState("success");
          } else {
            setInviteSuccess(false);
            setInviteFailed(true);
            
            setAlertOpen(true);
            setAlertMsg("Something Went Wrong!");
            setAlertState("error");
          }
        })
        .catch((err) => {
          
          setInviteSuccess(false);
          setInviteFailed(true);
          setAlertOpen(true);
          setAlertMsg("Something Went Wrong!");
          setAlertState("error");
        });
    }
  };

  useEffect(() => {
    sendData();
  }, [adminPermissions]);

  return (
    <Paper
      style={{
        padding: "18px 36px",
        maxWidth: 800,
        margin: "auto",
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <form action="" onSubmit={handleSubmit(() => handleCheckBoxes())}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} style={{ padding: "20px 0" }}>
            <Typography variant="h5" align="center" color="primary">
              Invite Admins
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              id="outlined-secondary"
              label="Email to Invite"
              variant="outlined"
              value={email}
              fullWidth
              className={classes.textField}
              onChange={(e) => setEmail(e.target.value)}
              helperText={errors.email && "Please enter a valid email address"}
            />
          </Grid>
          <div className={classes.border}>
            <FormGroup>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    Select Admin Permissions
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="VIEW_PROFILE_STATUS"
                        checked={state.checkedA}
                        onChange={handleChange}
                        color="primary"
                        value="VIEW_PROFILE_STATUS"
                      />
                    }
                    label="View Profile Status"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="CHECK_REFEREES"
                        checked={state.checkedB}
                        onChange={handleChange}
                        color="primary"
                        value="CHECK_REFEREES"
                      />
                    }
                    label="Check Referees"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="VERIFY_VIOS"
                        checked={state.checkedC}
                        onChange={handleChange}
                        color="primary"
                        value="VERIFY_VIOS"
                      />
                    }
                    label="Verify VIOs"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="APPROVE_OPPORTUNITIES"
                        checked={state.checkedD}
                        onChange={handleChange}
                        color="primary"
                        value="APPROVE_OPPORTUNITIES"
                      />
                    }
                    label="Approve Opportunities"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="CONFIRM_VOLUNTEERS"
                        checked={state.checkedE}
                        onChange={handleChange}
                        color="primary"
                        value="CONFIRM_VOLUNTEERS"
                      />
                    }
                    label="Confirm Volunteers"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="VIEW_REPORTS"
                        checked={state.checkedF}
                        onChange={handleChange}
                        color="primary"
                        value="VIEW_REPORTS"
                      />
                    }
                    label="View Reports"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </div>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{ width: 180, color: "white" }}
            // onClick={() => handleCheckBoxes()}
          >
            Invite
          </Button>
        </Grid>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Alert onClose={handleClose} severity={alertState}>
            {alertMsg}
          </Alert>
        </Snackbar>
      </form>
    </Paper>
  );
}

export default InviteAdmin;
