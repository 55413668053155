import { TextField, Grid, Typography, Divider } from '@material-ui/core'
import React, { useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { volunteerHeader } from '../Constants'
import VolunteerListElement from './VolunteerListElement'
import IconTextField from '../../../components/IconTextField'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'

function VolunteerSection({ details, onRegister, onRemove, onType }) {
    const [volunteerEmail, setVolunteerEmal] = useState("");
    const [vid, setVID] = useState(false);
    const history = useHistory();

    const onTypeEmail = (e) => {
        setVolunteerEmal(e.target.value);
    }
    return (
        <React.Fragment>
            <Typography variant="h5">{volunteerHeader}</Typography>
            <Grid item xs={12} container direction="row" justify="flex-end" style={{marginBottom: 16}}>
                <Grid item>
                    <CustomButton margin="12px 0" variant="contained" style={{ padding: '15px 20px' }} label={!vid ? "Add New Volunteer" : "Cancel"} onClick={() => { setVID(!vid) }} />
                </Grid>
                <Grid item>
                    <IconTextField
                        onChange={onType}
                        name="search"
                        placeholder="Search by volunteer name…"
                        defaultValue={""}
                        icon={<SearchIcon />}
                    />
                </Grid>
            </Grid>
            {vid &&
                <Grid container item justify="center" xs={12} style={{
                    border: '1px solid #c0c0c0',
                    padding: 24,
                    borderRadius: 4,
                    //  width: "60vw",
                    margin: '16px 0'
                }}>
                    <Grid item>
                        <TextField placeholder="Enter volunteer Email" onChange={onTypeEmail} variant="outlined" />
                    </Grid>
                    <Grid item >
                        <CustomButton label="Register"
                            margin="0 12px"
                            style={{ padding: '15px 20px' }}
                            onClick={() => {
                                onRegister(volunteerEmail)
                                setVID(!vid)
                            }} />
                    </Grid>
                </Grid>
            }
            {details.volunteers.map((volunteer, i) => (
                <VolunteerListElement
                    history={history}
                    key={i.toString()}
                    volunteer={volunteer}
                    onRemove={onRemove}
                />
            ))}

        </React.Fragment>
    )
}

export default VolunteerSection
