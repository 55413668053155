import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Button, Grid } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { serverHost, tokenKey } from "../../Constant";
import Axios from "axios";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function calculateAge(dateOfBirth) {
  var diff_ms = Date.now() - parseISOString(dateOfBirth);
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

function parseISOString(s) {
  var b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

function Row(props) {
  const {
    row,
    changeApplicantState,
    changeApplicantStateByAdmin,
    changeApplicantStateByVIO,
    isAdminView,
    disableStateTogle = false,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const { t } = useTranslation();
  const history = useHistory()

  return (
    <React.Fragment>
      {!isAdminView && (
        <React.Fragment>
          <TableRow className={classes.root}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {row.firstName} {row.lastName}
            </TableCell>
            <TableCell align="right">{row.contributeDistrict}</TableCell>
            <TableCell align="right">{calculateAge(row.dateOfBirth)}</TableCell>
            <TableCell align="right">
              <Button
                variant="outlined"
                color="secondary"
                style={{ width: 120 }}
                disabled={
                  row.status == "APPROVED" || disableStateTogle ? true : false
                }
                onClick={() => {
                  changeApplicantStateByVIO(row.applicationId, true);
                }}
              >
                {row.status == "APPROVED"
                  ? t("Common.selected")
                  : t("Common.select")}
              </Button>
            </TableCell>

            <TableCell align="right">
              <Button
                variant="outlined"
                color="secondary"
                style={{ width: 120 }}
                disabled={
                  row.status == "VIO_REJECTED" || disableStateTogle
                    ? true
                    : false
                }
                onClick={() => {
                  changeApplicantStateByVIO(row.applicationId, false);
                }}
              >
                {row.status == "VIO_REJECTED"
                  ? t("Common.ignored")
                  : t("Common.ignore")}
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t("VolunteerOpportunityPage.possibleDays")}
                        </TableCell>
                        <TableCell>
                          {t("VolunteerOpportunityPage.preferredLang")}
                        </TableCell>
                        <TableCell align="right">
                          {t("VolunteerOpportunityPage.contact")}
                        </TableCell>
                        <TableCell align="right">
                          {t("VolunteerOpportunityPage.currentEployment")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={row.date}>
                        <TableCell component="th" scope="row">
                          {row.joiningDates}
                          {/* date picker custome component should be added */}
                        </TableCell>
                        <TableCell>{row.preferredLanguage}</TableCell>
                        <TableCell align="right">{row.phoneNumber}</TableCell>
                        <TableCell align="right">
                          {row.employmentTitle}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}

      {/* Admin View  */}
      {isAdminView && (
        <React.Fragment>
          <TableRow className={classes.root}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              <Button
                onClick={() => history.push("/volunteer/"+row.id)}
              >
                {row.firstName} {row.lastName}
              </Button>
            </TableCell>
            <TableCell align="right">{row.contributeDistrict}</TableCell>
            <TableCell align="right">{row.volunteerHours}</TableCell>
            <TableCell align="right">{row.highestEducation}</TableCell>

            <TableCell align="right">
              <Button
                variant="outlined"
                color="secondary"
                style={{ width: 120 }}
                disabled={row.status == "SELECTED" ? true : false}
                onClick={() => {
                  changeApplicantStateByAdmin(row.applicationId, true);
                }}
              >
                {row.status == "SELECTED" ? "Selected" : "Select"}
              </Button>
            </TableCell>

            <TableCell align="right">
              <Button
                variant="outlined"
                color="secondary"
                style={{ width: 120 }}
                disabled={row.status == "ADMIN_REJECTED" ? true : false}
                onClick={() => {
                  changeApplicantStateByAdmin(row.applicationId, false);
                }}
              >
                {row.status == "ADMIN_REJECTED" ? "Rejected" : "Reject"}
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t("VolunteerOpportunityPage.possibleDays")}
                        </TableCell>
                        <TableCell>
                          {t("VolunteerOpportunityPage.preferredLang")}
                        </TableCell>
                        <TableCell align="right">
                          {t("VolunteerOpportunityPage.contact")}
                        </TableCell>
                        <TableCell align="right">
                          {t("VolunteerOpportunityPage.currentEployment")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={row.date}>
                        <TableCell component="th" scope="row">
                          {row.joiningDates}
                          {/* date picker custome component should be added */}
                        </TableCell>
                        <TableCell>{row.preferredLanguage}</TableCell>
                        <TableCell align="right">{row.phoneNumber}</TableCell>
                        <TableCell align="right">
                          {row.employmentTitle}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 185px)",
    },
    overflowX: "auto",
  },
}));

export default function VolunteerTable({
  id,
  changeApplicantState,
  changeApplicantStateByAdmin,
  changeApplicantStateByVIO,
  isAdminView,
  setRowCount,
  disableStateTogle,
}) {
  const [volunteerTableMode, setVolunteerTableMode] = React.useState("All");
  const handleVolunteerTableModeChange = (event) => {
    setVolunteerTableMode(event.target.value);
  };
  const [rows, setRows] = React.useState([]);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAdminView) {
      getApplicantListByAdmin();
    } else {
      getApplicantListByVIO();
    }
  }, []);

  const getApplicantListByAdmin = () => {
    const applicantListByAdminURL =
      serverHost + "/api/opportunity/filtered-volunteers/" + id;
    Axios.get(
      applicantListByAdminURL,
      {
        opportunityId: id,
        status: ["PENDING", "SELECTED", "ADMIN_REJECTED"],
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    )
      .then((res) => {
        
        if (res.status == 200) {
          
          setRows(res.data);
        }
      })
      .catch((err) => {});
  };

  const getApplicantListByVIO = () => {
    const applicantListByVIoURL =
      serverHost + "/api/opportunity/filtered-volunteers/" + id;
    Axios.get(
      applicantListByVIoURL,
      {
        opportunityId: id,
        status: ["SELECTED", "APPROVED", "VIO_REJECTED"],
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    )
      .then((res) => {
        if (res.status == 200) {
          setRows(res.data);
          setRowCount(res.data.length)
        }
      })
      .catch((err) => {});
  };

  const filteredRows = rows.filter(({ status, sdf }) =>
    volunteerTableMode == "All"
      ? true
      : status == volunteerTableMode
      ? true
      : false
  );

  

  return (
    <div
      style={{
        backgroundColor: "#e1ebff",
        padding: 24,
        borderRadius: 4,
        margin: "16px 0",
      }}
    >
      <RadioGroup
        aria-label="volunteerTblMode"
        name="volunteerTblMode"
        value={volunteerTableMode}
        onChange={handleVolunteerTableModeChange}
      >
        <Grid container justify="center">
          <Grid item>
            <FormControlLabel
              value="All"
              control={<Radio color="primary" />}
              label={t("Common.all")}
            />
          </Grid>
          <Grid item>
            {isAdminView && (
              <FormControlLabel
                value="PENDING"
                control={<Radio color="primary" />}
                label={t("Common.pending")}
              />
            )}
          </Grid>
          <Grid item>
            {!isAdminView && (
              <FormControlLabel
                value="APPROVED"
                control={<Radio color="primary" />}
                label={t("Common.approved")}
              />
            )}
            {isAdminView && (
              <FormControlLabel
                value="SELECTED"
                control={<Radio color="primary" />}
                label={t("Common.selected")}
              />
            )}
          </Grid>
          <Grid item>
            {!isAdminView && (
              <FormControlLabel
                value="VIO_REJECTED"
                control={<Radio color="primary" />}
                label={t("Common.ignored")}
              />
            )}
            {isAdminView && (
              <FormControlLabel
                value="ADMIN_REJECTED"
                control={<Radio color="primary" />}
                label={t("Common.rejected")}
              />
            )}
          </Grid>
        </Grid>
      </RadioGroup>
      <div
        style={{
          backgroundColor: "#e1ebff",
          padding: 12,
          borderRadius: 4,
          margin: "16px 0",
        }}
      >
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t("Common.name")}</TableCell>
                <TableCell align="right">
                  {t("EVO.contributeDistrict")}
                </TableCell>
                <TableCell align="right">
                  {t("LandingPage.statistics.volunteerHours")}
                </TableCell>
                <TableCell align="right">
                  {t("Volunteer.Highest Educational Level")}
                </TableCell>
                <TableCell align="right" colSpan={2}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!filteredRows.length && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {t("Common.noResults")}
                  </TableCell>
                </TableRow>
              )}
              {filteredRows.map((row) => {
                return !isAdminView ? (
                  <Row
                    key={row.name}
                    row={row}
                    changeApplicantStateByVIO={changeApplicantStateByVIO}
                    isAdminView={isAdminView}
                  />
                ) : (
                  <Row
                    key={row.name}
                    row={row}
                    changeApplicantStateByAdmin={changeApplicantStateByAdmin}
                    isAdminView={isAdminView}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
