import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import style from './ProfileComplete.styles';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ReactHookFormCheckbox from '../ReactHookFormCheckbox';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const schema = yup.object().shape({

});

function OtherTab(props) {
  const { children, value, setValue, register, control, index, errors, watch, formDisplayData, disabled, ...other } = props;

  const classes = useStyles();
  const watchSpecialCondition = watch("specialCondition");
  const watchConvicted = watch("convicted");
  const watchEmergencyPast = watch("emergencyPast");
  const { t } = useTranslation();

  return (
    // All Other Information
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <Typography variant="h6" color="primary">{t('Volunteer.Volunteering')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t('Volunteer.Preferred Areas for Volunteering')}
          {errors.areasOfVolunteering && <FormHelperText error>{errors.areasOfVolunteering?.message}</FormHelperText>}
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginBottom: 10 }}>
        {formDisplayData.preferred_areas_of_volunteering.map(({ id, area }) =>
          <Grid item md={3} xs={4} key={id}>
            <ReactHookFormCheckbox
              name="areasOfVolunteering"
              label={t(`AreasVO.${area}`)}
              value={area}
              control={control}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t('Volunteer.Do you possess any of the following skills/ abilities?')}
          {errors.specialSkills && <FormHelperText error>{errors.specialSkills?.message}</FormHelperText>}

        </Typography>
      </Grid>

      <Grid container item xs={12} style={{ marginBottom: 10 }}>
        {formDisplayData.skills.map(({ id, skill }) =>
          <Grid item md={3} xs={4} key={id}>
            <ReactHookFormCheckbox
              name="skillsToObtain"
              label={t(`Skills.${skill}`)}
              value={skill}
              control={control}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
      <Grid item md={8} xs={12}>
        <Typography variant="body1">
          {t('Volunteer.Do you have any special physical or medical conditions that have to be taken into consideration when assigning a volunteering opportunity?')}
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <Controller
          as={RadioGroup}
          control={control}
          name="specialCondition"
        >
          <FormControlLabel value={"true"} disabled={disabled} control={<Radio color="secondary" />} label={t('Common.yes')} />
          <FormControlLabel value={"false"} disabled={disabled} control={<Radio color="secondary" />} label={t('Common.no')} />
        </Controller>
        {errors.specialCondition && <FormHelperText error>{errors.specialCondition?.message}</FormHelperText>}
      </Grid>
      {(watchSpecialCondition == "true") && <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('Volunteer.If yes please specify')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.specialConditionDetails}
            helperText={errors.specialConditionDetails?.message}
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            name="specialConditionDetails"
            disabled={disabled}
          />
        </Grid>
      </React.Fragment>}


      <Grid item xs={8}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Do you consider yourself as a person with disabilities?')}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Controller
          as={RadioGroup}
          control={control}
          name="disabled"
        >
          <FormControlLabel value="true" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.yes')} />
          <FormControlLabel value="false" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.no')} />
        </Controller>
        {errors.disabled && <FormHelperText error>{errors.disabled?.message}</FormHelperText>}
      </Grid>


      <Grid item xs={8}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Have you ever been fined/ convicted / imprisoned for the violation of any law (excluding minor traffic violations)?')}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Controller
          as={RadioGroup}
          control={control}
          name="convicted"
        >
          <FormControlLabel value="true" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.yes')} />
          <FormControlLabel value="false" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.no')} />
        </Controller>
        {errors.convicted && <FormHelperText error>{errors.convicted?.message}</FormHelperText>}
      </Grid>
      {watchConvicted == "true" && <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1" component="h2">
            {t('Volunteer.If yes please provide details')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.convictionDetails}
            helperText={errors.convictionDetails?.message}
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            name="convictionDetails"
            disabled={disabled}
          />
        </Grid>
      </React.Fragment>}

      <Grid item xs={8}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Have you ever been involved by act or omission, in the commission of any act of sexual harasxsent, sexual exploitation or abuse?')}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Controller
          as={RadioGroup}
          control={control}
          name="abuse"
        >
          <FormControlLabel value="true" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.yes')} />
          <FormControlLabel value="false" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.no')} />
          {errors.abuse && <FormHelperText error>{errors.abuse?.message}</FormHelperText>}
        </Controller>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Do you have a valid driving license?')}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Controller
          as={RadioGroup}
          control={control}
          name="drivingLiscence"
        >
          <FormControlLabel value="true" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.yes')} />
          <FormControlLabel value="false" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.no')} />
          {errors.drivingLiscence && <FormHelperText error>{errors.drivingLiscence?.message}</FormHelperText>}
        </Controller>
      </Grid>

      {/* Referees */}

      <Grid item xs={8}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Are you willing and able to volunteer during an emergency as an emergency response/relief volunteer?')}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Controller
          as={RadioGroup}
          control={control}
          name="emergencyWill"
        >
          <FormControlLabel value="true" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.yes')} />
          <FormControlLabel value="false" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.no')} />
          {errors.emergencyWill && <FormHelperText error>{errors.emergencyWill?.message}</FormHelperText>}
        </Controller>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Have you previously received any training related to emergency response/humanitarian assistance?')}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Controller
          as={RadioGroup}
          control={control}
          name="emergencyPast"
        >
          <FormControlLabel value="true" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.yes')} />
          <FormControlLabel value="false" disabled={disabled} control={<Radio color="secondary" />} label={t('Common.no')} />
          {errors.emergencyPast && <FormHelperText error>{errors.emergencyPast?.message}</FormHelperText>}
        </Controller>
      </Grid>
      {watchEmergencyPast == "true" && <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1" component="h2">
            {t('Volunteer.If yes please specify')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.emergencyPastDescription}
            helperText={errors.emergencyPastDescription?.message}
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            name="emergencyPastDescription"
            disabled={disabled}
          />
        </Grid>
      </React.Fragment>}
      <Grid item xs={12}>
        <Typography variant="body1">
          {t('Volunteer.Please select the top 3 skills training you wish to obtain if you were to receive such training to enhance your volunteer experience')}
          {errors.skillsToObtain && <FormHelperText error>{errors.skillsToObtain?.message}</FormHelperText>}

        </Typography>
      </Grid>
      {/* <Grid container item xs={12} style={{ marginBottom: 10 }}>
        {[0, 1, 2, 3, 5, 6, 7, 8, 9, 10].map((value) => {
          return (
            <Grid item md={3} xs={4} key={value}>
              <FormControlLabel
                control={<Checkbox />}
                label={value}
                name={`specialSkills[${value}]`}
                inputRef={register}
              />
            </Grid>
          );
        })}
      </Grid> */}
      <Grid container item xs={12} style={{ marginBottom: 10 }}>
        {formDisplayData.skills.map(({ id, skill }) =>
          <Grid item md={3} xs={4} key={id}>
            <ReactHookFormCheckbox
              name="specialSkills"
              label={t(`Skills.${skill}`)}
              value={skill}
              control={control}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default withStyles(style)(OtherTab);