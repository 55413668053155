import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch} from 'react-router-dom';
import MessageCard from '../../components/MessageCard';
import LoginPage from '../../containers/LoginPage/LoginPage';
import { check } from '../../helper/route_helper'

function CustomRoute({path, component, routes, render, message}) {
  const access = useSelector(state => state.auth.access);
  
  return (
    check(routes, access, path) ? 
      <Route exact path={path} component = {component} render = {render}/>
      :
      localStorage.token ?
        <Route exact path={path} render={(props) => <MessageCard message={message}/>} />
      :
        <Route exact path={path} component = {LoginPage} />
  )
}

export default CustomRoute
