import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import useStyles from "./_styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import sdg1 from "../../images/SDG_01.png";
import sdg2 from "../../images/SDG_02.png";
import sdg3 from "../../images/SDG_03.png";
import sdg4 from "../../images/SDG_04.png";
import sdg5 from "../../images/SDG_05.png";
import sdg6 from "../../images/SDG_06.png";
import sdg7 from "../../images/SDG_07.png";
import sdg8 from "../../images/SDG_08.png";
import sdg9 from "../../images/SDG_09.png";
import sdg10 from "../../images/SDG_10.png";
import sdg11 from "../../images/SDG_11.png";
import sdg12 from "../../images/SDG_12.png";
import sdg13 from "../../images/SDG_13.png";
import sdg14 from "../../images/SDG_14.png";
import sdg15 from "../../images/SDG_15.png";
import sdg16 from "../../images/SDG_16.png";
import sdg17 from "../../images/SDG_17.png";
import { useTranslation } from "react-i18next";
import {compact_number} from "./utils/compact_number";

export default function SDG({data}) {
    const classes = useStyles();
    const {t} = useTranslation()

    /**
     * Resolve value of sdg box; default values is 0
     * @param id {number}
     */
    function sdgValue(id) {
        return compact_number(data[String(id)] || 0)
    }

    return(
        <Grid item className={classes.sdgSection}>
            <Divider variant="fullWidth" />
            <Grid container style={{marginTop: '50px'}}>
                <Grid item lg={12}>
                    <Paper elevation={3} className={classes.paper} style={{padding: '10px 5% ', margin: 0}}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Grid item>
                                <div style={{width: '100%', margin: '5% 0 2%'}}>
                                    <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                    {t('LandingPage.sdg.title')}
                                    </Typography>
                                    <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                                    {t('LandingPage.sdg.subtitle')}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#ea1b2d",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg1} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(1)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#d19e28",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg2} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(2)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#269a45",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg3} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(3)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#c11e31",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg4} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(4)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#ef4129",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg5} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(5)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#00add8",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg6} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(6)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#fbb611",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg7} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(7)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#8e1737",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg8} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(8)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#f26d22",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg9} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(9)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#df1382",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg10} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(10)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#f99d22",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg11} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(11)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#cc8b27",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg12} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(12)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#47773d",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg13} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(13)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#007cba",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg14} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(14)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#3eae48",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg15} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(15)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                    <Paper elevation={3} style={{padding: '5%', background: "#01548a",}}>
                                        <div style={{width: '100%'}}>
                                            <img src={sdg16} width={'100%'}/>
                                        </div>
                                    </Paper>
                                    <div style={{width: '100%', margin: '5% auto 0'}}>
                                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                            {sdgValue(16)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} style={{margin: '0 5px'}}>
                                  <Paper elevation={3} style={{padding: '5%', background: "#1a3666",}}>
                                    <div style={{width: '100%'}}>
                                      <img src={sdg17} width={'100%'}/>
                                    </div>
                                  </Paper>
                                  <div style={{width: '100%', margin: '5% auto 0'}}>
                                    <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                                        {sdgValue(17)}
                                    </Typography>
                                  </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}
