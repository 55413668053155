import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
    AppBar,
    Avatar,
    Grid,
    Paper,
    Typography
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import NonFormalVORow from "./NonFormalVORow";
import { fetchNonFormalVOList } from "../../../functions/Admin/NonFormalVOApproval/fetchNonFormalVOList";
// import { fetchVio } from "../../../functions/public_profiles/fetch_user";
import Alert from "@material-ui/lab/Alert";
import {TabPanel} from "@material-ui/lab";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "avatar",
        numeric: false,
        disablePadding: true,
        label: "",
    },
    { id: "vioName", numeric: false, disablePadding: false, label: "VIO Name" },
    { id: "supervisorName", numeric: false, disablePadding: false, label: "Supervisor Name" },
    { id: "supervisorEmail", numeric: false, disablePadding: false, label: "Supervisor Email" },
    { id: "title", numeric: false, disablePadding: false, label: "Opportunity Title" },
];

function EnhancedTableHead(props) {
    const [open, setOpen] = React.useState(false);
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id === "avatar" ? (
                            <div>
                                <Avatar style={{ margin: 6 }}>
                                    <AccountCircle />
                                </Avatar>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </div>
                        ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            )}
                    </TableCell>
                ))}
                <TableCell align="center" padding="checkbox" colSpan={2}>
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: '#e1ebff',
        padding: 12,
        borderRadius: 4,
        margin: '16px 0'
      },
      table: {
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100vw - 146px)', overflowX: 'auto'
        },
      },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function NonFormalVOList(props) {
    const [createdVOList, setCreatedVOList] = React.useState([]);
    const [approvedVOList, setApprovedVOList] = React.useState([]);
    const [rejectedVOList, setRejectedVOList] = React.useState([]);
    const [fetchError, setFetchError] = React.useState(false);
    const [showAlertApproved, setShowAlertApproved] = React.useState(false);
    const [showAlertRejected, setShowAlertRejected] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    React.useEffect(async () => {
        let mounted = true;
        let data = await fetchNonFormalVOList();
        if (mounted) {
            if (data.error) {
                setFetchError(true);
            }
            else {
                setCreatedVOList(data.item.filter(VO => VO.status === 'CREATED'))
                setApprovedVOList(data.item.filter(VO => VO.status === 'APPROVED'))
                setRejectedVOList(data.item.filter(VO => VO.status === 'REJECTED'))
                setFetchError(false);
            }
        }
    }, [])
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        
    }, [selected]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = VOList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isVOApproved = (row) => {
        row.status === "CREATED" && setCreatedVOList(createdVOList.filter(VO => VO.id !== row.id));
        row.status === "REJECTED" && setRejectedVOList(rejectedVOList.filter(VO => VO.id !== row.id));
        setApprovedVOList([...approvedVOList,row])
    }
    const isVORejected = (row) => {
        row.status === "CREATED" && setCreatedVOList(createdVOList.filter(VO => VO.id !== row.id));
        row.status === "APPROVED" && setApprovedVOList(approvedVOList.filter(VO => VO.id !== row.id));
        setRejectedVOList([...rejectedVOList,row])
    }
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const createdEmptyRows =
        rowsPerPage - Math.min(rowsPerPage, createdVOList.length - page * rowsPerPage);
    const approvedEmptyRows =
        rowsPerPage - Math.min(rowsPerPage, approvedVOList.length - page * rowsPerPage);
    const rejectedEmptyRows =
        rowsPerPage - Math.min(rowsPerPage, rejectedVOList.length - page * rowsPerPage);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    return (
        <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5" color="primary" align="center">
                        Non Formal Volunteer Approval
                </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.paper}>
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label="Pending"  />
                                <Tab label="Approved"  />
                                <Tab label="Rejected" />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={createdVOList.length}
                                    />
                                    <TableBody>
                                        {stableSort(createdVOList, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map( (row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                // let result = await fetchVio(row.id);
                                                // 
                                                const isApproved = () => isVOApproved(row);
                                                const isRejected = () => isVORejected(row);
                                                const showAlertApproved = () => {
                                                    setShowAlertApproved(true);
                                                    setTimeout(function () {
                                                        setShowAlertApproved(false)
                                                    }, 3000);
                                                };
                                                const showAlertRejected = () => {
                                                    setShowAlertRejected(true);
                                                    setTimeout(function () {
                                                        setShowAlertRejected(false)
                                                    }, 3000);
                                                };
                                                const setError = () => setIsError(true);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <NonFormalVORow
                                                        row={row}
                                                        labelId={labelId}
                                                        classes={classes}
                                                        isItemSelected={isItemSelected}
                                                        isApproved={isApproved}
                                                        isRejected={isRejected}
                                                        showAlertApproved={showAlertApproved}
                                                        showAlertRejected={showAlertRejected}
                                                        isError={setError}
                                                    />
                                                );
                                            })}
                                        {createdEmptyRows > 0 && (
                                            <TableRow style={{ height: (dense ? 33 : 53) * createdEmptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={createdVOList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={createdVOList.length}
                                    />
                                    <TableBody>
                                        {stableSort(approvedVOList, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map( (row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                // let result = await fetchVio(row.id);
                                                // 
                                                const isApproved = () => isVOApproved(row);
                                                const isRejected = () => isVORejected(row);
                                                const showAlertApproved = () => {
                                                    setShowAlertApproved(true);
                                                    setTimeout(function () {
                                                        setShowAlertApproved(false)
                                                    }, 3000);
                                                };
                                                const showAlertRejected = () => {
                                                    setShowAlertRejected(true);
                                                    setTimeout(function () {
                                                        setShowAlertRejected(false)
                                                    }, 3000);
                                                };
                                                const setError = () => setIsError(true);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <NonFormalVORow
                                                        row={row}
                                                        labelId={labelId}
                                                        classes={classes}
                                                        isItemSelected={isItemSelected}
                                                        isApproved={isApproved}
                                                        isRejected={isRejected}
                                                        showAlertApproved={showAlertApproved}
                                                        showAlertRejected={showAlertRejected}
                                                        isError={setError}
                                                    />
                                                );
                                            })}
                                        {approvedEmptyRows > 0 && (
                                            <TableRow style={{ height: (dense ? 33 : 53) * approvedEmptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={approvedVOList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <TableContainer className={classes.table} component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={createdVOList.length}
                                    />
                                    <TableBody>
                                        {stableSort(rejectedVOList, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map( (row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                // let result = await fetchVio(row.id);
                                                // 
                                                const isApproved = () => isVOApproved(row);
                                                const isRejected = () => isVORejected(row);
                                                const showAlertApproved = () => {
                                                    setShowAlertApproved(true);
                                                    setTimeout(function () {
                                                        setShowAlertApproved(false)
                                                    }, 3000);
                                                };
                                                const showAlertRejected = () => {
                                                    setShowAlertRejected(true);
                                                    setTimeout(function () {
                                                        setShowAlertRejected(false)
                                                    }, 3000);
                                                };
                                                const setError = () => setIsError(true);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <NonFormalVORow
                                                        row={row}
                                                        labelId={labelId}
                                                        classes={classes}
                                                        isItemSelected={isItemSelected}
                                                        isApproved={isApproved}
                                                        isRejected={isRejected}
                                                        showAlertApproved={showAlertApproved}
                                                        showAlertRejected={showAlertRejected}
                                                        isError={setError}
                                                    />
                                                );
                                            })}
                                        {rejectedEmptyRows > 0 && (
                                            <TableRow style={{ height: (dense ? 33 : 53) * rejectedEmptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={rejectedVOList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </TabPanel>

                    </div>
                </Grid>
                {showAlertApproved ? (isError ? <Alert severity="error">Something went wrong in approving ! </Alert> : <Alert severity="success">VIO approved Successfully !</Alert>) : null}
                {showAlertRejected ? (isError ? <Alert severity="error">Something went wrong in rejecting !</Alert> : <Alert severity="success">VIO rejected Successfully !</Alert>) : null}
            </Grid>
        </Paper>
    );
}
