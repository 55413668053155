import {
    TableCell,
    TableRow,
    Avatar,
} from "@material-ui/core";
import React from "react";
import { green, red } from '@material-ui/core/colors';
import Button from "@material-ui/core/Button"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const theme = createMuiTheme({
    palette: {
        primary: green,
        secondary: red,
    },
});

function NonFormalVORow({ row, labelId, isItemSelected, classes , isError}) {
    const [fetchError, setFetchError] = React.useState(false);
    const {t} = useTranslation()
    
    return (
        <React.Fragment>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
            >
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    <Avatar
                        alt={row.name}
                        style={{
                            color: "white",
                            backgroundColor: "#23395B",
                            margin: '20px 6px'
                        }}
                        src={row.cardImage}
                    />
                </TableCell>
                <TableCell align="left">
                    {row.supervisorName}
                </TableCell>
                <TableCell align="left">{row.supervisorEmail}</TableCell>
                <TableCell align="left"><Link to={`/NonFormalVO/${row.id}`} >{row.title}</Link></TableCell>
                <TableCell align="left">{t(`Common.${row.status.toLowerCase()}`)}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default NonFormalVORow;
