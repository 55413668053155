import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FileCopySharpIcon from "@material-ui/icons/FileCopySharp";
import {Alert} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {allRequests, proceedRequest} from "./utils";

const styles = {
    section: {
        marginBottom: "20px",
        marginTop: "20px",
    },
};

export default function ShareOpportunity() {

    const [requests, setRequests] = useState([]);
    const [alert, setAlert] = useState({})
    const userId = useSelector(state => state.auth.id)
    const history = useHistory()

    useEffect(() => {
        (async () => {
            if (!userId) return
            const [code, data] = await allRequests(userId)
            if (code === 200) {
                
                setRequests(data.map(r => ({...r, acceptLoading: false, rejectLoading: false, proceed: false})))
            }
        })()
    }, [userId]);

    const onAccept = async (request) => {
        request.acceptLoading = true
        setRequests((prev) => prev.map(r => r.id === request.id? request: r))
        const [code] = await proceedRequest(request.Opportunity.id, userId, 'Accept')
        if (code === 200) {
            setAlert({severity: 'success', message:'Successfully sent a request to share this opportunity'})
            request.acceptLoading = false
            request.proceed = true
            setRequests((prev) => prev.map(r => r.id === request.id? request: r))
        } else {
            setAlert({severity: 'error', message:'Something wrong while accepting!'})
            request.acceptLoading = false
            setRequests((prev) => prev.map(r => r.id === request.id? request: r))
        }
    }

    const onReject = async (request) => {
        request.rejectLoading = true
        setRequests((prev) => prev.map(r => r.id === request.id? request: r))
        const [code] = await proceedRequest(request.Opportunity.id, userId, 'Reject')
        if (code === 200) {
            setAlert({severity: 'success', message:'Request is accepted'})
            request.rejectLoading = false
            request.proceed = true
            setRequests((prev) => prev.map(r => r.id === request.id? request: r))
        } else {
            setAlert({severity: 'error', message:'Something wrong while rejecting!'})
            request.rejectLoading = false
            setRequests((prev) => prev.map(r => r.id === request.id? request: r))
        }
    }

    return (
        <Container
            maxWidth="md"
            style={{marginTop: "15px", marginBottom: "15px"}}
        >
            <Paper elevation={0} style={{padding: "20px"}}>
                <Grid item xs={12} style={styles.section}>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                    >
                        <Grid item>
                            <Typography variant="h5">
                                Requests to share opportunities with you,
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider/>
                </Grid>
                <Grid item xs={12} style={styles.section}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                    >
                        <Grid item xs={12} md={7}>
                            {(['error', 'success', 'info'].includes(alert?.severity || '')) &&
                            <Alert severity={alert.severity}>{alert.message}</Alert>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justify="space-around" style={styles.section}>
                    <Grid item xs={12} alignItems="left">
                        <List dense={false}>
                            {requests.map((r) => (
                                <ListItem>
                                    <ListItemIcon>
                                        <FileCopySharpIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={r.Opportunity.title}
                                        secondary={'from ' + r.Opportunity.Vio.name}
                                    />
                                    <ListItemSecondaryAction>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            style={{marginLeft: '10px', backgroundColor: '#dd670c', color: 'white'}}
                                            onClick={() => history.push("/volunteerOpportunity/" + r.Opportunity.id.toString())}
                                        >
                                            View
                                        </Button>
                                        <Button
                                            disabled={r.proceed || r.rejectLoading}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onAccept(r)}
                                            style={{marginLeft: '10px'}}
                                        >
                                            {r.acceptLoading && (
                                                <CircularProgress
                                                    style={{
                                                        color: "white",
                                                        marginRight: "10px",
                                                    }}
                                                    size={15}
                                                />
                                            )}
                                            Accept
                                        </Button>
                                        <Button
                                            disabled={r.proceed || r.acceptLoading}
                                            variant="contained"
                                            onClick={() => onReject(r)}
                                            style={{marginLeft: '10px', backgroundColor: (r.proceed || r.acceptLoading)?'rgba(0, 0, 0, 0.12)' :'#f52929', color: 'white'}}
                                        >
                                            {r.rejectLoading && (
                                                <CircularProgress
                                                    style={{
                                                        color: "white",
                                                        marginRight: "10px",
                                                    }}
                                                    size={15}
                                                />
                                            )}
                                            Reject
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}
