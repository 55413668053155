import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../Constant";
import { registerVIOPostUrl } from '../../containers/SignUpPage/Constants';

export const submitVIORegister = async ({name,email,password,type,description}) => {
    
    const registerUrl = serverHost + ":" + serverPort + registerVIOPostUrl;
    try {
        const response = await axios.post(registerUrl,{name,email,password,type,description});
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
       
    }

    catch (err) {
        
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

