import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { addReferee } from '../../functions/Referee/apiCalls';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { emailRegExp, phoneRegExp } from './Constants';
import { Grid, Paper, Typography } from '@material-ui/core';
import IconTextField from '../../components/IconTextField';
import CustomButton from '../../components/CustomButton';
import Alert from '@material-ui/lab/Alert';

import { tokenKey } from '../../Constant';
import { useDispatch } from 'react-redux'
import * as AuthActions from "../../actions/AuthActions";
// import { Refresh } from '@material-ui/icons';


function RefereeForm() {

  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const schema = yup.object().shape({
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    mobile: yup.string().matches(phoneRegExp, 'mobile number is not valid'),
    email: yup.string().matches(emailRegExp, 'Email is not valid')
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  React.useEffect(() => {

  }, [refresh])


  let history = useHistory();

  const createAddress = (data) => (
    data.no + ", " + data.street + ", " + data.city + ", " + data.state
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const resetToken = async (token) => dispatch(AuthActions.resetToken(token));

  const onSubmit = async (data) => {
    data.address = createAddress(data);
    delete data.no;
    delete data.street;
    delete data.city;
    delete data.state;
    
    const result = await addReferee(data);
    if (result.error) {
      setError(result.error)
      setErrorMessage(result.message)
    }
    else {
      setError(result.error);
      setErrorMessage("");
      const token = result.token;
      
      localStorage.setItem(tokenKey,token);
      await resetToken(token);
      history.push("/viewReferee")
    }
  }

  return (
    <Paper style={{ padding: '18px 36px', maxWidth: 800, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          <Grid item style={{ padding: '20px 0' }}>
            <Typography variant="h5" color="primary">{t('Refree.Add Referee')}</Typography>
          </Grid>
          <Grid item xs={12}>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Refree.Referee Name')}
              inputRef={register({ required: true })}
              required
              name="name"
              error={!!errors.name}
              helperText={errors.name && "Please enter the Referee Name"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Volunteer.Door Number')}
              inputRef={register({ required: true })}
              required
              type="no"
              name="no"
              error={!!errors.no}
              helperText={errors.no && "Please enter the number"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Volunteer.Street')}
              inputRef={register({ required: true })}
              required
              type="street"
              name="street"
              error={!!errors.street}
              helperText={errors.street && "Please enter the street"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Refree.City')}
              inputRef={register({ required: true })}
              required
              type="city"
              name="city"
              error={!!errors.city}
              helperText={errors.city && "Please enter the City"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Refree.State')}
              inputRef={register({ required: true })}
              required
              type="state"
              name="state"
              error={!!errors.state}
              helperText={errors.state && "Please enter the State"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Common.landline')}
              inputRef={register({ required: false })}
              required
              type="phone"
              name="phone"
              error={!!errors.phone}
              helperText={errors.phone && "Please enter a Valid Phone number"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Common.mobile')}
              inputRef={register({ required: true })}
              required
              type="mobile"
              name="mobile"
              error={!!errors.mobile}
              helperText={errors.mobile && "Please enter a Valid Mobile number"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconTextField
              label={t('Common.email')}
              inputRef={register({ required: true, })}
              required
              type="email"
              name="email"
              error={!!errors.email}
              helperText={errors.email && "Please enter a Valid Email Address"}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton label={t('Common.add')} type="submit" variant="contained" fullWidth />
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default RefereeForm

