import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    // inline: {
    //   display: 'inline',
    // },

    listItem:{
        marginHorizontal:'200px'
    }
  }));

export default function ListView(props){

  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
      <ListItemIcon className={classes.listItem}>
              <Checkbox
                edge="start"
                checked={false}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': '1' }}
              />
            </ListItemIcon>
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Volunteer name one"
        //   secondary={
        //     <React.Fragment>
        //       <Typography
        //         component="span"
        //         variant="body2"
        //         className={classes.inline}
        //         color="textPrimary"
        //       >
        //         Ali Connors
        //       </Typography>
        //       {" — I'll be in your neighborhood doing errands this…"}
        //     </React.Fragment>
        //   }
        />
        <ListItemSecondaryAction className={classes.listItem}>
              <IconButton edge="end" aria-label="comments">
                <EditIcon />
              </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
      <ListItemIcon>
              <Checkbox
                edge="start"
                checked={false}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': '1' }}
              />
            </ListItemIcon>
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Volunteer name two"
        //   secondary={
        //     <React.Fragment>
        //       <Typography
        //         component="span"
        //         variant="body2"
        //         className={classes.inline}
        //         color="textPrimary"
        //       >
        //         to Scott, Alex, Jennifer
        //       </Typography>
        //       {" — Wish I could come, but I'm out of town this…"}
        //     </React.Fragment>
        //   }
        />
        <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments">
                <EditIcon />
              </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
      <ListItemIcon>
              <Checkbox
                edge="start"
                checked={false}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': '1' }}
              />
            </ListItemIcon>
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Volunteer name three"
        //   secondary={
        //     <React.Fragment>
        //       <Typography
        //         component="span"
        //         variant="body2"
        //         className={classes.inline}
        //         color="textPrimary"
        //       >
        //         Sandra Adams
        //       </Typography>
        //       {' — Do you have Paris recommendations? Have you ever…'}
        //     </React.Fragment>
        //   }
        />
        <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments">
                <EditIcon />
              </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}