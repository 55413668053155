import { Grid, Container, Paper, Divider } from "@material-ui/core";
import React from "react";
import ResetPassword from "./ResetPassword"
import Typography from "@material-ui/core/Typography";
import DeleteAccount from "./DeleteAccount";
import { useTranslation } from "react-i18next";

const styles = {
    section: {
        marginBottom: '40px',
        marginTop: '15px'
    }
}

export default function ChangePassword() {
    const {t} = useTranslation()
    return (
        <Container maxWidth="md" style={{ marginTop: 40, marginBottom: 40 }}>
            <Paper style={{ padding: '18px 36px' }} >
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="center"
                    >
                        <Grid item style={{ padding: '20px 0' }}>
                            <Typography variant="h5" align="center" color="primary">{t('Settings.title')}</Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>

                <Grid container justify="space-around" style={styles.section}>
                    <Grid item xs={12} sm={6} md={4} alignItems="left" style={{textAlign: "center"}}>
                        <Typography variant="h6">
                            {t('Settings.resetPassword')}
                        </Typography>

                        <ResetPassword />
                    </Grid>
                </Grid>

                <Divider />
                <Grid container justify="space-around" style={styles.section}>
                    <Grid item xs={12} sm={6} md={4} alignItems="left" style={{textAlign: "center"}}>
                        <Typography variant="h6">
                        {t('Settings.termination')}
                        </Typography>
                        <DeleteAccount />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

