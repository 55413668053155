import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserRoutes from './ACL'

import { Container } from '@material-ui/core';
import LandingPage from "../LandingPage";

import { useDispatch } from 'react-redux'
import * as AuthActions from "../../actions/AuthActions";
import { PUBLIC } from './Constants';
import CustomRoute from './CustomRoute';
import Footer from '../../components/Footer';

function Router() {

  const dispatch = useDispatch();

  const recoverStore = (data) => dispatch(AuthActions.recoverStore(data));

  const setData = () => {
    const token = localStorage.token
    if (token != null) {
      recoverStore(token)
    }
  }
  setData();

  return (
    <React.Fragment>
      <UserRoutes />
      <Footer />
    </React.Fragment>
  )
}

export default Router;
