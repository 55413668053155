import {serverHost,serverPort,hostPortDivider,tokenKey} from "../../Constant";
import axios from 'axios';
import {addRefereeUrl,getVolunteerUrl ,submitUrl, getRefereeUrl } from '../../containers/Referees/Constants';



export const addReferee = async (data) => {
    
    const addUrl = serverHost+hostPortDivider+serverPort+addRefereeUrl;
    try{
        const response = await axios.post(addUrl,
            data
        ,
        {
            headers: {
                'token': localStorage.getItem(tokenKey)
            } 
        });
        
        if (response.status == 200) {
            
                const result =  {
                    error:false,
                    token:response.data.token
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }
    }
    catch(err){
        
        const result = {
            message: err.response ? err.response.data.message : "Something went wrong!",
            error:true
        }
        
        return result;
    } 
}


  export const getVolunteerData = async (token) => {
    
    const fetchUrl = serverHost+hostPortDivider+serverPort+getVolunteerUrl+`/${token}`;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                'token': localStorage.getItem(tokenKey)
            } 
        });
        
        
        if (response.status == 200) {
            
                const result =  {
                    volunteer:response.data,
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                item:response.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            item:err.message,
            error:true
        }
        
        return result;
    } 
  
  
  };

  export const postResult = async (data, refereeToken) => {
    
    const refereeSubmitUrl = serverHost+hostPortDivider+serverPort+submitUrl;
    try{
        const response = await axios.post(refereeSubmitUrl,
            {...data, refereeToken}
        ,
        {
            headers: {
                'token': localStorage.getItem(tokenKey)
            } 
        });
        
        if (response.status == 200) {
            
                const result =  {
                    error:false,
                    summary:response.data
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }
    }
    catch(err){
        
        const result = {
            message:err.message,
            error:true
        }
        
        return result;
    } 
}

export const getRefereeData = async () => {
    
    const fetchUrl = serverHost + hostPortDivider + serverPort + getRefereeUrl;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                'token': localStorage.getItem(tokenKey)
            } 
        });
        
        
        if (response.status == 200) {
            
                const result =  {
                    referee:response.data,
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }
    }
    catch(err){
        
        const result = {
            message:err.message,
            error:true
        }
        
        return result;
    } 
  };

