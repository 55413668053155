import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Divider, Card, Paper } from '@material-ui/core';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Typography from '@material-ui/core/Typography';
import 'date-fns';
import VOContainer from './VIOHome/VOContainer';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import MessageCard from '../MessageCard';

function VIOCard({ name, value }) {
  return (
    <Card style={{ padding: 20, backgroundColor: '#32c3f3', color: 'white' }} >
      <Typography variant="body1" noWrap>
        {name}
      </Typography>
      <Typography variant="h5">
        {value}
      </Typography>
    </Card>
  )
}

export default function VIOHome({ fetchDetails, voRows, page, pageCount, handlePageChange, handleSearchVO }) {

  const vioName = useSelector(state => state.auth.name);
  const {t} = useTranslation()

  if (fetchDetails)
    return (
      <React.Fragment>
        <Grid container spacing={2} style={{ marginTop: 40, marginBottom: 12, color: 'white' }}>
          <Grid item xs={12}>
            <Typography variant="h5" >
              {vioName}
            </Typography>
            <Typography variant="h6" >
              {t('VIOHome.title')}
              </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <VIOCard name={t('VIOHome.total')} value={fetchDetails.total} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VIOCard name={t('VIOHome.ongoing')} value={fetchDetails.onGoing} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VIOCard name={t('VIOHome.completed')} value={fetchDetails.completed} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VIOCard name={t('VIOHome.pending')} value={fetchDetails.pending} />
          </Grid>
        </Grid>
        <Paper style={{padding: 18}}>
          <Grid container>
            <Grid item xs={12}>
              <VOContainer
                voRows={voRows}
                page={page}
                pageCount={pageCount}
                handlePageChange={handlePageChange}
                handleSearchVO={handleSearchVO}
              />
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  style={{ width: '100%', paddingTop: 20 }}
                  boundaryCount={2}
                />
              </Grid>

            </Grid>

          </Grid>
        </Paper>
      </React.Fragment>
    );
  return (
    <MessageCard/>
  )
}