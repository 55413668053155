import axios from 'axios';
import { serverHost, serverPort, tokenKey, type } from "../../../Constant";
import {
    saveVIOProfilePrivateAcademiaUrl,
    saveVIOProfileStateInstituteUrl,
    saveVIOProfilePrivateOrganizationUrl,
    saveVIOProfileStateAcademiaUrl,
    saveVIOProfileNonProfitUrl,
    saveVIOProfileSchoolUrl
} from '../../../containers/VIOProfile/Constants';

export const saveVIOProfile = async (profileDetails, vioType) => {

    var profileSaveUrl = ""

    switch (vioType) {
        case "PRIVATEACADEMIA":
            profileSaveUrl = saveVIOProfilePrivateAcademiaUrl;
            break;
        case "STATEINSTITUTE":
            profileSaveUrl = saveVIOProfileStateInstituteUrl;
            break;
        case "STATEACADEMIA":
            profileSaveUrl = saveVIOProfileStateAcademiaUrl;
            break;
        case "PRIVATEORGANIZATION":
            profileSaveUrl = saveVIOProfilePrivateOrganizationUrl;
            break;
        case "NONPROFIT":
            profileSaveUrl = saveVIOProfileNonProfitUrl;
            break;
        case "SCHOOL":
            profileSaveUrl = saveVIOProfileSchoolUrl;
            break;
    }

    const saveUrl = serverHost + ":" + serverPort + profileSaveUrl;

    try {
        const response = await axios.put(saveUrl, { ...profileDetails }, { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                token: response.data.token,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

