import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import FetchDetails from './FetchDetails';
import VolunteerFeedback from './VolunteerFeedback';
import { defaultFetchDetails } from './Constant'
import { volunteerFetchFeedback, submitVolunterFeedback } from '../../functions/feedback/fetchDetails'
import { Grid, Typography, Paper } from '@material-ui/core';
import MessageCard from '../../components/MessageCard';
import {useSnackbar} from "notistack";
import LoadingPage from "../../components/LoadingPage";
import {useHistory} from 'react-router-dom';
// import Error from '../../components/Error'

function VolunteerFeedbackPage({ match }) {

    const [feedbackDetails, setFeedbackDetails] = useState({});
    const [fetchDetails, setFetchDetails] = useState({});
    const [error, setError] = useState(false);
    const [isSubmitted, setSubmit] = useState(false);
    const {t} = useTranslation();
    const { volunteerId, opportunityId, vioId } = match.params

    const state = true;//Identify the user type.

    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();

    useEffect(async () => {

        let mounted = true;
        

        let result = await volunteerFetchFeedback(volunteerId, opportunityId, vioId);

        

        if (mounted) {
            setFetchDetails(result);
            setError(result.error);
            if (result.error) {
                setFetchDetails(defaultFetchDetails);
            }
        }

        
        return () => mounted = false;


    }, [error, isSubmitted])

    const onSubmit = async (data) => {
        
        setFeedbackDetails(data);
        
        
        await submit(data)
    }
    const onClick = (name, value, title, key) => {
        setFetchDetails({ ...fetchDetails, [title]: { ...fetchDetails[title], [key]: value } })
    }

    const submit = async (feedbacks) => {
        
        const res = await submitVolunterFeedback(fetchDetails, feedbacks)
        if (res.error) {
            setError(res.error)
        }
        else {
            setSubmit(true)
            enqueueSnackbar("Thank you so much for providing your valuable feedback!", {
                variant: 'success',
                persist: false
            })
            history.push("/vioHome")
        }
    }


    return (
        (fetchDetails.vio) ?
            // error?
            // <Error/>
            // :
            !fetchDetails.alreadySubmitted ?
                <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ margin: '20px 0' }}>
                            <Typography variant="h5" color="primary" align="center">
                                {t('Feedback.Feedback')}
                            </Typography>
                        </Grid>
                        <FetchDetails
                            volunteer={fetchDetails.volunteer}
                            vio={fetchDetails.vio}
                            opportunity={fetchDetails.opportunity}
                            onClick={onClick}
                            isVolunteer={true}
                        />
                        <VolunteerFeedback
                            onSubmitDetails={onSubmit}
                            volunteerName={fetchDetails.volunteer.firstName}
                        />

                    </Grid>
                </Paper>
                : <MessageCard error={false} message="Thank you for submitting your response!!" />
            : <LoadingPage />
    )
}

export default VolunteerFeedbackPage
