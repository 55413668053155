import { hostPortDivider, serverHost, serverPort, tokenKey } from "../../Constant";
import axios from 'axios';
import { getAgreementUrl, volunteerConfirmAgreementUrl, vioConfirmAgreementUrl, volunteerFetchAgreementUrl, vioFetchAgreementUrl } from '../../containers/Agreement/Constants'

export const volunteerFetchAgreement = async (volunteerId, vioId, opportunityId) => {

    return await fetchAgreementDetails(volunteerId, vioId, opportunityId, volunteerFetchAgreementUrl);

}

export const vioFetchAgreement = async (volunteerId, vioId, opportunityId) => {

    return await fetchAgreementDetails(volunteerId, vioId, opportunityId, vioFetchAgreementUrl);

}

export const fetchAgreementDetails = async (volunteerId, opportunityId, vioId, url) => {

    const fetchUrl = serverHost + hostPortDivider + serverPort + url;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                },
                params: {
                    volunteerId, opportunityId, vioId
                }
            });
        if (response.status == 200) {
            const result = {
                volunteer: response.data.volunteer,
                vio: response.data.vio,
                opportunity: response.data.opportunity,
                alreadySubmitted: response.data.alreadySubmitted,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                message: response.message,
                error: true,
                status: response.status
            }
            return result;
        }

    }
    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};

export const confirmAgreement = async (volunteerId, vioId, opportunityId, confirmUrl) => {


    const agreementUrl = serverHost + hostPortDivider + serverPort + confirmUrl;
    try {
        const response = await axios.post(agreementUrl,
            {
                volunteerId, opportunityId, vioId
            },
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                }
            });
        if (response.status == 200) {
            const result = {
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                message: response.message,
                error: true,
                status: response.status
            }
            return result;
        }


    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


}

export const volunteerConfirmAgreement = async (volunteerId, vioId, opportunityId) => {

    return await confirmAgreement(volunteerId, vioId, opportunityId, volunteerConfirmAgreementUrl);

}

export const vioConfirmAgreement = async (volunteerId, vioId, opportunityId) => {

    return await confirmAgreement(volunteerId, vioId, opportunityId, vioConfirmAgreementUrl);

}