import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, TextField } from '@material-ui/core';
import style from './ProfileComplete.styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import { Controller } from "react-hook-form";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import 'date-fns';

import ReactHookFormSelect from '../ReactHookFormSelect';
import ReactHookFormDatePicker from '../ReactHookFormDatePicker';
import ReactHookFormMultipleSelect from '../ReactHookFormMultipleSelect'
import CustomLocationSelect from '../CustomLocationSelect';
import RoundImage from "../../containers/Admin/RoundImage";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function GeneralTab(props) {
  const { children, setValue, register, control, errors, watch, formDisplayData, disabled } = props;
  const { t, i18n } = useTranslation();
  const watchNationality = watch("currentNationality")
  // const watchFields = watch(["currentNationality"]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <Typography variant="h6" color="primary">
          {t("Volunteer.General Information")}
        </Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <ReactHookFormSelect
          label={t("Volunteer.title")}
          disabled={disabled}
          name="title"
          control={control}
          variant="outlined"
          fullWidth
          defaultValue={""}

          error={errors.title}
          helperText={errors.title?.message}
        >
          <MenuItem value={"Mr"}>{t("Volunteer.Mr")}</MenuItem>
          <MenuItem value={"Mrs"}>{t("Volunteer.Mrs")}</MenuItem>
          <MenuItem value={"Ms"}>{t("Volunteer.Ms")}</MenuItem>
          <MenuItem value={"Rev"}>{t("Volunteer.Rev")}</MenuItem>
          <MenuItem value={"Dr"}>{t("Volunteer.Dr")}</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6} xs={8}>
        <TextField
          inputRef={register}
          error={errors.nameInNic}
          helperText={errors.nameInNic?.message}
          disabled={disabled}
          name="nameInNic"
          variant="outlined"
          fullWidth
          label={t("Volunteer.Name according to NIC/ Valid Sri Lankan Visa")}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          inputRef={register}
          error={errors.nic}
          helperText={errors.nic?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.NIC")}
          disabled={disabled}
          name="nic"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("Volunteer.If you are a non Sri Lankan who has valid resident visa or short term visa")}
        </Typography>
      </Grid>
      <Grid item md={4} xs={6}>
        <ReactHookFormDatePicker disabled={disabled}
          name="visaIssuedDate" label={t("Volunteer.Visa Issued Date")} control={control} />
      </Grid>
      <Grid item md={4} xs={6}>
        <ReactHookFormDatePicker disabled={disabled}
          name="visaExpiryDate" label={t("Volunteer.Visa Expiry Date")} control={control} />
      </Grid>
      <Grid item xs={12} />
      <Grid item md={4} xs={6}>
        <ReactHookFormDatePicker disabled={disabled}
          name="dateOfBirth" label={t("Volunteer.Date of Birth")} control={control} />
      </Grid>
      {/* <Grid item md={4} xs={6}>
        <TextField
          inputRef={register}
          style={{ marginTop: 15 }}
          // error={errors.countryOfBirth}
          // helperText={errors.countryOfBirth?.message}
          fullWidth
          variant="outlined"
          label="Country of Birth"
          disabled={disabled}
          name="countryOfBirth"
        />
      </Grid> */}
      {/* Add a drop Down list off Countries */}

      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t("Volunteer.Country of Birth")}
          disabled={disabled}
          name="countryOfBirth"
          style={{ marginTop: 15 }}
          control={control}
          variant="outlined"
          defaultValue=""
          error={errors.countryOfBirth}
          helperText={errors.countryOfBirth?.message}
          fullWidth
        >
          {formDisplayData.country_of_birth.map(({ id, country, sinhala_name, tamil_name }) => (
            <MenuItem value={country} key={id}>
              {i18n.language === "si" ? sinhala_name : i18n.language === "ta" ? tamil_name : country}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>


      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t("Volunteer.Current Nationality")}
          disabled={disabled}
          name="currentNationality"
          style={{ marginTop: 15 }}
          control={control}
          variant="outlined"
          defaultValue=""
          error={errors.currentNationality}
          helperText={errors.currentNationality?.message}
          fullWidth
        >
          <MenuItem value={"Sri Lankan"}>{t("Volunteer.Sri Lankan")}</MenuItem>
          <MenuItem value={"Foreign"}>{t("Volunteer.Foreign")}</MenuItem>
          <MenuItem value={"Dual"}>{t("Volunteer.Dual")}</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      {((watchNationality == "Foreign") || (watchNationality == "Dual") ? true : false) && <Grid item xs={12} >
        <ReactHookFormMultipleSelect
          label={t("Volunteer.Select Country/Countries")}
          disabled={disabled}
          name="countries"
          style={{ marginTop: 15, marginBottom: 10 }}
          control={control}
          variant="outlined"
          fullWidth
          error={errors.countries}
          helperText={errors.countries?.message}
          defaultValue={[]}
        >
          {formDisplayData.country_of_birth.map(({ id, country, sinhala_name, tamil_name }) => (
            <MenuItem value={country} key={id}>
              {i18n.language === "si" ? sinhala_name : i18n.language === "ta" ? tamil_name : country}
            </MenuItem>
          ))}
        </ReactHookFormMultipleSelect>
      </Grid>}

      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t("Common.gender")}
          disabled={disabled}
          name="gender"
          control={control}
          variant="outlined"
          defaultValue={""}
          error={errors.gender}
          helperText={errors.gender?.message}
          fullWidth

        >
          <MenuItem value={"Female"}>{t("Volunteer.female")}</MenuItem>
          <MenuItem value={"Male"}>{t("Volunteer.male")}</MenuItem>
          <MenuItem value={"Other"}>{t("Volunteer.other")}</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t("Volunteer.Civil Status")}
          disabled={disabled}
          name="civilStatus"
          control={control}
          variant="outlined"
          defaultValue={""}
          error={errors.civilStatus}
          helperText={errors.civilStatus?.message}
          fullWidth

        >
          <MenuItem value={"Single"}>{t("Volunteer.single")}</MenuItem>
          <MenuItem value={"Married"}>{t("Volunteer.married")}</MenuItem>
          <MenuItem value={"Widowed"}>{t("Volunteer.widowed")}</MenuItem>
          <MenuItem value={"Divorced"}>{t("Volunteer.divorced")}</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      <Grid item md={4} xs={6}>
        <TextField
          inputRef={register}
          error={errors.familyCount}
          helperText={errors.familyCount?.message}
          fullWidth
          type="number"
          variant="outlined"
          defaultValue={1}
          label={t("Volunteer.Number of Family Members")}
          disabled={disabled}
          name="familyCount"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("Volunteer.Permanent Address")}
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <TextField
          inputRef={register}
          error={errors.permanentDoorNo}
          helperText={errors.permanentDoorNo?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Door Number")}
          disabled={disabled}
          name="permanentDoorNo"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          inputRef={register}
          error={errors.permanentStreet}
          helperText={errors.permanentStreet?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Street")}
          disabled={disabled}
          name="permanentStreet"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          inputRef={register}
          error={errors.permanentArea}
          helperText={errors.permanentArea?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Area")}
          disabled={disabled}
          name="permanentArea"
        />
      </Grid>


      <Grid item xs={12}>
        <Typography variant="body1">
          {t("Volunteer.Current Residential Address")}
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <TextField
          inputRef={register}
          error={errors.residentialDoorNo}
          helperText={errors.residentialDoorNo?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Door Number")}
          disabled={disabled}
          name="residentialDoorNo"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          inputRef={register}
          error={errors.residentialStreet}
          helperText={errors.residentialStreet?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Street")}
          disabled={disabled}
          name="residentialStreet"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          inputRef={register}
          error={errors.residentialArea}
          helperText={errors.residentialArea?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Area")}
          disabled={disabled}
          name="residentialArea"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("Volunteer.Where do you prefer to contribute")}
        </Typography>
      </Grid>

      <CustomLocationSelect
        control={control}
        errors={errors}
        watch={watch}
        namePrefix={"contribute"}
      />

      <Grid item xs={12} >
        <Typography variant="h6" color="primary">{t("Common.contactDetails")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          inputRef={register}
          error={errors.contactResidence}
          helperText={errors.contactResidence?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Residence")}
          disabled={disabled}
          name="contactResidence"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          inputRef={register}
          error={errors.contactAlternate}
          helperText={errors.contactAlternate?.message}
          fullWidth
          md={4} xs={6}
          variant="outlined"
          label={t("Volunteer.Alternate Contact Number")}
          disabled={disabled}
          name="contactAlternate"
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          as={TextField}

          control={control}
          error={errors.contactOffice}
          helperText={errors.contactOffice?.message}
          fullWidth
          variant="outlined"
          label={t("Volunteer.Office")}
          disabled={disabled}
          name="contactOffice"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          inputRef={register}
          error={errors.contactFax}
          helperText={errors.contactFax?.message}
          fullWidth
          variant="outlined"
          label={t("Common.fax")}
          disabled={disabled}
          name="contactFax"
        />
      </Grid>
      <Divider />
      <Grid item xs={12} >
        <Typography variant="h6" color="primary">{t("Volunteer.Languages")}</Typography>
      </Grid>

      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t("Volunteer.Your First Language")}
          disabled={disabled}
          name="firstLanguage"
          control={control}
          variant="outlined"
          defaultValue={""}
          error={errors.firstLanguage}
          helperText={errors.firstLanguage?.message}
          fullWidth

        >
          {formDisplayData.languages.map(({ id, language }) => {
            return (
              <MenuItem key={id} value={language}>{t(`Languages.${language}`)}</MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item md={4} xs={6} >
        <ReactHookFormSelect
          label={t("Volunteer.Other Language")}
          disabled={disabled}
          name="otherLanguages"
          control={control}
          variant="outlined"
          fullWidth
          defaultValue={""}
        >
          {formDisplayData.languages.map(({ id, language }) => (
            <MenuItem value={language} key={id}>{t(`Languages.${language}`)}</MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>

      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t("Volunteer.Your Preferred Language")}
          disabled={disabled}
          name="preferredLanguage"
          control={control}
          variant="outlined"
          defaultValue={""}
          error={errors.preferredLanguage}
          helperText={errors.preferredLanguage?.message}
          fullWidth

        >
          {formDisplayData.languages.map(({ id, language }) => {
            return (
              <MenuItem key={id} value={language}>{t(`Languages.${language}`)}</MenuItem>

            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item xs={12} >
        <Typography variant="h6" color="primary">
          {t("Volunteer.Please indicate your level of performance of your preferred language")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" >
          {t("Volunteer.Writing")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={RadioGroup}
          control={control}
          disabled={disabled}
          name="writingPerformance"
        >
          <FormControlLabel value="POOR" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Poor")} />
          <FormControlLabel value="BASIC" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Basic Knowledge")} />
          <FormControlLabel value="GOOD" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Good")} />
          <FormControlLabel value="EXCELLENT" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Excellent")} />
        </Controller>
        {errors.writingPerformance && <FormHelperText error>{errors.writingPerformance?.message}</FormHelperText>}
      </Grid>


      <Grid item xs={6}>
        <Typography variant="body1" >
          {t("Volunteer.Reading")}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Controller
          as={RadioGroup}

          control={control}
          disabled={disabled}
          name="readingPerformance"
        >
          <FormControlLabel value="POOR" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Poor")} />
          <FormControlLabel value="BASIC" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Basic Knowledge")} />
          <FormControlLabel value="GOOD" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Good")} />
          <FormControlLabel value="EXCELLENT" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Excellent")} />
        </Controller>
        {errors.readingPerformance && <FormHelperText error>{errors.readingPerformance?.message}</FormHelperText>}
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body1" >
          {t("Volunteer.Speaking")}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Controller
          as={RadioGroup}
          control={control}
          disabled={disabled}
          name="speakingPerformance"
        >
          <FormControlLabel value="POOR" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Poor")} />
          <FormControlLabel value="BASIC" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Basic Knowledge")} />
          <FormControlLabel value="GOOD" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Good")} />
          <FormControlLabel value="EXCELLENT" disabled={disabled} control={<Radio color="secondary" />} label={t("Volunteer.Excellent")} />
        </Controller>
        {errors.speakingPerformance && <FormHelperText error>{errors.speakingPerformance?.message}</FormHelperText>}
      </Grid>
    </Grid>

  );
}

export default withStyles(style)(GeneralTab);