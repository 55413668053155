import { Paper, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import CustomButton from "../../components/CustomButton";
import { submitComplaint } from "../../functions/Complaint/submitComplaint";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import FormLanguageAlert from "../../components/FormLanguageAlert";

const schema = yup.object().shape({
  title: yup.string().required('Required'),
  registrationNo: yup.string(),
  // subvioLeader: yup.string().required('Required'),
  description: yup.string().required('Required'),
});

export default function Complaint() {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [created, setCreated] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(async () => {
    let mounted = true;
    return () => mounted = false;
  }, [created, error])


  const onSubmit = async data => {

    setError(false);
    const result = await submitComplaint(data);
    console.log(data);
    if (result.error) {
      setError(true);
      const errorMsg = result.status == 500 ? "Something went wrong!" : result.item
      enqueueSnackbar(errorMsg, {
        variant: 'error',
        persist: false
      })
    }
    else {
      setCreated(true);
      const errorMsg = "Complaint submitted successfully!"
      enqueueSnackbar(errorMsg, {
        variant: 'success',
        persist: false
      })
      history.push("/")
    }

  };

  const {t} = useTranslation()

  return (
    <React.Fragment>
      <FormLanguageAlert/>
      <Paper style={{ padding: '18px 36px', maxWidth: 800, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            <Grid item style={{ padding: '20px 0' }}>
              <Typography variant="h5" color="primary" align="center">{t('complaint.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.regNo}
                helperText={errors.regNo?.message}
                name="registrationNo"
                variant="outlined"
                fullWidth
                label={t('complaint.regNo')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.complaintTitle}
                helperText={errors.complaintTitle?.message}
                name="title"
                variant="outlined"
                fullWidth
                label={t('complaint.complaintTitle')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.description}
                helperText={errors.description?.message}
                name="description"
                variant="outlined"
                fullWidth
                label={t('complaint.description')}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton label={t('Common.submit')} type="submit" fullWidth />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </React.Fragment>
  );
}
