import {
    TableCell,
    TableRow,
    Avatar,
} from "@material-ui/core";
import React from "react";
import { green, red } from '@material-ui/core/colors';
import Button from "@material-ui/core/Button"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// const theme = createMuiTheme({
//     palette: {
//         primary: green,
//         secondary: red,
//     },
// });

function SubVIORow({ row, labelId, isItemSelected, classes, isApproved , showAlertApproved, showAlertRejected , isError}) {
    
    const {t} = useTranslation()
    // const approveHandler = async (row) => {
    //     const res = await submitVIOStatus( row.id, true);
    //     showAlertApproved();
    //     if (res.error === false){
    //         isApproved();
    //     }else {
    //         isError();
    //     }
    // }
    // const rejectHandler = async (row) => {
    //     const res = await submitVIOStatus( row.id, false);
    //     showAlertRejected();
    //     if (res.error === false){
    //         isApproved();
    //     }else {
    //         isError();
    //     }
    // }
    return (
        <React.Fragment>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
            >
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    <Avatar
                        alt={row.name}
                        style={{
                            color: "white",
                            backgroundColor: "#23395B",
                            margin: '20px 6px'
                        }}
                        src={row.profileImage}
                    />
                </TableCell>
                <TableCell align="left"><Link to={`/viewSubVio/${row.id}`} >
                    {row.name}
                </Link></TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{t(`SubVioList.SubVioRow.${row.type}`)}</TableCell>
                {/*<TableCell padding="checkbox">*/}
                {/*    <ThemeProvider theme={theme}>*/}
                {/*        <Button onClick={()=>approveHandler(row)} variant="contained" size="small" color="primary" style={{color: 'white', marginRight: '5px'}} textPrimary='white' className={classes.margin}>*/}
                {/*            Approve*/}
                {/*        </Button>*/}
                {/*    </ThemeProvider>*/}
                {/*</TableCell>*/}
                {/*<TableCell padding="checkbox">*/}
                {/*    <ThemeProvider theme={theme}>*/}
                {/*        <Button onClick={()=>rejectHandler(row)} variant="contained" size="small" color="secondary" style={{color: 'white'}} textPrimary='white' className={classes.margin}>*/}
                {/*            Reject*/}
                {/*        </Button>*/}
                {/*    </ThemeProvider>*/}
                {/*</TableCell>*/}
            </TableRow>
        </React.Fragment>
    );
}

export default SubVIORow;
