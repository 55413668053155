import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from '@material-ui/core'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useEffect } from "react";
import IconTextField from '../IconTextField'
import CustomButton from '../CustomButton'
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import {submitVolunteerRegister} from "../../functions/Volunteer/submitVolunteerRegister";

// const regexPhoneNumber = /^(?:7|0|(?:\+94))[0-9]{9,10}$/
const nicRegex = /^[0-9A-Za-z]*$/;
const checkAge= (nic) => {
  const currentYear = new Date().getFullYear();
  if(nic.length === 10 && (currentYear - parseInt('19' + nic.substring(0,2))) >= 16){
    return true
  }if(nic.length === 12 && (currentYear - parseInt(nic.substring(0,4))) >= 16){
    return true
  }else{
    return false
  }
}

const schema = yup.object().shape({
  firstName: yup.string().required('Required').max(30, 'Must be at most 30 characters'),
  lastName: yup.string().required('Required').max(30, 'Must be at most 30 characters'),
  nic: yup.string().required('Required').matches(nicRegex, 'NIC should only have numbers and letters').min(10,'NIC must be at least 10 characters long').max(12,'Password must be at least 8 characters long').test('nic','You must be 16 years or above to register', val => checkAge(val)),
  email: yup.string().email('Email must be a valid email').required('Required'),
  // phone: yup.string().matches(regexPhoneNumber, 'Phone number is not valid').required('Required'),
  password: yup.string().required('Required').min(8, 'Password must be at least 8 characters long'),
  // Retype password should be equal to password
  retypePassword: yup
    .string()
    .oneOf([yup.ref('password'), null, ''], 'Passwords must match')
    .required('Required')
});

export default function SignUpForm({ onSubmit, t }) {

  const { register, errors, control, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });
  // console.log(errors);
  const [formDisabled, setFormDisabled] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  // const onSubmit = data =>
  const submitRegisterFunction = async (registerData) => {

    let result = await submitVolunteerRegister( registerData);
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item);
      result.status == 400 ? setErrorMsg(result.item) : setErrorMsg(result.item);
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setErrorMsg("you are successfully registered, please check your inbox or spam folders for the verification email!")
      setIsErrorVariant(false)
      setPushError(!pushError)
      setFormDisabled(true)
    }
  }
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(submitRegisterFunction)}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.firstName")}
                inputRef={register()}
                fullWidth
                icon={<AccountCircleIcon />}
                name="firstName"
                disabled={formDisabled}
                error={errors.firstName}
                helperText={errors.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.lastName")}
                inputRef={register()}
                fullWidth
                icon={<AccountCircleIcon />}
                name="lastName"
                disabled={formDisabled}
                error={errors.lastName}
                helperText={errors.lastName?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <IconTextField
                  label={t("Register.NIC")}
                  inputRef={register()}
                  fullWidth
                  icon={<FingerprintIcon />}
                  name="nic"
                  disabled={formDisabled}
                  error={errors.nic}
                  helperText={errors.nic?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <IconTextField
                label={t("Register.email")}
                type="email"
                inputRef={register()}
                fullWidth
                icon={<EmailIcon />}
                name="email"
                disabled={formDisabled}
                error={errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.password")}
                inputRef={register()}
                fullWidth
                type="password"
                icon={<LockIcon />}
                name="password"
                disabled={formDisabled}
                error={errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconTextField
                label={t("Register.retypePassword")}
                inputRef={register()}
                fullWidth
                type="password"
                icon={<LockIcon />}
                name="retypePassword"
                disabled={formDisabled}
                error={errors.retypePassword}
                helperText={errors.retypePassword?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton label={t("Register.registerButton")} type="submit" variant="contained" fullWidth disabled={formDisabled} />
            </Grid>
            <Grid item xs={6}>
              <CustomButton label={t("LoginPage.logInTitle")} component={Link} to="/login" variant="outlined" fullWidth />
            </Grid>
          </Grid>
        </form>
    </React.Fragment>
  );
}
