import { Grid } from '@material-ui/core';
import React from 'react';
import ReactRateComponent from 'react-rate-component';

function RateComponent({ onChange, value, showCount = false, edit = true }) {
    return (
        <React.Fragment>
            <Grid item xs={8}>
                {value}
            </Grid>
            <Grid item xs={4}>
                <ReactRateComponent
                    defaultValue={0}
                    showCount={showCount}
                    onChange={onChange}
                    edit={edit}
                />
            </Grid>
        </React.Fragment>
    )
}

export default RateComponent
