import React, { useEffect, useState } from "react";
import OpportunityView from "../../components/OpportunityView";
import { makeStyles } from "@material-ui/core/styles";
import { onClickCard } from "../../functions/opportunity/onClickFunctions";
import Error from "../../components/MessageCard";

import { Paper, Typography } from "@material-ui/core";
import { fetchOpportunitiesByAdminToApproveVolunteers } from "../../functions/opportunity/fetchOpportunities";
import LoadingPage from "../../components/LoadingPage";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}));

export default function VApproveViewOpportunities({
  isAdminView,
  isVolunteerApproveByAdmin,
  isVioView,
  isVolunteerApproveByVIO
}) {
  const classes = useStyles();

  const [details, setDetails] = useState({ error: false, items: [] });
  const [searchDetails, setSearchDetails] = useState({
    search: "",
    type: "Online",
  });
  const [isLoading, setLoading] = useState(true);

  const onSearch = (data) => {
    
    setSearchDetails(data);
  };

  // const onClickCard = () => {};
  useEffect(async () => {
      setLoading(true);
      let mounted = true;
      let result = await fetchOpportunitiesByAdminToApproveVolunteers();

      
      if (mounted) {
        let fetchItems =
          searchDetails.type === "Online"
            ? result.onlineItems
            : result.regularItems;
        setDetails({ items: fetchItems, error: result.error });
        setLoading(false);
      }
      
      return () => (mounted = false);
  }, [searchDetails]);

  return (
    <React.Fragment>
      {!isLoading ? (
        details.error ? (
          // <div>
          //   <Typography variant="h5">
          //     View All Opportunities
          //     </Typography>
          //   <OportunitySearchBar
          //     onSearch={onSearch}
          //   />
          //   <OpportunityView
          //     items={searchDetails.type === "Online" ? defaultItems.onlineItems : defaultItems.regularItems}
          //     defaultImageLink={searchDetails.type === "Online" ? volunteerHomePageOnlineOpportunityImageLink : volunteerHomePageRegularOpportunityImageLink}
          //     onClickLearnMore={defaultLearnmore}
          //     onClickShare={defaultShare}
          //     onClickCard={onClickCard}
          //     isAdminView={isAdminView}
          //   />
          // </div>
          <Error />
        ) : (
          <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
            <Typography variant="h5" align="center" color="primary" style={{padding :'20px 0'}}>Approve Volunteers For Opportunities</Typography>
            <OpportunityView
              items={details.items}
              onClickCard={onClickCard}
              isAdminView={isAdminView}
              isVolunteerApproveByAdmin={isVolunteerApproveByAdmin}
              isVioView={isVioView}
              isVolunteerApproveByVIO={isVolunteerApproveByVIO}
            />
          </Paper>
        )
      ) : (
        <LoadingPage />
      )}
    </React.Fragment>
  );
}
