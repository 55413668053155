import {serverHost,serverPort,tokenKey} from "../../../Constant";
import axios from 'axios';

const DELETE_ACCOUNT_URL = "/api/user/terminate/"

export async function deleteAccount(password){
    
    const URL_ENDPOINT = `${serverHost}:${serverPort}${DELETE_ACCOUNT_URL}`;
    try {
        const response = await axios.post(URL_ENDPOINT,
            {
                password
            }, {  headers:  {'token': localStorage.getItem(tokenKey)} }
            );

        return [response.status, response.data];
    }
    catch(err){
        const response = err.response
        return [response?.status || 408, response?.data];
    }
}
