import { Grid, Container, Paper, Divider, Card, CardHeader, Avatar } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles"
import React from "react";
import {useHistory} from "react-router-dom"

const useStyles = makeStyles(() => ({
    root: {
        cursor: "pointer",
        backgroundColor: "#32c3f3",
    },
    section: {
        marginBottom: '20px',
        marginTop: '20px'
    },
    avatar: {
        backgroundColor: "#1D4971",
    }
}))

function ReportCard({title, decription, link}) {
    
    const classes = useStyles()
    const history = useHistory()

    return (
        <Grid item xs={12} sm={6} md={6} alignItems="left">
            <Card className={classes.root} onClick={() => history.push(link)}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.avatar} sizes="large">
                            <DescriptionIcon fontSize="medium" /> 
                        </Avatar>
                    }
                    title={title}
                    subheader={decription}
                />
            </Card>
        </Grid>
    )
}

export default function ReportList() {

    const classes = useStyles();

    return (
        <Container maxWidth="md" style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Paper elevation={0} style={{ padding: '20px' }} >
                <Grid item xs={12} className={classes.section}>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                    >
                        <Grid item>
                            <Typography variant="h5">
                                Reports
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>

                <Grid container justify="space-around" spacing={3} className={classes.section}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Regular Reports</Typography>
                    </Grid>
                    <ReportCard title="Summary" decription="Daily summary" link="/admin/report/dailySummary" />
                    <ReportCard title="Volunteer Registration" decription="Volunteer registration on a daily basis." link="/admin/report/dailyVolRegistrations" />
                    <ReportCard title="VIO Registration" decription="VIO registration on a daily basis." link="/admin/report/dailyVioRegistrations" />
                    <ReportCard title="Volunteer Opportunities" decription="Posted Volunteer opportunities on a daily basis." link="/admin/report/dailyVoRegistrations" />
                    <ReportCard title="Pending Registrations" decription="Pending Volunteer and VIO registrations" link="/admin/report/dailyPendingRegistrations" />
                    <ReportCard title="Volunteers Mobilized" decription="Volunteers mobilized daily" link="/admin/report/dailyVolMobiRegistrations" />
                </Grid>

                <Grid container justify="space-around" spacing={3} className={classes.section}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Online Reports</Typography>
                    </Grid>
                    {/* <ReportCard title="Summary" decription="Daily summary" link="/admin/report/dailySummary" /> */}
                    <ReportCard title="Online Volunteer Registration" decription="Volunteer registration on a daily basis." link="/admin/report/onlinedailyVolRegistrations" />
                    <ReportCard title="Online VIO Registration" decription="VIO registration on a daily basis." link="/admin/report/onlinedailyVioRegistrations" />
                    <ReportCard title="Online Volunteer Opportunities" decription="Posted Volunteer opportunities on a daily basis." link="/admin/report/onlinedailyVoRegistrations" />
                    <ReportCard title="Online Pending Registrations" decription="Pending Volunteer and VIO registrations" link="/admin/report/onlinedailyPendingRegistrations" />
                    <ReportCard title="Online Volunteers Mobilized" decription="Volunteers mobilized daily" link="/admin/report/onlinedailyVolMobiRegistrations" />
                </Grid>
            </Paper>
        </Container>
    );
}

/*
/admin/report/dailyVolRegistrations
/admin/report/dailyVioRegistrations
/admin/report/dailyVoRegistrations
/admin/report/dailyPendingRegistrations
*/