import axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { submitNonFormalVOStatusURL } from "../../../containers/Admin/NonFormalVOApproval/Constants";

export const submitNonFormalVOStatus = async (id, approve) => {
  const registerUrl = serverHost + ":" + serverPort + submitNonFormalVOStatusURL+ "/" + id + "/" + approve;
  try {
    const response = await axios.put(
      registerUrl,
      {},
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    );
    if (response.status === 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.response ? err.response.data.message : "Error",
      error: true,
    };
    return result;
  }
};
