import React, { useEffect } from "react";
import {useDispatch, useSelector } from 'react-redux'

import * as AuthActions from "../../actions/AuthActions";
import LoginForm from "../../components/LoginForm";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';

export default function LoginPage() {
  let history = useHistory();
  const dispatch = useDispatch();
  const authSignIn = (data)=> dispatch(AuthActions.authSignIn(data, history));
  const {t} = useTranslation();

  return (
    <LoginForm onSubmit={authSignIn} t = {t}/>
  );
}
