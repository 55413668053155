import React, { useState } from 'react';
import { Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core';


function AgreementItem({ title, onChange }) {

    const [checked, set] = useState(false)

    const onTick = () => {

        if (!checked == true) {
            onChange(1)
        } else {
            onChange(-1)
        }
        set(!checked)
    }


    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={onTick} />}
            label={title}
        />
    )
}

export default AgreementItem
