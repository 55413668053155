import React from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Paper } from "@material-ui/core";
import MessageCard from '../../../components/MessageCard';
import LoadingPage from "../../../components/LoadingPage";
import VolunteerStory from '../../../components/Admin/VolunteerStory/VolunteerStory';
import { submitDeleteVolunteerStory } from '../../../functions/Admin/VolunteerStory/submitDeleteVolunteerStory';
import { fetchVolunteerStoryList } from '../../../functions/Admin/VolunteerStory/fetchVolunteerStoryList';

export default function VolunteerStoryPage() {

  const [errorMsg, setErrorMsg] = React.useState("");
  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);
  const [reload, setReload] = React.useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {
    let mounted = true;
    let result = await fetchVolunteerStoryList();
    if (mounted) {
      if (result.error) {
        setError(true)
      }
      else {
        setError(false)
        setFetchDetails(result.item);
      }
    }
    return () => mounted = false;
  }, [reload])

  const deleteVolunteerStoryFunc = async (id) => {
    let result = await submitDeleteVolunteerStory(id);
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Deleted!")
      setPushError(!pushError)
      setReload(!reload)
    }
  }

  return (
    <React.Fragment>
      {
        (fetchDetails) ?
          (!error) ?
            <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40, marginBottom: 40 }}>
              <VolunteerStory
                fetchDetails={fetchDetails}
                deleteVolunteerStoryFunc={deleteVolunteerStoryFunc}
              />
            </Paper>
            : <MessageCard message="Error" />
          : <LoadingPage />
      }
    </React.Fragment>

  );
}