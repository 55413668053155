import React, { useEffect, useState } from 'react';
import { fetchOpportunities, fetchOpportunitiesByAdmin, fetchOpportunitiesByAdminToApproveVolunteers } from '../../functions/opportunity/fetchOpportunities'
import OpportunityView from '../../components/OpportunityView';
import { makeStyles } from '@material-ui/core/styles';
import { onClickCard } from '../../functions/opportunity/onClickFunctions'
import Message from '../../components/MessageCard';
import { Paper, Typography } from '@material-ui/core';
import { serverHost } from '../../Constant';
import Axios from 'axios';
import { TrafficRounded } from '@material-ui/icons';
import LoadingPage from '../../components/LoadingPage';
import { useForm } from 'react-hook-form';
import SearchBar from '../../components/SearchBar';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8)
  },
}));



export default function ViewAllOpportunitiesPage({ isAdminView }) {
  const classes = useStyles();

  const [details, setDetails] = useState({ error: false, items: [] });
  const [isLoading, setLoading] = useState(true)
  const { register, control, watch } = useForm();

  // const onClickCard = () => {};
  useEffect(async () => {
    if (isAdminView) {
      setLoading(TrafficRounded)
      let mounted = true;
      let result = await fetchOpportunitiesByAdmin();

      
      if (mounted) {
        let fetchItems = watch("type") === "Online" ? result.onlineItems : result.regularItems
        setDetails({ items: fetchItems, error: result.error });
        setLoading(false)
      }
      
      return () => mounted = false;
    } else {
      setLoading(true)
      let mounted = true;
      let result = await fetchOpportunities(watch("type"), "");

      
      if (mounted) {
        let fetchItems = watch("type") === "Online" ? result.onlineItems : result.regularItems
        setDetails({ items: fetchItems, error: result.error });
        setLoading(false)
      }
      
      return () => mounted = false;
    }
  }, [watch("type")])



  return (
    <React.Fragment >
      {
        details.error ?
          <Message /> :
          <Paper style={{ padding: 18, marginTop: 40, marginBottom: 40}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography variant="h5" color="primary">
                {isAdminView ?
                  'Opportunities To Approve By Admin' :
                  'View All Opportunities'
                }
              </Typography>
              <SearchBar
                defaultValue=""
                defaultType="Online"
                register={register}
                control={control}
              />
            </div>
            {isLoading ?
              <LoadingPage /> :
              <OpportunityView
                items={details.items && details.items.filter(item => !watch("search") || item.title.toLowerCase().includes(watch("search").toLowerCase()))}
                onClickCard={onClickCard}
                isAdminView={isAdminView}
              />
            }
          </Paper>

      }
    </React.Fragment>
  )
}
