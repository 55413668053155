import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, CardContent, CardActions, Chip, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextTruncate from 'react-text-truncate';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { formDisplayData } from '../constants/generalFormDisplayData'
const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 12,
  },
  paper: {
    height: 120,
  },
  title: {
    color: '#23395B',
    fontSize: 26,
    fontWeight: 500
  }
}));

export default function MediaCard(
  { oppertunityTitle, url, name, id, voCategory, VODescriptionEnglish, isAdminView, isVolunteerApproveByAdmin, isVioView, isVolunteerApproveByVIO }
) {
  const classes = useStyles();
  const { t } = useTranslation();
  // const onPressedCard = () => {
  //   onClickCard({ id, content, contentTopic });
  // }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.paper}>
        <Typography color="textSecondary" color="secondary">
          {Number.isInteger(parseInt(voCategory)) ? formDisplayData.VOCategory[voCategory] : voCategory}
        </Typography>
        <Typography noWrap variant="h6" color="primary">
          {oppertunityTitle}
        </Typography>
        <Typography color="textSecondary" variant="ti" gutterBottom noWrap>
            {name} Ish
          </Typography>
        <TextTruncate
          line={2}
          element="span"
          truncateText="…"
          text={VODescriptionEnglish}
        // textTruncateChild={<a href="#">Read on</a>}
        />
      </CardContent>
      <Divider />
      <CardActions>
        {!isAdminView && !isVioView && <Button size="small" component={Link} to={`${url}${id}`}>{t('Common.showMore')}</Button>}
        {isAdminView && !isVolunteerApproveByAdmin && <Button size="small" component={Link} to={`/admin${url}${id}`}>{t('Common.showMore')}</Button>}
        {isAdminView && isVolunteerApproveByAdmin && <Button size="small" component={Link} to={`/admin/volunteerOpportunity/${id}`}>{t('Common.showMore')}</Button>}
        {isVioView && isVolunteerApproveByVIO && <Button size="small" component={Link} to={`/vio/volunteerOpportunity/${id}`}>{t('Common.showMore')}</Button>}
      </CardActions>
    </Card>
  );
}

// MediaCard.defaultProps = {
//   };

//   MediaCard.propTypes = {
//     imageLink: propTypes.string, imageTitle: propTypes.string, content: propTypes.string, contentTopic: propTypes.string
//   };
