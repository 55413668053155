import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { agreementSentence, volunteerAgreementPoints, vioAgreementPoints } from './Constants'
import { Typography, Grid, Paper, FormGroup, FormControl } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CustomButton from '../../components/CustomButton';
import MessageCard from '../../components/MessageCard';
// import { Link } from 'react-router-dom';
import { vioFetchAgreement, vioConfirmAgreement } from '../../functions/agreement/fetchAgreement'
import AgreementItem from './AgreementItem';
import LoadingPage from '../../components/LoadingPage';
import { useTranslation } from 'react-i18next';

export default function VioAgreement({ match }) {

    const vioId = match.params.vioId
    const opId = match.params.opId;
    const id = match.params.volunteerId

    const [confirm, setConfirm] = useState(false);
    const [agreeCount, setAgreeCount] = useState(0);
    const [details, setDetails] = useState({ vio: {}, volunteer: {}, opportunity: {}, error: false });
    const [agreeItems, setAgreeItems] = useState([])
    const [otherItems, setOtherItems] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = React.useState("");
    const [isError, setIsErrorVariant] = React.useState(true);
    const [pushError, setPushError] = React.useState(false);
    const {t, i18n} = useTranslation()

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    useEffect(() => {
        if (!errorMsg) return
        enqueueSnackbar(errorMsg, {
            variant: isError ? 'error' : 'success',
            persist: false
        })
    }, [pushError])

    useEffect(async () => {
        setLoading(true);
        let mounted = true;

        let result = await vioFetchAgreement(id, opId, vioId);

        setAgreeItems(vioAgreementPoints);
        setOtherItems(volunteerAgreementPoints);



        if (mounted) {
            if (result.error) {
                result.status === 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
                setIsErrorVariant(true)
                setPushError(!pushError)
            }
            else {
                setDetails(result);
                setLoading(false)
            }
        }

        return () => mounted = false;

    }, [confirm]);


    //Confirm Function
    const onConfirm = async () => {

        const result = await vioConfirmAgreement(id, vioId, opId);
        const error = result.error;
        result.volunteer = details.volunteer;
        result.vio = details.vio;
        result.opportunity = details.opportunity;
        setDetails(result)

        if (!error) {
            setErrorMsg("You have now completed the agreements, You have successfully started your volunteer opportunity!!")
            setIsErrorVariant(false)
            setPushError(!pushError)
            setConfirm(true);
            setTimeout(() => { history.push(`/viewOpportunity/${opId}`) }, 1000);
        }
        else {
            result.status === 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
            setIsErrorVariant(true)
            setPushError(!pushError)
            setConfirm(false);
        }
    }

    const onChange = (value) => {
        setAgreeCount(agreeCount + value)
    }

    return (
        !isLoading ?
            !details.alreadySubmitted ?
                <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} style={{ margin: '20px 0' }}>
                            <Typography variant="h5" color="primary" align="center">
                            {t('VolunteerAgreement.title')} - {details.opportunity.title}
                                </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="p" style={{ textAlign: 'justify' }}>
                                {agreementSentence(details.volunteer, details.vio, details.opportunity, i18n.language)}
                            </Typography>
                        </Grid>
                        <React.Fragment>
                            {agreeItems &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="primary">{t('VolunteerAgreement.vioAgreementTopics')}</Typography>
                                    </Grid>
                                    <FormControl>
                                        <FormGroup>
                                            {agreeItems.map((item, i) => (
                                                <AgreementItem
                                                    key={i.toString()}
                                                    title={t(`VolunteerAgreement.vioAgreementPoints.${item}`)}
                                                    onChange={onChange}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </React.Fragment>
                            }
                            {otherItems &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="primary">{t('VolunteerAgreement.volunteerAgreementTopics')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ul>
                                            {otherItems.map((item, i) => (
                                                <li key={i.toString()}>{t(`VolunteerAgreement.volunteerAgreementPoints.${item}`)}</li>
                                            ))}
                                        </ul>
                                    </Grid>
                                </React.Fragment>
                            }
                        </React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="p">{t('VolunteerAgreement.herebyAgree')}</Typography>
                        </Grid>
                        <Grid item>
                            <CustomButton
                                variant="contained"
                                label={t('VolunteerAgreement.confirm')}
                                disabled={!(agreeCount === agreeItems.length)}
                                onClick={onConfirm}
                                margin={0}
                            // component={Link} 
                            // to="/opportunities/myOpportunities"
                            />
                        </Grid>
                    </Grid>
                </Paper>
                : <MessageCard error={false} message='Thank you for agreeing with us!!' />
            : <LoadingPage />
    )
}




    // {
    //     vio:{
    //         regNum:"111",
    //         id:"111222",
    //         name:"Organization Name"
    //     }
    //     volunteer:{
    //         fullName:"Sasindu Dilshara Alahakoon",
    //         NIC:"973531322V",
    //         id:22233,
    //         fromDate:"1997/12/1",
    //         toDate:"2020/01/30",
    //         title:"Mr"
    //     }
    //     opportunity:{
    //         contentTopic:"Test opportunity topic",
    //         duration:"7 hours per day",
    //         location:"Colombo 10",:
    //         fromDate:"2021/12/13",
    //         toDate:"2022/12/11"
    //     }
    // }

// name,id,nic   ,name,vioId  ,title dates, duration