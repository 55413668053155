import axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { fetchVIOListURL } from "../../../containers/VIOApproval/Constants";

export const fetchVioListByAdmin = async () => {
  const fetchUrl = serverHost + ":" + serverPort + fetchVIOListURL;
  try {
    const response = await axios.get(fetchUrl, {
      headers: {
        usertoken: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const fetchVolunteerListByAdmin = async () => {
  const fetchUrl = serverHost + ":" + serverPort + "/api/volunteer/listAdmin";
  try {
    const response = await axios.get(fetchUrl, {
      headers: {
        usertoken: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const fetchAdminListByAdmin = async () => {
  const fetchAdminListByAdminURL =
    serverHost + ":" + serverPort + "/api/admin/list";
  try {
    const response = await axios.get(fetchAdminListByAdminURL, {
      headers: {
        usertoken: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};
