import { Paper, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import CustomButton from "../../components/CustomButton";
import { createSubvio } from '../../functions/subvio/fetchSubvio';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import FormLanguageAlert from "../../components/FormLanguageAlert";

const schema = yup.object().shape({
  subvioName: yup.string().required('Required'),
  // subvioLeader: yup.string().required('Required'),
  subvioDescription: yup.string().required('Required'),
  email: yup.string().required('Required').email('Enter a valid email'),
});

export default function CreateSubvio() {
  const history = useHistory();
  const [error, setError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [created, setCreated] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(async () => {
    let mounted = true;
    return () => mounted = false;
  }, [created, error])


  const onSubmit = async data => {
    
    setError(false);
    // setCreated(false);
    const result = await createSubvio(data.email, data.subvioName, data.subvioLeader, data.subvioDescription);
    if (result.error) {
      setError(true);
      const errorMsg = result.status == 500 ? "Something went wrong!" : result.item
      enqueueSnackbar(errorMsg, {
        variant: 'error',
        persist: false
      })
    }
    else {
      setCreated(true);
      const errorMsg = "Sub VIO created successfully!"
      enqueueSnackbar(errorMsg, {
        variant: 'success',
        persist: false
      })
      history.push("/vioHome")
    }

  };

  const {t} = useTranslation()

  return (
    <React.Fragment>
      <FormLanguageAlert/>
      <Paper style={{ padding: '18px 36px', maxWidth: 800, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            <Grid item style={{ padding: '20px 0' }}>
              <Typography variant="h5" color="primary" align="center">{t('CreateSubVio.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.subvioName}
                helperText={errors.subvioName?.message}
                name="subvioName"
                variant="outlined"
                fullWidth
                label={t('CreateSubVio.name')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.subvioLeader}
                helperText={errors.subvioLeader?.message}
                name="subvioLeader"
                variant="outlined"
                fullWidth
                label={t('CreateSubVio.leader')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.subvioDescription}
                helperText={errors.subvioDescription?.message}
                name="subvioDescription"
                variant="outlined"
                fullWidth
                label={t('CreateSubVio.description')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.email}
                helperText={errors.email?.message}
                name="email"
                variant="outlined"
                fullWidth
                label={t('Common.email')}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton label={t('Common.create')} type="submit" fullWidth />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </React.Fragment>
  );
}