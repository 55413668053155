
import { serverHost, serverPort, tokenKey } from "../../Constant";
import axios from 'axios';
import { subVioPublicProfileUrl } from '../../containers/subvio/Constants';


export const getSubVioPublicProfile = async (subVioId) => {

    const fetchUrl = serverHost + ":" + serverPort + subVioPublicProfileUrl + '/' + subVioId.toString();

    try {
        const response = await axios.get(fetchUrl,

            { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }


};