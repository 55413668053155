import {serverHost, serverPort, hostPortDivider, tokenKey} from "../../Constant";
import axios from 'axios';
import {volunteerFetchFeedbackUr, vioFetchFeedbackUrl, volunteerSubmitFeedbackUrl, vioSubmitFeedbackUrl
} from '../../containers/Feedback/Constant'

export const volunteerFetchFeedback = async(volunteerId, opportunityId, vioId) =>{

    return await fetchFeedbackDetails(volunteerId, vioId, opportunityId, volunteerFetchFeedbackUr);

}

export const vioFetchFeedback = async(volunteerId, opportunityId, vioId) =>{

    return await fetchFeedbackDetails(volunteerId, vioId, opportunityId, vioFetchFeedbackUrl);

}

export const fetchFeedbackDetails = async (volunteerId, vioId, opportunityId, fetchUrl)=>{

    
    const fetchFeedbackUrl = serverHost + hostPortDivider + serverPort + fetchUrl;
    try{
        const response = await axios.get(fetchFeedbackUrl,
            {
            headers: {
                'token': localStorage.getItem(tokenKey)
            },
            params: { 
                volunteerId,opportunityId,vioId
            } 
        });
        
        if (response.status == 200) {
                const result =  {
                    volunteer:response.data.volunteer,
                    vio:response.data.vio,
                    opportunity:response.data.opportunity,
                    alreadySubmitted:response.data.alreadySubmitted,
                    evaluation:{date:new Date()},
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }

}
catch(err){
    
    const result = {
        message:err.message,
        error:true
    }
    
    return result;
} 


};



const submitFeedback = async(opportunityDetails,feedback,submitUrl) =>{


    
    const feedbackSubmitUrl = serverHost+hostPortDivider+serverPort+submitUrl;
    const finObject =  { 
        vio: opportunityDetails.vio,
        volunteer: opportunityDetails.volunteer,
        opportunity: opportunityDetails.opportunity,
        feedback
    };
    delete finObject.volunteer.nameInNic;
    finObject.feedback.date = new Date();
    finObject.feedback.recommend = finObject.feedback.recommend === "Yes"? true: false;
    
    try{
        const response = await axios.post(feedbackSubmitUrl,
            finObject,
            {
            headers: {
                'token': localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
                const result =  {
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }


        }

        catch(err){
            
            const result = {
                message:err.message,
                error:true
            }
            
            return result;
        } 


}

export const submitVolunterFeedback = async(opportunityDetails,feedback) =>{

    return await submitFeedback(opportunityDetails,feedback,volunteerSubmitFeedbackUrl);

}

export const submitVioFeedback = async(opportunityDetails,feedback) =>{

    return await submitFeedback(opportunityDetails,feedback,vioSubmitFeedbackUrl);

}

