import Typography from "@material-ui/core/Typography";
import CustomButton from "../../components/CustomButton";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import useStyles from "./_styles";
import {useTranslation} from "react-i18next";

export default function Home() {
    const classes = useStyles();
    const [titleNo, setTitleNo] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => setTitleNo(n => (n + 1) % 3), 7000)
        return () => clearTimeout(timer)
    }, [])

    const {t} = useTranslation()
    return (
        <Grid item className={classes.homeSection} id="home">
            <div className={classes.mockBg}/>
            <div style={{
                zIndex: 10,
                position: 'absolute',
                margin: '3% 9% 0 9%',
                padding: '2%',
                backgroundColor: '#ffffff00'
            }}>
                <div style={{marginTop: '50px', width: '50vw', height: "200px", position: "relative"}}>
                    <Typography variant="h2" color='primary'
                                className={[classes.homeHeading, classes.fadeTransition, titleNo === 0 ? classes.show : classes.hide]}
                                gutterBottom>
                        {t('LandingPage.home.subtitle1')}
                    </Typography>
                    <Typography variant="h2" color='primary'
                                className={[classes.homeHeading, classes.fadeTransition, titleNo === 1 ? classes.show : classes.hide]}
                                gutterBottom>
                        {t('LandingPage.home.subtitle2')}
                    </Typography>
                    <Typography variant="h2" color='primary'
                                className={[classes.homeHeading, classes.fadeTransition, titleNo === 2 ? classes.show : classes.hide]}
                                gutterBottom>
                        {t('LandingPage.home.subtitle3')}
                    </Typography>
                </div>
                <div style={{marginTop: '50px'}}>
                    <CustomButton
                        size="large"
                        margin={'50px 0 2% 0'}
                        className={classes.button} color="primary"
                        component={Link} to="/volunteer-opportunities"
                        label={t('LandingPage.home.viewOpportunuties')}
                    />
                </div>
            </div>
        </Grid>
    )
}
