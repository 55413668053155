import {axiosClient, resolver} from "../client.js"

const DATE_RANGE_PAYLOAD = {
    startDate: new Date("2020-02-01"),
    endDate: new Date("2050-02-01"),
    type: "range"
}

export default {
    homeStatistics() {
        return resolver(
            axiosClient.post('/api/report/home')
        )
    },
    self: {
        admin() {
            return resolver(
                axiosClient.post('/api/report/self/adminReport', DATE_RANGE_PAYLOAD)
            )
        },
        vio(id) {
            return resolver(
                axiosClient.post('/api/report/self/vioReport', {id, ...DATE_RANGE_PAYLOAD})
            )
        },
        volunteer(id) {
            return resolver(
                axiosClient.post('/api/report/self/volunteerReport', {id, ...DATE_RANGE_PAYLOAD})
            )
        }
    }
}
