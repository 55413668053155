import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import useStyles from "./_styles";
import Home from "./home";
import AboutUs from "./aboutUs";
import Involvement from "./involvement";
import SDG from "./sdg";
import Statistics from "./statistics";
import Volunteer from "./volunteer";
import Footer from "../../components/Footer";
import Partnership from "./partners"
import api from "../../api";
import LoadingPage from '../../components/LoadingPage';

export default function Index() {
    let history = useHistory();
    const classes = useStyles();

    const [statistics, setStatistics] = useState(null)
    const [sgdData, setSgdData] = useState({})

    useEffect(() => {
        (async () => {
            const [status, data] = await api.report.homeStatistics()
            if (status === 200) {
                setSgdData(data.numVolunteerHoursBySDG || {})
                setStatistics(data)
            } else {
                
            }
        })();
    }, [])

    if (!statistics)
        return (<LoadingPage />)

    return (
        <Grid container component="main" className={classes.root}>
            <Home />
            <div style={{width: '100%', height: '70px'}} />
            <AboutUs />
            <Involvement />
            <SDG data={sgdData} />
            <Statistics statistics={statistics} />
            <Volunteer itemList={statistics.story} />
            <Partnership itemList={statistics.partner} />
        </Grid>
    );
}

