import React from 'react'

function SuccessMessage({message}) {
    return (
        <div>
            {message}
        </div>
    )
}

export default SuccessMessage
