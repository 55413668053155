import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller } from "react-hook-form";

export default function ReactHookFormCheckbox({  value, name, control, disabled = false, label }) {

	return (
		<FormControlLabel
			control={
				<Controller
					name={`${name}[${value}]`}
					control={control}
					render={(props) => (
						// 
						<Checkbox
							{...props}
							// value={!!props.value}
							checked={!!props.value}
							disabled={disabled}
							onChange={(e) => props.onChange(e.target.checked)}
						/>
					)}
				/>
			}
			label={label ?? value}
		/>
	)
}