const style = theme => ({
  root: {
    // background: `url("${process.env.PUBLIC_URL}/images/admin_wallpaper.png")`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundSize: 'stretch',
    minWidth:'100vh',
    
  },
  tabpannel: {
    // background: `url("${process.env.PUBLIC_URL}/images/admin_wallpaper.png")`,
    // backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'fixed',
    // backgroundPosition: 'flex-start',
    // backgroundSize: 'stretch',
    minWidth:'100vh',
    height:'100hv', 
    width:'100hv'
  },
  margin: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  padding: {
    padding: theme.spacing(1)
  },
  textField: {
    minWidth: 400
  },
  paragraph: {
    fontSize: 14
  },
  warning: {
    color: 'red'
  },
  buttonGrid: {
    textAlign: 'right'
  }
});

export default style;
