export const verifyPhoneUrl = "/api/volunteer/verify/phone";

export const enterNumberUrl = "/api/volunteer/register/phone";

export const successMessage = "Your Phone number verified successfully";

export const errorMessage = "Your Phone number verification failed";

export const instruction = "Enter the 6 digit code that sent your phone number";

export const numberInstruction = "Enter Your Phone Number";

export const minlength = 6;

export const maxlength = 6;

export const minPhonelength = 10;

export const maxPhonelength = 10;

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
