import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Controller } from "react-hook-form";
import DateFnsUtils from '@date-io/date-fns';


const ReactHookFormDatePicker = ({
    name,
    label,
    control,
    defaultValue,
    ...props
}) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
                as={KeyboardDatePicker}
                control={control}
                {...props}
                // error={errors.dob}
                // helperText={errors.dob?.message}
                defaultValue={new Date()}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                name={name}
                fullWidth
                label={label}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
export default ReactHookFormDatePicker;