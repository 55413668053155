import {hostPortDivider, serverHost,serverPort,tokenKey} from "../../Constant";
import axios from 'axios';
import {verifyVioUrl, verifyUrl} from '../../containers/verify/email/Constants'
import {verifyPhoneUrl, enterNumberUrl} from '../../containers/verify/phone/Constants';


export const verifyUser = async (key)=>{

    
    const emailVerifyUrl = serverHost+hostPortDivider+serverPort+verifyUrl;
    try{
        const response = await axios.post(emailVerifyUrl,{emailToken:key},
            {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
                const result =  {
                    error:false,
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }

}
    catch(err){
        
        const result = {
            message: err.response ? err.response.data.message : "Something went wrong!",
            error:true
        }
        
        return result;
    } 


};

export const verifyVio = async (key)=>{

    
    const emailVerifyUrl = serverHost+hostPortDivider+serverPort+verifyVioUrl;
    try{
        const response = await axios.post(emailVerifyUrl,{emailToken:key},
            {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
                const result =  {
                    error:false,
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }

}
    catch(err){
        
        const result = {
            message: err.response ? err.response.data.message : "Something went wrong!",
            error:true
        }
        
        return result;
    } 


};

export const verifyPhone = async (emailToken, phoneToken)=>{

    
    const emailVerifyUrl = serverHost+":"+serverPort+verifyPhoneUrl;
    try{
        const response = await axios.post(emailVerifyUrl,{emailToken, phoneToken},
            {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
                const result =  {
                    error:false,
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }

}
    catch(err){
        
        const result = {
            message: err.response ? err.response.data.message : "Something went wrong!",
            error:true
        }
        
        return result;
    } 


};


export const EnterNumber = async (emailToken, phoneNumber)=>{

    
    const emailVerifyUrl = serverHost+":"+serverPort+enterNumberUrl;
    try{
        const response = await axios.post(emailVerifyUrl,{emailToken, phoneNumber},
            {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
                const result =  {
                    error:false,
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }
}
    catch(err){
        
        const result = {
            message: err.response ? err.response.data.message : "Something went wrong!",
            error:true
        }
        
        return result;
    } 
};