import React from 'react'
import { Route, Switch} from 'react-router-dom';

import Profile from '../../Admin/Profile';
import ProfilePage from '../../VolunteerProfile/ProfilePage';
import VIOProfilePage from '../../VIOProfile/VIOProfilePage'
import SettingsPage from '../../UserCommon/SettingsPage';

import ViewAllOpportunitiesPage from '../../viewOpportunitiesPage/ViewAllOpportunitiesPage';
import OportunityDetailView from '../../viewOpportunitiesPage/OportunityDetailView'
import ViewVolunteers from '../../ViewVolunteers/volunteers';

import Report from '../../Reports/Report';
import VolunteerOpportunityPage from '../../VIO/VolunteerOppertunityPage';
import ViewSubvio from '../../subvio/ViewSubvio';
import ViewSubvios from '../../subvio/ViewSubvios';
import ViewOrgVolunteers from '../../OrgVolunteers/ViewOrgVolunteers';

import InviteAdmin from '../../Admin/InviteAdmin';
import ChangePassword from '../../Admin/ChangePassword';
import AdminList from '../../Admin/AdminList';
import VIOList from '../../VIOApproval/VIOList';
import NonFormalVOList from '../../Admin/NonFormalVOApproval/NonFormalVOList';
import AdminVerify from '../../Admin/AdminVerify';
import VApproveViewOpportunities from '../../Admin/VApproveViewOpportunities';
import VIOTable from '../../Admin/VIOList/VIOTable';
import VolunteerTable from '../../Admin/VolunteerList/VolunteerTable';
import VolunteerPublicProfile from '../../public_profiles/Volunteer';
import VioPublicProfile from '../../public_profiles/Vio';
import SubVioTransfer from '../../Admin/SubVioTransfer';
import DailyVolRegistration from '../../Reports/DailyVolRegistration';
import OnlineDailyVolRegistration from '../../Reports/OnlineDailyVolRegistration';
import DailyVioRegistration from '../../Reports/DailyVioRegistration';
import OnlineDailyVioRegistration from '../../Reports/OnlineDailyVioRegistration';
import DailyVoRegistration from '../../Reports/DailyVoRegistration';
import OnlineDailyVoRegistration from '../../Reports/OnlineDailyVoRegistration';
import DailyVoMobilizedRegistration from '../../Reports/DailyVolMobilized';
import OnlineDailyVoMobilizedRegistration from '../../Reports/OnlineDailyVolMobilized';
import DailyPendingReg from '../../Reports/DailyPendingRegistration';
import OnlineDailyPendingReg from '../../Reports/OnlineDailyPendingRegistration';
import DailySummary from '../../Reports/DailySummary';
import CreateEmgUser from '../../Admin/EmergencyUser/CreateEmgUser';
import EmgUserTable from '../../Admin/EmergencyUser/EmgUserTable';
import ReportNav from "../../ReportNav"
import EmgVolunteerTable from '../../EmergencyUser/VolunteerList/EmgVolunteerTable';
import {ALL_ADMINS, COMPLAINT, CORE_ADMIN} from '../Constants';
import CustomRoute from '../CustomRoute';
import News from '../../Admin/News';
import AddVolunteerStoryPage from '../../Admin/VolunteerStory/AddVolunteerStoryPage';
import VolunteerStoryPage from '../../Admin/VolunteerStory/VolunteerStoryPage';
import EditVolunteerStoryPage from '../../Admin/VolunteerStory/EditVolunteerStoryPage';
import AddPartnerPage from '../../Admin/Partner/AddPartnerPage';
import PartnerPage from '../../Admin/Partner/PartnerPage';
import EditPartnerPage from '../../Admin/Partner/EditPartnerPage';
import NonFormalOportunityDetailView from '../../Admin/NonFormalOpportunityView/NonFormalOportunityDetailView';
import AdminDashboard from "../../Admin/AdminDashboard";
import ComplaintTable from "../../Admin/Complaint/ComplaintTable";

function AdminRoutes() {
    return (
        <Switch>
            <CustomRoute exact path="/admin/invite" component={InviteAdmin} routes = {CORE_ADMIN} message = "Only Core Admin users can view this page!"/>
            <CustomRoute exact path="/admin/adminlist" component={AdminList} routes = {CORE_ADMIN} message = "Only Core Admin users can view this page!"/>
            <CustomRoute exact path="/admin/changepw" component={ChangePassword} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/verify/:token" component={AdminVerify} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/viewOpportunity/:id" render={(props) => (<OportunityDetailView {...props} isAdminView={true}/>)}  routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/viewAllOpportunities" render={(props) => (<ViewAllOpportunitiesPage {...props} isAdminView={true}/>)}  routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/volunteerOpportunity" render={(props) => (<VApproveViewOpportunities {...props} isAdminView={true} isVolunteerApproveByAdmin={true}/>)}  routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>

            <CustomRoute exact path="/report" component={Report} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/volunteerOpportunity/:id" render={(props) => (<VolunteerOpportunityPage {...props} isAdminView={true}/>)}  routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            {/* <CustomRoute exact path="/volunteerOpportunity" component={VolunteerOpportunityPage} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/> */}
            <CustomRoute exact path="/admin/VIOApproval" component={VIOList} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/NonFormalVOApproval" component={NonFormalVOList} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/NonFormalVO/:id" component={NonFormalOportunityDetailView} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            {/* <CustomRoute exact path="/viewSubVios" component={ViewSubvios} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/viewSubVio/:subvioId" component={ViewSubvio} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/> */}
            <CustomRoute exact path="/viewOrganizationalVolunteers" component={ViewOrgVolunteers} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/VIOlist" component={VIOTable} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/volunteerList" component={VolunteerTable} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/complaintList" component={ComplaintTable} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>

            <CustomRoute exact path="/admin/volunteer/:volunteerId" render={(props) => (<VolunteerPublicProfile {...props} isAdminView={true}/>)} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/vio/:vioId" render={(props) => (<VioPublicProfile {...props} isAdminView={true}/>)}  routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/subVioTransfer/:subVioId" component={SubVioTransfer} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>

            <CustomRoute exact path="/admin/reports" component={ReportNav} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/createemguser" component={CreateEmgUser} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/admin/emguserslist" component={EmgUserTable} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>


            {/*<Route exact path="/viewAllOpportunities" component={ViewAllOpportunitiesPage}  message = "Only Admin users can view this page!"/>
            <Route exact path="/viewAllOpportuniy" component={ViewAllOpportunitiesPage}  message = "Only Admin users can view this page!"/>
        <Route exact path="/volunteerHome/viewOpportunity" component={ViewVolunteers}  message = "Only Admin users can view this page!"/> */}
            {/* <Route exact path="/verifyMobile" component={VerifyMobilePage}  message = "Only Admin users can view this page!"/> */}
            {/*<CustomRoute exact path="/viewAllOpportunities" component={ViewAllOpportunitiesPage} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/viewAllOpportuniy" component={ViewAllOpportunitiesPage} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/>
            <CustomRoute exact path="/volunteerHome/viewOpportunity" component={ViewVolunteers} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/> */}
            {/* <CustomRoute exact path="/verifyMobile" component={VerifyMobilePage} routes = {ALL_ADMINS} message = "Only Admin users can view this page!"/> */}


            {/* <Route exact path="/profile" component={ProfilePage}  message = "Only Admin users can view this page!"/>
            <Route exact path="/vioprofile" component={VIOProfilePage}  message = "Only Admin users can view this page!"/> */}
            <CustomRoute exact path="/admin/profile" component={Profile} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            {/* <Route exact path="/settings" component={SettingsPage}  message = "Only Admin users can view this page!"/> */}



            {/* This routes are for Emergency Users (Will be change later) */}
            {/* <CustomRoute exact path="/emguser/volunteerList" component={EmgVolunteerTable} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/> */}


            <CustomRoute exact path= "/admin/report/dailyVolRegistrations" component={DailyVolRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/dailyVioRegistrations" component={DailyVioRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/dailyVoRegistrations" component={DailyVoRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/dailyVolMobiRegistrations" component={DailyVoMobilizedRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/dailyPendingRegistrations" component={DailyPendingReg} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/dailySummary" component={DailySummary} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>

            <CustomRoute exact path= "/admin/report/onlinedailyVolRegistrations" component={OnlineDailyVolRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/onlinedailyVioRegistrations" component={OnlineDailyVioRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/onlinedailyVoRegistrations" component={OnlineDailyVoRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/onlinedailyVolMobiRegistrations" component={OnlineDailyVoMobilizedRegistration} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/report/onlinedailyPendingRegistrations" component={OnlineDailyPendingReg} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>

            <CustomRoute exact path="/admin/news" component={News} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/addVolunteerStory" component={AddVolunteerStoryPage} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/editVolunteerStory/:id" component={EditVolunteerStoryPage} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/volunteerStory" component={VolunteerStoryPage} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/addPartner" component={AddPartnerPage} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/editPartner/:id" component={EditPartnerPage} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>
            <CustomRoute exact path= "/admin/partners" component={PartnerPage} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>

            <CustomRoute exact path= "/admin/dashboard" component={AdminDashboard} routes = {ALL_ADMINS}  message = "Only Admin users can view this page!"/>


        </Switch>
    )
}

export default AdminRoutes
