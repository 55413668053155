import React from 'react'

import EmgVolunteerTable from '../../EmergencyUser/VolunteerList/EmgVolunteerTable';
import EmergencyUserHomePage from '../../EmergencyUser/EmergencyUserHomePage';
import EmergencyVolunteerOpportunityHome from '../../EmergencyUser/EmergencyVolunteerOppertunityPage';
import CreateEVOPage from '../../EmergencyUser/CreateEVOPage';
import emgUserEVOFeedbackPage from '../../Feedback/emgUserEVOFeedbackPage';
import { ALL_ADMINS, CORE_ADMIN, EMERGENCY_USER } from '../Constants';
import CustomRoute from '../CustomRoute';
import { Switch } from 'react-router-dom';


function EmgUserRoutes() {
    return (
        <Switch>
  
            {/* This routes are for Emergency Users (Will be change later) */}
            {/* <CustomRoute exact path="/emguser/volunteerList" component={EmgVolunteerTable} routes = {EMERGENCY_USER} message = "Sorry! You have not access to perform this action"/> */}
            <CustomRoute exact path="/emguserHome" component={EmergencyUserHomePage} routes = {EMERGENCY_USER} message = "Sorry! You have not access to perform this action"/>
            <CustomRoute exact path="/emg-VolunteerOpportunity/:id" component={EmergencyVolunteerOpportunityHome} routes = {EMERGENCY_USER} message = "Sorry! You have not access to perform this action"/>
            <CustomRoute exact path="/createEmergencyVO" component={CreateEVOPage} routes = {EMERGENCY_USER} message = "Sorry! You have not access to perform this action"/>
            <CustomRoute exact path="/emergencyVolunteerFeedback/:opportunityId/:volunteerId/:emergencyLoginId" component={emgUserEVOFeedbackPage} routes = {EMERGENCY_USER}  message = "Sorry! Only Email Verified Emergency Users have access to this page"/>
            
        </Switch>
    )
}

export default EmgUserRoutes;
