import React, { useEffect, useState } from 'react'
import {numberInstruction,minPhonelength, maxPhonelength, phoneRegExp } from './Constants'
import { Grid, Paper, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { useForm } from "react-hook-form";
import IconTextField from '../../../components/IconTextField';
import CustomButton from '../../../components/CustomButton';

function EnterPhone({ email, onEnter, t, resend, phnNumber}) {

    const schema = yup.object().shape({
        number: yup.string().min(10).max(10).matches(phoneRegExp, 'Phone number is not valid')
      });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onEnterPhoneNumber = async (data) => {
        return await onEnter(email, data.number);
    }

    return (
        <React.Fragment>
                <Paper style={{ padding: '18px 36px', maxWidth: 800, margin: 'auto', marginTop: 40, marginBottom: 40 }}>

                <form onSubmit={handleSubmit(onEnterPhoneNumber)}>
                    <Grid container justify="center" spacing={2}>
                    <Typography variant="subtitle1" color="primary">{t("VerifyMobile.numberInstruction")}</Typography>
                        <Grid item xs={12}>
                            <IconTextField
                                inputRef={register({ required: true, maxLength: maxPhonelength, minLength: minPhonelength })}
                                required
                                type = "phonenumber"
                                name="number"
                                error={!!errors.number}
                                helperText={errors.number && t("VerifyMobile.numberValidate")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        {
                            !resend ?
                            <CustomButton
                                fullWidth
                                label={t("VerifyMobile.confirmButtonLabel")}
                                type="submit"
                            />
                        :
                        <CustomButton onClick = {() => onEnter(email, phnNumber)} label = {t("VerifyMobile.resendSMS")} />
                        }
                        </Grid>
                    </Grid>
                </form>
                </Paper>
        </React.Fragment>
    )
}

export default EnterPhone
