import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../Constant";
import { registerPostUrl } from '../../containers/SignUpPage/Constants';

export const submitVolunteerRegister = async ({email, nic, firstName, lastName, password}) => {

    const registerUrl = serverHost + ":" + serverPort + registerPostUrl;
    try {
        const response = await axios.post(registerUrl,{
                email, nic, firstName, lastName, password
            });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }else if (response.status == 400) {
            const result = {
                item: response.data.message,
                error: true,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: "Something went wrong!",
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

