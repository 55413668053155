import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, makeStyles, CardActions, CardContent, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../CustomButton';


export default function StoryListItem({ storyRow, deleteVolunteerStoryFunc }) {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={6} style={{ textDecoration: 'none' }}>
      <Card>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar alt="Remy Sharp" style={{ width: '100px', height: '100px' }} src={storyRow.imageURL} />
          </div>
          <Typography variant="h6" color="primary" gutterBottom>
            {storyRow.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">{storyRow.description}</Typography>
        </CardContent>
        <CardActions>
          <CustomButton
            color="primary"
            label={t('Common.edit')}
            margin={6}
            component={Link} to={`/admin/editVolunteerStory/${storyRow.id}`}
          />
          <CustomButton
            color="primary"
            label={t('Common.delete')}
            margin={6}
            style={{backgroundColor: 'red'}}
            onClick={() => deleteVolunteerStoryFunc(storyRow.id)}
          />
        </CardActions>
      </Card>
    </Grid>
  );
}