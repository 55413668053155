import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
// import { CloudinaryContext } from 'cloudinary-react';
import theme from './App.theme';
import store from './store';
import ResponsiveDrawer from './containers/Router/ResponsveDrawer';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';

function App() {
  return (
    <Suspense fallback={null}>
      <Provider store={store}>
        {/* <CloudinaryContext cloudName="open-inventory-system"> */}
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <ResponsiveDrawer />
          </SnackbarProvider>
        </ThemeProvider>
        {/* </CloudinaryContext> */}
      </Provider>
    </Suspense>
  );
}

export default App;