import { Divider, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import CustomButton from '../../components/CustomButton';
import { fetchSubvios, removeSubvio } from '../../functions/subvio/fetchSubvio';
import IconTextField from '../../components/IconTextField'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom';
import SubVioElement from './SubContainers/SubVioElement';
import { Link } from 'react-router-dom';

function ViewSubvios() {
    const history = useHistory();
    const [error, setError] = useState(false);
    const [load, setLoading] = useState(false);
    const [subvioList, setList] = useState([]);
    const [searchValue, onSearchValue] = useState("");

    useEffect(async () => {
        let mounted = true;
        
        let result = await fetchSubvios(searchValue);

        
        if (mounted) {
            setError(result.error);
            setList(result.subvios);
        }
        
        return () => mounted = false;

    }, [error, load])

    const onType = (e) => {
        onSearchValue(e.target.value)
    }
    const onSearch = () => {
        setLoading(!load)
    }
    const onDelete = async (id) => {
        const result = await removeSubvio(id);
        if (!(result.error)) {
            setLoading(!load)
        }
        else {
            setError(true);
        }
    }

    if (!error && subvioList) {
        return (
            <React.Fragment>
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Typography variant="h5">
                            View Sub VIOs
                    </Typography>
                    </Grid>
                    <Grid item>
                        <CustomButton 
                            label="Add Subvio" 
                            margin={0}
                            component={Link} 
                            to="/createSubVio"
                            />
                    </Grid>
                </Grid>
                <Divider style={{marginTop: 20, marginBottom: 10, backgroundColor: '#c0c0c0'}}/>
                <Grid container spacing={4}>
                    <Grid item xs={12} container direction="row" justify="flex-end">
                        <Grid item>
                            <IconTextField
                                onChange={onType}
                                name="search"
                                placeholder="Search by ID…"
                                defaultValue={""}
                                icon={<SearchIcon />}
                            />
                        </Grid>
                        <Grid item>
                            <CustomButton label="search" onClick={onSearch} margin="12px 0" variant="contained" style={{ padding: '15px 20px' }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {subvioList.map((subvio, i) => (
                            <SubVioElement
                                key={i.toString()}
                                subvio={subvio}
                                history={history}
                                onDelete={onDelete}
                            />
                        ))}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
    return <div/>
}

export default ViewSubvios
