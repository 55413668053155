import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";
import FetchDetails from './FetchDetails';
import { defaultFetchDetails } from './Constant'
import { fetchEVOEmgUserFeedbackDetails } from '../../functions/feedback/fetchEVOFeedback';
import { emgUserSubmitEVOFeedback } from '../../functions/feedback/submitEVOFeedback';
import { Grid, Paper, Typography } from '@material-ui/core';
import VolunteerFeedback from './VolunteerFeedback';
import MessageCard from '../../components/MessageCard';
import LoadingPage from '../../components/LoadingPage';

export default function emgUserEVOFeedbackPage({ match }) {

    const [feedbackDetails, setFeedbackDetails] = useState({});
    const [fetchDetails, setFetchDetails] = useState(null);
    const [error, setError] = useState(false);
    const [isSubmitted, setSubmit] = useState(false);

    const { volunteerId, opportunityId, emergencyLoginId } = match.params
    const state = true;//Identify the user type.

    const [errorMsg, setErrorMsg] = React.useState("");
    const [isError, setIsErrorVariant] = React.useState(true);
    const [pushError, setPushError] = React.useState(false);
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!errorMsg) return
        enqueueSnackbar(errorMsg, {
            variant: isError ? 'error' : 'success',
            persist: false
        })
    }, [pushError])

    useEffect(async () => {
        let mounted = true;
        let result = await fetchEVOEmgUserFeedbackDetails(volunteerId, opportunityId, emergencyLoginId);
        if (mounted) {
            setFetchDetails(result.item);
            setError(result.error);
            if (result.error) {
                setFetchDetails(defaultFetchDetails);
            }
        }
        return () => mounted = false;
    }, [error, isSubmitted])

    const onSubmit = async (data) => {
        setFeedbackDetails(data);
        await submit(data)
    }

    const onClick = (name, value, title, key) => {
        setFetchDetails({ ...fetchDetails, [title]: { ...fetchDetails[title], [key]: value } })
    }

    const submit = async (feedbacks) => {
        const sendData = {
            // emergencyLogin: fetchDetails.emergencyLogin,
            // volunteer: fetchDetails.volunteer,
            // opportunity: fetchDetails.opportunity,

            volunteerId: fetchDetails.volunteer.id,
            opportunityId: fetchDetails.opportunity.id,
            emergencyLoginId: fetchDetails.emergencyLogin.id,
            feedback: {
                ...feedbacks,
                date: new Date().toString(),
                recommend: feedbacks.recommend === "Yes"
            }
        }
        
        const result = await emgUserSubmitEVOFeedback(sendData)
        if (result.error) {
            result.status === 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
            setIsErrorVariant(true)
            setPushError(!pushError)
        }
        else {
            setSubmit(true)
            setIsErrorVariant(false)
            setErrorMsg("Thank you so much for providing your valuable feedback!")
            setPushError(!pushError)
        }
    }


    return (
        fetchDetails ?
            !fetchDetails.alreadySubmitted ?
                <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ margin: '20px 0' }}>
                            <Typography variant="h5" color="primary" align="center">
                                {t('Feedback.Feedback')}
                            </Typography>
                        </Grid>
                        <FetchDetails
                            volunteer={fetchDetails.volunteer}
                            emergencyLogin={fetchDetails.emergencyLogin}
                            opportunity={fetchDetails.opportunity}
                            onClick={onClick}
                            isVolunteer={true}
                            isEVO={true}
                        />
                        <VolunteerFeedback
                            onSubmitDetails={onSubmit}
                            volunteerName={fetchDetails.volunteer.firstName}
                        />

                    </Grid>
                </Paper>
                : <MessageCard error={false} message="Thank you for submitting your response!!" />
            : <LoadingPage />
    )
}