import { useSelector } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import {
  fetchOpportunity,
  applyOpportunity,
} from "../../functions/opportunity/fetchOpportunities";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import PopUpCalendar from "./PopUpCalendar";
import { defaultItem } from "./Constants";
import SuccessMessage from "../../components/SuccessMessage";
import {
  Chip,
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
} from "@material-ui/core";
import { serverHost, tokenKey } from "../../Constant";
import Axios from "axios";
import Error from "../../components/MessageCard";
import { useHistory } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
// import { formDisplayData } from '../../constants/generalFormDisplayData'
import LoadingPage from "../../components/LoadingPage";
import { fetchVOFormDataFunc } from "../../functions/VIO/VolunteerOppertunity/fetchVOFormData";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { getUserType } from "../../helper/user_helper";
import { check } from '../../helper/route_helper';
import { FULLY_COMPLETED_VOLUNTEER } from "../../containers/Router/Constants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const opportunityList = {
  b: [
    "supervisorName",
    "supervisorEmail",
    "supervisorMobile",
    "supervisorLandline",
    "supervisorFax",
  ],
  c: [
    "location",
    "gnDivision",
    "divisionalSecretariat",
    "district",
  ],
  d: [
    "designation",
    "degreeLevelRequired",
    "language",
    "subjectAreaRequired",
  ],
};
function OportunityDetailView({ match, isAdminView }) {
  const id = match.params.id;
  const [error, setError] = useState(false);
  const [item, setItem] = useState();
  const [formDisplayData, setFormDisplayData] = useState(null);
  const [applySuccess, setApplySuccess] = useState(false);
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const email = useSelector((state) => state.auth.email);
  const history = useHistory();
  const type = useSelector(state => state.auth.type);
  const access = useSelector(state => state.auth.access)


  useEffect(async () => {
    let mounted = true;
    
    let result = await fetchOpportunity(id);
    let resultFormData = await fetchVOFormDataFunc();

    
    if (mounted) {
      if (result.error || resultFormData.error) {
        setError(true);
      } else {
        const result_item = {
          ...result.item,
          startDate: new Date(result.item.startDate),
          endDate: new Date(result.item.endDate),
        };
        setItem(result_item);
        setFormDisplayData(resultFormData.item);
      }
      // setError(result.error);
    }
    
    return () => (mounted = false);
  }, [applySuccess]);

  const apply = async (dates) => {
    dates = dates.map(
      (date) =>
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()
    );
    const result = await applyOpportunity(email, id, dates);
    if (result.error) {
      setError(true);
    } else {
      setApplySuccess(true);
      history.push("/opportunities/myOpportunities");
    }
  };

  const approveOpportunity = () => {
    const approveUrl = serverHost + "/api/admin/opportunity/review";
    Axios.post(
      approveUrl,
      {
        id: id,
        approve: true,
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    )
      .then((res) => {
        
        if (res.status == 200) {
          enqueueSnackbar("Opportunity Approved Successfully!", {
            persist: false,
            variant: "success"
          })
          window.location.reload(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error in Opportunity Approving!", {
          persist: false,
          variant: "error"
        })
      });
  };

  const rejectOpportunity = () => {
    const approveUrl = serverHost + "/api/admin/opportunity/review";
    Axios.post(
      approveUrl,
      {
        id: id,
        approve: false,
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    )
      .then((res) => {
        
        if (res.status == 200) {
          enqueueSnackbar("Opportunity Rejected!", {
            variant: 'success',
            persist: false
          })
          window.location.reload(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error in Opportunity Rejecting!", {
          variant: 'error',
          persist: false
        });
      });
  };

  if (error) return <Error />;
  if (!item || !formDisplayData) return <LoadingPage />;

  const sdgMap = formDisplayData.sdg_numbers.reduce((map, obj) => {
    map[obj.id] = obj.sdg_number;
    return map;
  }, {});
  const skillMap = formDisplayData.required_skills.reduce((map, obj) => {
    map[obj.id] = t(`Skills.${obj.skill}`);
    return map;
  }, {});
  const categoryMap = formDisplayData.areas_VO.reduce((map, obj) => {
    map[obj.id] = t(`AreasVO.${obj.area}`);
    return map;
  }, {});

  return (
    <Paper style={{ padding: "18px 36px", marginTop: 40, marginBottom: 40 }}>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h5" color="primary">
            {item.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {item.sdgNumbers.map((goal) => (
            <Chip
              label={t(`Sdg.${sdgMap[goal].split(":")[0]}`)}
              color="primary"
              key={Math.random()}
              style={{ margin: 6 }}
            />
          ))}
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            {t(`OpportunityDetailsView.type`)}
          </Typography>
          <Typography variant="body1">
            {t(`VOProcess.${item.type.toLowerCase()}`)}
          </Typography>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            {t(`OpportunityDetailsView.category`)}
          </Typography>
          <Typography variant="body1">
            {categoryMap[item.category]}
          </Typography>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            {t(`OpportunityDetailsView.noOfVolunteers`)}
          </Typography>
          <Typography variant="body1">
            {item.noOfVolunteers}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('Common.description')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {item.descriptionSinhala}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {item.descriptionEnglish}
          </Typography>
          <Typography variant="body1">{item.descriptionTamil}</Typography>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              {t('OpportunityDetailsView.requirements')}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ marginTop: 12 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {t(`OpportunityDetailsView.designation`)}
            </Typography>
            <Typography variant="body1">{item.designation}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ marginTop: 12 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {t(`OpportunityDetailsView.degreeLevelRequired`)}
            </Typography>
            <Typography variant="body1">{t(`Degrees.${item.degreeLevelRequired}`)}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ marginTop: 12 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {t(`OpportunityDetailsView.language`)}
            </Typography>
            <Typography variant="body1">{t(`Languages.${item.language}`)}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ marginTop: 12 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {t(`OpportunityDetailsView.subjectAreaRequired`)}
            </Typography>
            <Typography variant="body1">{item.subjectAreaRequired.map(area => t(`Areas.${area}`)).join(", ")}</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('OpportunityDetailsView.skills')}
            </Typography>
            <Typography variant="body1">
              {item.requiredSkills.map((skill) => skillMap[skill]).join(", ")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              {t('VolunteerOpportunityPage.duration')}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('OpportunityDetailsView.period')}
            </Typography>
            <Typography variant="body1">
              {format(item.startDate, "dd/MM/yyyy")} -{" "}
              {format(item.endDate, "dd/MM/yyyy")}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('OpportunityDetailsView.hours')}
            </Typography>
            <Typography variant="body1">
              {`${item.workingHourCount} hours (${item.isFullTime ? t('VolunteerOpportunityPage.fullTime') : t('VolunteerOpportunityPage.partTime')
                })`}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('VolunteerOpportunityPage.time')}
            </Typography>
            <Typography variant="body1">{item.time.map(time => t(`Times.${time}`)).join(", ")}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('VolunteerOpportunityPage.possibleDays')}
            </Typography>
            <Typography variant="body1">{item.days.map(day => t(`Days.${day}`)).join(", ")}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              {t('OpportunityDetailsView.supervisorDetails')}
            </Typography>
          </Grid>
          {opportunityList.b.map((i) => (
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              key={Math.random()}
              style={{ marginTop: 12 }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {t(`OpportunityDetailsView.${i}`)}
              </Typography>
              <Typography variant="body1">{item[i]}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              {t('OpportunityDetailsView.locationOfWorkStation')}
            </Typography>
          </Grid>
          {opportunityList.c.map((i) => (
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
              key={Math.random()}
              style={{ marginTop: 12 }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {t(`OpportunityDetailsView.${i}`)}
              </Typography>
              <Typography variant="body1">{item[i]}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              {t('VolunteerOpportunityPage.other')}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('OpportunityDetailsView.isTraining')}
            </Typography>
            <Typography variant="body1">
              {item.isTrainingProvided ? t('Common.yes') : t('Common.no')}
            </Typography>
            <Typography variant="body1">
              {item.isTrainingProvided ? item.trainingDetails : ""}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('OpportunityDetailsView.isExpenses')}
            </Typography>
            <Typography variant="body1">
              {item.isExpenseCovered ? t('Common.yes') : t('Common.no')}
            </Typography>
            <Typography variant="body1">
              {item.isExpenseCovered ? item.coveredExpenseDetails : ""}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('OpportunityDetailsView.special')}
            </Typography>
            <Typography variant="body1">{item.specialRequirnments}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ margin: "12px 0" }} />
      {!isAdminView && getUserType(type) == 1 && check(FULLY_COMPLETED_VOLUNTEER, access, "") && (
        <Grid item xs={12} style={{ justifyItems: "center" }}>
          {!item.applied ? (
            <PopUpCalendar
              apply={apply}
              startDate={item.startDate}
              endDate={item.endDate}
              expectedDays={item.days}
            />
          ) : (
            <Chip label={t('OpportunityDetailsView.applied')} color="primary" />
          )}
        </Grid>
      )}
      {isAdminView && item.status != "APPROVED" && item.status != "REJECTED" && (
        <Grid>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => approveOpportunity()}
          >
            {t('OpportunityDetailsView.approve')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => rejectOpportunity()}
          >
            {t('OpportunityDetailsView.reject')}
          </Button>
        </Grid>
      )}

      {isAdminView && item.status == "APPROVED" && item.status != "REJECTED" && (
        <Grid>
          <Chip label={t('Common.approved')} icon={<CheckCircleIcon />} color="primary" />
        </Grid>
      )}

      {isAdminView && item.status == "REJECTED" && (
        <Grid>
          <Chip label={t('Common.rejected')} icon={<NotInterestedIcon />} color="primary" />
        </Grid>
      )}

      {isAdminView && item.status == "COMPLETED" && (
        <Grid>
          <Chip label={t('Common.completed')} icon={<CheckCircleIcon />} color="primary" />
        </Grid>
      )}
    </Paper>
  );
}

export default OportunityDetailView;
