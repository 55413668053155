export const AUTH_SIGN_IN_BEGIN = 'SIGN_IN_BEGIN';
export const AUTH_SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const AUTH_SIGN_UP_BEGIN = 'AUTH_SIGN_UP_BEGIN';
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE';

export const AUTH_SIGN_UP_VIO_BEGIN = 'AUTH_SIGN_UP_VIO_BEGIN';
export const AUTH_SIGN_UP_VIO_SUCCESS = 'AUTH_SIGN_UP_VIO_SUCCESS';
export const AUTH_SIGN_UP_VIO_FAILURE = 'AUTH_SIGN_UP_VIO_FAILURE';

export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';

export const RECOVER_STORE = "RECOVER_STORE";
export const RESET_TOKEN = "RESET_TOKEN";
export const CLEAR_STORE = "CLEAR_STORE";

export const FINISH_LOADING = "FINISH_LOADING";
export const LOADING = "LOADING";
export const ONSEARCH = "ONSERACH";
export const VIEW_VOLUNTEERS_SUCCESS='VIEW_VOLUNTEERS_SUCCESS';
export const VIEW_VOLUNTEERS_FAILURE='VIEW_VOLUNTEERS_FAILURE'


export const ADDDATE = "ADDDATE"
export const DELETEDATE = "DELETEDATE"
export const CLEARALL = "CLEARALL"