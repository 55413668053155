import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import {fetchVolunteerOpportunityUrl, changeVolunteerOpportunityApplicantStateUrl} from '../../../containers/VIO/Constants';


export const fetchVolunteerOpportunity = async (id) => {
    const fetchUrl = serverHost+":"+serverPort+fetchVolunteerOpportunityUrl+"/"+id;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },
                
            });
  
        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }
  
  };


  export const changeVolunteerOpportunityApplicantState = async (userID, voID, applicantID, applicantState) => {
    
    const fetchUrl = serverHost+":"+serverPort+changeVolunteerOpportunityApplicantStateUrl;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            },
            params: { 
                userID:userID,
                voID: voID,
                applicantID: applicantID,
                applicantState: applicantState
            } 
        });
        
        if (response.status == 200) {
            // 
            // const {id,type} = response.data
            // 
                const result =  {
                    item:response.data,
                    error:false,
                    status: response.status
                }
                
                return result;
            }
        else {
            
            const result = {
                item:response.message,
                error:true,
                status: response.status
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        
        return result;
    } 
  
  };
