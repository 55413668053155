import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Paper, Grid, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Axios from "axios";
// import { Alert } from "@material-ui/lab";
import { serverHost, serverPort } from "../../../Constant";
import { createEmgUser } from "../../../functions/EmergencyUser/functions";
import { useHistory } from "react-router";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {
    // width: "60vw",
  },
  border: {
    border: "1px solid #c0c0c0",
    padding: 24,
    borderRadius: 4,
    margin: "16px 8px",
  },
}));

function CreateEmgUser() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [msgAllow, setMsgAllow] = useState(false);

  let history = useHistory();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [alertState, setAlertState] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
    if (alertState == "success") {
      history.push("/admin/emguserslist");
    }
  };

  const onSubmit = async (data) => {
    
    let result = await createEmgUser(data.name, data.email);
    if (result.error) {
      setAlertOpen(true);
      setAlertMsg("Error in Creating User. Try Again");
      setAlertState("error");
    } else {
      setAlertOpen(true);
      setAlertMsg("User Created Successfully!");
      setAlertState("success");
    }
  };

  return (
    <Paper
      style={{
        padding: "18px 36px",
        maxWidth: 800,
        margin: "auto",
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <form action="" onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} style={{ padding: "20px 0" }}>
            <Typography variant="h5" align="center" color="primary">
              Create Emergency User
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              inputRef={register({
                required: "Required",
              })}
              id="outlined-secondary"
              label="Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              helperText={errors.name && "Please enter a name"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              id="outlined-secondary"
              label="Email"
              variant="outlined"
              fullWidth
              className={classes.textField}
              helperText={errors.email && "Please enter a valid email address"}
            />
          </Grid>

          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{ width: 180, color: "white" }}
            // onClick={() => handleCheckBoxes()}
          >
            Create
          </Button>
        </Grid>
      </form>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert onClose={handleClose} severity={alertState}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default CreateEmgUser;
