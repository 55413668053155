export const getUserType = (type) => {
    if(type.includes("VIO")){
        return 0;
    } else if(type.includes("VOLUNTEER")){
        return 1;
    } else if(type.includes("ADMIN")){
        return 2;
    } else if(type.includes("EMERGENCY_LOGIN")){
        return 3;
    } else{
        return 0;
    }
}