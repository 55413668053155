import { Button, Card, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { forgotPasswordReset } from "../utils/forgot_password";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
    newPassword: yup.string().required('required'),
    newPasswordConfirm: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'passwords must match')
});


export default function ResetPasswordForm() {



    const { register, errors, handleSubmit } = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange'
        }
    );

    const { token } = useParams()
    const [status, setStatus] = useState('')
    const {t} = useTranslation();

    const onSubmit = async (data) => {
        const [code] = await forgotPasswordReset(token, data.newPassword)
        
        if (code === 200) setStatus('SUCCESS')
        else setStatus('FAILED')
    }

    return (
        <Paper style={{ padding: '18px 36px', maxWidth: 480, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justify="center">
                    <Grid item style={{ padding: '20px 0' }}>
                        <Typography variant="h5" color="primary" align="center">{t("ForgetPassword.resetPasswordTopic")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="newPassword"
                            label={t("ForgetPassword.newPassword")}
                            type="password"
                            size="small"
                            id="outlined-margin-normal"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            error={errors.newPassword}
                            helperText={errors.newPassword?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="newPasswordConfirm"
                            label={t("ForgetPassword.confirmPassword")}
                            type="password"
                            size="small"
                            id="outlined-margin-normal"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            error={errors.newPasswordConfirm}
                            helperText={errors.newPasswordConfirm?.message}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                        {t("ForgetPassword.resetPassword")}
                        </Button>
                    </ Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        {status === 'FAILED' && <Alert severity="error">{t("ForgetPassword.notSuccessfullyChanged")}</Alert>}
                        {status === 'SUCCESS' && <Alert severity="success">{t("ForgetPassword.successfullyChanged")}</Alert>}
                    </ Grid>
                </Grid>
            </form>
        </Paper>
    );
}

