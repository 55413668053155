import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { fetchVIOListURL } from '../../../containers/VIOApproval/Constants';


export const fetchVIOList = async (userID) => {

    const fetchUrl = serverHost + ":" + serverPort + fetchVIOListURL;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'usertoken': localStorage.getItem(tokenKey)
                },
                params: {
                    userID: userID
                }
            });
        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.message,
            error: true
        }
        return result;
    }

};

