import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

function generate(element) {
  return [0].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

export default function InteractiveList({
  status,
  onRemove,
  onRequest,
  name,
  id,
  type,
  onAction,
  parameter,
  user,
}) {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          noWrap
          variant="h6"
          color="primary"
          onClick={() => {
            history.push("/" + user + "/" + id.toString());
          }}
          style={{
            cursor: "pointer",
            display: "flex",
          }}
        >
          <span style={{marginTop : 11}}>{name}</span>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/vio/" + id.toString());
            }}
            style={{ marginLeft: "auto" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        {type != "removed" ? (
          type != "rejected" ? (
            type != "resigned" ? (
              <Button size="small" onClick={() => onAction(id, parameter)}>
                {t(`Common.${type}`)}
              </Button>
            ) : (
              <Button size="small" disabled>
                {t("Common.resigned")}
              </Button>
            )
          ) : (
            <Button size="small" disabled>
              {t("Common.rejected")}
            </Button>
          )
        ) : (
          <Button size="small" disabled>
            {t("Common.removed")}
          </Button>
        )}
        {type == "accept" && (
          <Button size="small" onClick={() => onAction(id, false)}>
            {t("Common.decline")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
