import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import CustomButton from "../../components/CustomButton";
import Icon from "@material-ui/core/Icon";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <Grid item xs={12} style={{
        padding: "40px",
        textAlign: "center",
        backgroundColor: "#1d4971",
        color: "white"
      }}>
        <Typography variant="h4" style={{ fontWeight: "600" }}>
          {t('Footer.contactUs')}
                </Typography>
        <Typography variant="subtitle2">
          Home / Contact Us
                </Typography>
      </Grid>
      <div style={{
        padding: "40px",
        textAlign: "center"
      }}>
        <Typography variant="h4" style={{ fontWeight: "600" }}>
          {t('ContactUs.title')}
        </Typography>
      </div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginBottom: 20, color: "#14334F" }}
      >
        <Grid item style={{ minWidth: "70vw" }}>
          <Grid style={{ backgroundColor: "#E1EBFE" }}>
            <Typography
              variant="h6"
              color="inherit"
              gutterBottom
              style={{
                fontWeight: "bold",
                backgroundColor: "#14334F",
                color: "white",
                padding: 10,
                textTransform: "uppercase",
              }}
            >
              {t('ContactUs.director')}
            </Typography>
            <Box m={1}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    gutterBottom
                    style={{ color: "#14334F", fontWeight: "bold" }}
                  >
                    {t('ContactUs.directorName')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 187040
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 186 276 ({t('Common.fax')})
                </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={11}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    director@socialservices.gov.lk
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    dirssdss@gmail.com
                </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <div style={{ marginBottom: "20px", width: "100%" }} />
          <Grid style={{ backgroundColor: "#E1EBFE" }}>
            <Typography
              variant="h6"
              color="inherit"
              gutterBottom
              style={{
                fontWeight: "bold",
                backgroundColor: "#14334F",
                color: "white",
                padding: 10,
              }}
            >
              {t('ContactUs.deputy')}
            </Typography>
            <Box m={1}>

              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    gutterBottom
                    style={{ color: "#14334F", fontWeight: "bold" }}
                  >
                    {t('ContactUs.deputyName')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 187 042
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 187 054 ({t('Common.fax')})
                </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={11}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    assdirdevelopment.dss@gmail.com
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    services@socialservices.gov.lk
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    info@volunteering.socialservices.gov.lk
                </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <div style={{ marginBottom: "20px", width: "100%" }} />
          <Grid style={{ backgroundColor: "#E1EBFE" }}>
            <Typography
              variant="h6"
              color="inherit"
              gutterBottom
              style={{
                fontWeight: "bold",
                backgroundColor: "#14334F",
                color: "white",
                padding: 10,
              }}
            >
              {t('ContactUs.sso')}
            </Typography>
            <Box m={1}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    gutterBottom
                    style={{ color: "#14334F", fontWeight: "bold" }}
                  >
                    {t('ContactUs.ssoName')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 187 081 / +94 112 187 080
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 187 054 ({t('Common.fax')})
                </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <div style={{ marginBottom: "20px", width: "100%" }} />
          <Grid style={{ backgroundColor: "#E1EBFE" }}>
            <Box m={1}>
              <Grid container>
                <Grid xs={11} item>
                  <Typography
                    variant="h6"
                    color="inherit"
                    gutterBottom
                    style={{ color: "#14334F", paddingTop: 12 }}
                  >
                    {t('ContactUs.address1')}
                  </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    {t('ContactUs.address2')}
                  </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    {t('ContactUs.address3')}
                  </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    {t('ContactUs.address4')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 187 050 / +94 112 186 275
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 186 276 ({t('Common.fax')})
                </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    +94 112 186 010 ({t('Common.fax')})
                </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={11}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    info@socialservices.gov.lk{" "}
                  </Typography>
                  <Typography variant="h6" color="inherit" gutterBottom>
                    info@volunteering.socialservies.gov.lk
                </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContactUs;
