export const serverHost = "https://volunteering.socialservices.gov.lk";
export const serverPort = ""
export const hostPortDivider = "";

export const tokenKey = "token";
export const type ="type"

// export const serverHost = "http://localhost"
// export const serverPort = "5000"
// export const hostPortDivider = ":";

// export const serverHost = "https://covid19.projects.uom.lk";
