import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../Constant";
import { districtsListUrl } from './contants';

export default async function fetchDistrictList() {
    const fetchUrl = serverHost + ":" + serverPort + districtsListUrl;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },
                
            });
  
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.message,
            error: true
        }
        return result;
    }

};

