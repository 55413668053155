import {
  ListItemText,
  TableCell,
  ListItemIcon,
  IconButton,
  ListItem,
  List,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableRow,
  Avatar,
  Button,
} from "@material-ui/core";
import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import StarIcon from "@material-ui/icons/Star";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import EditAdminPermissionsModal from "./EditAdminPermissionsModal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import { serverHost, tokenKey } from "../../Constant";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteAdminPopup from "./Components/DeleteAdminPopup";
import Axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AdminRow({ row, labelId, isItemSelected, classes , getAdminList}) {
  const [accordianOpen, setAccordianOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const adminPermissions = [
    "VIEW_PROFILE_STATUS",
    "CHECK_REFEREES",
    "VERIFY_VIOS",
    "APPROVE_OPPORTUNITIES",
    "CONFIRM_VOLUNTEERS",
    "VIEW_REPORTS",
    "ADD_REMOVE_ADMINS",
    "CRUD_ON_DATABASE",
    "SUSPEND_VOLUNTEERS",
  ];
  const [deleteSuccessOpen, setDeleteSuccessOpen] = React.useState(false);
  const [deleteAlertMsg, setDeleteAlertMsg] = React.useState("");
  const [deleteSuccessAlertState, setDeleteSuccessAlertState] = React.useState(
    ""
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeleteSuccessOpen(false);
    // window.location.reload(false);
    if (deleteSuccessAlertState == "success") {
      getAdminList();
    }
  };

  const onDeleteAdmin = (id) => {
    
    const adminDeleteUrl = serverHost + "/api/admin/" + id;
    axios
      .delete(adminDeleteUrl, {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      })
      .then((res) => {
        if (res.status == 200) {
          // alert("Deeted Successfully!");
          // window.location.reload(false);
          setDeleteSuccessOpen(true);
          setDeleteAlertMsg("Deleted Successfully!");
          setDeleteSuccessAlertState("success");
        } else {
          // alert("Error in Deleting. Try again!");
          setDeleteSuccessOpen(true);
          setDeleteAlertMsg("Error in Deleting. Try again!");
          setDeleteSuccessAlertState("error");
        }
      })
      .catch((err) => {
        setDeleteSuccessOpen(true);
        setDeleteAlertMsg("Error in Deleting. Try again!");
        setDeleteSuccessAlertState("error");
      });
  };

  

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          <Avatar
            alt={row.name}
            style={{
              color: "white",
              backgroundColor: "#23395B",
              margin: 6,
            }}
            src={row.profileImage}
          />
          {/* {row.name} */}
        </TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{row.type}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List dense={true}>
                {row.permissions.map((permission) => {
                  return (
                    <ListItem>
                      <ListItemIcon>
                        <StarIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>{permission.toLowerCase()}</ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </TableCell>
        {/* <TableCell padding="checkbox">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <EditIcon handleOpen={() => alert("hi")} />
          </IconButton>
        </TableCell> */}
        <TableCell padding="checkbox">
          {/* <IconButton
            style={{ color: "red" }}
            aria-label="upload picture"
            component="span"
            onClick={() => onDeleteAdmin(row.id)}
          >
            <DeleteIcon />
          </IconButton> */}
          <DeleteAdminPopup onDeleteAdmin={onDeleteAdmin} id={row.id} />
        </TableCell>
        {/* <EditAdminPermissionsModal row={row} /> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <h3>Bio</h3>
            <p>{row.bio}</p>
          </Collapse>
        </TableCell>
      </TableRow>
      <Snackbar
        open={deleteSuccessOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert onClose={handleClose} severity={deleteSuccessAlertState}>
          {deleteAlertMsg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default AdminRow;
