import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Card, makeStyles, CardContent, CardActions } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Avatar from "@material-ui/core/Avatar";
import { useTranslation } from 'react-i18next';
import CustomButton from '../../CustomButton';

const useStyles = makeStyles({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
  },
})

export default function StoryListItem({ partnerRow, deletePartnerFunc }) {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={6} style={{ textDecoration: 'none' }}>
      <Card >
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img alt="Remy Sharp" style={{ height: '100px' }} src={partnerRow.imageURL} />
          </div>
          <Typography variant="h6" color="primary" gutterBottom>
            {partnerRow.name}
          </Typography>
        </CardContent>
        <CardActions>
          <CustomButton
            color="primary"
            label={t('Common.edit')}
            margin={6}
            component={Link} to={`/admin/editPartner/${partnerRow.id}`}
          />
          <CustomButton
            color="primary"
            label={t('Common.delete')}
            margin={6}
            style={{backgroundColor: 'red'}}
            onClick={() => deletePartnerFunc(partnerRow.id)}
          />
        </CardActions>
      </Card>
    </Grid>
  );
}