export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const addRefereeUrl = "/api/volunteer/referee/information";
export const getVolunteerUrl = "/api/referee";
export const submitUrl = "/api/referee/review";
export const getRefereeUrl = "/api/volunteer/referee/information";

export const starComponents = [
    {
        title:"Reliability",
        nameValue:"reliability"
    },
    {
        title:"Flexibility",
        nameValue:"flexibility"
    },
    {
        title:"Professionalism",
        nameValue:"professionalism"
    },
    {
        title:"Work ethic",
        nameValue:"workEthic"
    },
    {
        title:"Quality of work",
        nameValue:"qualityOfWork"
    },
    {
        title:"Time Management",
        nameValue:"timeManagement"
    },
    {
        title:"Communication Skills",
        nameValue:"communicationSkills"
    },
    {
        title:"Responsibility",
        nameValue:"responsibility"
    },
    {
        title:"Teamwork",
        nameValue:"teamwork"
    },
    {
        title:"Commitment",
        nameValue:"commitment"
    }
];

export const systemGeneratedDetails = (volunteer)=>{

    return [
        {
            title:"Name of the applicant",
            value:volunteer.name.firstName+" "+volunteer.name.lastName
        },
        {
            title:"Email",
            value:volunteer.email
        },
        {
            title:"Contact number",
            value:volunteer.phoneNumber
        },
        {
            title:"Address",
            value:volunteer.address.permanentDoorNo+", "+volunteer.address.permanentStreet+", "+volunteer.address.permanentArea
        }
        ,{
            title:"Highest education qualification",
            value:volunteer.education.highestEducation
        },
        {
            title:"Employment details",
            value:volunteer.employment.employmentTitle
        }
    ];

}

export const nonStarSummary = (summary)=>{
    return [
        {
            title:"How long have you known this applicant for?",
            value: "Years - "+summary.durationYears+" Months - "+summary.durationMonths
        },
        {
            title:"In what capacity?",
            value:summary.relation
        },
        {
            title:"Other Information",
            value:summary.otherInformation            
        }
    ]
}
export const starSummary = (summary)=>{
    return [
        {
            title:"Reliability",
            nameValue:convertValue(summary.reliability)
        },
        {
            title:"Flexibility",
            nameValue:convertValue(summary.flexibility)
        },
        {
            title:"Professionalism",
            nameValue:convertValue(summary.professionalism)
        },
        {
            title:"Work ethic",
            nameValue:convertValue(summary.workEthic)
        },
        {
            title:"Quality of work",
            nameValue:convertValue(summary.qualityOfWork)
        },
        {
            title:"Time Management",
            nameValue:convertValue(summary.timeManagement)
        },
        {
            title:"Communication Skills",
            nameValue:convertValue(summary.communicationSkills)
        },
        {
            title:"Responsibility",
            nameValue:convertValue(summary.responsibility)
        },
        {
            title:"Teamwork",
            nameValue:convertValue(summary.teamwork)
        },
        {
            title:"Commitment",
            nameValue:convertValue(summary.commitment)
        }
    ]
}

const convertValue = (num)=>(
    num == 0?"N/A":num==1?"requires improvement":num==2?"Satisfactory":num==3?"good":num==4?"very good":"Excellent"
)

// "durationMonths": 9,
// "durationYears": 4,
// "accurate": true,
// "reliability": 5,
// "flexibility": 2,
// "professionalism": 5,
// "workEthic": 3,
// "qualityOfWork":3 ,
// "timeManagement": 3,
// "communicationSkills":3 ,
// "responsibility":2 ,
// "teamwork": 2,
// "commitment": 5


// 4.1.1. Reliability
// 4.1.2. Flexibility
// 4.1.3. Professionalism
// 4.1.4. Work ethic
// 4.1.5. Quality of work
// 4.1.6. Time Management
// 4.1.7. Communication Skills
// 4.1.8. Responsibility
// 4.1.9. Teamwork
// 4.1.10. Commitment