import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

function OrgVolunteerElement({volunteer,onDelete, history}) {
    return (
        <Chip
            style={{ margin: 6, padding: 2 }}
            size="medium"
            avatar={<Avatar>{volunteer.volunteerName.substr(0, 1).toUpperCase()}</Avatar>}
            color="primary"
            label={volunteer.volunteerName}
            // {/* Name should be a link to click and go to the profile  */}
            // onClick={() => history.push(`/profile/${volunteer.volunteerId}`)}
            onClick={() => history.push(`/profile`)}
            onDelete={()=>{onDelete(volunteer.volunteerId)}}
        />
    )
}

export default OrgVolunteerElement
