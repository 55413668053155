import React,{useEffect} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { tokenKey } from '../../Constant';
var jwt = require('jsonwebtoken');

// export default () => (
//   <Popup repositionOnResize={true} arrowStyle={false} closeOnDocumentClick={true} trigger={<button> Trigger</button>} position="right center">
//     <TestComponent/>
//   </Popup>
// );

//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExMSIsImVtYWlsIjoiMjIyIiwibmFtZSI6IjMzMyIsInR5cGUiOiI0NDQiLCJhY2Nlc3MiOjI1NjcwNzQsImlhdCI6MTYxMDgwMTQ1OSwiZXhwIjoxNjEwODAyODk5fQ.PzRTVLTQnI_FCjVdok1liFFJ-zciLD39UcRzbJ5anZ4

function TestComponent() {

  var token = jwt.sign({
    id: "1",
    email: "2",
    name: "3",
    type: "4",
    access: 5,
    iat: 6,
    success:"7",
    error:false
  }, 'shhhhh');

  useEffect(() => {
    
    
    localStorage.setItem(tokenKey,token);
    
    

  }, [])

    return (
        <React.Fragment>
            Popup
        </React.Fragment>
    )

}

export default TestComponent

