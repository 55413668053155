import axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../../Constant";

export const suspendVolunteer = async (id, date, suspend) => {
  const suspendVolunteerURL =
    serverHost + ":" + serverPort + "/api/admin/user/suspend";
  try {
    const response = await axios.put(
      suspendVolunteerURL,
      {
        id: id,
        date: date,
        suspend: suspend,
      },
      {
        headers: {
          usertoken: localStorage.getItem(tokenKey),
        },
      }
    );
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const terminateVolunteer = async (id) => {
  const terminateVolunteerURL =
    serverHost + ":" + serverPort + "/api/admin/user/terminate/" + id;
  try {
    const response = await axios.delete(terminateVolunteerURL, {
      headers: {
        usertoken: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const fetchVolunteerSuspendStatus = async (id) => {
  const fetchVolunteerSuspendStatusURL =
    serverHost + ":" + serverPort + "/api/admin/user/suspend/" + id;
  try {
    const response = await axios.get(fetchVolunteerSuspendStatusURL, {
      headers: {
        usertoken: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};



export const suspendVIO = async (id, date, suspend) => {
  const suspendVolunteerURL =
    serverHost + ":" + serverPort + "/api/admin/user/suspend";
  try {
    const response = await axios.put(
      suspendVolunteerURL,
      {
        id: id,
        date: date,
        suspend: suspend,
      },
      {
        headers: {
          usertoken: localStorage.getItem(tokenKey),
        },
      }
    );
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};



export const terminateVIO = async (id) => {
  const terminateVolunteerURL =
    serverHost + ":" + serverPort + "/api/admin/user/terminate/" + id;
  try {
    const response = await axios.delete(terminateVolunteerURL, {
      headers: {
        usertoken: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const fetchVIOSuspendStatus = async (id) => {
  const fetchVolunteerSuspendStatusURL =
    serverHost + ":" + serverPort + "/api/admin/user/suspend/" + id;
  try {
    const response = await axios.get(fetchVolunteerSuspendStatusURL, {
      headers: {
        usertoken: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};



