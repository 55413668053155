import {serverHost,serverPort,tokenKey} from "../../Constant";
import axios from 'axios';
import {vioListUrl, vioRequestUrl, vioRemoveUrl, volunteerListUrl, volunteerReviewUrl, volunteerRemoveUrl} from "../../containers/OrgVolunteers/List/Constants";


export const fetchVioList = async (status, search) => {
    
    const fetchUrl = serverHost + ":" + serverPort + vioListUrl + `/${status}`;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
            
                const result =  {
                    vios:response.data,
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        
        return result;
    } 
}

export const fetchVolunteerList = async (status, search) => {
    
    const fetchUrl = serverHost + ":" + serverPort + volunteerListUrl + `/${status}`;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
            
                const result =  {
                    volunteers:response.data,
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        
        return result;
    } 
}

export const requestVio = async (vioId, request) => {
    
    const fetchUrl = serverHost + ":" + serverPort + vioRequestUrl;
    try{
        const response = await axios.post(fetchUrl,
            {
                vioId, request
            },
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
            
                const result =  {
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        
        return result;
    } 
}


export const reviewVolunteer = async (volunteerId, accept) => {
    
    const fetchUrl = serverHost + ":" + serverPort + volunteerReviewUrl;
    try{
        const response = await axios.post(fetchUrl,
            {
                volunteerId, accept
            },
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
            
                const result =  {
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        
        return result;
    } 
}

export const removeVolunteer = async (volunteerId) => {
    
    const fetchUrl = serverHost + ":" + serverPort + volunteerRemoveUrl;
    try{
        const response = await axios.post(fetchUrl,
            {
                volunteerId
            },
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
            
                const result =  {
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        
        return result;
    } 
}

export const removeVio = async (vioId) => {
    
    const fetchUrl = serverHost + ":" + serverPort + vioRemoveUrl;
    try{
        const response = await axios.post(fetchUrl,
            {
                vioId
            },
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
            
                const result =  {
                    error:false
                }
                
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            
            return result;
        }
    }
    
    catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        
        return result;
    } 
}
