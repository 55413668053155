import {
  TableCell,
  TableRow,
  Checkbox,
  Grid,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Route } from "react-router-dom";
import { terminateEmgUser } from "../../../functions/EmergencyUser/functions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function EmgUserRow({ labelId, row, isItemSelected }) {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [alertState, setAlertState] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
    if (alertState == "success") {
      window.location.reload(false);
    }
  };

  const terminateUser = async (id, bool) => {
    let result = await terminateEmgUser(id, bool);
    if (result.error) {
      
      if (bool) {
        // alert("Error in Terminating User. Try Again!");
        setAlertOpen(true);
        setAlertMsg("Error in Terminating User. Try Again!");
        setAlertState("error");
      } else {
        // alert("Error in Activating User. Try Again!");
        setAlertOpen(true);
        setAlertMsg("Error in Activating User. Try Again!");
        setAlertState("error");
      }
    } else {
      
      if (bool) {
        // alert("User Terminated Successfully!");
        setAlertOpen(true);
        setAlertMsg("User Terminated Successfully!");
        setAlertState("success");
        // window.location.reload(false);
      } else {
        // alert("User Activated Successfully!");
        // window.location.reload(false);
        setAlertOpen(true);
        setAlertMsg("User Activated Successfully!");
        setAlertState("success");
      }
    }
  };

  return (
    <Route
      render={({ history }) => (
        <TableRow
          hover
          //   onClick={(event) => handleClick(event, row.name)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.name}
          selected={isItemSelected}
        >
          <TableCell component="th" id={labelId} scope="row" padding="none">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item style={{ marginLeft: 16 }}>
                {row.name}
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                {row.status == "Verified" && (
                  <Tooltip title="Verified" placement="top">
                    <VerifiedUserIcon fontSize="small" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>
            {row.status.charAt(0) + row.status.toLowerCase().slice(1)}
          </TableCell>

          <TableCell>
            {row.status == "CREATED" && (
              <Button
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => terminateUser(row.id, true)}
              >
                TERMINATE
              </Button>
            )}

            {row.status == "TERMINATED" && (
              <Button
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => terminateUser(row.id, false)}
              >
                ACTIVATE
              </Button>
            )}
          </TableCell>
          <Snackbar
            open={alertOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={handleClose} severity={alertState}>
              {alertMsg}
            </Alert>
          </Snackbar>
        </TableRow>
      )}
    />
  );
}

export default EmgUserRow;
