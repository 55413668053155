
import authReducer from "./AuthReducer";
import OpportunitySearchbarReducer from "./OpportunitySearchbarReducer";
import CalenderReducer from "./CalenderReducer";


const { combineReducers } = require("redux");

const rootReducer = combineReducers({
    auth: authReducer,
    opSearchbar:OpportunitySearchbarReducer,
    calender:CalenderReducer
})

export default rootReducer;