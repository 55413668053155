import { Button, CircularProgress, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import DeleteAccountDialog from "./DelAccDialog";
import { deleteAccount } from "./utils/delete_account";
import { logout } from "./utils/logout";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export default function DeleteAccount() {

    // const userId = useSelector(state => state.auth.id)
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (data) => {
        
        const [code] = await deleteAccount(data)
        
        if (code === 200) {
            enqueueSnackbar('Account Removed', {
                variant: 'success',
                persist: false
            })
            setTimeout(() => logout(history, dispatch, "/"), 1000)
        } else if (code <= 400 && code < 500) {
            enqueueSnackbar('Incorrect password', {
                variant: 'error',
                persist: false
            })
        }
        else {
            enqueueSnackbar('Something wrong', {
                variant: 'error',
                persist: false
            })
        }
    }

    const onDialogCancel = () => {
        setDialog(false)
    }

    const onDialogDelete = async (password) => {
        setDialog(false)
        setLoading(true)
        await onSubmit(password)
        setLoading(false)
    }

    return (
        // <Card>
        <Grid style={{ padding: "10px" }}>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Button onClick={() => setDialog(true)} variant="contained" style={{ backgroundColor: '#f52929', color: 'white' }} fullWidth>
                    {loading && <CircularProgress style={{ color: "white", marginRight: '10px' }} size={15} />}
                    {t('Settings.deleteAcc')}
                    </Button>
            </ Grid>
            <DeleteAccountDialog dialog={dialog} onCancel={onDialogCancel} onDelete={onDialogDelete} />
        </Grid>
        // </Card>
    );
}

