import React from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import NonFormalVOProcess from '../../components/VIO/NonFormalVOProcess';
import { submitCreateNonFormalVO } from '../../functions/VIO/NonFormalVolunteerOppertunity/submitCreateNonFormalVO';
import { fetchNonFormalVOFormData } from '../../functions/VIO/NonFormalVolunteerOppertunity/fetchNonFormalVOFormData';
import { Paper } from "@material-ui/core";
import MessageCard from '../../components/MessageCard';
import FormLanguageAlert from "../../components/FormLanguageAlert";

export default function CreateNonFormalVO() {
  const [errorMsg, setErrorMsg] = React.useState("");
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const [fetchErrorFormData, setFetchErrorFormData] = React.useState(false);
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {

    let mounted = true;
    let resultFormData = await fetchNonFormalVOFormData();

    if (mounted) {
      if (resultFormData.error) {
        setFetchErrorFormData(true);
        setErrorMsg("Error")
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFormDisplayData(resultFormData.item);
        setFetchErrorFormData(false);
      }
    }
    return () => mounted = false;
  }, [])

  const createNonFormalVOFunc = async (voDetails) => {

    var formData = { ...voDetails }
    
    delete formData.history

    let result = await submitCreateNonFormalVO(formData);
    
    if (result.error) {
      setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Created!")
      setPushError(!pushError)
      voDetails.history.push("/NonFormalVO/" + result.item.id)
    }
  }
  return (
    <React.Fragment>
      <FormLanguageAlert/>
      {
        (formDisplayData) ?
          (!fetchErrorFormData) ?
            <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
              <NonFormalVOProcess
                onSubmit={createNonFormalVOFunc}
                formDisplayData={formDisplayData}
              />
            </Paper>
            : <MessageCard message="Error" />
          : ""
      }
    </React.Fragment>

  );
}