import { useSelector } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import {
  Chip,
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
} from "@material-ui/core";
import Axios from "axios";
// import Error from '../../components/MessageCard';
import { useHistory } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { defaultItem } from "../../viewOpportunitiesPage/Constants";
import { serverHost, tokenKey } from "../../../Constant";
import LoadingPage from "../../../components/LoadingPage";
import SuccessMessage from "../../../components/SuccessMessage";
import { fetchVOFormDataFunc } from "../../../functions/VIO/VolunteerOppertunity/fetchVOFormData";
import {
  applyOpportunity,
  fetchOpportunity,
} from "../../../functions/opportunity/fetchOpportunities";
// import PopUpCalendar from "../../viewOpportunitiesPage/PopUpCalendar";
import { fetchNonFormalVOFormData } from "./Functions";

const opportunityList = {
  a: [
    // ["Opportunity Type", "type"],
    ["Category", "category"],
    ["No of Volunteers", "noOfVolunteers"],
  ],
  b: [
    ["Supervisor Name", "supervisorName"],
    ["Email", "supervisorEmail"],
    ["Mobile", "supervisorMobile"],
    ["Landline", "supervisorLandline"],
    ["Fax", "supervisorFax"],
  ],
  c: [
    ["Location", "location"],
    ["Grama Nildari Division", "gnDivision"],
    ["Divisional Secretariat", "divisionalSecretariat"],
    ["Disctrict", "district"],
  ],
  d: [
    ["Designation", "designation"],
    // ["Degree Level Required", "degreeLevelRequired"],
    ["Language Required", "language"],
    // ["Subject Area Required", "subjectAreaRequired"],
  ],
};
function NonFormalOportunityDetailView({ match, isAdminView }) {
  const id = match.params.id;
  const [error, setError] = useState(false);
  const [item, setItem] = useState();
  const [formDisplayData, setFormDisplayData] = useState(null);
  const [applySuccess, setApplySuccess] = useState(false);

  const email = useSelector((state) => state.auth.email);

  const history = useHistory();

  useEffect(async () => {
    let mounted = true;
    
    let result = await fetchNonFormalVOFormData(id);
    let resultFormData = await fetchVOFormDataFunc();

    
    if (mounted) {
      if (result.error) {
        setError(true);
      } else {
        const result_item = {
          ...result.item,
          startDate: new Date(result.item.startDate),
          endDate: new Date(result.item.endDate),
        };
        setItem(result_item);
        setFormDisplayData(resultFormData.item);
      }
      // setError(result.error);
    }
    
    return () => (mounted = false);
  }, []);

  const apply = async (dates) => {
    dates = dates.map(
      (date) =>
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()
    );
    const result = await applyOpportunity(email, id, dates);
    if (result.error) {
      setError(true);
    } else {
      setApplySuccess(true);
      history.push("/opportunities/myOpportunities");
    }
  };

  const approveOpportunity = () => {
    const approveUrl = serverHost + "/api/admin/opportunity/review";
    Axios.post(
      approveUrl,
      {
        id: id,
        approve: true,
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    )
      .then((res) => {
        
        if (res.status == 200) {
          alert("Opportunity Approved Successfully!");
          window.location.reload(false);
        }
      })
      .catch((err) => {
        alert("Error in Opportunity Approving!");
      });
  };

  const rejectOpportunity = () => {
    const approveUrl = serverHost + "/api/admin/opportunity/review";
    Axios.post(
      approveUrl,
      {
        id: id,
        approve: false,
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    )
      .then((res) => {
        
        if (res.status == 200) {
          alert("Opportunity Rejected!");
          window.location.reload(false);
        }
      })
      .catch((err) => {
   
        alert("Error in Opportunity Rejecting!");
      });
  };

  if (error) return <Error />;
  if (!item || !formDisplayData) return <LoadingPage />;

  const sdgMap = formDisplayData.sdg_numbers.reduce((map, obj) => {
    map[obj.id] = obj.sdg_number;
    return map;
  }, {});
  const skillMap = formDisplayData.required_skills.reduce((map, obj) => {
    map[obj.id] = obj.skill;
    return map;
  }, {});
  const categoryMap = formDisplayData.categories_VO.reduce((map, obj) => {
    map[obj.id] = obj.opport;
    return map;
  }, {});
  const areaMap = formDisplayData.areas_VO.reduce((map, obj) => {
    map[obj.id] = obj.area;
    return map;
  }, {});

  return (
    <Paper style={{ padding: "18px 36px", marginTop: 40, marginBottom: 40 }}>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h5" color="primary">
            {item.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {item.sdgNumbers.map((goal) => (
            <Chip
              label={sdgMap[goal]}
              color="primary"
              key={Math.random()}
              style={{ margin: 6 }}
            />
          ))}
        </Grid>
        {opportunityList.a.map((i, index) => (
          <Grid item md={4} sm={6} xs={12} key={Math.random()}>
            <Typography variant="subtitle2" color="textSecondary">
              {i[0]}
            </Typography>
            <Typography variant="body1">
              {index === 1 && Number.isInteger(parseInt(item[i[1]]))
                ? areaMap[item[i[1]]]
                : item[i[1]]}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            Description
          </Typography>
          <Typography variant="body1" gutterBottom>
            {item.description}
          </Typography>
          {/* <Typography variant="body1" gutterBottom>
            {item.descriptionEnglish}
          </Typography>
          <Typography variant="body1">{item.descriptionTamil}</Typography> */}
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Reqiurements
            </Typography>
          </Grid>
          {opportunityList.d.map((i) => (
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
              key={Math.random()}
              style={{ marginTop: 12 }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {i[0]}
              </Typography>
              <Typography variant="body1">{item[i[1]]}</Typography>
            </Grid>
          ))}
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <Typography variant="subtitle2" color="textSecondary">
              Skills Required
            </Typography>
            <Typography variant="body1">
              {item.requiredSkills.map((skill) => skillMap[skill]).toString()}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Duration
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Period
            </Typography>
            <Typography variant="body1">
              {format(item.startDate, "dd/MM/yyyy")} -{" "}
              {format(item.endDate, "dd/MM/yyyy")}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Working Hours Per Day
            </Typography>
            <Typography variant="body1">
              {`${item.workingHourCount} hours (${
                item.isFullTime ? "Full Time" : "Part Time"
              })`}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Time
            </Typography>
            <Typography variant="body1">{item.time.toString()}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            {/* <Typography variant="subtitle2" color="textSecondary">
              Days of Week
            </Typography> */}
            {/* <Typography variant="body1">{item.days.toString()}</Typography> */}
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Supervisor Details
            </Typography>
          </Grid>
          {opportunityList.b.map((i) => (
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              key={Math.random()}
              style={{ marginTop: 12 }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {i[0]}
              </Typography>
              <Typography variant="body1">{item[i[1]]}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Location of Work Station
            </Typography>
          </Grid>
          {opportunityList.c.map((i) => (
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
              key={Math.random()}
              style={{ marginTop: 12 }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {i[0]}
              </Typography>
              <Typography variant="body1">{item[i[1]]}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 32 }}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Other
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Will training provided?
            </Typography>
            <Typography variant="body1">
              {item.isTrainingProvided ? "Yes" : "No"}
            </Typography>
            <Typography variant="body1">
              {item.isTrainingProvided ? item.trainingDetails : ""}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Will expenses covered?
            </Typography>
            <Typography variant="body1">
              {item.isExpenseCovered ? "Yes" : "No"}
            </Typography>
            <Typography variant="body1">
              {item.isExpenseCovered ? item.coveredExpenseDetails : ""}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Special Requirements
            </Typography>
            <Typography variant="body1">{item.specialRequirnments}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Average Volunteer Hours
            </Typography>
            <Typography variant="body1">{item.averageVolunteerHours}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ margin: "12px 0" }} />

      {/* TODO: here */}
      {isAdminView && item.status != "APPROVED" && item.status != "REJECTED" && (
        <Grid>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => approveOpportunity()}
          >
            APPROVE
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => rejectOpportunity()}
          >
            REJECT
          </Button>
        </Grid>
      )}

      {isAdminView && item.status == "APPROVED" && item.status != "REJECTED" && (
        <Grid>
          <Chip label="APPROVED" icon={<CheckCircleIcon />} color="primary" />
        </Grid>
      )}

      {isAdminView && item.status == "REJECTED" && (
        <Grid>
          <Chip label="REJECTED" icon={<NotInterestedIcon />} color="primary" />
        </Grid>
      )}

      {isAdminView && item.status == "COMPLETED" && (
        <Grid>
          <Chip label="COMPLETED" icon={<CheckCircleIcon />} color="primary" />
        </Grid>
      )}
    </Paper>
  );
}

export default NonFormalOportunityDetailView;
