import React from "react";
import { Route, Switch } from "react-router-dom";

import LoginPage from "../../LoginPage/LoginPage";
import Profile from "../../Admin/Profile";
import ProfilePage from "../../VolunteerProfile/ProfilePage";
import VIOProfilePage from "../../VIOProfile/VIOProfilePage";
import SettingsPage from "../../UserCommon/SettingsPage";
import HomePage from "../../HomePage/HomePage";
import SignUpPage from "../../SignUpPage/SignUpPage";
import VolunteerOpportunities from "../../VolunteerOpportunity"

import LandingPage from "../../LandingPage";
import TestComponent from "../../TestPage/TestComponent";
import VerifyEmail from "../../verify/email/VerifyEmail";
import VioVerifyEmail from "../../verify/email/VioVerifyEmail";
import VerifyPhone from "../../verify/phone/VerifyPhone";
import ForgotPasswordRequest from "../../UserCommon/ForgotPasswordRequest";
import ForgotPassword from "../../UserCommon/ForgotPassword";
import VolunteerPublicProfile from "../../public_profiles/Volunteer";
import VioPublicProfile from "../../public_profiles/Vio";
import AboutUs from "../../AboutUs";
import ContactUs from "../../ContactUs/ContactUs";
import {PUBLIC, LOGGED_OUT, COMPLAINT} from "../Constants";
import CustomRoute from "../CustomRoute";
import EngageWith from "../../EngageWith"
import Newsfeed from "../../UserCommon/Newsfeed";
import Complaint from '../../../components/Complaint/Complaint';
import VIOProfile from "../../VIO/VIOProfile"
import VolunteerProfile from "../../Volunteer/VolunteerProfile"

function CommonRoutes() {
  return (
    <Switch>
      <CustomRoute exact path="/" component={LandingPage} routes={PUBLIC} />
      <CustomRoute exact path="/login" component={LoginPage} routes={LOGGED_OUT} message="You already Logged into the system" />
      <CustomRoute exact path="/register" component={SignUpPage} routes={LOGGED_OUT} message="You already Logged into the system" />
      <CustomRoute exact path="/aboutus" component={AboutUs} routes={PUBLIC} />
      <CustomRoute exact path="/contactus" component={ContactUs} routes={PUBLIC} />
      <CustomRoute exact path="/engage-with-us" component={EngageWith} routes={PUBLIC} />
      <CustomRoute exact path="/newsfeed" component={Newsfeed} routes={PUBLIC} message = "You already Logged into the system" />

      {/* <CustomRoute exact path="/profile" component={ProfilePage} routes = {PUBLIC} /> */}
      {/* <CustomRoute exact path="/vioprofile" component={VIOProfilePage} routes = {PUBLIC} /> */}
      {/* <CustomRoute exact path="/admin/profile" component={Profile} routes = {PUBLIC} /> */}
      {/*<CustomRoute exact path="/user-settings" component={SettingsPage} routes = {PUBLIC} />*/}
      <CustomRoute exact path="/verifyEmail/:secretKey" component={VerifyEmail} routes={PUBLIC} />
      <CustomRoute
        exact
        path="/verifyVioEmail/:secretKey"
        component={VioVerifyEmail}
        routes={PUBLIC} />

      <CustomRoute exact path="/verifyMobile/:email" component={VerifyPhone} routes={PUBLIC} />

      {/* <CustomRoute exact path="/profile" component={ProfilePage} routes = {PUBLIC} /> */}
      {/* <CustomRoute exact path="/vioprofile" component={VIOProfilePage} routes = {PUBLIC} /> */}
      {/* <CustomRoute exact path="/admin/profile" component={Profile} routes = {PUBLIC} /> */}
      {/* <CustomRoute exact path="/verifyMobile" component={VerifyMobilePage} routes = {PUBLIC} />*/}

      <CustomRoute exact path="/testComponent" component={TestComponent} routes={PUBLIC} />
      <CustomRoute exact path="/forgetPassword" component={ForgotPasswordRequest} routes={PUBLIC} />
      <CustomRoute exact path="/forgetPassword/:token" component={ForgotPassword} routes={PUBLIC} />
      <CustomRoute exact path="/submitComplaint" component={Complaint} routes = {COMPLAINT}  message = "Sorry! Only Email Verified Volunteers and Volunteer Involving Organizations have access to this page"/>

      <CustomRoute
        exact
        path="/volunteer/:volunteerId"
        component={VolunteerPublicProfile}
        routes={PUBLIC} />
      <CustomRoute exact path="/vio/:vioId" component={VioPublicProfile} routes={PUBLIC} />
      <CustomRoute exact path="/profile/vio/:vioId" component={VIOProfile} routes={PUBLIC} />
      <CustomRoute exact path="/profile/volunteer/:volunteerId" component={VolunteerProfile} routes={PUBLIC} />

      <CustomRoute exact path="/volunteer-opportunities" component={VolunteerOpportunities} routes={PUBLIC} />
    </Switch>
  );
}

export default CommonRoutes;
