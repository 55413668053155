import React from "react";
import Carousel from "react-material-ui-carousel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ImageCard from "./ImageCard";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OpportunityCard from "../containers/VolunteerOpportunity/OpportunityCard";
import { Link } from "react-router-dom";

function chunk(array, size) {
  const chunked_arr = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunked_arr[chunked_arr.length - 1];
    if (!last || last.length === size) {
      chunked_arr.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunked_arr;
}

const useStyles = makeStyles((theme) => ({
  background: {
    padding: "18px 6px",
    margin: "12px 0",
    borderRadius: 4,
    backgroundColor: "#e1ebff",
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  header: {
    marginBottom: 2,
  },
  parallel: {
    padding: "0 66px",
    display: "flex",
    // whiteSpace: 'nowrap'
  },
}));

export default function CustomCarousal({ header, items }) {
  const classes = useStyles();
  const lg = useMediaQuery("(min-width:1280px)");
  const sm = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography
        variant="h5"
        marked="left"
        gutterBottom
        color="primary"
        className={classes.header}
      >
        {t("Volunteer." + header.toString())}
      </Typography>
      <div className={classes.background}>
        {items.length != 0 ? (
          <Carousel
            navButtonsAlwaysVisible="true"
            animation="slide"
            autoPlay={false}
          >
            {chunk(items, lg ? 3 : sm ? 2 : 1).map((itemList, i) => (
              <Grid
                container
                key={i.toString()}
                className={(classes.container, classes.parallel)}
                spacing={3}
              >
                {itemList.map((item) => (
                  <Grid item xs={12} sm={6} md={4}>
                    {/* <ImageCard
                      id={item.id}
                      url={"/viewOpportunity/"}
                      oppertunityTitle={item.title}
                      voCategory={item.category}
                      type={item.type}
                      volunteerCount={item.volunteerCount}
                      VODescriptionEnglish={item.descriptionEnglish}
                      requiredSkills={item.requiredSkills}
                    /> */}
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/viewOpportunity/${item.id}`}
                    >
                      <OpportunityCard
                        sdg={Number(item.sdgNumbers[0]) || 1}
                        // className={cls.card}
                        header={Number(item.category) || 1}
                        title={item.title || ""} 
                        vio={item.Vio.name || ""}
                        duration={item.workingHourCount || 0}
                        functions={item.coreFunctions.slice(0, 3) || []}
                        location={item.district || ""}
                      />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Carousel>
        ) : (
          t("Volunteer.No Items")
        )}
      </div>
    </div>
  );
}

CustomCarousal.defaultProps = {
  header: "Online Volunteering Opportunities",
};

// Carousal.propTypes = {
//   header: propTypes.string
// };
