import { TextField, Grid, Typography, Divider } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import CustomButton from '../../components/CustomButton';
import { fetchOrgVolunteers, removeOrgVolunteer, addOrgVolunteer } from '../../functions/OrgVolunteer/apicalls';
import OrgVolunteerElement from './OrgVolunteerElement';
import IconTextField from '../../components/IconTextField'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MessageCard from '../../components/MessageCard';
import LoadingPage from '../../components/LoadingPage';

function ViewOrgVolunteers() {
    const [error, setError] = useState(false);
    const [load, setLoading] = useState(false);
    const [volunteerList, setList] = useState([]);
    const [vid, setVID] = useState(false);
    const history = useHistory();
    const [searchValue, onSearchValue] = useState("");
    const [volunteerEmail, setVolunteerEmal] = useState("");
    const [change, setChange] = useState(false);


    useEffect(async () => {
        let mounted = true;
        
        let result = await fetchOrgVolunteers(searchValue);

        
        if (mounted) {
            setChange(false);
            setError(result.error);
            setList(result.volunteers);
        }
        
        return () => mounted = false;

    }, [error, load, change])

    const onTypeEmail = (e) => {
        setVolunteerEmal(e.target.value);
    }

    const onType = (e) => {
        onSearchValue(e.target.value)
    }
    const onSearch = () => {
        setLoading(!load)
    }
    const onDelete = async (id) => {
        const result = await removeOrgVolunteer(id);
        if (!(result.error)) {
            setLoading(!load)
            enqueueSnackbar('Volunteer deleted successfully!', {
                variant: 'success',
                persist: false
            })
        }
        else {
            setError(true);
        }
    }

    const onRegister = async (email) => {

        const result = await addOrgVolunteer(email);
        if (!(result.error)) {
            setChange(true);
            enqueueSnackbar('Volunteer registered successfully!', {
                variant: 'success',
                persist: false
            })
        }
        else {
            setError(true);
        }
    }
    const { t } = useTranslation()

    return (
        volunteerList ?
            !error ?
                <React.Fragment>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item>
                            <Typography variant="h5">
                                {t('ViewOrgVolunteers.title')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-end" style={{ marginBottom: 16 }}>
                            <Grid item>
                                <CustomButton margin="12px 0" variant="contained" style={{ padding: '15px 20px' }} label={!vid ? t('ViewOrgVolunteers.addTitle') : t('Common.cancel')} onClick={() => { setVID(!vid) }} />
                            </Grid>
                        </Grid>

                        {vid &&
                            <Grid container item justify="center" xs={12} style={{
                                border: '1px solid #c0c0c0',
                                padding: 24,
                                borderRadius: 4,
                                //  width: "60vw",
                                margin: '16px 0'
                            }}>
                                <Grid item>
                                    <TextField placeholder={t('ViewOrgVolunteers.enterEmail')} onChange={onTypeEmail} variant="outlined" />
                                </Grid>
                                <Grid item >
                                    <CustomButton label={t('Custom.register')}
                                        margin="0 12px"
                                        style={{ padding: '15px 20px' }}
                                        onClick={() => {
                                            onRegister(volunteerEmail)
                                            setVID(!vid)
                                        }} />
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                    <Divider style={{ marginTop: 20, marginBottom: 10, backgroundColor: '#c0c0c0' }} />
                    <Grid container spacing={4}>
                        <Grid item xs={12} container direction="row" justify="flex-end">
                            <Grid item>
                                <IconTextField
                                    onChange={onType}
                                    name="search"
                                    placeholder={t('ViewOrgVolunteers.searchHere')}
                                    defaultValue={""}
                                    icon={<SearchIcon />}
                                />
                            </Grid>
                            <Grid item>
                                <CustomButton label={t('Common.search')} onClick={onSearch} margin="12px 0" variant="contained" style={{ padding: '15px 20px' }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {volunteerList.map((volunteer, i) => (
                                <OrgVolunteerElement
                                    history={history}
                                    key={i.toString()}
                                    volunteer={volunteer}
                                    onDelete={onDelete}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </React.Fragment>
                :
                <MessageCard/>
            :
            <LoadingPage/>
    )
}

export default ViewOrgVolunteers
