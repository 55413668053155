import {serverHost,serverPort,tokenKey} from "../../Constant";
import axios from 'axios';
import {getReportUrl} from '../../containers/Reports/utils/constants'

export const fetchReportbackDetails = async (params)=>{

    
    const fetchUrl = serverHost+":"+serverPort+getReportUrl;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                tokenKey: localStorage.getItem(tokenKey)
            },
            params
        });
        
        if (response.status == 200) {
                const result =  {
                    items:response.data
                }
                
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }

}
catch(err){
    
    const result = {
        message:err.message,
        error:true
    }
    
    return result;
} 


};