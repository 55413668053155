export const registerPostUrl = '/api/register';
export const fetchVolunteerOpportunityUrl = '/api/opportunity/get';
export const fetchNonFormalVolunteerOpportunityUrl = '/api/nonformal-opportunity/get';
export const changeVolunteerOpportunityApplicantStateUrl = '/api/VIO/VO/changeVolunteerOpportunityApplicantState';
export const createVOUrl = '/api/vio/create-opportunity';
export const createNonFormalVOUrl = '/api/nonformal-opportunity/vio/create';
export const updateVOUrl = '/api/opportunity/update';
export const updateNonFormalVOUrl = '/api/nonformal-opportunity/vio';
export const fetchVIOHomeUrl = '/api/VIO/home';
export const fetchVOSearchUrl = '/api/vio/vio-opportunity/search';
export const fetchVOFormDataUrl = '/api/formData/createVO';
export const fetchVIOHomeCardsUrl = '/api/vio/opportunity-stat';
export const deleteVOUrl = '/api/opportunity/delete';
export const completeVOUrl = '/api/opportunity/complete';