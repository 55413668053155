const convertToBinary = (x) => {
    let bin = 0;
    let rem, i = 1;
    while (x !== 0) {
        rem = x % 2;
        x = parseInt(x / 2);
        bin = bin + rem * i;
        i = i * 10;
    }
    let len = 0;
    if (bin.toString().length < 21) {
        len = 21 - bin.toString().length;
    }
    while (len > 0) {
        bin = "0" + bin
        len = len - 1;
    }
    return bin;
}

export function check(acl, access, path) {
    const token = localStorage.token;
    if (acl["all"] === true) {
        return true;
    } else {
        if (token != null) {
            const binary = convertToBinary(parseInt(access)).toString();
            // console.log(access);
            if ("and" in acl) {
                const andRoutes = acl['and'];
                for (var i = 0; i < andRoutes.length; i++) {
                    if (binary[andRoutes[i]] !== "1") {

                        return false;
                    }
                }
            }
            if ("or" in acl) {
                const orRoutes = acl['or'];
                let or = false;
                for (var i = 0; i < orRoutes.length; i++) {
                    if (binary[orRoutes[i]] === "1") {
                        or = true;
                    }
                }
                if (or === false) {
                    return false;
                }
            }
            if ("type" in acl) {
                const typeRoutes = acl['type'];
                let type = false;
                for (var i = 0; i < typeRoutes.length; i++) {
                    if (binary[typeRoutes[i]] === "1") {
                        type = true;
                    }
                }
                if (type === false) {
                    return false;
                }
            }
            if ("not" in acl) {
                const notRoutes = acl['not'];
                for (var i = 0; i < notRoutes.length; i++) {
                    if (binary[notRoutes[i]] === "1") {
                        return false;
                    }
                }
            }
            return true;
        } else {
            if (path === '/register') {
                return true;
            }
            return false;
        }
    }
}