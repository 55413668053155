
import React from "react";
import RequestEmailForm from "./forms/RequestEmailForm";
import ResetPasswordForm from "./forms/ResetPasswordForm";

export default function ForgotPassword({type}) {
    

    return (
        <div>
            {type === "REQUEST" && <RequestEmailForm/>}
            {type === "RESET" && <ResetPasswordForm/>}
        </div>
    );
}

