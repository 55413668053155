import {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {Grid, Typography, Divider} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles} from "@material-ui/styles";
import OpportunityCard from "./OpportunityCard"
import api from "../../api"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    root: {},
    header: {
        padding: "40px",
        textAlign: "center",
        backgroundColor: "#1d4971",
        color: "white"
    },
    container: {
        padding: "0 5%"
    },
    containerHeader: {
        padding: "40px",
        textAlign: "center",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gridGap: "2rem"
    },
    card: {
        gridArea: "auto",
        height: "350px"
    },
    pagination: {
        margin: "40px auto"
    }
}))


function numberOfPages(numOfOpps, pageSize) {
    return Math.ceil(numOfOpps / pageSize)
}

function opporunityWindow(opporunities, pageSize, page) {
    return opporunities.slice(pageSize*(page-1), pageSize*page)
}

export default function () {

    const cls = useStyles()
    const [page, setPage] = useState(1)
    const [pageSize, _]  = useState(12)
    const [opporunities, setOpportunities] = useState([])
    const {t} = useTranslation()

    useEffect(() => {
        (async () => {
            const [status, data] = await api.opporunity.getOpportunities({searchValue: "", type: "ALL"})
            if (status === 200) {
                setOpportunities(data)

            }
        })()
    }, [])

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={cls.header}>
                <Typography variant="h4" style={{fontWeight: "600"}}>
                    {t('VolunteeringOpportunities.volunteeringOp')}
                </Typography>
                <Typography variant="subtitle2">
                    Home / Volunteering Opportunities
                </Typography>
            </Grid>
            <Grid item container xs={12} className={cls.container}>
                <Grid item xs={12} className={cls.containerHeader}>
                    <Typography variant="subtitle1" style={{textTransform: "uppercase"}}>
                        {t('VolunteeringOpportunities.title')}
                    </Typography>
                    <Typography variant="h4" style={{fontWeight: "600"}}>
                        {t('VolunteeringOpportunities.subtitle')}
                    </Typography>
                </Grid>
                <Divider/>
                <Grid item xs={12} spacing={0} className={cls.grid}>
                    {
                        opporunityWindow(opporunities, pageSize, page).map(opp => (
                        <Link style={{textDecoration: "none"}} to={`/viewOpportunity/${opp.id}`}>
                            <OpportunityCard
                                sdg={Number(opp.sdgNumbers[0]) || 1}
                                className={cls.card}
                                header={Number(opp.category) || 1}
                                title={opp.title || ""}
                                vio={opp.Vio?.name || ""}
                                duration={opp.workingHourCount || 0}
                                functions={opp.coreFunctions.slice(0, 2) || []}
                                location={opp.district || ""}
                                />
                        </Link>
                    ))
                    }
                </Grid>
                <Divider/>
                <Grid item container xs={12} justify="space-around" spacing={2} className={cls.pagination}>
                    <Pagination
                        disabled={opporunities.leigth < 1}
                        count={numberOfPages(opporunities.length, pageSize)}
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        onChange={ (_, p) => setPage(p) }
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
