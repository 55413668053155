import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../Constant";
import { subVioTransferUrl, subVioGetUrl } from '../../containers/Admin/Constants';

export const submitSubVioTransfer = async (transferDetails) => {

    const saveUrl = serverHost + ":" + serverPort + subVioTransferUrl;
    try {
        const response = await axios.post(saveUrl,{...transferDetails},{headers: {'token': localStorage.getItem(tokenKey)}});
  
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status
        }
        return result;
    }

};

export const getSubVioDetails = async ({parentVioId, newRegistrationNumber}) => {
        const getUrl = serverHost + ":" + serverPort + subVioGetUrl;
        try {
            const response = await axios.get(getUrl,
                {headers: {'token': localStorage.getItem(tokenKey)}, 
                params : {parentVioId, newRegistrationNumber}});
            if (response.status === 200) {
                const result = {
                    item: response.data,
                    error: false,
                    status: response.status
                }
                return result;
            }
            else {
                const result = {
                    item: response.message,
                    error: true,
                    status: response.status
                }
                return result;
            }
        }
    
        catch (err) {
            const result = {
                item: err.response ? err.response.data.message : "Something went wrong!",
                error: true,
                status: err.status
            }
            return result;
        }
    
    };

