import axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { submitComplaintURL } from "../../../containers/Admin/Complaint/Constants";

export const submitComplaintStatus = async (id, resolve) => {
  console.log(resolve);
  console.log(id);
  const registerUrl = serverHost + ":" + serverPort + submitComplaintURL+ "/" + id ;
  try {
    const response = await axios.post(
      registerUrl,
      {
        resolve: resolve
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    );
    if (response.status === 200) {
      console.log(response.data);
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.response ? err.response.data.message : "Error",
      error: true,
    };
    return result;
  }
};
