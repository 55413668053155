import React from 'react'
import { Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function ViewSubvioDetails({ details }) {
    const { t } = useTranslation()
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item style={{ padding: '20px 0' }}>
                <Typography variant="h5" align="center" color="primary">{t('ViewSubvio.title')}</Typography>
            </Grid>
            <Grid container direction="row" xs={12} item>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('Common.name')}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {details.name}
                    </Typography>
                </Grid>
                {/* <Grid item6xs={4}>
                <Typography variant="subtitle2" color="textSecondary">
                Subvio Leader     
                </Typography>
                <Typography variant="body1" color="textPrimary">
                {details.subvioLeader}     
                </Typography>
            </Grid> */}
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('CreateSubVio.description')}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {details.description}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('Common.email')}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {details.email}
                    </Typography>
                </Grid>
                {/* This should be a link to redirect VIO profile */}
                {/* <Grid item xs={4}>
                <Typography variant="subtitle2" color="textSecondary">
                Vio Name   
                </Typography>
                <Typography variant="body1" color="textPrimary">
                {details.vioName}     
                </Typography>
            </Grid>  */}
            </Grid>
        </Grid>
    )
}

export default ViewSubvioDetails
