import React, { useEffect, useState } from 'react'
import MessageCard from '../../../components/MessageCard';
import { successMessage } from './Constants'
import { verifyPhone, EnterNumber } from '../../../functions/verify/apiCalls';
import { Paper} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useForm } from "react-hook-form";

import EnterPhone from './EnterPhone';
import EnterCode from './EnterCode';
import CustomButton from '../../../components/CustomButton';
import { useTranslation } from 'react-i18next';

function VerifyPhone({ match }) {
    const email = match.params.email;

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [resend, setResend] = useState(false);
    const [phnNumber, setNumber] = useState("");
    const {t} = useTranslation()

    const onSubmit = async (email, phoneToken) => {
        setResend(false);
        const result = await verifyPhone(email, phoneToken);
        if (result.error) {
            setError(result.error);
            setErrMessage(result.message);
        }
        else {
            setError(result.error);
            setErrMessage("");
            setSuccess(true);
        }
    }

    const onEnter = async (email, number) => {
        setResend(false);
        setNumber(number);
        const result = await EnterNumber(email, number);
        
        if (result.error) {
            setError(result.error);
            setErrMessage(result.message);
            setResend(true);
        }
        else {
            setError(result.error);
            setErrMessage("");
            setResend(true);
            // setSuccess(true);
        }
    }

    return (
        <React.Fragment>
            {success ?
                <React.Fragment>
                <CustomButton label={t("VerifyMobile.verifyEmail")} component={Link} to={`/verifyEmail/${email}`} variant="outlined" />
                    <MessageCard
                        error={false}
                        message={t("VerifyMobile.success")}
                    />
                </React.Fragment>
                    :
                    <Paper style={{ padding: '18px 36px', maxWidth: 800, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
                    <CustomButton label={t("VerifyMobile.verifyEmail")} component={Link} to={`/verifyEmail/${email}`} variant="outlined" />
                        <EnterPhone t = {t} email = {email} onEnter = {onEnter} resend = {resend} phnNumber = {phnNumber}/>
                        <EnterCode t = {t} email = {email} onSubmit = {onSubmit} error = {error} errMessage ={errMessage}/>
                    </Paper>
                
            }
        </React.Fragment>
    )
}

export default VerifyPhone
