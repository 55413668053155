import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { fetchNonFormalVOListURL } from '../../../containers/VIO/NonFormalVO/Constants';


export const fetchNonFormalVOList = async (userID) => {

    const fetchUrl = serverHost + ":" + serverPort + fetchNonFormalVOListURL;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },
                params: {
                    userID: userID
                }
            });
        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.message,
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

