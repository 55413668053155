import React from 'react';
import Grid from '@material-ui/core/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@material-ui/core/Typography';
import 'date-fns';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import CustomButton from '../../CustomButton';
import RoundImage from "../../../containers/Admin/RoundImage";
import StoryListItem from './StoryListItem';
import { useTranslation } from 'react-i18next';

export default function VolunteerStory({ fetchDetails, deleteVolunteerStoryFunc }) {

    let history = useHistory();
    const { t } = useTranslation()

    return (
        <div>
            <Grid container justify="space-between">
                <Grid item>
                    <Typography variant="h5" color="primary">
                        {t('Admin.volunteerStory')}
                    </Typography>
                </Grid>
                <Grid item>
                    <CustomButton
                        color="primary"
                        label={t('Admin.addVolunteerStory')}
                        margin={6}
                        component={Link} to={"/admin/addVolunteerStory"}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} style={{
                // border: '1px solid #c0c0c0',
                padding: 16,
                borderRadius: 4,
                marginTop: 12,
                backgroundColor: '#e1ebff',
                margin: 0
            }}>
                {
                    fetchDetails ? fetchDetails.map((storyRow) => (
                        <StoryListItem
                            key={Math.random()}
                            storyRow={storyRow}
                            deleteVolunteerStoryFunc={deleteVolunteerStoryFunc}
                        />
                    )) : ""
                }
                {fetchDetails.length == 0 && <Typography>{t('Common.noResults')}</Typography>}
            </Grid>
        </div>
    );
}
