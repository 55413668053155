import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useForm } from "react-hook-form";
import IconTextField from '../../IconTextField';
import CustomButton from '../../CustomButton';
import EVOListItem from './EVOListItem';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function EVOContainer({ voRows, handleSearchVO, name }) {

    const { register, handleSubmit } = useForm();
    const history = useHistory()
    const { t } = useTranslation()

    React.useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleSubmit(handleSearchVO);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(handleSearchVO)}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h5" color="primary">
                        {name}
                    </Typography>
                    <Grid container spacing={1} direction="row" style={{maxWidth: 400}}>
                        <Grid item xs={9}>
                            <IconTextField
                                name="search"
                                inputRef={register}
                                placeholder={t('Common.typeHere')}
                                defaultValue={""}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CustomButton label={t('Common.search')} type="submit" margin="12px 0" variant="contained"
                                style={{ padding: '15px 20px' }} />
                        </Grid>
                    </Grid>
                </div>
            </form>
            <Grid container item xs={12} spacing={2} style={{
                // border: '1px solid #c0c0c0',
                padding: 16,
                borderRadius: 4,
                backgroundColor: '#e1ebff',
                margin: 0
            }}>
                {/* maximum 10 items for a page(voRows has max 10 items) */}
                {
                    voRows ? voRows.map((voRow) => (
                        <EVOListItem
                            key={Math.random()}
                            voRow={voRow}
                        />
                    )) : ""
                }
                {voRows.length == 0 && <Typography>{t('Common.noResults')}</Typography>}
            </Grid>
        </React.Fragment>
    );
}