
export const volunteerFeedbackStarHeading = "Rate the volunteer performance in the following areas:";

export const volunteerStarFeedbackComponents = [

    {
        title:"Technical knowledge related to the assignment (if applicable)",
        name:"technical"

    },
    {
        title:"Ability to work in teams",
        name:"teamwork"

    },
    {
        title:"Reliability",
        name:"reliability"

    },
    {
        title:"Ability to meet deadlines",
        name:"deadlines"

    },
    {
        title:"Communication skills",
        name:"communication"

    },
    {
        title:"Adaptability and flexibility",
        name: "adaptability"

    },
    {
        title:"Knowledge sharing",
        name:"knowledgeSharing"
    },
    {
        title:"Self-management",
        name:"selfManagement"
    },
    {
        title:"Ethics and values",
        name:"ethics"
    },
    {
        title:"Quality of work",
        name:"qualityOfWork"
    },
    {
        title:"Relationship with others",
        name:"relationship"
    },
    {
        title:"Overall behavior",
        name:"behavior"
    },
    {
        title:"Attendance",
        name:"attendance"
    },
    {
        title:"Adheres to safety measures",
        name:"safety"
    },



];

// export const ratingAdvice = "Star rating (5=Excellent, 4=very good, 3=good 2=satisfactory, 1=requires improvement)"                           

export const volunteerPerfomance = "volunteerPerfomance";

// export const areYouSatis= "Overall, were you satisfied with volunteer contribution Please rate your satisfaction"
export const satisName = "satisfied";
// export const areYouSatisVolunteer= (volunteerName)=>("Overall, were you satisfied with ("+volunteerName+") volunteer contribution?")


// export const volunteerRecommendation = "Do you recommend this Volunteer for similar volunteer assignments elsewhere ";
export const recommendedName = "recommend"

// export const numOfHour = "Please provide the number of hours you have contributed to this volunteering opportunity?";
export const contributionHoursName = "contributionHours"


export const exportedDetails = ({vio,volunteer,opportunity}) =>( [

{
    name:"Volunteers Name",
    value:volunteer.firstName + " " + volunteer.lastName,
    type:"volunteer",
    key:"name",
    allowEdit: false
},
{
    name:"Volunteer NIC",
    value:volunteer.nic,
    type:"volunteer",
    key:"registrationNumber",
    allowEdit: false
},
{
    name:"Vio Name",
    value:vio.name,
    type:"vio",
    key:"name",
    allowEdit: false
},
{
    name:"VIO Registration Number",
    value:vio.registrationNo,
    type:"vio",
    key:"registrationNumber",
    allowEdit: false
},
{
    name:"Assignment Title",
    value:opportunity.title,
    type:"opportunity",
    key:"title",
    allowEdit: false
},
{
    name:"Assignment period Start Date",
    value:opportunity.startDate,
    type:"opportunity",
    key:"from",
    allowEdit: true
},
{
    name:"Assignment period End Date",
    value:opportunity.endDate,
    type:"opportunity",
    key:"to",
    allowEdit: true
},
{
    name:"Evaluation Date",
    value:new Date().toString(),
    type:"evaluation",
    key:"date",
    allowEdit: false
}

]);


export const exportedDetailsEVO = ({emergencyLogin,volunteer,opportunity}) =>( [

    {
        name:"Volunteers Name",
        value:volunteer.firstName + " " + volunteer.lastName,
        type:"volunteer",
        key:"name"
    },
    {
        name:"Volunteer NIC",
        value:volunteer.nic,
        type:"volunteer",
        key:"registrationNumber"
    },
    {
        name:"Emergency User Name",
        value:emergencyLogin.name,
        type:"vio",
        key:"name"
    },
    {
        name:"Assignment Title",
        value:opportunity.title,
        type:"opportunity",
        key:"title"
    },
    {
        name:"Assignment period Start Date",
        value:opportunity.startDate,
        type:"opportunity",
        key:"from"
    },
    {
        name:"Assignment period End Date",
        value:opportunity.endDate,
        type:"opportunity",
        key:"to"
    },
    {
        name:"Evaluation Date",
        value:new Date().toString(),
        type:"evaluation",
        key:"date"
    }
    
    ]);

export const exportedDetailsForVolunteerFeedback = ({vio,volunteer,opportunity})=>([

    {
        name:"Evaluators Name",
        value:opportunity.supervisorName,
        type:"opportunity",
        key: "supervisorName",
        allowEdit: false
    },
    {
        name:"Evaluator Title",
        value:opportunity.supervisorTitle,
        type:"opportunity",
        key:"supervisorTitle",
        allowEdit: false
    }

]);


export const defaultFetchDetails = {
    vio:{

        name:"Vio Name",
        registrationNumber:"123456"

    },
    volunteer:{

        name:"Volunteeer Name",
        registrationNumber:"123456789"

    },
    opportunity:{
        id:"25670",
        title:"Opportunity title",
        supervisorName:"Dr.ABCD",
        supervisorTitle:"HRM",
        from:"1994-10-10",
        to:"1999-7-12"
    }
}

export const getFeedbackUrl = "/api/getFeedbackDetails";
export const volunteerSubmitFeedbackUrl = "/api/feedback/submit/volunteer";
export const vioSubmitFeedbackUrl = "/api/feedback/submit/vio";
export const volunteerFetchFeedbackUr = "/api/feedback/fetch/volunteer"
export const vioFetchFeedbackUrl = "/api/feedback/fetch/vio"
export const emgUserFetchEVOFeedbackUrl = '/api/emergency-login/feedback/fetch/volunteer';
export const volunteerFetchEVOFeedbackUrl = '/api/emergency-login/feedback/fetch/emergencyLogin';
export const emgUserSubmitEVOFeedbackUrl = '/api/emergency-login/feedback/submit/volunteer';
export const volunteerSubmitEVOFeedbackUrl = '/api/emergency-login/feedback/submit/emergencyLogin';

export const vioStarFeedbackTopics = [

    {
        title:"Pre-placement Information",
        items:[
            {
                title:"How do you rate the pre placement arrangements",
                name:"preplacement"
            },
        ]
    },

    {
        title:"Orientation & Induction",
        items:[
            {
                title:"Standard of formal induction and orientation process",
                name:"orientationStandard"
            },
            {
                title:"Policies & procedures easily understood and accessible",
                name:"orientationPolicy"
            }
        ]
    },
    {
        title:"Training",
        items:[
            {
                title:"Training related to the volunteer assignment",
                name:"training"
            }
        ]
    },
    {
        title:"Activities",
        items:[
            {
                title:"Clearly explained expectations and volunteer role",
                name:"expectationsExplanation"
            },
            {
                title:"Appropriate to your level of skill",
                name:"appropriateToSkills"
            },
            {
                title:"Learning opportunities",
                name:"learningOpportunities"
            },
            {
                title:"Appropriate time allocation",
                name:"timeAllocation"
            }
        ]
    },
    {
        title:"Supervision",
        items:[
            {
                title:"Availability of supervisor/s",
                name:"supervisorAvailability"
            },
            {
                title:"Supervisor was approachable & supportive",
                name:"supervisorApproachability"
            },
            {
                title:"Regular supervision",
                name:"regularSupervision"
            },
            {
                title:"Standard of supervision",
                name:"supervisionStandard"
            },
            {
                title:"Support from staff other than supervisor/s",
                name:"staffSupport"
            }
        ]
    },
    {
        title:"Recognition",
        items:[
            {
                title:"Recognition given to volunteers",
                name:"volunteerRecognition"
            },
            {
                title:"Recognition given to volunteer efforts and tasks",
                name:"volunteerEffort"
            }
        ]
    },
    {
        title:"Safety and security",
        items:[
            {
                title:"Safety and security of the assignment location",
                name:"safetyOfLocation"
            },
            {
                title:"Safety and security of work-related traveling",
                name:"safetyOfTravelling"
            }
        ]
    }



];
// export const howYouHearOther = "Other method of hear about the system"
export const howGetKnowName = "otherMethodDescription"

export const reviewTopic = "Review: (optional) word limit max. 200";
export const review = "review";

// export const vioSatisfaction = (vioName)=>(
//     "Overall, were you satisfied with your volunteer experience with "+vioName+"?"
// );

// export const howYouHear= "How did you hear about this volunteering opportunity?"
export const methodGetKnowName= "informationMethod"
export const areYouSatisVio= (vioName)=>("Overall, were you satisfied with volunteer contribution with(%s) Please rate your satisfaction",vioName)


// export const vioRecommondation = "Would you recommend volunteering through the NVS to any of your friends or family?"
export const vioRecommondName = "recommend"



// eedback: {
// 	date,	// feedback date

// 	technical,	// 1 -5 value
// 	teamwork,
// 	reliability,
// 	deadlines,
// 	communication,
// 	adaptability,
// 	knowledgeSharing,
// 	selfManagement,
// 	ethics,
// 	qualityOfWork,
// 	relationship,
// 	behavior,
// 	attendance,
// 	safety,

// 	recommend,	// boolean
// 	satisfied,	// 1-5 value
// 	contributionHours, // int 0<x<10**5
// }