import React from "react";
import ForgotPassword from '../../components/ForgotPassword'



export default function ForgotPasswordReset() {
    return (
        <React.Fragment>
            <ForgotPassword type="RESET" />
        </React.Fragment>
    );
}