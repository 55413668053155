import {serverHost,serverPort,tokenKey} from "../../Constant";
import axios from 'axios';
import {getVioProfilDetails, getVolunteerProfilDetails} from "../../containers/public_profiles/Constants";

export const fetchVio = async (vioId) => {
    const fetchUrl = serverHost + ":" + serverPort + getVioProfilDetails + `/${vioId}`;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
                const result =  {
                    vio:response.data,
                    error:false
                }
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            return result;
        }
    } catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        return result;
    } 
}

export const fetchVolunteer = async (volunteerId) => {
    const fetchUrl = serverHost + ":" + serverPort + getVolunteerProfilDetails + `/${volunteerId}`;
    try{
        const response = await axios.get(fetchUrl,
            {
            headers: {
                token: localStorage.getItem(tokenKey)
            }
        });
        
        if (response.status == 200) {
                const result =  {
                    volunteer:response.data,
                    error:false
                }
                return result;
            }
        else {
            
            const result = {
                errorMessage:response.err.message,
                error:true
            }
            return result;
        }
    } catch(err){
        
        const result = {
            errorMessage:err.message,
            error:true
        }
        return result;
    } 
}
