import React from 'react'
import { Route, Switch} from 'react-router-dom';

import Profile from '../../Admin/Profile';
import ProfilePage from '../../VolunteerProfile/ProfilePage';
import VIOProfilePage from '../../VIOProfile/VIOProfilePage'
import SettingsPage from '../../UserCommon/SettingsPage';

import ViewAllOpportunitiesPage from '../../viewOpportunitiesPage/ViewAllOpportunitiesPage';
import OportunityDetailView from '../../viewOpportunitiesPage/OportunityDetailView'
import ViewVolunteers from '../../ViewVolunteers/volunteers';

import Report from '../../Reports/Report';
import CreateVO from '../../VIO/CreateVO';
import CreateNonFormalVO from '../../VIO/CreateNonFormalVO';
import NonFormalVOList from '../../VIO/NonFormalVO/NonFormalVOList';
import VO from '../../VIO/VO';
import NonFormalVO from '../../VIO/NonFormalVO';
import VolunteerOpportunityPage from '../../VIO/VolunteerOppertunityPage';
import CreateSubvio from '../../subvio/CreateSubVio';
import ViewSubvio from '../../subvio/ViewSubvio';
import ViewSubvios from '../../subvio/ViewSubvios';
import ViewOrgVolunteers from '../../OrgVolunteers/ViewOrgVolunteers';
import VIOHomePage from '../../VIO/VIOHomePage';
import VolunteerFeedbackPage from '../../Feedback/volunteerFeedbackPage';
import VioAgreement from '../../Agreement/VioAgreement';
import VApproveViewOpportunities from '../../Admin/VApproveViewOpportunities';
import PublicVioProfile from '../../../components/PublicVioProfile'
import SubVIOList from "../../SubVIOList/SubVIOList";
import OrganizationalVolunteerList from '../../OrgVolunteers/List/VolunteerList';
import SOPages from "../../../components/ShareOpportunity"
import CustomRoute from '../CustomRoute';
import {
    CHANGE_VO_STATUS,
    CREATE_SUB_VIOS,
    CREATE_VO,
    EDIT_VO,
    VIEW_ORG_VOLS,
    VIEW_SUB_VIO,
    VIEW_SUB_VIOS,
    VIO_HOME,
    VIO_PROFILE,
    VOLUNTEER_AGREEMENT,
    VOLUNTEER_FEEDBACK,
    SHARE_OPPORTUNITIES,
    ACCEPT_SHARE_OPPORTUNITIES,
    CREATE_NON_FORMAL_VO, EDIT_NON_FORMAL_VO, ALL_ADMINS
} from '../Constants';
import EmgVolunteerTable from '../../EmergencyUser/VolunteerList/EmgVolunteerTable';
import VIOGeneralReports from '../../Reports/VIOGeneralReports';

function VioRoutes() {
    return (
        <Switch>

            <CustomRoute exact path="/vioAgreement/:opId/:volunteerId/:vioId" component={VioAgreement} routes = {VOLUNTEER_AGREEMENT} message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/volunteerFeedback/:opportunityId/:volunteerId/:vioId" component={VolunteerFeedbackPage} routes = {VOLUNTEER_FEEDBACK}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            {
                // <CustomRoute exact path="/report" component={Report} message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            }
            <CustomRoute exact path="/createVO" component={CreateVO} routes = {CREATE_VO}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/createNonFormalVO" component={CreateNonFormalVO} routes = {CREATE_NON_FORMAL_VO}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/nonFormalVOList" component={NonFormalVOList} routes = {CREATE_NON_FORMAL_VO}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/VO/:id" component={VO} routes = {EDIT_VO}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/NonFormalVO/:id" component={NonFormalVO} routes = {EDIT_NON_FORMAL_VO}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            {/* <CustomRoute exact path="/volunteerOpportunity" component={VolunteerOpportunityPage}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}
            <CustomRoute exact path="/volunteerOpportunity/:id"  render={(props) => (<VolunteerOpportunityPage {...props}/>)} routes = {CHANGE_VO_STATUS}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/createSubVio" component={CreateSubvio} routes = {CREATE_SUB_VIOS}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/viewSubVios" component={ViewSubvios} routes = {VIEW_SUB_VIOS}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/viewSubVio/:subvioId" component={ViewSubvio} routes = {VIEW_SUB_VIO}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/viewOrganizationalVolunteers" component={ViewOrgVolunteers} routes = {VIEW_ORG_VOLS}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/viohome" component={VIOHomePage} routes = {VIO_HOME}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            {
                // <CustomRoute exact path="/vio-public-view/:id" component={PublicVioProfile}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            }
            <CustomRoute exact path="/subVIOList" component={SubVIOList} routes = {VIEW_SUB_VIOS}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>

            {/* <CustomRoute exact path="/viewOpportunity/:id" component={OportunityDetailView}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/viewAllOpportunities" component={ViewAllOpportunitiesPage}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/viewAllOpportuniy" component={ViewAllOpportunitiesPage}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}
            {/* <CustomRoute exact path="/volunteerHome/viewOpportunity" component={ViewVolunteers}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}
            {/* <CustomRoute exact path="/verifyMobile" component={VerifyMobilePage}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}


            {/* <CustomRoute exact path="/profile" component={ProfilePage}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}
            <CustomRoute exact path="/vioprofile" component={VIOProfilePage} routes = {VIO_PROFILE}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            {/* <CustomRoute exact path="/admin/profile" component={Profile}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}
            {/* <CustomRoute exact path="/settings" component={SettingsPage}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}
            <CustomRoute exact path="/org-volunteers" component={OrganizationalVolunteerList} routes = {VIEW_ORG_VOLS}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>

            <CustomRoute exact path="/share-opportunity/:opportunityId" component={SOPages.ShareOpportunity} routes = {SHARE_OPPORTUNITIES}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>
            <CustomRoute exact path="/accept-shared-opportunities" component={SOPages.AcceptRequests} routes = {ACCEPT_SHARE_OPPORTUNITIES}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>

            <CustomRoute exact path="/vioReports" component={VIOGeneralReports} routes = {ACCEPT_SHARE_OPPORTUNITIES}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/>

            {/* This routes are for Emergency Users (Will be change later)
            <CustomRoute exact path="/emguser/volunteerList" component={EmgVolunteerTable} routes = {VIO_PROFILE}  message = "Sorry! Only Email Verified and Admin Approved Volunteer Involving Organizations have access to this page"/> */}


        </Switch>
    )
}

export default VioRoutes
