import React from "react";
import VolunteerOpportunityHome from '../../components/VIO/VolunteerOppertunityHome';
import { useEffect } from "react";
import { fetchVolunteerOpportunity, changeVolunteerOpportunityApplicantState } from '../../functions/VIO/VolunteerOppertunityHome/fetchVolunteerOppertunity';
import { submitCompleteVolunteerOppertunity } from '../../functions/VIO/VolunteerOppertunityHome/submitCompleteVolunteerOppertunity';
import { submitDeleteVolunteerOppertunity } from '../../functions/VIO/VolunteerOppertunityHome/submitDeleteVolunteerOppertunity';
import { serverHost, tokenKey } from "../../Constant";
import Axios from "axios";
import LoadingPage from "../../components/LoadingPage";
import MessageCard from "../../components/MessageCard";
import { useSnackbar } from "notistack";
import { fetchVOFormDataFunc } from "../../functions/VIO/VolunteerOppertunity/fetchVOFormData";

export default function VolunteerOpportunityPage({ match, isAdminView }) {
  const id = match.params.id;
  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [applicantDetailsToAdmin, setApplicantDetailsToAdmin] = React.useState([])
  const [applicantDetailsToVIO, setApplicantDetailsToVIO] = React.useState([])
  const [reload, setReload] = React.useState(false);
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar()

  useEffect(async () => {
    let mounted = true;
    let result = await fetchVolunteerOpportunity(id);
    let resultFormData = await fetchVOFormDataFunc();
    if (mounted) {
      if (result.error || resultFormData.error) {
        setError(true);
      }
      else {
        if (isAdminView) {
          setFetchDetails({ ...result.item, applicantDetails: applicantDetailsToAdmin });
        } else {
          setFetchDetails({ ...result.item, applicantDetails: applicantDetailsToVIO });
        }
        setFormDisplayData(resultFormData.item);
        setError(false);
      }
    }
    return () => mounted = false;
  }, [error, reload])

  const completeVO = async (history) => {
    let mounted = true;
    let result = await submitCompleteVolunteerOppertunity(id);
    if (mounted) {

      if (result.error) {
        enqueueSnackbar('Error', {
          variant: 'error',
          persist: false
        })
      }
      else {
        enqueueSnackbar('Successfully Completed', {
          variant: 'success',
          persist: false
        })
        setReload(!reload)
      }
    }
  }

  const deleteVO = async (history) => {
    let mounted = true;
    let result = await submitDeleteVolunteerOppertunity(id);
    if (mounted) {
      if (result.error) {
        enqueueSnackbar('Error', {
          variant: 'error',
          persist: false
        })
      }
      else {
        enqueueSnackbar('Successfully Deleted', {
          variant: 'success',
          persist: false
        })
        history.push("/vioHome")
      }
    }
  }

  const changeApplicantState = async (applicantID, newState) => {
    var newFetchDetails = { ...fetchDetails }
    for (var i = 0; i < newFetchDetails.applicantDetails.length; i++) {
      if (newFetchDetails.applicantDetails[i].id == applicantID) {
        newFetchDetails.applicantDetails[i].applicantState = newState
      }
    }
    setFetchDetails(newFetchDetails)
  }

  const changeApplicantStateByAdmin = (applicantID, newState) => {
    const changeApplicationStateURLByAdmin = serverHost + "/api/admin/opportunity/apply/review";
    Axios.post(changeApplicationStateURLByAdmin, {
      applicationId: applicantID,
      approve: newState
    }, {
      headers: {
        token: localStorage.getItem(tokenKey),
      },
    })
      .then(res => {
        
        if (res.status == 200) {
          
          window.location.reload(false);
        } else if (res.status == 400) {
          
          enqueueSnackbar('Error', {
            variant: 'error',
            persist: false
          })
        }
      })
      .catch(err => {
        enqueueSnackbar('Error', {
          variant: error,
          persist: false
        })
      })
  }

  const changeApplicantStateByVIO = (applicantID, newState) => {
    const changeApplicationStateURLByVIO = serverHost + "/api/vio/opportunity/apply/review";
    Axios.post(changeApplicationStateURLByVIO, {
      applicationId: applicantID,
      approve: newState
    }, {
      headers: {
        token: localStorage.getItem(tokenKey),
      },
    })
      .then(res => {
        
        if (res.status == 200) {
          
          window.location.reload(false);
        } else if (res.status == 400) {
          
          enqueueSnackbar('Error', {
            variant: error,
            persist: false
          })
        }
      })
      .catch(err => {
        enqueueSnackbar('Error', {
          variant: 'error',
          persist: false
        })
      })
  }





  return (
    <React.Fragment>
      {
        !error ?
          (fetchDetails && formDisplayData) ?
            <div>
              <VolunteerOpportunityHome
                // onSubmit={authSignUp}
                // error={error}
                // success={success}
                // message={message}
                fetchDetails={fetchDetails}
                formDisplayData={formDisplayData}
                changeApplicantState={changeApplicantState}
                changeApplicantStateByAdmin={changeApplicantStateByAdmin}
                changeApplicantStateByVIO={changeApplicantStateByVIO}
                isAdminView={isAdminView}
                id={id}
                completeVO={completeVO}
                deleteVO={deleteVO}
                disableStateTogle={fetchDetails.status == "COMPLETED" ? true : false}
              />
            </div>
            : <LoadingPage />
          : <MessageCard />
      }

    </React.Fragment>

  );
}
