import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { useHistory} from 'react-router-dom';
import { agreementSentence, volunteerAgreementPoints, vioAgreementPoints, defaultDetails } from './Constants'
import { Typography, Grid, Paper } from '@material-ui/core';
import CustomButton from '../../components/CustomButton';
import Error from '../../components/MessageCard';
// import { Link } from 'react-router-dom';
import { volunteerFetchAgreement, volunteerConfirmAgreement } from '../../functions/agreement/fetchAgreement'
import AgreementItem from './AgreementItem';
import LoadingPage from '../../components/LoadingPage';
import MessageCard from '../../components/MessageCard';
import { useTranslation } from 'react-i18next';

export default function VolunteerAgreement({ match }) {

    const vioId = match.params.vioId
    const opId = match.params.opId;
    const id = match.params.volunteerId

    const [confirm, setConfirm] = useState(false);
    const [agreeCount, setAgreeCount] = useState(0);
    const [details, setDetails] = useState({ vio: {}, volunteer: {}, opportunity: {}, error: false });
    const [agreeItems, setAgreeItems] = useState([]);
    const [otherItems, setOtherItems] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [isError, setIsErrorVariant] = React.useState(true);
    const [pushError, setPushError] = React.useState(false);
    const {t, i18n} = useTranslation()

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    useEffect(() => {
        if (!errorMsg) return
        enqueueSnackbar(errorMsg, {
            variant: isError ? 'error' : 'success',
            persist: false
        })
    }, [pushError])

    useEffect(async () => {
        setLoading(true);
        let mounted = true;
        let result = await volunteerFetchAgreement(id, opId, vioId);
        setAgreeItems(volunteerAgreementPoints);
        setOtherItems(vioAgreementPoints);
        if (mounted) {
            if (result.error) {
                result.status === 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
                setIsErrorVariant(true)
                setPushError(!pushError)
            }
            else {
                setDetails(result);
                setLoading(false);
            }
        }
        return () => mounted = false;
    }, [confirm]);

    const onConfirm = async () => {

        const result = await volunteerConfirmAgreement(id, vioId, opId);
        const error = result.error;
        result.volunteer = details.volunteer;
        result.vio = details.vio;
        result.opportunity = details.opportunity;
        setDetails(result)

        if (!error) {
            setConfirm(true);
            setErrorMsg("You have now completed the agreements, You have successfully started your volunteer opportunity!!")
            setIsErrorVariant(false)
            setPushError(!pushError)
            setTimeout(()=>{history.push(`/viewOpportunity/${opId}`)}, 1000);
        }
        else if (details.error) {
            setConfirm(true);
            result.status === 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
            setIsErrorVariant(true)
            setPushError(!pushError)

        }
        else {
            setConfirm(false);
            result.status === 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
            setIsErrorVariant(true)
            setPushError(!pushError)
        }
    }

    const onChange = (value) => {
        setAgreeCount(agreeCount + value)
    }

    return (
        !isLoading ?
            // !details.error ?
                !details.alreadySubmitted ?
                    <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} style={{ margin: '20px 0' }}>
                                <Typography variant="h5" color="primary" align="center">
                                     {t('VolunteerAgreement.title')} - {details.opportunity.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p" style={{ textAlign: 'justify' }}>
                                    {agreementSentence(details.volunteer, details.vio, details.opportunity, i18n.language)}
                                </Typography>
                            </Grid>
                            <React.Fragment>
                                {agreeItems &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" color="primary">{t('VolunteerAgreement.volunteerAgreementTopics')}</Typography>
                                        </Grid>
                                        <div>
                                        {agreeItems.map((item, i) => (
                                            <AgreementItem
                                                key={i.toString()}
                                                title={t(`VolunteerAgreement.volunteerAgreementPoints.${item}`)}
                                                onChange={onChange}
                                            />
                                        ))}
                                        </div>
                                    </React.Fragment>

                                }
                                {otherItems &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" color="primary">{t('VolunteerAgreement.vioAgreementTopics')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ul>
                                                {otherItems.map((item, i) => (
                                                    <li key={i.toString()}>{t(`VolunteerAgreement.vioAgreementPoints.${item}`)}</li>
                                                ))}
                                            </ul>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">{t('VolunteerAgreement.herebyAgree')}</Typography>
                            </Grid>
                            <Grid item>
                                <CustomButton
                                    variant="contained"
                                    label={t('VolunteerAgreement.confirm')}
                                    disabled={!(agreeCount === agreeItems.length)}
                                    onClick={onConfirm}
                                // component={Link} 
                                // to="/opportunities/myOpportunities"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    : <MessageCard error={false} message='Thank you for agreeing with us!!' />
                // // : 
                // : ""
            : <LoadingPage />
    )
}
