import {
    TableCell,
    TableRow,
    Avatar,
} from "@material-ui/core";
import React from "react";
import { green, red } from '@material-ui/core/colors';
import Button from "@material-ui/core/Button"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { submitNonFormalVOStatus } from "../../../functions/Admin/NonFormalVOApproval/submitNonFormalVOStatus";
import { fetchVIO } from "../../../functions/VIO/NonFormalVolunteerOppertunity/fetchVIO";


const theme = createMuiTheme({
    palette: {
        primary: green,
        secondary: red,
    },
});

function NonFormalVORow({ row, labelId, isItemSelected, classes, isApproved,isRejected , showAlertApproved, showAlertRejected , isError}) {
    const [fetchError, setFetchError] = React.useState(false);
    const [VIODetails, setVIODetails] = React.useState([]);
    const approveHandler = async (row) => {
        const res = await submitNonFormalVOStatus( row.id, true);
        showAlertApproved();
        if (res.error === false){
            isApproved();
        }else {
            isError();
        }
    }
    const rejectHandler = async (row) => {
        const res = await submitNonFormalVOStatus( row.id, false);
        showAlertRejected();
        if (res.error === false){
            isRejected();
        }else {
            isError();
        }
    }
    React.useEffect(async () => {
        let mounted = true;
        let data = await fetchVIO(row.vioId);
        
        if (mounted) {
            if (data.error) {
                setFetchError(true);
            }
            else {
                setVIODetails(data.item);
                setFetchError(false);
            }
        }
    }, [])
    
    return (
        <React.Fragment>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
            >
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    <Avatar
                        alt={row.name}
                        style={{
                            color: "white",
                            backgroundColor: "#23395B",
                            margin: '20px 6px'
                        }}
                        src={VIODetails.cardImage}
                    />
                </TableCell>
                <TableCell align="left">
                    {VIODetails.name}
                </TableCell>
                <TableCell align="left">
                    {row.supervisorName}
                </TableCell>
                <TableCell align="left">{row.supervisorEmail}</TableCell>
                <TableCell align="left"><Link to={`/admin/NonFormalVO/${row.id}`} >{row.title}</Link></TableCell>
                { row.status !== "APPROVED" && <TableCell padding="checkbox">
                    <ThemeProvider theme={theme}>
                        <Button onClick={()=>approveHandler(row)} variant="contained" size="small" color="primary" style={{color: 'white', marginRight: '5px'}} textPrimary='white' className={classes.margin}>
                            Approve
                        </Button>
                    </ThemeProvider>
                </TableCell>}
                { row.status !== "REJECTED" && <TableCell padding="checkbox">
                    <ThemeProvider theme={theme}>
                        <Button onClick={()=>rejectHandler(row)} variant="contained" size="small" color="secondary" style={{color: 'white'}} textPrimary='white' className={classes.margin}>
                            Reject
                        </Button>
                    </ThemeProvider>
                </TableCell>}
            </TableRow>
        </React.Fragment>
    );
}

export default NonFormalVORow;
