import React from "react";
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import FooterLogo from "../images/footer-logo.png"
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import getLandingMenu from "../constants/landingMenu";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";


const useStyles = makeStyles((theme) => ({
    footerContainer: {
        backgroundColor: '#024a93',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '40px 20px 0 20px',
        color: 'white'
    },
    sectionLeft: {
        margin: "20px"
    },
    footerLogo: {
        width: '100%'
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: "20px",
        marginTop: "20px",
    },
    itemText: {
        fontSize: '17px',
        fontWeight: '500',
        textTransform: 'uppercase'
    },
    itemEmail: {
        fontSize: '15px',
        fontWeight: '500',
        textTransform: 'lowercase'
    },
    copyrightText: {
        fontSize: "12px",
        fontWeight: 'bold',
        textAlign: "center",
        textTransform: 'uppercase',
    }
}));

export default function Footer() {
    const classes = useStyles();
    const history = useHistory()
    const {t} = useTranslation()

    return (
        <Grid container item className={classes.footerContainer} id="footer">
            <Grid item xs={10} sm={7} md={6} lg={4} className={classes.sectionLeft}>
                <img className={classes.footerLogo} src={FooterLogo} alt="footer logo"/>
            </Grid>
            <Grid item xs={10} sm={7} md={3} lg={3}>
                <div style={{width: "100%", padding: "0 20px"}}>
                    <Typography
                        variant="h4"
                        color="inherit"
                        gutterBottom
                        className={classes.title}
                    >
                        {t("Footer.links")}
                    </Typography>
                    {getLandingMenu(history, t).map(item => (
                        <div
                            key={item.name}
                            onClick={item.action}
                            style={{textDecoration: "none", marginTop:15, cursor:"pointer"}}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.itemText}
                                style={{textAlign: "justify", margin: "5px 0"}}
                            >
                                {item.name}
                            </Typography>
                        </div>
                    ))}
                </div>
            </Grid>
            <Grid item xs={10} sm={7} md={5} lg={4}>
                <div style={{width: "100%", padding: "0 20px"}}>
                    <Typography
                        variant="h5"
                        color="inherit"
                        gutterBottom
                        className={classes.title}
                    >
                        {t("Footer.contactUs")}
                    </Typography>
                    <Grid container>
                        {/* <Grid item xs={1}>
                            <HomeIcon />
                        </Grid> */}
                        <Grid item xs={11}>
                            <Typography className={classes.itemText} variant="h6" color="inherit" gutterBottom>
                                {t("ContactUs.address3")}
                            </Typography>
                            <Typography className={classes.itemText} variant="h6" color="inherit" gutterBottom>
                                {t("ContactUs.address4")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{marginTop: "10px"}}>
                        {/* <Grid item xs={1}>
                            <PhoneIcon/>
                        </Grid> */}
                        <Grid item xs={11}>
                            <Typography className={classes.itemText} variant="h6" color="inherit" gutterBottom>
                                +94 112 187 050 / +94 112 186 275
                            </Typography>
                            <Typography className={classes.itemText} variant="h6" color="inherit" gutterBottom>
                                +94 112 186 276 / +94 112 186 010 (Fax)
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{marginTop: "10px"}}>
                        {/* <Grid item xs={1}>
                            <EmailIcon/>
                        </Grid> */}
                        <Grid item xs={11}>
                            <Typography className={classes.itemEmail} variant="h6" color="inherit" gutterBottom>
                                info@volunteering.socialservices.gov.lk
                            </Typography>
                            <Typography className={classes.itemEmail} variant="h6" color="inherit" gutterBottom>
                                <a style={{textDecoration: "none", color: "inherit"}} href="https://www.volunteering.socialservices.gov.lk">
                                    www.volunteering.socialservices.gov.lk
                                </a>
                            </Typography>
                            <Typography className={classes.itemEmail} variant="h6" color="inherit" gutterBottom>
                                <a style={{textDecoration: "none", color: "inherit"}} href="https://www.socialservices.gov.lk">
                                    www.socialservices.gov.lk
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Divider style={{marginTop: "6%", width: "100%"}}/>
            <Grid item xs={12} style={{margin: "20px"}}>
                <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.copyrightText}
                >
                    &copy; copyright department of social services, 2021
                </Typography>
            </Grid>
        </Grid>

    );
}
