
import {serverHost,serverPort,tokenKey} from "../../../Constant";
import axios from 'axios';

/**
 * Axios Wrapper
 * GET Method
 */
export function GET(url, query={}) {
    const PATH = `${serverHost}:${serverPort}${url}`;
    const headers = {'token': localStorage.getItem(tokenKey)}
    return axios.get(PATH, {headers, params: query})
}

/**
 * Axios Wrapper
 * POST Method
 */
export function POST(url, data = {}) {
    const PATH = `${serverHost}:${serverPort}${url}`;
    const headers = {'token': localStorage.getItem(tokenKey)}
    return axios.post(PATH, data, {headers})
}

/**
 * Axios Wrapper
 * PUT Method
 */
export function PUT(url, data = {}) {
    const PATH = `${serverHost}:${serverPort}${url}`;
    const headers = {'token': localStorage.getItem(tokenKey)}
    return axios.get(PATH, data, {headers})
}

/**
 * Axios Response Resolver
 * will do error handling part
 * @returns [status_code, response_body]
 */
export async function resolver(axiosResponse) {
    try {
        const response = await axiosResponse
        return [response.status, response.data]
    } catch (e) {
        const response = e.response
        return [response?.status || 408, response?.data || 'Connection error']
    }
}

