import Input from "@material-ui/core/Input";
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
import ReactHookFormSelect from './ReactHookFormSelect';
import { useForm } from "react-hook-form";
import React from 'react'
import IconTextField from "./IconTextField";
import CustomButton from "./CustomButton";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    marginTop: 12
  },
  button: {
    padding: '15px 20px'
  }
}));

export default function SearchBar({ value1 = "Online", value2 = "Regular", defaultValue, defaultType, register, control }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <form>
      <Grid container direction="row" justify="flex-end">
        <Grid item style={{ margin: '0 4px' }}>
          <ReactHookFormSelect
            className={classes.select}
            label={t('Common.type')}
            name="type"
            fullWidth
            control={control}
            variant="outlined"
            defaultValue={defaultType}
          >
            <MenuItem value={value1}>{t(`Common.${value1.toLowerCase()}`)}</MenuItem>
            <MenuItem value={value2}>{t(`Common.${value2.toLowerCase()}`)}</MenuItem>
          </ReactHookFormSelect>
        </Grid>
        <Grid item style={{ margin: '0 4px' }}>
          <IconTextField
            // onChange={onSearching}
            name="search"
            inputRef={register}
            placeholder={t('Common.search')+'...'}
            defaultValue={defaultValue}
            icon={<SearchIcon />}
          />
        </Grid>
      </Grid>
    </form>
  );
}





