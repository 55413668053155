import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Divider, makeStyles, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../CustomButton';
import 'date-fns';
import { useHistory } from "react-router-dom";
import MessageCard from '../MessageCard';
import IconTextField from '../IconTextField';
import EVolunteerTable from './EVolunteerTable';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(() => ({
  chip: {
    padding: 6,
    margin: 6
  }
}))

export default function EmergencyVolunteerOppertunityHome({ id, onSubmit, fetchDetails, emgVolunteers, inviteEmgVolunteers, invitedVolunteers, addEmgVolunteer, removeEmgVolunteer, completeEmgVolunteer, oppVolunteers, formDisplayData, changeApplicantState, changeApplicantStateByAdmin, changeApplicantStateByVIO, isAdminView, completeVO, deleteVO, disableStateTogle = false }) {

  const classes = useStyles();
  const { t, i18n } = useTranslation()

  let history = useHistory();
  const userId = useSelector(state => state.auth.id)

  const [selectedRegIDs, setSelectedRegIDs] = React.useState(null)
  const [disbleAddCompleInvite, setDisableAddCompleteInvite] = React.useState(!(fetchDetails.status == "OPEN"))

  const getInput = (event, val, res) => {
    setSelectedRegIDs(val)
  }

  if (fetchDetails)
    return (
      <React.Fragment>
        <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary" align="center" gutterBottom>
                {fetchDetails.title}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12} container justify="flex-end">
            <CustomButton
              color="secondary"
              label={t('Common.complete')}
              onClick={completeVO}
              disabled={!(fetchDetails.status == "OPEN")}
              margin={6} />
            <CustomButton
              color="secondary"
              label={t('Common.delete')}
              onClick={() => { deleteVO(history) }}
              disabled={!(fetchDetails.status == "OPEN")}
              margin={6} />
          </Grid>
          <Grid container spacing={1} style={{ paddingBottom: 20 }}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("EVO.emailDescription")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.descriptionEnglish}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t('EVO.smsDescription')}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.descriptionSMSEnglish}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary" >
                {t("VolunteerOpportunityPage.volunteetingLocation")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.district")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.district}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.ds")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.divisionalSecretariat}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.gn")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.gnDivision}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ margin: '20px 0' }} />
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" color="primary" >
                {t('EVO.addVolunteers')}
              </Typography>
            </Grid>
            <Grid item>
              <Grid item container spacing={1} direction="row">
                <Grid item>
                  <Autocomplete
                    id="combo-box-demo"
                    options={invitedVolunteers}
                    getOptionLabel={(option) => option.registrationNo}
                    style={{ width: 300, paddingTop: 12 }}
                    renderInput={(params) => <TextField {...params} label={t("EVO.registrationId")} variant="outlined"
                    />}
                    onChange={getInput}
                  />
                </Grid>
                <Grid item>
                  <CustomButton label={t("Common.add")} type="submit" margin="12px 0" variant="contained"
                    onClick={() => { addEmgVolunteer(selectedRegIDs[0].id); setSelectedRegIDs(null); }}
                    style={{ padding: '15px 20px' }}
                    disabled={!(selectedRegIDs) || !(fetchDetails.status == "OPEN")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{margin: '20px 0'}} />
            <EVolunteerTable
              emgVolunteers={oppVolunteers}
              inviteEmgVolunteers={inviteEmgVolunteers}
              addEmgVolunteer={addEmgVolunteer}
              removeEmgVolunteer={removeEmgVolunteer}
              completeEmgVolunteer={completeEmgVolunteer}
              inviteMode={false}
              disbleAddCompleInvite={disbleAddCompleInvite}
            />
          <Divider />
          <Grid>
            <Typography variant="subtitle1" color="primary">
              {t("EVO.inviteVolunteers")}
            </Typography>
            <EVolunteerTable
              emgVolunteers={emgVolunteers}
              inviteEmgVolunteers={inviteEmgVolunteers}
              inviteMode={true}
              disbleAddCompleInvite={disbleAddCompleInvite}
            />
          </Grid>
        </Paper>
      </React.Fragment >
    )
  return (
    <MessageCard />
  )
}
