import {
  // AUTH_SIGN_IN_BEGIN,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_IN_FAILURE,
  // AUTH_SIGN_UP_BEGIN,
  // AUTH_SIGN_UP_SUCCESS,
  // AUTH_SIGN_UP_FAILURE,
} from '../../actiontypes';

import axios from 'axios';
import jwt_decode from 'jwt-decode'

import { hostPortDivider, serverHost, serverPort, tokenKey } from '../../Constant';
import { loginPostUrl } from '../../containers/LoginPage/Constants';

export const authSignIn = ({ email, password, history, setErrorMsg }) => dispatch => {

  // 
  const loginUrl = serverHost + hostPortDivider + serverPort + loginPostUrl;
  axios.post(loginUrl, { email, password }).
    then(response => {
      if (response.status === 200) {
        const token = response.data.token;
        const decoded = jwt_decode(token);
        localStorage.setItem(tokenKey, token);
        dispatch(authSignInSuccess(decoded));
        if(decoded.type.includes("VIO")){
          
          history.push("/vioHome")
        }
        else if(decoded.type.includes("VOLUNTEER")){
          
          history.push("/volunteerHome")
        }
        else if(decoded.type.includes("ADMIN")){
          
          history.push("/admin/profile")
        }else if(decoded.type.includes("EMERGENCY_LOGIN")){
          
          history.push("/emguserHome")
        }
        else{
          
          history.push("/vioHome")
        }
      }
      else {
        dispatch(authSignInFailure(response.error));
      }
    }).catch(err => {
      
      setErrorMsg(err.response?err.response.data.message:"Error")
      dispatch(authSignInFailure(err))
    });
};

const authSignInSuccess = (user) => ({
  type: AUTH_SIGN_IN_SUCCESS,
  payload: user
});

const authSignInFailure = error => ({
  type: AUTH_SIGN_IN_FAILURE,
  payload: error
});

// export default function authSignIn() {
//   return async dispatch => {
//     dispatch(authSignInBegin());
//     try {
//       dispatch(authSignInSuccess())
//     } catch (error) {
//       dispatch(authSignInFailure())
//     }
//   }
// }