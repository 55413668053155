import React from 'react'
import { Route, Switch} from 'react-router-dom';
import SettingsPage from '../../UserCommon/SettingsPage';
import ViewAllOpportunitiesPage from '../../viewOpportunitiesPage/ViewAllOpportunitiesPage';
import OportunityDetailView from '../../viewOpportunitiesPage/OportunityDetailView'
import { LOGGED_USER, PUBLIC } from '../Constants';
import CustomRoute from '../CustomRoute';

function CommonUserRoutes() {
    return (
        <Switch>

            <CustomRoute exact path="/settings" component={SettingsPage} routes = {LOGGED_USER} message = "You already Logged into the system"/>
            <CustomRoute exact path="/viewOpportunity/:id" component={OportunityDetailView} routes = {LOGGED_USER} message = "You already Logged into the system"/>
            <CustomRoute exact path="/viewAllOpportunities" component={ViewAllOpportunitiesPage} routes = {LOGGED_USER} message = "You already Logged into the system"/>
            <CustomRoute exact path="/viewAllOpportuniy" component={ViewAllOpportunitiesPage} routes = {LOGGED_USER} message = "You already Logged into the system"/>

        </Switch>
    )
}

export default CommonUserRoutes
