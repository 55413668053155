import React,{useEffect, useState} from 'react'
import MessageCard from '../../../components/MessageCard';
import {successMessage ,errorMessage} from './Constants'
import {verifyVio} from '../../../functions/verify/apiCalls';
import CustomButton from '../../../components/CustomButton';
import { Link } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { useTranslation } from 'react-i18next';

function VioVerifyEmail({match}) {
    const key = match.params.secretKey;

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const {t} = useTranslation()

    useEffect(() => {
        if(success) {
            localStorage.setItem("PROFILE_INCOMPLETE", true);
        }
    }, success);

    useEffect(async() => {
            const result = await verifyVio(key);
            if(result.error){
                setError(result.error);
                setErrMessage(result.message);
            }
            else{
                setError(result.error);
                setErrMessage("");
                setSuccess(true);
            }
    }, [])

    return (
        <React.Fragment>
        {/* <CustomButton label={t("VerifyEmail.verifyPhone")} component={Link} to={`/verifyMobile/${key}`} variant="outlined" /> */}
            {success?
                <React.Fragment>
                    <MessageCard
                        error={false}
                        message ={t("VerifyEmail.successMessage")}
                        label={t("VerifyEmail.gotoLogin")} component={Link} to="/login"
                    />
                </React.Fragment>
                :
            error?
            <React.Fragment>
                <MessageCard
                    message ={errMessage}
                />
            </React.Fragment>
            :
            <LoadingPage/>
            }
        </React.Fragment>
    )
}

export default VioVerifyEmail
