import React, { useEffect, useState, useCallback } from 'react';
import { fetchVioList, requestVio, removeVio } from '../../../functions/OrgVolunteer/list_apis.js'
import { makeStyles } from '@material-ui/core/styles';
import ListItem from "./ListItem";
import Error from '../../../components/MessageCard'
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import SearchBar from './Selector';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingPage from '../../../components/LoadingPage.jsx';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8)
  },
}));


function VioList() {
  const classes = useStyles();

  const [details, setDetails] = useState({ error: false, items: [], errorMessage: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { register, control, watch, getValues } = useForm();
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()  


  useEffect(async () => {
    setIsLoading(true)
    let mounted = true;
    let result = await fetchVioList(watch("type"), "");

    
    if (mounted) {
      let fetchItems = result.vios;
      setDetails({ items: fetchItems, error: result.error, errorMessage: result.errorMessage });
      setIsLoading(false)
    }
    
    
    return () => mounted = false;

  }, [watch("type"), refresh])

  const onRequest = async (id, parameter) => {
    const result = await requestVio(id, parameter);
    if (result.error) {
      enqueueSnackbar(result.errorMessage, {
        variant: 'error',
        persist: false
      })
    } else {
      setRefresh(!refresh);
      enqueueSnackbar('VIO requested successfully!', {
        variant: 'success',
        persist: false
    })
    }
  }

  const onRemove = async (id, status) => {
    const result = await removeVio(id);
    if (result.error) {
      enqueueSnackbar(result.errorMessage, {
        variant: 'error',
        persist: false
      })
    } else {
      setRefresh(!refresh);
      enqueueSnackbar('VIO deleted successfully!', {
        variant: 'success',
        persist: false
    })
    }
  }

  const menuItems = ["all", "requested", "joined"];
  const requestTrueStates = ["new", "rejected", "resigned", "removed"];
  const requestFalseStates = ["requested"];
  const cancelStates = ["joined"];

  return (
    <React.Fragment>
      {
        details.error ?
          <Error message={details.errorMessage} />
          :
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h5" color="primary">
              {t('VioList.title')}
              </Typography>
              <SearchBar
                defaultValue=""
                defaultType="all"
                register={register}
                control={control}
                menuItems={menuItems}
              />
            </div>

            {!isLoading ?
              <Grid container style={{
                padding: 24,
                borderRadius: 4,
                backgroundColor: '#e1ebff',
              }}>
                <Grid container spacing={2}>
                  {details.items && details.items.length ?
                    details.items
                      .filter(item => !watch("search") || item.name.toLowerCase().includes(watch("search").toLowerCase()))
                      .map(vio => (
                        <Grid item lg={3} md={4} sm={6} xs={12} key={vio.vioId}>
                          <ListItem
                            key={vio.vioId}
                            name={vio.name}
                            id={vio.vioId}
                            status={vio.state}
                            type={requestTrueStates.includes(vio.state) ? "request" : requestFalseStates.includes(vio.state) ? "cancelRequest" : cancelStates.includes(vio.state) ? "resign" : ""}
                            onRequest={onRequest}
                            onRemove={onRemove}
                            onAction={cancelStates.includes(vio.state) ? onRemove : onRequest}
                            parameter={requestTrueStates.includes(vio.state) ? true : false}
                            user={"vio"}
                          />
                        </Grid>
                      ))
                      : (getValues("requested") === "") ? t('VioList.noJoinedList') : t('VioList.noRequestList')
                    }
                </Grid>
              </Grid>
              :
              <LoadingPage />
            }
          </Paper>
      }
    </React.Fragment>
  )
}


export default VioList
