import jwt_decode from 'jwt-decode'
import {
    RECOVER_STORE, RESET_TOKEN, CLEAR_STORE
  } from '../../actiontypes';

export const recoverStore = (token) => dispatch => {
    const decoded = jwt_decode(token)
    dispatch({
        type: RECOVER_STORE,
        payload: decoded
    })
    return null;
}

export const clearStore = () => async dispatch => {
    dispatch({
        type: CLEAR_STORE,
        payload: null
    })

    return null;

}

export const resetToken =  (token) => async dispatch => {
    const decoded = await jwt_decode(token)
    dispatch({
        type: RESET_TOKEN,
        payload: decoded
    })

    return null;

}
