import React, { useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { TextField, Grid, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';


function EditableText({ value, name = "textfield", onClick, title, attribute, allowEdit }) {

    const [editState, setEditState] = useState(false);
    const [content, setContent] = useState(value);

    useEffect(() => {


    }, [content, editState])

    const changeState = (e) => {
        // 
        setEditState(true);
    }
    const onSubmit = (data) => {
        setEditState(false);
        onClick(name, data, title, attribute)
    }
    // const onEdit = (e) => {
    //     e.preventDefault();
    //     // 
    //     setContent(e.target.value)
    // }
    const onEdit = (e) => {
        if (["Evaluation Date", "Assignment Period"].indexOf(name) !== -1){
            setContent(e)
        }else{
            setContent(e.target.value)
        }
    }
    {}
    return (
        allowEdit?
        <Grid container item lg={4} md={6} xs={12}>
            <Grid item xs={11}>
                {["Evaluation Date", "Assignment Period"].indexOf(name) !== -1 ? (
                    // <KeyboardDatePicker value={content} inputVariant="outlined" style={{ width: 246 }} label={name} disabled={!editState} />
                    <KeyboardDatePicker value={content} onChange={onEdit} inputVariant="outlined" fullWidth label={name} disabled={!editState} />
                ) : (
                        <TextField name={name} value={content} onChange={onEdit} id="outlined-basic" label={name} variant="outlined" fullWidth disabled={!editState}/>
                    )}
            </Grid>
            <Grid item xs={1}>
                {editState ? (
                    <IconButton onClick={e => onSubmit(content)}><CheckIcon htmlColor="green" /></IconButton>
                ) : (
                        <IconButton onClick={changeState}><EditIcon htmlColor="black" /></IconButton>
                    )}
            </Grid>
        </Grid>
        :
        <Grid container item lg={4} md={6} xs={12}>
            <Grid item xs={11}>
                {["Evaluation Date", "Assignment Period"].indexOf(name) !== -1 ? (
                    // <KeyboardDatePicker value={content} inputVariant="outlined" style={{ width: 246 }} label={name} disabled={!editState} />
                    <KeyboardDatePicker value={content} inputVariant="outlined" fullWidth label={name} disabled={true} />
                ) : (
                        <TextField name={name} value={content} id="outlined-basic" label={name} variant="outlined" fullWidth disabled={true}/>
                    )}
            </Grid>
        </Grid>
        )
}

export default EditableText
