import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../../components/CustomButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link, useHistory } from "react-router-dom";
import LogoutButton from "../../containers/LoginPage/LogoutButton";
import { Grid, Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import logo from "../../images/logo-original.png";
import LangSelector from "./LangSelector"
import { getLandingMenuHeader } from "../../constants/landingMenu";
import { useTranslation } from "react-i18next";
import CustomizedMenus from './DropDown';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      maxHeight: 120,
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: 95,
    },
    zIndex: 1400,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    [theme.breakpoints.up("sm")]: {
      height: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "40px",
    },
  },
  logoSubHeading: {
    fontFamily: ["Oswald"],
    fontStyle: "italic",

    [theme.breakpoints.up("sm")]: {
      marginLeft: "25px",
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      fontSize: "8px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2px",
      fontSize: "6px",
    },

    marginTop: "-5px",
    marginBottom: "5px",
  },

  button: {
    fontWeight: "bold",
    fontSize: "medium",
    minWidth: 40,
    textAlign: 'center'
  },
}));

function gotoHomeSection(history) {
  const home = document.getElementById("home");
  if (home) {
    window.scrollTo({
      top: home.offsetTop - 150,
      behavior: "smooth",
    });
  } else {
    history.push("/");
  }
}

function gotoRegisterSection(history) {
  const regOps = document.getElementById("regOps");
  if (regOps) {
    window.scrollTo({
      top: regOps.offsetTop - 150,
      behavior: "smooth",
    });
  } else {
    history.push("/register");
  }
}
function gotoViewOpportunitiesSection(history) {
  history.push("/viewAllOpportunities");
}

async function gotoAboutSection(history) {
  await gotoHomeSection(history);
  const aboutUs = document.getElementById("aboutUs");
  if (aboutUs) {
    window.scrollTo({
      top: aboutUs.offsetTop - 150,
      behavior: "smooth",
    });
  } else {
    history.push("/aboutus");
  }
}

function gotoContactUsPage(history) {
  history.push("/contactus");
}

export default function Header({ handleDrawerToggle }) {
  const classes = useStyles();
  const isLogged = useSelector((state) => state.auth.id);
  const history = useHistory();
  const { t } = useTranslation()
  return (
    <AppBar position="fixed" className={classes.root} color="default">
      <Toolbar>
        {isLogged && (
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        )}
        <div className={classes.title} onClick={() => history.push("/")}>
          <img alt="logo" src={logo} className={classes.logo} style={{cursor : "pointer"}}/>
          <Typography color="primary" className={classes.logoSubHeading}>
            THE NATIONAL VOLUNTEERING PLATFORM
          </Typography>
        </div>
        <Hidden mdDown>
          <Button
            className={classes.button}
            size="large"
            color="primary"
            onClick={() => gotoHomeSection(history)}
          >
            {t("LandingMenu.home")}
          </Button>
          <CustomizedMenus />
          {getLandingMenuHeader(history, t, isLogged).map(item => (
            <Button
              key={item.name}
              className={classes.button}
              size="large"
              color="primary"
              onClick={item.action}
            >
              {item.name}
            </Button>
          ))}
        </Hidden>
        {!isLogged ? (
          <>
            <CustomButton
              color="primary"
              onClick={() => gotoRegisterSection(history)}
              label={t("LandingMenu.register")}
            />
            <CustomButton
              color="primary"
              component={Link}
              to="/login"
              margin={0}
              label={t("LandingMenu.login")}
            />
          </>
        ) : (
          <LogoutButton label={t("LandingMenu.logout")} />
        )}
        <LangSelector className={classes.button} />
      </Toolbar>
    </AppBar>
  );
}
