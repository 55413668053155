import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { serverHost, tokenKey } from '../../Constant';
import Axios from 'axios';
import CustomButton from '../CustomButton';
import ReactHookFormSelect from '../ReactHookFormSelect';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row, changeSubVio, t } = props;
  const { register, errors, control, handleSubmit, setValue, getValues, watch } = useForm()
  const [subVio, setSubVio] = React.useState(row.subVioId ? row.subVioId : "NONE");
  const [error, setError] = React.useState(false);
  const classes = useRowStyles();
  const {enqueueSnackbar} = useSnackbar()
  const watchSubVio = watch("subVio");
  
  let history = useHistory();

  useEffect(() => {
    if (!error) return
    enqueueSnackbar("Update failed", {
      variant: 'error',
      persist: false
    })
  }, [error])

  return (
    <React.Fragment>
      {
        <React.Fragment>
          <TableRow className={classes.root}>
            <TableCell>{row.email}</TableCell>
            <TableCell component="th" scope="row" component={Link} to={`/volunteer/${row.volunteerId}`}>
              {row.volunteerFName} {row.volunteerLName}

            </TableCell>
            <TableCell align="right">
              <Button variant="outlined"
                color="secondary"
                style={{ width: 120 }}
                disabled={(row.assigned) ? true : false}
                onClick={() => { changeSubVio(row.volunteerId, true, setError) }}
              >
                {t(`Common.${row.assigned ? "added" : "add"}`)}
              </Button></TableCell>

            <TableCell align="right">
              <Button variant="outlined"
                color="secondary"
                style={{ width: 120 }}
                disabled={(!row.assigned) ? true : false}
                onClick={() => { changeSubVio(row.volunteerId, false, setError) }}
              >
                {t(`${!row.assigned ? "removed" : "remove"}`)}
              </Button></TableCell>
            {/* <TableCell>
              <form>
                <ReactHookFormSelect
                  label="Sub VIO"
                  name="subVio"
                  control={control}
                  variant="outlined"
                  fullWidth
                  defaultValue={subVio}
                  
                >
                  <MenuItem value={"1"}>Mr</MenuItem>
                  <MenuItem value={"Mrs"}>Mrs</MenuItem>
                  <MenuItem value={"Ms"}>Ms</MenuItem>
                  <MenuItem value={"Rev"}>Rev</MenuItem>
                  <MenuItem value={"Dr"}>Dr</MenuItem>
                  <MenuItem value={"NONE"}>None</MenuItem>
                </ReactHookFormSelect>
                {error && <FormHelperText error>Update Failed</FormHelperText>}
                {watchSubVio &&
                  <Button variant="outlined"
                    color="secondary"
                    style={{ width: 120 }}
                    
                    disabled={(subVio && subVio.toString() == watchSubVio.toString()) ? true : false}
                    onClick={() => { changeSubVio(row.subVioId, getValues("subVio"), setSubVio, setError) }}
                  >Save</Button>
                }
              </form>
            </TableCell> */}
          </TableRow>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 185px)",
    },
    overflowX: "auto",
  },
}));


export default function VolunteerSubVIOTable({ id, rows, changeSubVio, pageCount, handlePageChange, page }) {
  const {t} = useTranslation()
  const classes = useStyles();
  return (
    <div style={{
      backgroundColor: '#e1ebff',
      padding: 24,
      borderRadius: 4,
      margin: '16px 0'
    }}>

      <TableContainer style={{ marginTop: 10, overflowX: 'auto' }} className={classes.table} component={Paper}>
        <Table aria-label="collapsible table" style={{ minWidth: 600 }} size="small">
          {/* <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right" colSpan={2}></TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {!rows.length && <TableRow>
              <TableCell colSpan={6} align="center">{t('Common.noResults')}</TableCell>
            </TableRow>}
            {rows.map((row) => {
              return <Row key={row.volunteerId} row={row} changeSubVio={changeSubVio} t={t}/>
            })}
          </TableBody>
        </Table>
      </TableContainer>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
          size="large"
          style={{ width: '100%', paddingTop: 20, justifyContent: "center" }}
          boundaryCount={2}
        />
    </div>
  );
}