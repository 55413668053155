import {Button, Card, CircularProgress, Grid, TextField} from "@material-ui/core";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetPassword} from "./utils/reset_password";
import {Alert} from "@material-ui/lab";
import {logout} from "./utils/logout";
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
    currentPassword: yup.string().required('required'),
    newPassword: yup.string().required('required'),
    newPasswordConfirm: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'passwords must match')
});

const feedbacks = {
    SUCCESS: "SUCCESS",
    INV_PASS: "INV_PASS",
    UNKNOWN: "UNKNOWN",
}

export default function ResetPassword() {

    const {register, errors, handleSubmit} = useForm(
        {
            resolver: yupResolver(schema),
            mode: 'onChange'
        }
    );

    const userId = useSelector(state => state.auth.id)
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()

    const history = useHistory()
    const dispatch = useDispatch()

    const onSubmit = async (data) => {
        
        setLoading(true)
        const [code] = await resetPassword(userId, data.currentPassword, data.newPassword)
        setLoading(false)
        
        if (code === 200) {
            setStatus(feedbacks.SUCCESS)
            setTimeout(() => logout(history, dispatch, "/login"), 1000)
        } else if (code <= 400 && code < 500) {
            setStatus(feedbacks.INV_PASS)
        }
        else setStatus(feedbacks.UNKNOWN)
    }

    return (
        // <Card>
            <Grid style={{padding: "10px"}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12}>
                        <TextField
                            name="currentPassword"
                            label={t('Settings.current')}
                            type="password"
                            size="small"
                            id="outlined-margin-normal"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            error={errors.currentPassword}
                            helperText={errors.currentPassword?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="newPassword"
                            label={t('Settings.new')}
                            type="password"
                            size="small"
                            id="outlined-margin-normal"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            error={errors.newPassword}
                            helperText={errors.newPassword?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="newPasswordConfirm"
                            label={t('Settings.confirm')}
                            type="password"
                            size="small"
                            id="outlined-margin-normal"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            error={errors.newPasswordConfirm}
                            helperText={errors.newPasswordConfirm?.message}
                        />
                    </Grid>
                    <Grid item xs={12} style={{marginTop: "10px"}}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            {loading && <CircularProgress style={{color: "white", marginRight: '10px'}} size={15}/>}
                            {t('Settings.resetPassword')}
                        </Button>
                    </ Grid>
                    <Grid item xs={12} style={{marginTop: "10px"}}>
                        {status === feedbacks.UNKNOWN && <Alert severity="error">Something wrong</Alert>}
                        {status === feedbacks.INV_PASS && <Alert severity="error">Password is incorrect</Alert>}
                        {status === feedbacks.SUCCESS && <Alert severity="success">Success, please login again</Alert>}
                    </ Grid>
                </form>
            </Grid>
        // </Card>
    );
}

