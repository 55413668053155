import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import 'date-fns';
import EVOContainer from './EmergencyUserHome/EVOContainer';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import MessageCard from '../MessageCard';

export default function EmergencyUserHome({ fetchDetails, voRows, page, pageCount, handlePageChange, handleSearchVO }) {

  const { t } = useTranslation()

  if (fetchDetails)
    return (
      <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
        <EVOContainer
          voRows={voRows}
          page={page}
          name={fetchDetails.name}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          handleSearchVO={handleSearchVO}
        />
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
          size="large"
          style={{ width: '100%', paddingTop: 20 }}
          boundaryCount={2}
        />
      </Paper>
    );
  return (
    <MessageCard />
  )
}