import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
    Avatar,
    Grid,
    Paper,
    Typography
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import SubVIORow from "./SubVIORow";
import { fetchSubVIOList } from "../../functions/VIO/SubVIOList/fetchSubVIOList";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "avatar",
        numeric: false,
        disablePadding: true,
        label: "",
    },
    { id: "name", numeric: false, disablePadding: false, label: "Name" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    { id: "type", numeric: false, disablePadding: false, label: "VIO Type" },
];

function EnhancedTableHead(props) {
    const [open, setOpen] = React.useState(false);
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const {t} = useTranslation()

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id === "avatar" ? (
                            <div>
                                <Avatar style={{ margin: 6 }}>
                                    <AccountCircle />
                                </Avatar>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </div>
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(`SubVioList.${headCell.label}`)}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
                {/*<TableCell align="center" padding="checkbox" colSpan={2}>*/}
                {/*    Actions*/}
                {/*</TableCell>*/}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: '#e1ebff',
        padding: 12,
        borderRadius: 4,
        margin: '16px 0'
    },
    table: {
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 146px)', overflowX: 'auto'
        },
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function SubVIOList(props) {
    const [VIOList, setVIOList] = React.useState([]);
    const [fetchError, setFetchError] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    React.useEffect(() => {
        const fetchData = async () => {
            let mounted = true;
            let data = await fetchSubVIOList();
            
            if (mounted) {
                if (data.error) {
                    
                    setFetchError(true);
                }
                else {
                    
                    setVIOList(data.item);
                    // setVIOList(data.item.filter( VIO => VIO.status === 'PROFILECOMPLETED'))
                    setFetchError(false);
                }
            }
        }
        fetchData()
    }, [])
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const {t} = useTranslation()


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isVIOApproved = (id) => {
        setVIOList(VIOList.filter(VIO => VIO.id !== id))
    }

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, VIOList.length - page * rowsPerPage);

    return (
        <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
            <Grid container direction="column" spacing={2}>
                <Grid item style={{ padding: '20px 0' }}>
                    <Typography variant="h5" align="center" color="primary">{t('SubVioList.title')}</Typography>
                </Grid>
                <Grid item>
                    <div className={classes.paper}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={VIOList.length}
                                />
                                <TableBody>
                                    {stableSort(VIOList, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isApproved = () => isVIOApproved(row.id);
                                            const showAlertApproved = () => {
                                                enqueueSnackbar(isError ? 'Something went wrong! ': 'VIO approved Successfully !', {
                                                    variant: isError ? 'error': 'success',
                                                    persist: false
                                                })
                                            };
                                            const showAlertRejected = () => {
                                                enqueueSnackbar(isError ? 'Something went wrong! ': 'VIO rejected Successfully !', {
                                                    variant: isError ? 'error': 'success',
                                                    persist: false
                                                })

                                            };
                                            const setError = () => setIsError(true);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <SubVIORow
                                                    row={row}
                                                    labelId={labelId}
                                                    classes={classes}
                                                    isApproved={isApproved}
                                                    showAlertApproved={showAlertApproved}
                                                    showAlertRejected={showAlertRejected}
                                                    isError={setError}
                                                />
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={VIOList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}
