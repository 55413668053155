import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { fetchVIOProfileUrl } from '../../../containers/VIOProfile/Constants';


export const fetchVIOProfile = async (userID) => {

    const fetchUrl = serverHost + ":" + serverPort + fetchVIOProfileUrl;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },
                
            });
  
        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

