import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';

import style from './ProfileComplete.styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Container } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReactHookFormSelect from '../ReactHookFormSelect';
import IconTextField from '../IconTextField';
import ReactHookFormCheckbox from '../ReactHookFormCheckbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function EduEmpSkills(props) {
  const { children, setValue, register, control, errors, watch, formDisplayData, disabled, ...other } = props;

  const classes = useStyles();
  const watchhighestEducation = watch("highestEducation")
  const watchEmploymentStatus = watch("employmentStatus");
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <Typography variant="h6" color="primary">{t('Volunteer.Education')}</Typography>
      </Grid>
      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t('Volunteer.Highest Educational Level')}
          disabled={disabled}
          name="highestEducation"
          control={control}
          variant="outlined"
          defaultValue=""
          fullWidth
          error={errors.highestEducation}
          helperText={errors.highestEducation?.message}
          disabled={disabled}
        >
          <MenuItem value={"PHD"}>{t('Degrees.PHD')}</MenuItem>
          <MenuItem value={"MSC"}>{t('Degrees.Masters degree')}</MenuItem>
          <MenuItem value={"DEGREE"}>{t('Degrees.Bachelors degree')}</MenuItem>
          <MenuItem value={"DIPLOMA"}>{t('Degrees.Diploma')}</MenuItem>
          <MenuItem value={"GCE_AL"}>{t('Degrees.Als')}</MenuItem>
          <MenuItem value={"GCE_OL"}>{t('Degrees.Ols')}</MenuItem>
          <MenuItem value={"NONE"}>{t('Degrees.None')}</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      {(["PHD", "MSC", "DEGREE"].includes(watchhighestEducation)) && <React.Fragment><Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t('Volunteer.Title of the Degree')}
          disabled={disabled}
          name="degreeTitle"
          control={control}
          variant="outlined"
          defaultValue=""
          fullWidth
          error={errors.degreeTitle}
          helperText={errors.degreeTitle?.message}
          disabled={disabled}
        >
          {formDisplayData.ares_of_expertise.map(({ id, area }) => (
            <MenuItem value={area} key={id}>{t(`Areas.${area}`)}</MenuItem>
          ))}
        </ReactHookFormSelect>

      </Grid>

        <Grid item md={4} xs={6}>
          <TextField
            inputRef={register}
            error={errors.degreeInstitute}
            helperText={errors.degreeInstitute?.message}
            disabled={disabled}
            name="degreeInstitute"
            variant="outlined"
            label={t('Volunteer.Name of the Institution')}
            fullWidth
          />
        </Grid></React.Fragment>}

      <Grid item xs={12} >
        <Typography variant="h6" color="primary">{t('Volunteer.Employment Status')}</Typography>
      </Grid>
      <Grid item md={4} xs={6}>
        <ReactHookFormSelect
          label={t('Volunteer.Employment Status')}
          disabled={disabled}
          name="employmentStatus"
          control={control}
          variant="outlined"
          defaultValue=""
          fullWidth
          error={errors.employmentStatus}
          helperText={errors.employmentStatus?.message}
        >
          <MenuItem value={"EMPLOYED"}>{t('Volunteer.Employed')}</MenuItem>
          <MenuItem value={"SELF_EMPLOYED"}>{t('Volunteer.Self-employed')}</MenuItem>
          <MenuItem value={"UNEMPLOYED"}>{t('Volunteer.Unemployed')}</MenuItem>
          <MenuItem value={"HOUSEWIFE"}>{t('Volunteer.Housewife')}</MenuItem>
          <MenuItem value={"STUDENT"}>{t('Volunteer.Student')}</MenuItem>
          <MenuItem value={"JOB_SEEKER"}>{t('Volunteer.Job seeker')}</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      {(watchEmploymentStatus == "EMPLOYED") && <React.Fragment>
        <Grid item xs={12} >
          <Typography variant="body1">
            {t('Volunteer.If employed please select')}
          </Typography>
        </Grid>

        <Grid item md={4} xs={6}>
          <ReactHookFormSelect
            label={t('Volunteer.Sector')}
            disabled={disabled}
            name="employmentSector"
            control={control}
            variant="outlined"
            defaultValue=""
            fullWidth
            error={errors.employmentSector}
            helperText={errors.employmentSector?.message}
          >
            <MenuItem value={"PRIVATE"}>{t('Volunteer.Private')}</MenuItem>
            <MenuItem value={"GOVERNMENT"}>{t('Volunteer.Government')}</MenuItem>
            <MenuItem value={"NGO"}>{t('Volunteer.NGO')}</MenuItem>
            <MenuItem value={"ACADEMIA"}>{t('Volunteer.Academia')}</MenuItem>
            <MenuItem value={"FORCES"}>{t('Volunteer.Forces')}</MenuItem>
            <MenuItem value={"RETIRED"}>{t('Volunteer.Retired')}</MenuItem>
            <MenuItem value={"OTHER"}>{t('Volunteer.Other')}</MenuItem>
          </ReactHookFormSelect>
        </Grid>
        <Grid item md={8} xs={6}>
          <TextField
            inputRef={register}
            error={errors.employmentTitle}
            helperText={errors.employmentTitle?.message}
            disabled={disabled}
            name="employmentTitle"
            fullWidth
            variant="outlined"
            label={t('Volunteer.Current Job Title')}
          />
        </Grid>
      </React.Fragment>}

      {/* <Grid item xs={12}>
        <Typography variant="body1" component="h2" color={errors.employmentArea ? 'error' : 'initial'}>
          Areas of Expertise
              </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginBottom: 10 }}>
        {[0, 1, 2, 3, 5, 6, 7, 8, 9, 10].map((value) =>
          <Grid item md={3} xs={4} key={value}>
            <FormControlLabel
              control={<Checkbox />}
              label={value}
              value={value}
              disabled={disabled}
              name="employmentArea"
              inputRef={register}
            />
          </Grid>
        )}
      </Grid> */}

      <Grid item xs={12} >
        <Typography variant="body1" component="h2">{t('Volunteer.Areas of Expertise')}</Typography>
        {errors.employmentArea && <FormHelperText error>{errors.employmentArea?.message}</FormHelperText>}
      </Grid>
      <Grid container item xs={12} style={{ marginBottom: 10 }}>
        {formDisplayData.ares_of_expertise.map(({ id, area }) =>
          <Grid item md={3} xs={4} key={area}>
            <ReactHookFormCheckbox 
            // lplpl
              disabled={disabled}
              name="employmentArea"
              label={t(`Areas.${area}`)}
              value={area}
              control={control}
              defaultValue={false}
            />
          </Grid>
        )}
      </Grid>

      {/* {errors.employmentArea && <FormHelperText error>{errors.employmentArea?.message}</FormHelperText>} */}

      <Grid item xs={12} >
        <Typography variant="h6" color="primary">{t('Volunteer.Skills')}</Typography>
      </Grid>

      <Grid item md={4} xs={6}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Communication')}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Controller
          as={RadioGroup}
          control={control}
          disabled={disabled}
          name="communicationSkill"
        >
          <FormControlLabel value="NO" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.No Knowledge')} />
          <FormControlLabel value="BASIC" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Basic Knowledge')} />
          <FormControlLabel value="MODERATE" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Moderate')} />
          <FormControlLabel value="ADEQUATE" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Adequate Experience')} />
          <FormControlLabel value="HIGH" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Highly Skilled')} />
        </Controller>
        {errors.communicationSkill && <FormHelperText error>{errors.communicationSkill?.message}</FormHelperText>}
      </Grid>

      <Grid item md={4} xs={6}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Computer literacy')}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Controller
          as={RadioGroup}
          control={control}
          disabled={disabled}
          name="computerLiteracySkill"
        >
          <FormControlLabel value="NO" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.No Knowledge')} />
          <FormControlLabel value="BASIC" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Basic Knowledge')} />
          <FormControlLabel value="MODERATE" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Moderate')} />
          <FormControlLabel value="ADEQUATE" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Adequate Experience')} />
          <FormControlLabel value="HIGH" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Highly Skilled')} />
        </Controller>
        {errors.computerLiteracySkill && <FormHelperText error>{errors.computerLiteracySkill?.message}</FormHelperText>}
      </Grid>

      <Grid item md={4} xs={6}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Social Media Interaction')}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Controller
          as={RadioGroup}
          control={control}
          disabled={disabled}
          name="socialMediaInteractionSkill"
        >
          <FormControlLabel value="NO" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.No Knowledge')} />
          <FormControlLabel value="BASIC" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Basic Knowledge')} />
          <FormControlLabel value="MODERATE" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Moderate')} />
          <FormControlLabel value="ADEQUATE" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Adequate Experience')} />
          <FormControlLabel value="HIGH" disabled={disabled} control={<Radio color="secondary" />} label={t('Volunteer.Highly Skilled')} />
        </Controller>
        {errors.socialMediaInteractionSkill && <FormHelperText error>{errors.socialMediaInteractionSkill?.message}</FormHelperText>}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="h2">
          {t('Volunteer.Other skills')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputRef={register}
          error={errors.otherSkills}
          helperText={errors.otherSkills?.message}
          fullWidth
          disabled={disabled}
          name="otherSkills"
          variant="outlined"
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(style)(EduEmpSkills);