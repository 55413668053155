import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress, Container, Typography } from '@material-ui/core';
import NewsRow from '../../components/NewsRow';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import { FaPlusSquare, FaWindowClose, FaSave } from "react-icons/fa";
import { fetchNews, updateNews, updateMessageRequest, createNewReqeust } from '../../functions/Admin/FetchData/fetchNews';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
  }
}));

export default function News() {
    const classes = useRowStyles();
    const [newsRows, setNewsRows] = React.useState([]);
    const [update, setUpdate] = React.useState(false);
    const [createBtn, setCreateBtn] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [body, setBody] = React.useState("");

    const [errorMsg, setErrorMsgUpdate] = React.useState("");
    const [pushError, setPushError] = React.useState(false);

    const {enqueueSnackbar} = useSnackbar()  

    React.useEffect(()=> {
        const fetch = async () => {
            const { item, error }  = await fetchNews();
            if(error) {
                
                return;
            }
            
            setNewsRows(item.activeNews.concat(item.archivedNews));
        }
        fetch();
        setPushError(false);
    }, [update]);

    React.useEffect(() => {
        if (!errorMsg || !pushError) return
        enqueueSnackbar(errorMsg, {
          variant: 'error',
          persist: false
        })
      }, [pushError]);

    const updateRequest = async (id) => {
        const { item, error } = await updateNews(id);
        if(error) {
            setErrorMsgUpdate("Unable to ARCHIVE/ACTIVE");
            setPushError(true);
            
        }
        setUpdate(!update);
    };

    const updateMessage = async (id, body) => {
        const { item, error } = await updateMessageRequest(id, body);
        if(error) {
            setErrorMsgUpdate("Unable to update the message!");
            setPushError(true);
            
        }
        setUpdate(!update);
    }

    const handleCreateNew = async (event) => {
        event.preventDefault();
        const { item, error } = await createNewReqeust({title, body});
        if(error) {
            setErrorMsgUpdate("Unable to create the message!");
            setPushError(true);
            
        }
        setTitle("");
        setBody("");
        setCreateBtn(false);
        setUpdate(!update);
    }

    return (
        <>
            
            <Paper
                style={{
                    padding: "18px 36px",
                    margin: "auto",
                    marginTop: 40,
                    marginBottom: 40,
                }}
            >
                <Container className={classes.container}>
                    <Grid container spacing={3} margin={3}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item style={{ padding: "20px 0" }}>
                            <Typography variant="h4" align="center" color="primary">
                                News
                            </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={9}>
                                    <Typography variant="h5" component="h6">
                                        All news
                                    </Typography>
                                </Grid>
                                <Grid container xs={3} justify="flex-end">
                                    {!createBtn && <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={()=> setCreateBtn(!createBtn)}
                                    > 
                                        Create new &nbsp;<FaPlusSquare />
                                    </Button>}
                                    {createBtn && <Button 
                                        variant="contained" 
                                        color="secondary"
                                        onClick={()=> setCreateBtn(!createBtn)}
                                    >
                                        Close &nbsp;<FaWindowClose />
                                    </Button>}
                                </Grid>
                            </Grid>
                        </Grid>
                        { createBtn &&
                        <Grid item xs={12}>
                            <form>
                                <Grid container spacing={3} margin={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="title"
                                        label="Title"
                                        value={title}
                                        onChange={(event) => setTitle(event.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        id="body"
                                        label="Message"
                                        value={body}
                                        onChange={(event) => setBody(event.target.value)}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        multiline
                                        rows={12}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" type="submit" onClick={handleCreateNew}>
                                        SAVE &nbsp; <FaSave />
                                    </Button>
                                </Grid>
                                </Grid>  
                            </form>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Title</TableCell>
                                            <TableCell align="left">Date</TableCell>
                                            <TableCell align="right">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {newsRows.map((row) => (
                                        <NewsRow key={row.id} row={row} update={updateRequest} updateMsg={updateMessage}/>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>  
                </Container>
            </Paper>
        </>
    );
}
