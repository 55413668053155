import {serverHost,serverPort,tokenKey} from "../../../Constant";
import axios from 'axios';
import {changePasswordUrl} from '../../../containers/UserCommon/Constants'

export async function resetPassword(userId, oldPassword, newPassword){
    
    const URL_ENDPOINT = `${serverHost}:${serverPort}${changePasswordUrl}`;
    try{
        const response = await axios.post(URL_ENDPOINT,
            {
                id: userId,
                oldPassword: oldPassword,
                newPassword1: newPassword,
                newPassword2: newPassword,
            }, {  headers:  {'token': localStorage.getItem(tokenKey)} }
            );

        return [response.status, response.data];
    }
    catch(err){
        const response = err.response
        return [response?.status || 408, response?.data];
    }
}
