import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import React, {useState, useEffect} from "react";
import useStyles from "./_styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import stat1 from "../../images/stat-1.png";
import stat2 from "../../images/stat-2.png";
import stat3 from "../../images/stat-3.png";
import stat4 from "../../images/stat-4.png";
import stat5 from "../../images/stat-5.png";
import { useTranslation } from "react-i18next";
import {compact_number} from "./utils/compact_number";

export default function Statistics({statistics}) {
    const classes = useStyles();
    const { t } = useTranslation()

    return (
        <Grid item className={classes.statisticsSection}>
            <Divider variant="fullWidth" />
            <Grid container style={{ marginTop: '50px' }}>
                <Paper elevation={3} className={classes.paper} style={{ padding: '10px 5%', margin: 0 }}>
                    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={12} >
                            <div style={{ width: '100%', margin: '5% 0 2%' }}>
                                <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                    {t('LandingPage.statistics.title')}
                                </Typography>
                                <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center" }} >
                                    {t('LandingPage.statistics.subtitle')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} style={{ padding: '0' }}>
                            <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                <img src={stat1} width={'50%'} />
                            </div>
                            <div style={{ width: '100%', margin: '5% auto 0' }}>
                                <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                    {compact_number(statistics.numVolunteersRegistered) || 0}
                                </Typography>
                            </div>
                            <div style={{ width: '100%', margin: '0 0 5%' }}>
                                <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center" }} >
                                    {t('LandingPage.statistics.volenteersRegistered')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} style={{ padding: '0' }}>
                            <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                <img src={stat2} width={'50%'} />
                            </div>
                            <div style={{ width: '100%', margin: '5% auto 0' }}>
                                <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                    {compact_number(statistics.numViosRegistered) || 0}
                                </Typography>
                            </div>
                            <div style={{ width: '100%', margin: '0 0 5%' }}>
                                <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center" }} >
                                    {t('LandingPage.statistics.volunteersMobilized')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} style={{ padding: '0' }}>
                            <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                <img src={stat3} width={'50%'} />
                            </div>
                            <div style={{ width: '100%', margin: '5% auto 0' }}>
                                <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                    {compact_number(statistics.numVolunteersMobilized) || 0}
                                </Typography>
                            </div>
                            <div style={{ width: '100%', margin: '0 0 5%' }}>
                                <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center" }} >
                                    {t('LandingPage.statistics.viosRegistered')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} style={{ padding: '0' }}>
                            <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                <img src={stat4} width={'50%'} />
                            </div>
                            <div style={{ width: '100%', margin: '5% auto 0' }}>
                                <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                    {compact_number(statistics.numVolunteerHoursContributed) || 0}
                                </Typography>
                            </div>
                            <div style={{ width: '100%', margin: '0 0 5%' }}>
                                <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center" }} >
                                    {t('LandingPage.statistics.volunteerHours')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} style={{ padding: '0px' }}>
                            <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                <img src={stat5} width={'50%'} />
                            </div>
                            <div style={{ width: '100%', margin: '5% auto 0' }}>
                                <Typography variant="h5" color='primary' gutterBottom style={{ textAlign: "center", fontWeight: 'bold' }} >
                                    {compact_number(statistics.valueOfContribution) || 0}
                                </Typography>
                            </div>
                            <div style={{ width: '100%', margin: '0 0 0' }}>
                                <Typography variant="h6" color='primary' gutterBottom style={{ textAlign: "center" }} >
                                    {t('LandingPage.statistics.valueOFVolunteerContribution')}
                                </Typography>
                            </div>
                        </Grid>
                        <div style={{ width: '100%', margin: '0 0 5%', lineHeight: '0.7', textAlign: 'center' }}>
                            <Typography variant="inherit" color='primary' gutterBottom
                                style={{ textAlign: "center", fontSize: '7px', textTransform: 'uppercase' }}
                            >
                                {t('LandingPage.statistics.calculationMethodExplanation')}
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
