import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper, TextField
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { allVios, shareOpportunity } from "./utils";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const styles = {
    section: {
        marginBottom: "20px",
        marginTop: "20px",
    },
};

export default function ShareOpportunity() {

    const [vios, setVios] = useState([]);
    const [query, setQuery] = useState('')

    const userId = useSelector(state => state.auth.id)
    const {opportunityId} = useParams()
    const history = useHistory()

    const [errorMsg, setErrorMsg] = React.useState("");
    const [isError, setIsErrorVariant] = React.useState(true);
    const [pushError, setPushError] = React.useState(false);
  
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation();

    useEffect(() => {
      if (!errorMsg) return
      enqueueSnackbar(errorMsg, {
        variant: isError ? 'error' : 'success',
        persist: false
      })
    }, [pushError])

    useEffect(() => {
        (async () => {
            const [code, data] = await allVios()
            if (code === 200) {
                
                setVios(data.filter(v => v.status === "APPROVED" && v.id !== userId).map(v => ({...v, loading: false, disabled: false})))
            }
        })()
    }, []);

    const onRequested = async (secondaryVio) => {
       
        secondaryVio.loading = true
        setVios(prev => 
            prev.map(v => (v.id === secondaryVio.id)? secondaryVio: v)
        )

        const [code] = await shareOpportunity(opportunityId, userId, secondaryVio.id)
        if (code === 200) {
            setErrorMsg("Successfully sent a request to share this opportunity")
            setIsErrorVariant(false)
            setPushError(!pushError)
        } else {
            secondaryVio.loading = false
            setVios(prev => 
                prev.map(v => (v.id === secondaryVio.id)? secondaryVio: v)
            )
            setErrorMsg("Something went wrong!")
            setIsErrorVariant(true)
            setPushError(!pushError)
        }
        secondaryVio.loading = false
        secondaryVio.disabled = true
        setVios(prev => 
            prev.map(v => (v.id === secondaryVio.id)? secondaryVio: v)
        )
    }

    const goBack = () => {
        history.goBack()
    }

    const onClickView = (id) => {
        history.push("/vio/"+ id)
    }

    return (
        <Container
            maxWidth="md"
            style={{marginTop: "15px", marginBottom: "15px"}}
        >
            <Paper elevation={0} style={{padding: "20px"}}>
                <Grid item xs={12} style={styles.section}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <Grid item xs={12} md={9}>
                            <Typography variant="h5">
                                {t('VolunteerOpportunityPage.Share this opportunity with')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={goBack}
                            >
                                {t('Common.goBack')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider style={{marginTop: '5px'}}/>
                </Grid>
                <Grid item xs={12} style={styles.section}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                id="standard-adornment-password"
                                placeholder={t('VolunteerOpportunityPage.Search for VIO')}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                variant="outlined"
                                size="small"
                                startAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container justify="space-around" style={styles.section}>
                    <Grid item xs={12} alignItems="left">
                        <List dense={false}>
                            {vios.filter(v => query === '' ||
                                v.name.toUpperCase().includes(query.toUpperCase()) ||
                                v.email.includes(query.toLowerCase())
                            ).map((v) => (
                                <ListItem>
                                    <ListItemIcon>
                                        <AccountCircleIcon/>
                                    </ListItemIcon>
                                    <Button
                                        onClick={() => onClickView(v.id)}
                                    >
                                    <ListItemText
                                        primary={v.name}
                                        secondary={v.email} 
                                        style={{ textAlign : 'left'}}
                                    >
                                    </ListItemText>
                                    </Button>
                                    <ListItemSecondaryAction>
                                        <Button
                                            disabled={v.disabled}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => v.loading || onRequested(v)}
                                        >
                                            {v.loading && (
                                                <CircularProgress
                                                    style={{
                                                        color: "white",
                                                        marginRight: "10px",
                                                    }}
                                                    size={15}
                                                />
                                            )}
                                            {t('Common.request')}
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}
