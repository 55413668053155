import {serverHost,serverPort,tokenKey} from "../../Constant";
import axios from 'axios';
const putProfilePictureURL = '/api/asset/image';
const updateProfilePictureURL = '/api/asset/updateImage';

export const uploadProfilePicture = async (images)=>{
    const submitUrl = serverHost+":"+serverPort + putProfilePictureURL;
    try{
        const response = await axios.put(submitUrl,
            images);
        if (response.status == 200) {
                const result =  {
                    item:response.data,
                    error:false
                }
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }
}
    catch(err){
        
        const result = {
            message:err.message,
            error:true
        }
        
        return result;
    }

};

export const updateProfilePicture = async (imageURL)=>{
    const submitUrl = serverHost+":"+serverPort + updateProfilePictureURL;
    try{
        const response = await axios.post(submitUrl,
            {
                imageURL
            },
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },
            });
        if (response.status == 201) {
                const result =  {
                    item:response.data,
                    error:false
                }
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }

}
    catch(err){
        
        const result = {
            message:err.message,
            error:true
        }
        
        return result;
    }

};

// export const submitOTP = async (userId,otp)=>{
//
//     const submitUrl = serverHost+":"+serverPort+submitOPTUrl;
//     try{
//         const response = await axios.post(submitUrl,
//             {
//             headers: {
//                 'usertoken': localStorage.getItem(tokenKey)
//             },
//             params: {
//                 userId:userId,
//                 otp:otp
//             }
//         });
//
//         if (response.status == 200) {
//                 const result =  {
//                     item:response.data,
//                     error:false
//                 }
//
//                 return result;
//             }
//         else {
//             
//             const result = {
//                 message:response.message,
//                 error:true
//             }
//             
//             return result;
//         }
//
// }
// catch(err){
//     
//     const result = {
//         message:err.message,
//         error:true
//     }
//     
//     return result;
// }
//
// };
