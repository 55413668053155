export const changeVolDailyReport = (report) => {
    report.numVolunteersByNationality = [['Nationality', 'Count'], ["Locals", report.numVolunteersByNationality.numLocal], ["Overseas", report.numVolunteersByNationality.numOverseas]];
    report.numVolunteersByKeySectors = [["Employment sector", "Count"], ...objToArr(report.numVolunteersByKeySectors)];
    report.numVoluteersByDistricts = [["District", "Count"], ...objToArr(report.numVoluteersByDistricts)];
    report.numVolunteersByGender = [["Gender", "Count"], ...objToArr(report.numVolunteersByGender)];
    return report;
}

export const changeVIOGeneralReport = (report) => {
    report.volunteerHours.sectors = [["Sector", "Count"]].concat(Object.keys(report.volunteerHours.sectors).map((key) => [key, report.volunteerHours.sectors[key]]).filter(item => item[1] > 0));
    report.volunteerHours.sdgs = [["SDG", "Volunteering Hours"]].concat(Object.keys(report.volunteerHours.sdgs).map((key) => [key.toString(), report.volunteerHours.sdgs[key]]).filter(item => item[1] > 0));
    report.volunteerHours.coreFunctions = [["Core Functions", "Volunteering Hours"]].concat(Object.keys(report.volunteerHours.values).map((key) => [key.toString(), report.volunteerHours.values[key]]).filter(item => item[1] > 0));
    report.gender = [["Gender", "Count"]].concat(Object.keys(report.gender).map((key) => [key.toString(), report.gender[key]]));
    return report;
}

export const changeVolunteerGeneralReport = (report) => {
    report.volunteerHours.sectors = [["Sector", "Count"]].concat(Object.keys(report.volunteerHours.sectors).map((key) => [key, report.volunteerHours.sectors[key]]).filter(item => item[1] > 0))
    report.volunteerHours.sdgs = [["SDG", "Volunteering Hours"]].concat(Object.keys(report.volunteerHours.sdgs).map((key) => [key.toString(), report.volunteerHours.sdgs[key]]).filter(item => item[1] > 0));
    report.volunteerHours.coreFunctions = [["Core Functions", "Volunteering Hours"]].concat(Object.keys(report.volunteerHours.values).map((key) => [key.toString(), report.volunteerHours.values[key]]).filter(item => item[1] > 0));
    return report;
}

export const changeVolMobilizedDailyReport = (report) => {
    report.VolunteersMobilizedByNationality = [['Nationality', 'Count'], ["Locals", report.VolunteersMobilizedByNationality.local], ["Overseas", report.VolunteersMobilizedByNationality.overseas]];
    report.volunteerMobilizedBySector = [["Employment sector", "Count"], ...Object.entries(report.volunteerMobilizedBySector)];
    report.volunteerMobilizedByDistrict = [["District", "Count"], ...Object.entries(report.volunteerMobilizedByDistrict)];
    report.volunteerMobilizedByGender = [["Gender", "Count"], ...Object.entries(report.volunteerMobilizedByGender)];
    return report;
}

export const changeVioDailyReport = (report) => {
    report.numVioByType = [['Type', 'Count'], ...objToArr(report.numVioByType)];
    report.numVioByDistricts.countByDistrictPrivateAcademia = [['District', 'Count'], ...objToArr(report.numVioByDistricts.countByDistrictPrivateAcademia)];
    report.numVioByDistricts.countByDistrictPrivateOrganization = [['District', 'Count'], ...objToArr(report.numVioByDistricts.countByDistrictPrivateOrganization)];
    report.numVioByDistricts.countByDistrictStateAcademia = [['District', 'Count'], ...objToArr(report.numVioByDistricts.countByDistrictStateAcademia)];
    report.numVioByDistricts.countByDistrictStateInstitute = [['District', 'Count'], ...objToArr(report.numVioByDistricts.countByDistrictStateInstitute)];
    return report;
}

export const changeVoDailyReport = (report) => {
    const arr = [['District', 'Count']];
    Object.keys(report.numVOByDistrict).forEach(element => {
        arr.push([element, report.numVOByDistrict[element]]);
    });
    report.numVOByDistrict = arr;
    return report;
}

export const changeVolPendingReport = (report) => {
    report.numVolunteersPendingByNationality = [['Nationality', 'Count'], ["Locals", report.numVolunteersPendingByNationality.numLocal], ["Overseas", report.numVolunteersPendingByNationality.numOverseas]];
    report.numVolunteersPendingByDistrict = [['District', 'Count'], ...objToArr(report.numVolunteersPendingByDistrict)];
    report.numVolunteersPendingByGender = [["Gender", "Count"], ...objToArr(report.numVolunteersPendingByGender)];

    const arr = [['District', 'Count']];
    Object.keys(report.numVioPendingByDistrict).forEach(element => {
        arr.push([element, report.numVioPendingByDistrict[element]]);
    });
    report.numVioPendingByDistrict = arr;
    return report;
}

const objToArr = (objArr) => {
    const arr = [];
    objArr.forEach(element => {
        const vals = Object.values(element)
        arr.push([vals[0], parseInt(vals[1])]);
    });
    return arr;
}