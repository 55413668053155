import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { fetchVOSearchUrl } from '../../../containers/VIO/Constants';


export const fetchVOSearch = async (query = "", pageNumber = 1, status = "", pageSize = 10) => {

    const fetchUrl = serverHost + ":" + serverPort + fetchVOSearchUrl;
    try {

        const response = await axios.post(fetchUrl,
            {
                query: query,
                status: status,
                pageNumber: pageNumber,
                pageSize: pageSize
            },
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },
            });

        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

