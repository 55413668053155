import { Typography, Grid } from '@material-ui/core'
import React from 'react'

function DetailCard({ title, value }) {
    return (
        <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
                {title}
            </Typography>
            <Typography variant="body2" >
                {value}
            </Typography>
        </Grid>
    )
}

export default DetailCard
