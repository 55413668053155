import React from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Paper } from "@material-ui/core";
import MessageCard from '../../../components/MessageCard';
import LoadingPage from "../../../components/LoadingPage";
import AddPartner from '../../../components/Admin/Partner/AddPartner';
import { fetchPartner } from '../../../functions/Admin/Partner/fetchPartner';
import { submitUpdatePartner } from "../../../functions/Admin/Partner/submitUpdatePartner";

export default function EditPartnerPage({ match }) {

  const id = match.params.id;
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [fetchDetails, setFetchDetails] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {
    let mounted = true;
    let result = await fetchPartner(id);
    if (mounted) {
      if (result.error) {
        setError(true);
      }
      else {
        setError(false);
        setFetchDetails(result.item)
      }
    }
    return () => mounted = false;
  }, [])

  const updatePartnerFunc = async (details) => {
    var formData = { id: id, ...details }
    delete formData.history
    let result = await submitUpdatePartner(formData);
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Updated!")
      setPushError(!pushError)
      details.history.push("/admin/partners")
    }
  }

  return (
    <React.Fragment>
      {
        (fetchDetails) ?
          (!error) ?
            <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40, marginBottom: 40 }}>
              <AddPartner
                onSubmit={updatePartnerFunc}
                fetchDetails={fetchDetails}
                isEdit={true}
              />
            </Paper>
            : <MessageCard message="Error" />
          : <LoadingPage />
      }
    </React.Fragment>

  );
}