import React from 'react';
import { Chart } from 'react-google-charts';

function BarChart (props) {
 return (
    <>
        <Chart
            chartType="BarChart"
            loader={<div>Loading Chart</div>}
            data={props.data || ['']}
            options={{
                title: props.title,
                chartArea: { width: '50%' },
                hAxis: {
                    title: props.hAxis,
                    minValue: 0,
                },
                vAxis: {
                    title: props.vAxis,
                },
            }}
            rootProps={{ 'data-testid': '1' }}
        />
    </>
    );
}

export default BarChart;