import {GET, POST, resolver} from "./resolver"

/**
 * Endpoints
 */
export const allVios = () => resolver(GET('/api/vio/list'))

export const shareOpportunity = (opportunityId, primaryVio, secondaryVio) => {
    return resolver(POST(
        '/api/sharedOpportunity/request',
        {opportunityId, primaryVio, secondaryVio}
    ))
}

export const allRequests = (vioId) => resolver(GET('/api/sharedOpportunity/getRequests', {vioId}))

export const proceedRequest = (opportunityId, vioId, action = 'Accept') => {
    return resolver(POST(
            '/api/sharedOpportunity/Accept',
        {opportunityId, vioId, Accepted: action === 'Accept'}
    ))
}
