import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FaSave } from "react-icons/fa";

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function NewsRow(props) {
    const { row, update, updateMsg } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const [status, setStatus] = React.useState(row.status);
    const [title, setTitle] = React.useState(row.title);
    const [body, setBody] = React.useState(row.body);
  
    const handleChange = (event) => {
      setStatus(event.target.value);
      update(row.id);
    };

    const handleSave = (event) => {
      event.preventDefault();
      updateMsg(row.id, {title, body});
    }

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.title}
          </TableCell>
          <TableCell align="left">{row.date}</TableCell>
          <TableCell align="right">
            <FormControl className={classes.formControl}>
              <Select
                value={status}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
                <MenuItem value={'ARCHIVED'}>ARCHIVED</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="body1" gutterBottom component="div">
                  <form>
                    <Grid container spacing={3} margin={3}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="title"
                          label="Title"
                          value={title}
                          onChange={(event) => setTitle(event.target.value)}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField 
                          id="body"
                          label="Message"
                          value={body}
                          onChange={(event) => setBody(event.target.value)}
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={10}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" onClick={handleSave}>
                          SAVE &nbsp; <FaSave />
                        </Button>
                      </Grid>
                    </Grid>  
                  </form>
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  NewsRow.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.string.isRequired,
      adminId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['ACTIVE','ARCHIVED']).isRequired,
    }).isRequired,
    update: PropTypes.func.isRequired,
    updateMsg: PropTypes.func.isRequired,
  };