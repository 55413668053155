import React, { useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactHookFormSelect from "../ReactHookFormSelect";
import MenuItem from "@material-ui/core/MenuItem";
import VIOForm from "../SignUp/VIOForm";
import VolunteerForm from "../SignUp/VolunteerForm";
import {useHistory, useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const schema = yup.object().shape();

export default function SignUp({
  onSubmit,
  success,
  error,
  message,
  formDisabled,
}) {
  const query = useQuery();
  const history = useHistory()
  const {t} = useTranslation()

  const { register, errors, control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { registerAs: "vio" },
  });
  // const onSubmit = data => 

  useEffect(() => {
    const registerAs = query.get("registerAs");
    if (["vio", "volunteer"].includes(registerAs))
      setValue("registerAs", registerAs);
  }, [history]);

  return (
    <React.Fragment>
      <Paper
        style={{
          padding: "18px 36px",
          maxWidth: 800,
          margin: "auto",
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Grid item style={{ padding: "20px 0" }}>
              <Typography variant="h5" color="primary" align="center">
                {t("Register.welcome")}
              </Typography>
            </Grid>
            <form onSubmit={handleSubmit(() => {})}>
              <ReactHookFormSelect
                label={t("Register.registerAs")}
                name="registerAs"
                control={control}
                variant="outlined"
                style={{ margin: "12px 0" }}
                fullWidth
              >
                <MenuItem value="vio">{t("Register.vioMenuItem")}</MenuItem>
                <MenuItem value="volunteer">{t("Register.volunteerMenuItem")}</MenuItem>
              </ReactHookFormSelect>
            </form>
            {watch("registerAs") === "vio" ? <VIOForm t = {t}/> : <VolunteerForm t = {t}/>}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
