import { Box, Grid } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Truncate from 'react-truncate'
import { useTranslation } from 'react-i18next';

export default function NewsBox({ classes, row }) {
    const [truncated, setTruncated] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false)
    const {t} = useTranslation()

    const handleTruncate = (isTruncated) => {
        if (truncated !== isTruncated) {
            setTruncated(isTruncated)
        }
    }

    const toggleLines = (event) => {
        event.preventDefault();
        setExpanded(!expanded);
    }

    return (
        <Box
            m={1}
            style={{
                padding: "10px 30px",
                textIndent: 30,
                textAlign: 'justify'
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.pos} color="textSecondary" variant="subtitle2">
                        {row.date}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Truncate
                        ellipsis={(
                            <span>... <a href='#' onClick={toggleLines}>{t('Newsfeed.more')}</a></span>
                        )}
                        lines={!expanded && 3}
                        onTruncate={handleTruncate}
                    >
                        <Typography
                            variant="p"
                            color="inherit"
                            gutterBottom
                            style={{ color: "#14334F", fontweight: "500", lineHeight: 1.6, }}
                        >
                            {row.body}
                        </Typography>
                    </Truncate>
                    {!truncated && expanded && (
                        <span> <a href='#' onClick={toggleLines}>{t('Newsfeed.less')}</a></span>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}