import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const middlewares = [applyMiddleware(thunk)];

// Activate devtools if and only if in available
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

// Add middlewares and reducers to create store
const store = createStore(
    rootReducer,
    compose(...middlewares)
)

export default store;