import React,{useEffect, useState} from 'react'
import MessageCard from '../../../components/MessageCard';
import {successMessage ,errorMessage} from './Constants'
import {verifyUser} from '../../../functions/verify/apiCalls';
import CustomButton from '../../../components/CustomButton';
import { Link } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { useTranslation } from 'react-i18next';

function VerifyEmail({match}) {
    const key = match.params.secretKey;

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const {t} = useTranslation()

    useEffect(() => {
        if(success) {
            localStorage.setItem("PROFILE_INCOMPLETE", true);
        }
    }, success);

    useEffect(async() => {
        // const setData = async() => {
            const result = await verifyUser(key);
            if(result.error){
                setError(result.error);
                setErrMessage(result.message);
            }
            else{
                setError(result.error);
                setErrMessage("");
                setSuccess(true);
            }
        // }
        // await setData();
    }, [])

    return (
        <React.Fragment>
        <CustomButton label={t("VerifyEmail.verifyPhone")} component={Link} to={`/verifyMobile/${key}`} variant="outlined" />
        {success?
            <React.Fragment>
                <MessageCard
                    error={false}
                    message ={t("VerifyEmail.successMessage")}
                    label={t("VerifyEmail.gotoLogin")} component={Link} to="/login"
                />
            </React.Fragment>
            :
        error?
            <MessageCard
                message ={errMessage}
            />
        :
        <LoadingPage/>
        }
        </React.Fragment>
    )
}

export default VerifyEmail
