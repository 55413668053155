import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { deleteVOUrl } from '../../../containers/VIO/Constants';


export const submitDeleteVolunteerOppertunity = async (id) => {

    const deleteUrl = serverHost + ":" + serverPort + deleteVOUrl + "/" + id;
    try {
        const response = await axios.delete(deleteUrl,
            {
                headers: {
                    token: localStorage.getItem(tokenKey)
                },

            });
        if (response.status == 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }
};
