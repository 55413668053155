import React from "react";
import ForgotPassword from '../../components/ForgotPassword'



export default function ForgotPasswordRequest() {
    return (
        <React.Fragment>
            <ForgotPassword type="REQUEST" />
        </React.Fragment>
    );
}