import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import CustomLocationSelect from '../CustomLocationSelect';
import IconTextField from '../IconTextField';
import EmgVolunteerRow from "./EmgVolunteerRow";
import SendIcon from "@material-ui/icons/Send";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import CustomButton from "../CustomButton";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

function createData(
  id,
  email,
  contributeDistrict,
  contributeDivisionalSecretariat,
  contributegnDivision,
  name,
  registrationNo,
  status
) {
  return {
    id,
    email,
    contributeDistrict,
    contributeDivisionalSecretariat,
    contributegnDivision,
    name,
    registrationNo,
    status
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    inviteMode
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t, i18n } = useTranslation();
  const headCells = [
    { id: "registraionNo", numeric: false, disablePadding: true, label: t("EVO.registrationNo") },
    { id: "name", numeric: false, disablePadding: true, label: t("Common.name") },
    // { id: "email", numeric: true, disablePadding: false, label: t("Common.email") },
    {
      id: "contribute District",
      numeric: true,
      disablePadding: false,
      label: t("EVO.contributeDistrict"),
    },
    {
      id: "contribute Divisional Secretariat",
      numeric: true,
      disablePadding: false,
      label: t("EVO.contributeDS"),
    },
    {
      id: "contribute gn Division",
      numeric: true,
      disablePadding: false,
      label: t("EVO.contributeGN"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {inviteMode && <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "center" : "center"}
            // padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {!inviteMode && <TableCell colSpan={3}>{t("EVO.status")}</TableCell>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, inviteEmgVolunteers, selected, inviteMode } = props;
  const { t, i18n } = useTranslation();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {t("EVO.selected")}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >

        </Typography>
      )}

      {inviteMode && (numSelected > 0 ? (
        <Tooltip title={t("EVO.invite")}>
          <IconButton aria-label="Invite" onClick={() => inviteEmgVolunteers(selected)}>
            <SendIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t("EVO.nothingSelected")}>
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      ))}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#e1ebff",
    padding: 12,
    borderRadius: 4,
    margin: "16px 0",
  },
  table: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 144px)",
      overflowX: "auto",
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EVolunteerTable(props) {

  const { emgVolunteers, inviteEmgVolunteers, inviteMode = false, addEmgVolunteer, removeEmgVolunteer, completeEmgVolunteer, disbleAddCompleInvite } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [resultList, setResultList] = React.useState([]);
  const [filterAddedState, setFilterAddedState] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false);
  // const { register, handleSubmit, errors } = useForm();
  let history = useHistory();
  const { t, i18n } = useTranslation();

  const { register, control, errors, watch, handleSubmit, setValue } = useForm();

  const districtWatch = watch("district");
  const dsWatch = watch("divisionalSecretariat");
  const gnWatch = watch("gnDivision")
  const searchWatch = watch("search")

  useEffect(() => {
    const fetchItems = async () => {
      let resultList = [];
      emgVolunteers.map((result) => {

        resultList.push(
          createData(
            result.id,
            result.email,
            result.contributeDistrict,
            result.contributeDivisionalSecretariat,
            result.contributegnDivision,
            result.name,
            result.registrationNo,
            result.status
          )
        );
      });
      setRows(resultList);
      setResultList(resultList);
      setSelected([])
      setFilterAddedState(false)
    };
    fetchItems();
  }, [emgVolunteers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const addFilter = () => {
    if (districtWatch) {
      setRows(resultList.filter((item) => {
        var filterState = true
        if (districtWatch) {
          filterState &= (item.contributeDistrict == districtWatch)
          if (dsWatch) {
            filterState &= (item.contributeDivisionalSecretariat == dsWatch)
            if (gnWatch) {
              filterState &= (item.contributegnDivision == gnWatch)
            }
          }
        }
        return filterState
      }))
      setFilterAddedState(true)
    }
  }

  const removeFilter = () => {
    setRows(resultList)
    setFilterAddedState(false)
  }

  const searchByRegID = () => {
    if (searchWatch) {
      setRows(resultList.filter(item => item.registrationNo == searchWatch))
    }
    else {
      setRows(resultList)
      setFilterAddedState(false)
    }
  }

  return (
    <div className={classes.root}>
      {/* <Paper > */}
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1" color="primary">
            {t("EVO.oppVolunteers")}
          </Typography>
        </Grid>
        <Grid item container spacing={1} direction="row" style={{ maxWidth: 400 }}>
          <Grid item>
            <IconTextField
              name="search"
              // style={{ maxWidth: 200 }}
              inputRef={register}
              placeholder={t("EVO.registrationId")}
              defaultValue={""}
            />
          </Grid>
          <Grid item>
            <CustomButton variant="contained"
              type="submit"
              color="primary"
              margin="12px 0"
              style={{ padding: '15px 20px' }}
              label={t("Common.search")}
              onClick={searchByRegID}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <CustomLocationSelect
          control={control}
          errors={errors}
          watch={watch}
        />
        <Grid item lg={3} sm={6} xs={12} style={{ display: 'inline-flex' }}>
          <CustomButton variant="contained"
            type="submit"
            color="primary"
            margin={'0 2px'}
            style={{ padding: '15px 20px', color: "white" }}
            label={t("Common.filter")}
            onClick={addFilter}
          />
          <CustomButton variant="contained"
            type="submit"
            color="primary"
            margin={'0 12px'}
            style={{ padding: '15px 20px', color: "white" }}
            label={t("Common.removeFilter")}
            disabled={!filterAddedState}
            onClick={removeFilter}
          />
        </Grid>
      </Grid>
      <div className={classes.paper}>
        <TableContainer className={classes.table} component={Paper}>
          {inviteMode && <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            inviteEmgVolunteers={inviteEmgVolunteers}
            inviteMode={inviteMode}
          />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              inviteMode={inviteMode}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <EmgVolunteerRow
                      handleClick={handleClick}
                      row={row}
                      isItemSelected={isItemSelected}
                      inviteMode={inviteMode}
                      addEmgVolunteer={addEmgVolunteer}
                      removeEmgVolunteer={removeEmgVolunteer}
                      completeEmgVolunteer={completeEmgVolunteer}
                      disbleAddCompleInvite={disbleAddCompleInvite}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {/* </Paper> */}
    </div >
  );
}
