import React, { useState } from 'react';
import CommonRoutes from './CommonRoutes';
import VolunteerRoutes from './VolunteerRoutes';
import VioRoutes from './VioRoutes';
import AdminRoutes from './AdminRoutes';
import RefereeRoutes from './RefereeRoutes';
import CommonUserRoutes from './CommonUserRoutes';
import EmgUserRoutes from './EmgUserRoutes';
import { Container } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

function UserRoutes() {

    return (
        <React.Fragment>
            <CommonRoutes />
            <Container>
                <VolunteerRoutes />
                <VioRoutes />
                <AdminRoutes />
                <RefereeRoutes />
                <CommonUserRoutes />
                <EmgUserRoutes />
            </Container>
        </React.Fragment>
    )
}

export default UserRoutes
