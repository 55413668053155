import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ReactHookFormSelect from './ReactHookFormSelect';
import fetchDistrictList from '../functions/location/fetchDistrictList'
import fetchDivSecList from '../functions/location/fetchDivSecList'
import fetchGnListByDs from '../functions/location/fetchGnListByDs'
import { useTranslation } from "react-i18next";

export default function CustomLocationSelect({ control, watch, disabled = false, namePrefix = "", errors = {}, ...props }) {
  const disName = namePrefix ? namePrefix + "District" : "district"
  const dsName = namePrefix ? namePrefix + "DivisionalSecretariat" : "divisionalSecretariat"
  const gnName = namePrefix ? namePrefix + "gnDivision" : "gnDivision"

  const [districtsList, setDistrictsList] = useState([])
  const [divisionalSecretariatList, setDivisionalSecretariatList] = useState([])
  const [gnDivisionList, setGnDivisionList] = useState([])
  const { t, i18n } = useTranslation()

  const disNameWatch = watch(disName)
  const dsNameWatch = watch(dsName)

  useEffect(() => {
    const loadData = async () => {
      // await call distict api
      const result = await fetchDistrictList()
      if (Array.isArray(result.item)) {
        setDistrictsList(result.item)
      }
    }
    loadData()
  }, [])

  useEffect(() => {
    const loadData = async (district) => {
      if (!district) return
      // await call ds api
      const result = await fetchDivSecList(district)
      if (Array.isArray(result.item)) {
        setDivisionalSecretariatList(result.item)
      }
    }
    loadData(disNameWatch)
  }, [disNameWatch])

  useEffect(() => {
    const loadData = async (ds) => {
      // await call distict api
      if (!ds) return
      const result = await fetchGnListByDs(ds)
      if (Array.isArray(result.item)) {
        setGnDivisionList(result.item)
      }
    }
    loadData(dsNameWatch)
  }, [dsNameWatch])

  return (
    <React.Fragment >
      <Grid item lg={3} sm={6} xs={12}>
        <ReactHookFormSelect
          label={t('Common.district')}
          name={disName}
          control={control}
          variant="outlined"
          fullWidth
          error={errors[disName]}
          helperText={errors[disName]?.message}
          defaultValue={""}
          disabled={disabled}
        >
          {districtsList.map((value, id) => {
            return (
              <MenuItem key={id} value={value.name}>
                {i18n.language === "si" ? value.sinhala_name : i18n.language === "ta" ? value.tamil_name : value.name}
              </MenuItem>
            );
          })}

        </ReactHookFormSelect>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <ReactHookFormSelect
          label={t('Common.ds')}
          name={dsName}
          control={control}
          variant="outlined"
          fullWidth
          error={errors[dsName]}
          helperText={errors[dsName]?.message}
          defaultValue={""}
          disabled={disabled || !divisionalSecretariatList.length}
        >
          {divisionalSecretariatList.map((value, id) => {
            return (
              <MenuItem key={id} value={value.name}>
                {i18n.language === "si" ? value.sinhala_name : i18n.language === "ta" ? value.tamil_name : value.name}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <ReactHookFormSelect
          label={t('Common.gn')}
          name={gnName}
          control={control}
          variant="outlined"
          fullWidth
          error={errors[gnName]}
          helperText={errors[gnName]?.message}
          defaultValue={""}
          disabled={disabled || !gnDivisionList.length}
        >
          {gnDivisionList.map((value, id) => {
            return (
              <MenuItem key={id} value={value.name}>
                {i18n.language === "si" ? value.sinhala_name : i18n.language === "ta" ? value.tamil_name : value.name}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>
    </React.Fragment>
  )
}