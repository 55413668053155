import React from 'react'
import { Route, Switch} from 'react-router-dom';

import Profile from '../../Admin/Profile';
import ProfilePage from '../../VolunteerProfile/ProfilePage';
import VIOProfilePage from '../../VIOProfile/VIOProfilePage'
import SettingsPage from '../../UserCommon/SettingsPage';

import VolunteerHomePage from '../../HomePage/VolunteerHomePage'
import ViewAllOpportunitiesPage from '../../viewOpportunitiesPage/ViewAllOpportunitiesPage';
import OportunityDetailView from '../../viewOpportunitiesPage/OportunityDetailView'
import ViewVolunteers from '../../ViewVolunteers/volunteers';
import UserOpportunitiesPage from '../../viewOpportunitiesPage/UserOpportunitiesPage'
import VioFeedbackPage from '../../Feedback/vioFeedbackPage';
import VolunteerAgreement from '../../Agreement/VolunteerAgreement';
import RefereeForm from '../../Referees/RefereeForm';
import VioList from '../../OrgVolunteers/List/VioList';
import CustomRoute from '../CustomRoute';
import volunteerEVOFeedbackPage from '../../Feedback/volunteerEVOFeedbackPage'
import { useSelector } from 'react-redux';

import VolunteerGeneralReport from '../../Reports/VolunteerGeneralReports';
import {ADD_REFEREE, USER_OPPORTUNITIES, VIO_AGREEMENT, VIO_FEEDBACK, VOLUNTEER_HOME, VOLUNTEER_ORGANIZATIONS, VOLUNTEER_PROFILE} from "../Constants";
import ViewReferee from '../../Referees/ViewReferee';

function VolunteerRoutes() {
    const refereeFilled = useSelector(state => state.auth.isRefereeFilled);
    return (
        <Switch>
            { !refereeFilled ?
                <CustomRoute exact path="/referee" component={RefereeForm} routes = {ADD_REFEREE}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
                :
                <CustomRoute exact path="/viewReferee" component={ViewReferee} routes = {VOLUNTEER_HOME}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
            }
            <CustomRoute exact path="/volunteerHome" component={VolunteerHomePage} routes = {VOLUNTEER_HOME}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
            {/* <CustomRoute exact path="/viewOpportunity/:id" component={OportunityDetailView}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}
            {/* <CustomRoute exact path="/viewAllOpportunities" component={ViewAllOpportunitiesPage}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}
            {/* <CustomRoute exact path="/viewOpportuniy" component={SignUpPage}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}
            {/* <CustomRoute exact path="/viewAllOpportuniy" component={ViewAllOpportunitiesPage}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}
            {
                // <CustomRoute exact path="/volunteerHome/viewOpportunity" component={ViewVolunteers}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
            }
            <CustomRoute exact path="/opportunities/myOpportunities" component={UserOpportunitiesPage} routes = {USER_OPPORTUNITIES}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
            <CustomRoute exact path="/volunteerAgreement/:opId/:volunteerId/:vioId" component={VolunteerAgreement} routes = {VIO_AGREEMENT}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approve by Admin User " />
            <CustomRoute exact path="/vioFeedback/:opportunityId/:volunteerId/:vioId" component={VioFeedbackPage} routes = {VIO_FEEDBACK}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
            <CustomRoute exact path="/emergencyLoginFeedback/:opportunityId/:volunteerId/:emergencyLoginId" component={volunteerEVOFeedbackPage} routes = {VIO_FEEDBACK}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
            {/* <CustomRoute exact path="/verifyMobile" component={VerifyMobilePage}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}

            <CustomRoute exact path="/volunteerReports" component={VolunteerGeneralReport} routes = {VOLUNTEER_PROFILE}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />

            <CustomRoute exact path="/profile" component={ProfilePage} routes = {VOLUNTEER_PROFILE}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
            {/* <CustomRoute exact path="/vioprofile" component={VIOProfilePage}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}
            {/* <CustomRoute exact path="/admin/profile" component={Profile}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}
            {/* <CustomRoute exact path="/settings" component={SettingsPage}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " /> */}
            <CustomRoute exact path="/org-vios" component={VioList} routes = {VOLUNTEER_ORGANIZATIONS}  message = "You haven't access to this page, You need to Complete Your Profile, Add Referee and Approv by Admin User " />
        </Switch>
    )
}

export default VolunteerRoutes
