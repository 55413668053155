import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import CustomButton from "../../../components/CustomButton";
import DeleteIcon from "@material-ui/icons/Delete";

export default class DeleteAdminPopup extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      selectedDay: undefined,
      open: false,
    };
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOKClick = () => {
    this.props.onDeleteAdmin(this.props.id);
  };

  render() {
    return (
      <Grid container>
        {/* <CustomButton
          label="Terminate"
          color="primary"
          style={{ backgroundColor: "#f52929", color: "white" }}
          onClick={this.handleOpen}
          type="submit"
          variant="contained"
        /> */}
        <IconButton
          style={{ color: "red" }}
          aria-label="upload picture"
          component="span"
          onClick={this.handleOpen}
        >
          <DeleteIcon />
        </IconButton>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="simple-dialog-title">
            Are You Sure to Terminate This Admin?
          </DialogTitle>
          <DialogContent dividers>This action cannot be UNDO!!!</DialogContent>
          <DialogActions>
            <CustomButton label="OK" onClick={this.handleOKClick} />
            {/* <CustomButton label="Clear Al" onClick={this.clearDates} /> */}
            <CustomButton label="Cancel" onClick={this.handleClose} />
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}
