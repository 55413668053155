import {serverHost,serverPort} from "../../../Constant";
import axios from 'axios';

const FORGOT_PASSWORD_URL = "/api/user/forgetPassword"


export async function forgotPasswordRequest(email){
    
    const URL_ENDPOINT = `${serverHost}:${serverPort}${FORGOT_PASSWORD_URL}`;

    try{
        const response = await axios.post(URL_ENDPOINT,
            {
                email
            });
        
        return [response.status, response.data];
    }
    catch(err){
        const response = err.response
        
        return [response?.status || 408, response?.data];
    }
}

export async function forgotPasswordReset(token, password){
    
    const URL_ENDPOINT = `${serverHost}:${serverPort}${FORGOT_PASSWORD_URL}`;

    try{
        const response = await axios.put(URL_ENDPOINT,
            {
                token,
                password1: password,
                password2: password
            });
        
        return [response.status, response.data];
    }
    catch(err) {
        const response = err.response
        
        return [response?.status || 408, response?.data];
    }
}
