import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { serverHost, tokenKey } from "../../Constant";
import SaveIcon from "@material-ui/icons/Save";

function ChangePassword() {
  const { register, errors, handleSubmit, watch } = useForm({});
  const [successAlertShow, setSuccessAlertShow] = useState(false);
  const [failedAlertShow, setFailedAlertShow] = useState(false);
  const newPassword = useRef({});

  const token = localStorage.getItem(tokenKey);

  newPassword.current = watch("newPassword", "");

  const onSubmit = async (data) => {
    const changepwURL = serverHost + "/api/admin/change-password";
    Axios.post(
      changepwURL,
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
      {
        headers: {
          token: token,
        },
      }
    )
      .then((res) => {
        if (res.status == 200) {
          setSuccessAlertShow(true);
          setFailedAlertShow(false);
          
          alert("Password Changed Successfully!");
        } else {
          
          alert("Password Changed Failed!");
        }
      })
      .catch((err) => {
        alert("Password Changed Failed!");
        setFailedAlertShow(true);
        setSuccessAlertShow(false);
        
      });
  };

  return (
    <Paper style={{ padding: '18px 36px', maxWidth: 420, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Grid container justify="center">
        <Grid item xs={12} style={{padding :'20px 0'}}>
          <Typography variant="h5" color="primary" align="center">Change Password</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="oldPassword"
            type="password"
            inputRef={register({
              required: "Current Password is required",
            })}
            fullWidth
            label="Old Password*"
            id="outlined-margin-normal"
            margin="normal"
            variant="outlined"
            helperText={
              <span style={{ color: "red" }}>
                {errors.oldPassword && <p>{errors.oldPassword.message}</p>}
              </span>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="newPassword"
            type="password"
            inputRef={register({
              required: "You must specify a password",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            fullWidth
            label="New Password*"
            id="outlined-margin-normal"
            margin="normal"
            variant="outlined"
            helperText={
              <span style={{ color: "red" }}>
                {errors.newPassword && <p>{errors.newPassword.message}</p>}
              </span>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="password_repeat"
            type="password"
            inputRef={register({
              validate: (value) =>
                value === newPassword.current || "The passwords do not match",
            })}
            fullWidth
            label="Verify New Password*"
            id="outlined-margin-normal"
            margin="normal"
            variant="outlined"
            helperText={
              <span style={{ color: "red" }}>
                {errors.password_repeat && (
                  <p>{errors.password_repeat.message}</p>
                )}
              </span>
            }
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            startIcon={<SaveIcon style={{ color: "white" }} />}
            style={{ width: 180, margin: 20 }}
            variant="contained"
            color="primary"
          >
            Save Changes
          </Button>
        </Grid>
        {/* Change Password Success Alert  */}

        {successAlertShow && (
          <Alert severity="success">Password Changed Successfully!</Alert>
        )}

        {/* Change Password Failed Alert  */}
        {failedAlertShow && (
          <Alert severity="error">Error in changing password!</Alert>
        )}
      </Grid>
    </form>
    </Paper>
  );
}

export default ChangePassword;
