import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { useTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    style={{ zIndex: 1500 }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function LanguageSelector({ className }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t, i18n } = useTranslation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLang = (lang) => {
    i18n.changeLanguage(lang)
    handleClose()
  }

  return (
    <div style={{ margin: 10 }}>
      <Hidden xsDown>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          color="primary"
          variant="outlined"
          onClick={handleClick}
          className={className}
          style={{ width: "100px" }}
        >
          {t('LandingMenu.lang')}
        </Button>
      </Hidden>
      <Hidden smUp>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          color="primary"
          variant="outlined"
          onClick={handleClick}
          className={className}
          style={{ width: "60px" }}
        >
          {t('LandingMenu.lang').substr(0,3)}
        </Button>
      </Hidden>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLang("en")}>
          <ListItemText primary="English" />
        </MenuItem>
        <MenuItem onClick={() => changeLang("si")}>
          <ListItemText primary="සිංහල" />
        </MenuItem>
        <MenuItem onClick={() => changeLang("ta")}>
          <ListItemText primary="தமிழ்" />
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
