import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Divider, makeStyles, Button, ButtonGroup, Paper } from '@material-ui/core';

import * as yup from "yup";

// import style from './VOProcess.styles';
import Typography from '@material-ui/core/Typography';

import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import StopIcon from '@material-ui/icons/Stop';
import { Link } from 'react-router-dom';
import CustomButton from '../CustomButton';
import CustomDialog from '../CustomDialog';
import 'date-fns';
import { useHistory } from "react-router-dom";
import VolunteerTable from './VolunteerTable';
import { blue, green, red, yellow } from '@material-ui/core/colors';
import MessageCard from '../MessageCard';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  chip: {
    padding: 6,
    margin: 6
  }
}))

export default function VolunteerOppertunityHome({ id, onSubmit, fetchDetails, formDisplayData, changeApplicantState, changeApplicantStateByAdmin, changeApplicantStateByVIO, isAdminView, completeVO, deleteVO, disableStateTogle = false }) {

  const classes = useStyles();
  const { t, i18n } = useTranslation()

  let history = useHistory();
  const userId = useSelector(state => state.auth.id)
  const [rowCount, setRowCount] = React.useState([]);


  var sdgMap = formDisplayData.sdg_numbers.reduce((map, obj) => {
    map[obj.id] = obj.sdg_number;
    return map;
  }, {});

  var skillMap = formDisplayData.required_skills.reduce((map, obj) => {
    map[obj.id] = obj.skill;
    return map;
  }, {});

  
  
  

  if (fetchDetails)
    return (
      <React.Fragment>
        <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary" align="center" gutterBottom>
                {fetchDetails.title}
              </Typography>
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: 0 }}>
              {((!fetchDetails.isShared) && (userId === fetchDetails.vioId)) && <Grid item>
                <CustomButton
                  color="secondary"
                  label={t("Common.share")}
                  margin={6}
                  component={Link} to={"/share-opportunity/" + id.toString()}
                />
              </Grid>}
              {(fetchDetails.status == "APPROVED" || fetchDetails.status == "CREATED") && (userId === fetchDetails.vioId) && (
                <Grid item>
                  <CustomButton
                    color="secondary"
                    label={t("Common.edit")}
                    margin={6}
                    component={Link} to={"/vo/" + id.toString()}
                  />
                </Grid>
              )}
              {fetchDetails.status == "APPROVED" && (userId === fetchDetails.vioId) && (
                <Grid item>
                  <CustomButton color="secondary"
                    label={t("Common.complete")}
                    margin={6}
                    onClick={() => completeVO(history)}
                  />
                </Grid>
              )}
              {(fetchDetails.status == "APPROVED" || fetchDetails.status == "CREATED") && (userId === fetchDetails.vioId) && (
                <Grid item>
                  <CustomButton color="secondary"
                    margin={6}
                    onClick={() => deleteVO(history)}
                    label={t("Common.delete")}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {fetchDetails.status == "APPROVED" && (<Chip
                size="large"
                className={classes.chip}
                icon={<DoneIcon />}
                label={t("Common.approved")}
                color="primary"
                style={{ backgroundColor: green[600] }}
              />)}
              {fetchDetails.status == "REJECTED" && (<Chip
                size="large"
                className={classes.chip}
                icon={<CloseIcon />}
                label={t("Common.rejected")}
                color="primary"
                style={{ backgroundColor: red[900] }}
              />)}

              {fetchDetails.status == "CREATED" && (<Chip
                size="large"
                className={classes.chip}
                icon={<HourglassFullIcon />}
                label={t("Common.pending")}
                color="primary"
                style={{ backgroundColor: yellow[900] }}
              />)}

              {/* {fetchDetails.status=="APPROVED" && (<Chip
              size="large"
              className={classes.chip}
              icon={<AutorenewIcon />}
              label="On going"
              color="primary"
              style={{ backgroundColor: blue[500] }}
            />)} */}

              {fetchDetails.status == "COMPLETED" && (<Chip
                size="large"
                className={classes.chip}
                icon={<StopIcon />}
                label={t("Common.completed")}
                color="primary"
                style={{ backgroundColor: red[500] }}
              />)}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom style={{ textAlign: 'justify' }}>
                {i18n.language === "en" && fetchDetails.descriptionEnglish}
                {i18n.language === "si" && fetchDetails.descriptionSinhala}
                {i18n.language === "ta" && fetchDetails.descriptionTamil}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.supervisor")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.supervisorName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.designation")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.designation}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.category")}
              </Typography>
              <Typography variant="body2" >
                {t(`CategoriesVO.${formDisplayData.categories_VO[fetchDetails.category]?.opport}`)}
              </Typography>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" style={{ margin: '12px 0', backgroundColor: '#c0c0c0' }} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary" >
                {t("VolunteerOpportunityPage.volunteetingLocation")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.district")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.district}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.ds")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.divisionalSecretariat}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.gn")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.gnDivision}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("Common.location")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.location}
              </Typography>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" style={{ margin: '12px 0', backgroundColor: '#c0c0c0' }} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary" >
                {t("VolunteerOpportunityPage.other")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.duration")}
              </Typography>
              <Typography variant="body2"  >
                {fetchDetails.startDate} - {fetchDetails.endDate}
              </Typography>
              <Typography variant="body2"  >
                {fetchDetails.days.map(day => t(`Days.${day}`)).join(', ')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.time")}
              </Typography>
              <Typography variant="body2"  >
                {fetchDetails.time.map(time => t(`Times.${time}`)).join(', ')}
              </Typography>
              <Typography variant="body2"  >
                {fetchDetails.workingHourCount} {t("VolunteerOpportunityPage.hoursPerDay")} ({fetchDetails.isFullTime ? t("VolunteerOpportunityPage.fullTime") : t("VolunteerOpportunityPage.partTime")})
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.isTraining")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.isTrainingProvided ? t("Common.yes") : t("Common.no")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.isExpense")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.isExpenseCovered ? t("Common.yes") : t("Common.no")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.requiredSkills")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.requiredSkills.map(skill => t(`Skills.${skillMap[skill]}`)).join(', ')}
              </Typography>
            </Grid><Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.sdgGoals")}
              </Typography>
              <Typography variant="body2" >
                {fetchDetails.sdgNumbers.map(skill => t(`Sdg.${sdgMap[skill].split(":")[0]}`)).join(', ')}
              </Typography>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#c0c0c0' }} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary" >
                {t("Common.volunteers")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.noOfVolunteersRequired")}
              </Typography>
              <Typography variant="body" >
                {fetchDetails.noOfVolunteers}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("VolunteerOpportunityPage.noOfVolunteers")}
              </Typography>
              <Typography variant="body" >
                {rowCount}
              </Typography>
            </Grid>
          </Grid>
          <VolunteerTable
            id={id}
            setRowCount={setRowCount}
            changeApplicantState={changeApplicantState}
            changeApplicantStateByAdmin={changeApplicantStateByAdmin}
            changeApplicantStateByVIO={changeApplicantStateByVIO}
            isAdminView={isAdminView}
            disableStateTogle={disableStateTogle}
          />
        </Paper>
      </React.Fragment >
    )
  return (
    <MessageCard />
  )
}
