import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState, useCallback } from 'react';
import { fetchUserOpportunities } from '../../functions/opportunity/fetchOpportunities'
import OpportunityView from '../../components/OpportunityView';
import { makeStyles } from '@material-ui/core/styles';
import { onClickCard } from '../../functions/opportunity/onClickFunctions'
import { useTranslation } from "react-i18next";
import Error from '../../components/MessageCard'
import LoadingPage from '../../components/LoadingPage';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import SearchBar from '../../components/SearchBar';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8)
  },
}));


function UserOpportunitiesPage() {
  const classes = useStyles();
  // const userId = useSelector(state => state.auth.id);

  const [details, setDetails] = useState({ error: false, items: [] });
  const [isLoading, setIsLoading] = useState(true);
  const { register, control, watch } = useForm();
  const {t} = useTranslation();

  const adjustDetails = (opportunityList) => {
    if (opportunityList && opportunityList.length > 0) {
      opportunityList = opportunityList.map((opportunity) => opportunity["opportunity"])
    }
    return opportunityList;
  }

  useEffect(async () => {
    setIsLoading(true)
    let mounted = true;
    let result = await fetchUserOpportunities(watch("type") === "Pending" ? "PENDING" : "APPROVED", "");

    
    if (mounted) {
      let fetchItems = result.opportunities;
      setDetails({ items: adjustDetails(fetchItems), error: result.error });
      setIsLoading(false)
    }
    
    
    return () => mounted = false;

  }, [watch("type")])


  return (
    <React.Fragment>
      {
        details.error ?
          <Error />
          :
          <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40}}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h5" color="primary">
                {t('Volunteer.My Opportunities')}
              </Typography>
              <SearchBar
                defaultValue=""
                defaultType="Pending"
                register={register}
                control={control}
                value1="Pending"
                value2="Approved"
              />
            </div>
            {!isLoading ?
              <OpportunityView
              items={details.items.filter(item => !watch("search") || item.title.toLowerCase().includes(watch("search").toLowerCase()))}
              onClickCard={onClickCard}
              /> :
              <LoadingPage />
            }
          </Paper>
      }
    </React.Fragment>
  )
}


export default UserOpportunitiesPage
