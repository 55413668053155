import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import EditableText from './EditableText';
import StarFeedback from './StarFeedback';
import CustomButton from '../../components/CustomButton'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { volunteerStarFeedbackComponents,contributionHoursName,recommendedName, satisName } from './Constant'
import { Select, Grid, Typography } from '@material-ui/core';
import ReactHookFormSelect from '../../components/ReactHookFormSelect';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


function VolunteerFeedback({ volunteerName, onSubmitDetails }) {

    const [error, setError] = useState(false)
    const [errMessage, setErrorMessage] = useState("")
    const [result, setResult] = useState({
        [recommendedName]: "No",
        [contributionHoursName]: 0
    });
    const {t} = useTranslation();

    useEffect(() => {


    }, [error])


    const onTarget = (e) => {
        const { name, value } = e.target
        if (name == contributionHoursName) {
            // result[name] = value>=0?value:0
            setResult({ ...result, [name]: value >= 0 ? value : 0 })
        }
        else {
            // result[name] = value
            setResult({ ...result, [name]: value })
        }
        

    }


    const onChange = (name, value) => {
        result[name] = value
    }
    const validate = () => {
        for (let number = 0; number < volunteerStarFeedbackComponents.length; number++) {
            let element = volunteerStarFeedbackComponents[number];
            if (!result[element.name] || (result[element.name] & result[element.name] < 1)) {

                
                return false
            }
        }
        if (!result[recommendedName] || (result[recommendedName] & result[recommendedName] == "")) {

            
            return false
        }
        if (!result[satisName] || (result[satisName] & result[satisName] == "")) {

            
            return false
        }
        if (Number(result[contributionHoursName]) < 0) {
            
            
            return false
        }
        
        return true;

    }
    const onSubmit = () => {
        setError(false);
        setErrorMessage("")
        
        const err = validate()
        
        if (!err) {
            setError(true)
            setErrorMessage("Please Fill the all components")
        }
        else {
            
            onSubmitDetails(result)
        }

    }


    return (
        <React.Fragment>
            <Grid item xs={12} style={{padding: 20}}/>
            {
                volunteerStarFeedbackComponents.map((item, i) => (
                    <React.Fragment key={i.toString()}>
                        <StarFeedback
                            key={i.toString()}
                            name={(item.name)}
                            value={t(`Feedback.${item.title}`)}
                            onChange={onChange}
                        />
                        <div>
                            {
                                //error?errMessage:""
                            }

                        </div>
                    </React.Fragment>
                ))
            }
            <Grid item sx={12} style={{ marginBottom: 24 }}>
                <Typography variant="caption" color="textSecondary">{t('Feedback.ratingAdvice')}</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography variant="body1" component="h2">{t('Feedback.volunteerRecommendation')}</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <RadioGroup aria-label="recommend" name={recommendedName} defaultValue="No" value={result[recommendedName]} onChange={onTarget}>
                    <FormControlLabel value="Yes" control={<Radio />} label={t('Common.yes')} />
                    <FormControlLabel value="No" control={<Radio />} label={t('Common.no')} />
                </RadioGroup>
                <div>
                    {
                        //error?errMessage:""
                    }
                </div>
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography variant="body1" component="h2"> {t('Feedback.areYouSatisVolunteer').replace('%volunteerName%',volunteerName)} </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Select
                    name={satisName}
                    variant="outlined"
                    defaultValue=""
                    fullWidth
                    onChange={onTarget}
                >
                    <MenuItem value="1">{t('Feedback.Dissatisfied')}</MenuItem>
                    <MenuItem value="2">{t('Feedback.Neither Satisfied nor Dissatisfied')}</MenuItem>
                    <MenuItem value="3">{t('Feedback.Satisfied')}</MenuItem>
                    <MenuItem value="4">{t('Feedback.Extremly Satisfied')}</MenuItem>
                </Select>
                {
                    //error?errMessage:""
                }
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography variant="body1" component="h2"> {t('Feedback.numOfHour')} </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <TextField
                    name={contributionHoursName}
                    onChange={onTarget}
                    variant="outlined"
                    fullWidth
                    value={Number(result[contributionHoursName]) > 0 ? Number(result[contributionHoursName]) : 0}
                    type="number"
                // error= {() => (parseInt(result[numOfHour]) >=0 ) }
                // defaultValue = "0"
                />
                {
                    //error?errMessage:""
                }
            </Grid>
            <Grid item xs={12}>
                {
                    error ? errMessage : ""
                }
            </Grid>
            <Grid item xs={12} container direction="column" justify="center" alignItems="center">
                <CustomButton
                    label={t('Common.submit')}
                    color="primary"
                    style={{ width: 180 }}
                    variant="contained"
                    onClick={onSubmit}
                />
            </Grid>
        </React.Fragment>
    )
}

export default VolunteerFeedback
