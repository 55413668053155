import {
	// AUTH_SIGN_IN_BEGIN,
	AUTH_SIGN_IN_SUCCESS,
	AUTH_SIGN_IN_FAILURE,
	// AUTH_SIGN_UP_BEGIN,
	AUTH_SIGN_UP_SUCCESS,
	AUTH_SIGN_UP_FAILURE,
	RECOVER_STORE,
	RESET_TOKEN,
	CLEAR_STORE
} from '../actiontypes';

/**
 * x - CORE ADMIN
 * x - GENERAL ADMIN
 *
 * x - GENERAL VOLUNTEER
 * x - ORGANIZATIONAL_VOLUNTEER
 * x - SCHOOL_VOLUNTEER
 *
 * x - MOBILIZING VIO
 * x - NON MOBILIZING VIO
 * x - MOBILIZING SUB VIO
 * x - NON MOBILIZING SUB VIO
 *
 * x - LOGGED OUT
 *
 *
 * x - ADMIN STATUS(FOR CONVENIENCE)
 *
 * x - VOLUNTEER MOBILE UNVERIFIED STATUS
 * x - VOLUNTEER BIO INCOMPLETE STATUS
 * x - VOLUNTEER COMPLETELY REGISTERED STATUS
 *
 * x - VIO EMAIL VERIFIED STATUS
 * x - VIO PROFILE COMPLETED STATUS
 * x - VI0 APPROVED STATUS
 *
 * x - LOGGED OUT STATUS(FOR CONVENIENCE)
 */


const INITIAL_STATE = {
	id: "",
	email: "",
	name: "",
	type: "",
	access: 0,
	iat: 0,
	registrationNo: "",
	success:true,
	isRefereeFilled: false,
	error:""
}

export default function authReducer(state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case AUTH_SIGN_IN_SUCCESS:
			return { ...state,
				id:payload.id,
				email: payload.email,
				name: payload.name,
				type: payload.type,
				access: payload.access,
				iat: payload.iat,
				isRefereeFilled: payload.isRefereeFilled,
				registrationNo: payload.registrationNo,
				success:true,
				error:"" }
		case RECOVER_STORE:
			return { ...state,
				id:payload.id,
				email: payload.email,
				name: payload.name,
				type: payload.type,
				access: payload.access,
				iat: payload.iat,
				isRefereeFilled: payload.isRefereeFilled,
				registrationNo: payload.registrationNo,
			}
		case RESET_TOKEN:
			return { ...state,
				id:payload.id,
				email: payload.email,
				name: payload.name,
				type: payload.type,
				access: payload.access,
				iat: payload.iat,
				isRefereeFilled: payload.isRefereeFilled,
				registrationNo: payload.registrationNo,
			}
		case AUTH_SIGN_IN_FAILURE:
			return { ...state, error: payload.message, success: false }
		case AUTH_SIGN_UP_SUCCESS:
			return { ...state, success: true, message: payload.message }
		case AUTH_SIGN_UP_FAILURE:
			return { ...state, error: payload.error, success: false }
		case CLEAR_STORE:
			return { ...state,
				id: "",
				email: "",
				name: "",
				type: "",
				access: 0,
				iat: 0,
				registrationNo: "",
				success:true,
				error:""
			 }
		default:
			return state
	}
}
