import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
/**
 * Complete MUI theme of the app
 */
let muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1D4971',
    },
    secondary: {
      main: '#32c3f3',
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.2
    },
    fontFamily: ['Montserrat', 'Open Sans','Oswald','sans-serif']
  },
});
muiTheme = responsiveFontSizes(muiTheme);
/**
 * Complete theme of the app extending MUI Theme
 */
const theme = {
  ...muiTheme,

  // overrides: {
  //   MuiDrawer: {
  //     paper: {
  //       backgroundColor: '#1c2d48'
  //     }
  //   },
  //   MuiButton: {
  //     label: {
  //       textTransform: 'none'
  //     },
  //     contained: {
  //       boxShadow: 'none',
  //       '&:active': {
  //         boxShadow: 'none'
  //       }
  //     }
  //   },
  //   MuiTabs: {
  //     root: {
  //       marginLeft: muiTheme.spacing(1)
  //     },
  //     indicator: {
  //       height: 3,
  //       borderTopLeftRadius: 3,
  //       borderTopRightRadius: 3,
  //       backgroundColor: muiTheme.palette.common.white
  //     }
  //   },
  //   MuiTab: {
  //     root: {
  //       textTransform: 'none',
  //       margin: '0 16px',
  //       minWidth: 0,
  //       padding: 0,
  //       [muiTheme.breakpoints.up('md')]: {
  //         padding: 0,
  //         minWidth: 0
  //       }
  //     }
  //   },
  //   MuiIconButton: {
  //     root: {
  //       padding: muiTheme.spacing(1)
  //     }
  //   },
  //   MuiTooltip: {
  //     tooltip: {
  //       borderRadius: 4
  //     }
  //   },
  //   MuiDivider: {
  //     root: {
  //       backgroundColor: '#404854'
  //     }
  //   },
  //   MuiListItemText: {
  //     primary: {
  //       fontWeight: muiTheme.typography.fontWeightMedium
  //     }
  //   },
  //   MuiListItemIcon: {
  //     root: {
  //       color: 'inherit',
  //       marginRight: 0,
  //       '& svg': {
  //         fontSize: 20
  //       }
  //     }
  //   },
  //   MuiAvatar: {
  //     root: {
  //       width: 32,
  //       height: 32
  //     }
  //   }
  // }
};


export default theme;