import React,{useEffect,useState} from 'react'
import DropdownList from '../../components/DropdownList'
import {dropdownDetails,displayDetails} from './utils/constants'
import ReportItem from './ReportItem'
import {fetchReportbackDetails} from '../../functions/Reports/fetchReportDetails'

function Report() {
    const[items,setItems]= useState({})
    const[selections,set]= useState({})
    const[error,setError]= useState(false)

    useEffect(async() => {

        let mounted = true;
        

        let result = await fetchReportbackDetails(selections);

        

        if (mounted) {
            setItems(result);
            setError(result.error);
            // if(result.error){
            //     setFetchDetails(defaultFetchDetails);
            // }
        }

        
        return () => mounted = false;
        
        
    }, [error])

    return (
        <div>
            <DropdownList
            
            menuItemList = {dropdownDetails}
            
            />
            {displayDetails.map((item,i)=>(

                <ReportItem
                    key = {i.toString()}
                    item = {item}                
                />

            ))}            
        </div>
    )
}

export default Report
