import { serverHost, serverPort, hostPortDivider, tokenKey } from "../../Constant";
import axios from 'axios';
import {
    emgUserFetchEVOFeedbackUrl, volunteerFetchEVOFeedbackUrl
} from '../../containers/Feedback/Constant'

export const fetchEVOEmgUserFeedbackDetails = async (volunteerId, opportunityId, emergencyLoginId) => {

    const fetchFeedbackUrl = serverHost + hostPortDivider + serverPort + emgUserFetchEVOFeedbackUrl

    try {
        const response = await axios.get(fetchFeedbackUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                },
                params: {
                    volunteerId, opportunityId, emergencyLoginId
                }
            });
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }
    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }
};

export const fetchEVOVolunteerFeedbackDetails = async (volunteerId, opportunityId, emergencyLoginId) => {

    const fetchFeedbackUrl = serverHost + hostPortDivider + serverPort + volunteerFetchEVOFeedbackUrl

    try {
        const response = await axios.get(fetchFeedbackUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                },
                params: {
                    volunteerId, opportunityId, emergencyLoginId
                }
            });
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }
    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }
};
