import React, { } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import CustomButton from '../../components/CustomButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';

const weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
]

export default class PopUpCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.clearDates = this.clearDates.bind(this);
        this.clickOkButton = this.clickOkButton.bind(this);
        this.startDate = props.startDate || new Date()
        this.endDate = props.endDate || new Date()
        this.expectedDays = props.expectedDays.map(d => d.toLowerCase())
        // 
        this.state = {
            selectedDays: [],
            open: false,
        };
    }

    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
    }

    handleOpen() {
        this.setState({ open: true });
    };

    handleClose() {
        this.setState({ open: false });
    };

    clearDates() {
        this.setState({ selectedDays: [] })
    }

    clickOkButton() {
        this.props.apply(this.state.selectedDays)
    }

    get __validStartDate() {
        const today = new Date()
        if (this.startDate >= today) return this.startDate
        else return today
    }

    /**
     *
     * @param date {Date}
     * @return {boolean}
     * @private
     */
    __disableDates = (date) => {
        return (
            // (!this.startDate || !this.endDate) ||
            (date < new Date()) ||
            ((date < this.__validStartDate) || (date > this.endDate)) ||
            (!this.expectedDays.includes(weekdays[date.getDay()]))
        )
    }

    render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <CustomButton
                    label="Apply"
                    color="primary"
                    onClick={this.handleOpen}
                    style={{ width: 180 }}
                    type="submit"
                    variant="contained"
                />
                <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title">Select Days</DialogTitle>
                    <DialogContent dividers>
                        <DayPicker
                            selectedDays={this.state.selectedDays}
                            onDayClick={this.handleDayClick}
                            disabledDays={this.__disableDates}
                            fromMonth={this.__validStartDate}
                            initialMonth={this.__validStartDate}
                            toMonth={this.endDate}
                        />
                    </DialogContent>
                    <DialogActions>
                        <CustomButton disabled={this.state.selectedDays.length <= 0} label="OK" onClick={this.clickOkButton} />
                        <CustomButton disabled={this.state.selectedDays.length <= 0} label="Clear Al" onClick={this.clearDates} />
                        <CustomButton label="Cancel" onClick={this.handleClose} />
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}