import axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../Constant";

export const createEmgUser = async (name, email) => {
  const createEmgUserURL =
    serverHost + ":" + serverPort + "/api/admin/emergency-login/invite";
  try {
    const response = await axios.post(
      createEmgUserURL,
      { name, email },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    );
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const fetchEmgUsersList = async () => {
  const fetchEmgUsersListURL =
    serverHost + ":" + serverPort + "/api/admin/emergency-login/list";
  try {
    const response = await axios.get(fetchEmgUsersListURL, {
      headers: {
        token: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const terminateEmgUser = async (id, bool) => {
  const terminateEmgUserURL =
    serverHost + ":" + serverPort + "/api/admin/emergency-login/terminate";
  try {
    const response = await axios.put(
      terminateEmgUserURL,
      {
        id: id,
        terminate: bool,
      },
      {
        headers: {
          token: localStorage.getItem(tokenKey),
        },
      }
    );
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const fetchEmgVolunteersList = async () => {
  const fetchEmgUsersListURL =
    serverHost + ":" + serverPort + "/api/emergency-login/list-volunteers";
  try {
    const response = await axios.get(fetchEmgUsersListURL, {
      headers: {
        token: localStorage.getItem(tokenKey),
      },
    });
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};

export const createEmgOpportunity = async (data, selected) => {
  const createEmgOpportunityURL =
    serverHost + ":" + serverPort + "/api/emergency-login/opportunity/create";
  try {
    const response = await axios.post(
      createEmgOpportunityURL,
      {
        name: data.oppName,
        emailMessage: data.emailMessage,
        smsMessage: data.smsMessage,
        volunteers: selected,
      },
      { headers: { token: localStorage.getItem(tokenKey) } }
    );
    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.message,
      error: true,
    };
    return result;
  }
};
