export const agreementSentence =(volunteer,vio,opportunity, language)=>
{
    if(language === 'en') {
        const hisOrHer = volunteer.title=="Mr"||volunteer.title==="Mr."?"his":"her";
        return (
            <>
            This understanding is hereby made between <b>{volunteer.fullName}</b> bearing NVS Registration No. <b>{volunteer.registrationNo}</b> and
            NIC No. <b>{volunteer.nic}</b> (Volunteer) (hereinafter referred to as "the
            Volunteer") and Volunteer Involving Organization <b>{vio.name}</b> Bearing NVS Registration 
            No. <b>{vio.registrationNo}</b> (hereinafter referred to as "the VIO").
            The Volunteer hereby agrees to donate {hisOrHer} time, effort, and services to the VIO on a
            voluntary basis. The Volunteer understands that no compensation of any kind will be provided in exchange for these services.
            The Volunteer will participate in volunteer work from <b>{opportunity.startDate}</b> to <b>{opportunity.endDate}</b>, working a 
            minimum of <b>{opportunity.workingHourCount}</b> hours.
            </>
            )
    }
    if(language === 'si') {
        const hisOrHer = volunteer.title=="Mr"||volunteer.title==="Mr."? "ඔහුගේ":"ඇයගේ"; 
        return(
        <>
        මෙම අවබෝධය මෙතැන් පටන් <b>{volunteer.fullName}</b> NVS ලියාපදිංචි අංකය <b>{volunteer.registrationNo}</b> සහ 
        ජාතික හැඳුනුම්පත් අංක <b>{volunteer.nic}</b> දරන ස්වෙච්ඡාදායක (මෙතැන් සිට "ස්වෙච්ඡාදායක" ලෙස හඳුන්වනු ලැබේ) 
        සහ NVS ලියාපදිංචි අංකය <b>{vio.registrationNo}</b> දරන ස්වෙච්ඡාදායකයන් සම්බන්ධ වන <b>{vio.name}</b> සංවිධානය (මෙතැන් සිට "VIO" ලෙස හැඳින්වේ) අතර ඇතිවේ.
        ස්වෙච්ඡාදායකයා විසින් {hisOrHer} කාලය, උත්සාහය සහ සේවාවන් VIO වෙත ස්වෙච්ඡා පදනම මත පරිත්‍යාග කිරීමට මෙනයින් එකඟ වේ. 
        ලබා දෙන සේවා සම්බන්ධයෙන් කිසිඳු ආකාරයක වන්දි මුදලක් නොලැබෙන බවට ස්වෙච්ඡාදායකයා වටහා ගෙන ඇත. ස්වෙච්ඡාදායකයා ස්වෙච්ඡා කටයුතු 
        සඳහා <b>{opportunity.startDate}</b> සිට <b>{opportunity.endDate}</b> දක්වා සහභාගී වන අතර, අවම වශයෙන් <b>{opportunity.workingHourCount}</b> පැය ප්‍රමාණයක් 
        එම ක්‍රියාවෙහි යෙදිය යුතු වේ.
        </>
        )
    }
    if(language === 'ta') {
        const hisOrHer = volunteer.title=="Mr"||volunteer.title==="Mr."? "அவனது":"அவளது";
        return(
        <>
        இப் புரிதல் ஒப்பந்தம் <b>{volunteer.fullName}</b> என்ற பின்வரும் NVS பதிவு எண் <b>{volunteer.registrationNo}</b> மற்றும் தேசிய 
        அடையாள அட்டை எண் <b>{volunteer.nic}</b> என்பவற்றின் உரிமையாளருக்கிடையிலும் (தன்னார்வலர் ) (இனி மேல் "the Volunteer" என்று குறிப்பிடப்படுவார் ) 
        மற்றும் தன்னார்வலர் சம்பந்தப்படும் நிறுவனம் <b>{vio.name}</b> NVS பதிவு எண் <b>{vio.registrationNo}</b> (இனி மேல் "the VIO" என்று குறிப்பிடப்படும். 
        நிறுவனத்திற்கு இடையிலும் கைச்சாத்திடப்பட்டுள்ளது இந்த ஒப்பந்தத்தின் அடிப்படையில் தன்னார்வலர் {hisOrHer} நேரம், முயற்சி மற்றும் சேவைகளை VIO வுக்கு
        நன்கொடையாக வழங்க தன்னார்வலர் ஒப்புக்கொள்கிறார். 
        இச்சேவைகளுக்கு ஈடாக எந்தவொரு வகை சன்மானமும் வழங்கப்பட மாட்டாது என்பதை தன்னார்வலர் புரிந்து கொள்ள வேண்டும்
        தன்னார்வலர் இத் <b>{opportunity.startDate}</b> முதல் <b>{opportunity.endDate}</b> வரை தன்னார்வப் பணிகளில் பங்கேற்பார்.  குறைந்தபட்சம் <b>{opportunity.workingHourCount}</b> மணிநேரம் தொண்டு செய்வார்.
        </>
        )
    }
};
export const vioAgreementPoints = ['agreement1','agreement2','agreement3']
export const volunteerAgreementPoints = ['agreement1','agreement2','agreement3']


export const getAgreementUrl = "/api/getAgreement/fetch"
// export const confirmAgreementUrl = "/api/confirmAgreement"
export const volunteerConfirmAgreementUrl = "/api/agreement/confirm/volunteer"
export const vioConfirmAgreementUrl = "/api/agreement/confirm/vio"
export const volunteerFetchAgreementUrl = "/api/agreement/fetch/volunteer"
export const vioFetchAgreementUrl = "/api/agreement/fetch/vio"

export const defaultDetails = {
    vio:{
        regNum:"111",
        id:"111222",
        name:"Organization Name"
    },
    volunteer:{
        fullName:"A.M Sasindu Dilshara Alahakoon",
        NIC:"973531322V",
        id:22233,
        fromDate:"1997/12/1",
        toDate:"2020/01/30",
        title:"Mrs",
        alreadyAgreed:false
    },
    opportunity:{
        contentTopic:"Test opportunity topic",
        duration:"7 hours per day",
        location:"Colombo 10",
        fromDate:"2021/12/13",
        toDate:"2022/12/11"
    }
};