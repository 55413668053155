import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@material-ui/core/Typography';
import ReactHookFormSelect from '../ReactHookFormSelect';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CreateIcon from '@material-ui/icons/Create';
import 'date-fns';
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import FormHelperText from '@material-ui/core/FormHelperText';
import CustomLocationSelect from '../CustomLocationSelect';
import ReactHookFormMultipleSelect from '../ReactHookFormMultipleSelect';
import ReactHookFormDatePicker from '../ReactHookFormDatePicker';
import CustomButton from '../CustomButton';
import ReactHookFormCheckbox from '../ReactHookFormCheckbox';
import { schema } from './EVOProcessValidation';
import { editFormat, editFormat2 } from '../../functions/common/dataFetchFormatting';
import { editSubmitFormat2 } from '../../functions/common/dataSubmitFormatting';
import { useTranslation } from 'react-i18next';

export default function EVOProcess({ onSubmit, isEdit = false, fetchDetails = {}, formDisplayData }) {

  if (isEdit) {
    fetchDetails = {
      ...fetchDetails,
      sdgNumbers: editFormat(formDisplayData.sdg_numbers, fetchDetails.sdgNumbers, "sdg_number"),
      coreFunctions: editFormat(formDisplayData.categories_VO, fetchDetails.coreFunctions, "opport"),
    }
  }

  const { register, control, errors, watch, handleSubmit, setValue } = useForm(
    {
      resolver: yupResolver(schema),
      defaultValues: fetchDetails,
      mode: "onChange",
    }
  );

  let history = useHistory();

  const onSubmitOverwrite = (data) => {

    onSubmit({
      ...data,
      sdgNumbers: editSubmitFormat2(data.sdgNumbers, formDisplayData.sdg_numbers),
      coreFunctions: editSubmitFormat2(data.coreFunctions, formDisplayData.categories_VO),
      startDate: data.startDate instanceof Date ? data.startDate.toISOString().split('T')[0] : data.startDate,
      endDate: data.endDate instanceof Date ? data.endDate.toISOString().split('T')[0] : data.endDate,

      history: history
    })
  }
  const { t } = useTranslation()


  return (
    <form onSubmit={handleSubmit(onSubmitOverwrite)} >
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} style={{ padding: '20px 0' }}>
          <Typography variant="h5" color="primary">
            {/* {isEdit ? t('VOProcess.editTitle') : t('VOProcess.createTitle')} */}
            {t("EVO.createNewEVO")}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            inputRef={register}
            error={errors.title}
            helperText={errors.title?.message}
            name="title"
            variant="outlined"
            fullWidth
            label={t('VOProcess.title')}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ReactHookFormSelect
            label={t('Common.category')}
            name="category"
            control={control}
            variant="outlined"
            fullWidth
            defaultValue={""}
            error={errors.category}
            helperText={errors.category?.message}
          >
            {(formDisplayData.areas_VO && (formDisplayData.areas_VO.map(({ id, area }) => {
              return (
                <MenuItem key={id} value={id.toString()}>{t(`Categories.${area}`)}</MenuItem>
              );
            })))}
          </ReactHookFormSelect>
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            inputRef={register}
            onChange={(e) => { setValue('noOfVolunteers', Number(String(e.target.value).replace(/[^0-9]/g, ''))) }}
            error={errors.noOfVolunteers}
            helperText={errors.noOfVolunteers?.message}
            type="number"
            min='0'
            variant="outlined"
            label={t('VOProcess.requiredNumber')}
            name="noOfVolunteers"
            defaultValue={1}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} >
          <Typography variant="subtitle2" component="h2">{t('VOProcess.coreFunctions')}</Typography>
          {errors.coreFunctions && <FormHelperText error>{errors.coreFunctions?.message}</FormHelperText>}
        </Grid>
        <Grid container item xs={12} style={{ marginBottom: 10 }}>
          {formDisplayData.categories_VO && formDisplayData.categories_VO.map(({ id, opport }) =>
            <Grid item sm={6} xs={12} key={id}>
              <ReactHookFormCheckbox
                name="coreFunctions"
                value={opport}
                control={control}
                label={t(`CategoriesVO.${opport}`)}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} >
          <Typography variant="subtitle2" component="h2">{t('VOProcess.supervisor')}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            inputRef={register}
            error={errors.supervisorName}
            helperText={errors.supervisorName?.message}
            name="supervisorName"
            variant="outlined"
            fullWidth
            label={t('Common.name')}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            inputRef={register}
            error={errors.supervisorLandline}
            helperText={errors.supervisorLandline?.message}
            variant="outlined"
            fullWidth
            label={t('Common.landline')}
            name="supervisorLandline"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            inputRef={register}
            error={errors.supervisorMobile}
            helperText={errors.supervisorMobile?.message}
            variant="outlined"
            fullWidth
            label={t('Common.mobile')}
            name="supervisorMobile"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <TextField
            inputRef={register}
            error={errors.supervisorEmail}
            helperText={errors.supervisorEmail?.message}
            variant="outlined"
            fullWidth
            label={t('Common.email')}
            name="supervisorEmail"
          />
        </Grid>
        <Grid item xs={12} >
          <Typography variant="subtitle2" component="h2">{t('VOProcess.SdgNumbers')}</Typography>
          {errors.sdgNumbers && <FormHelperText error>{errors.sdgNumbers?.message}</FormHelperText>}
        </Grid>
        <Grid container item xs={12} style={{ marginBottom: 10 }}>
          {formDisplayData.sdg_numbers.map(({ id, sdg_number }) =>
            <Grid item sm={6} xs={12} key={sdg_number}>
              <ReactHookFormCheckbox
                name="sdgNumbers"
                value={sdg_number}
                label={t(`Sdg.${sdg_number.split(":")[0]}`)}
                control={control}
                defaultValue={false}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} >
          <Typography variant="subtitle2" component="h2">{t('VOProcess.description')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.descriptionSinhala}
            helperText={errors.descriptionSinhala?.message}
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            label={t('VOProcess.sinhala')}
            name="descriptionSinhala"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.descriptionEnglish}
            helperText={errors.descriptionEnglish?.message}
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            label={t('VOProcess.english')}
            name="descriptionEnglish"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.descriptionTamil}
            helperText={errors.descriptionTamil?.message}
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            label={t('VOProcess.tamil')}
            name="descriptionTamil"
          />
        </Grid>
        <Grid item xs={12} >
          <Typography variant="subtitle2" component="h2">{t('VOProcess.SmsDescription')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.descriptionSMSSinhala}
            helperText={errors.descriptionSMSSinhala?.message}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            label={t('VOProcess.sinhala')}
            name="descriptionSMSSinhala"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.descriptionSMSEnglish}
            helperText={errors.descriptionSMSEnglish?.message}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            label={t('VOProcess.english')}
            name="descriptionSMSEnglish"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            error={errors.descriptionSMSTamil}
            helperText={errors.descriptionSMSTamil?.message}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            label={t('VOProcess.tamil')}
            name="descriptionSMSTamil"
          />
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} >
          <Typography variant="subtitle2" component="h2">{t('VOProcess.location')}</Typography>
        </Grid>
        <CustomLocationSelect
          control={control}
          errors={errors}
          watch={watch}
        />
        <Grid item lg={3} sm={6} xs={12}>
          <TextField
            inputRef={register}
            error={errors.location}
            helperText={errors.location?.message}
            fullWidth
            variant="outlined"
            label={t('Common.location')}
            name="location"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReactHookFormDatePicker name="startDate" label={t('VOProcess.from')} control={control} error={errors.startDate}
            helperText={errors.startDate?.message} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReactHookFormDatePicker name="endDate" label={t('VOProcess.to')} control={control} error={errors.endDate}
            helperText={errors.endDate?.message} />
        </Grid>
        <Grid item xs={6} direction="column" justify="center" alignItems="center">
          <CustomButton
            label={isEdit ? t('Common.save') : t('Common.create')}
            color="primary"
            type="submit"
            variant="contained"
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
}
