import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import PieChart from "../../components/report/PieChart";
import BarChart from "../../components/report/BarChart";
import { fetchVolunteerReports } from "../../functions/Reports/fetchVolunteerReports";
import { changeVolunteerGeneralReport } from "./utils/restructure";
import SimpleTabs from "../../components/report/DateArea";
import ReactToPrint from "react-to-print";
import asset7 from "../../images/Asset7.png";
import { useSelector } from "react-redux";
import PDFHeaderImage from "../../components/report/PDFHeaderImage";
import PDFFooter from "../../components/report/PDFFooter";
import LoadingPage from '../../components/LoadingPage';

const ref = React.createRef();

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  table: {
    minWidth: 250,
  },
  pieChart: {
    marginLeft: "50px",
    // marginRight: "auto",
  },
}));

export default function VlunteerGeneralReports(props) {
  const [reportType, setReportType] = useState("DAILY");
  const [reportBody, setReportBody] = useState(
    [new Date().toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)]
  );
  const componentRef = useRef();
  const [reportData, setReportData] = useState(null);
  const volunteerName = useSelector(state => state.auth.name)
  const volunteerRegistrationNo = useSelector(state => state.auth.registrationNo)

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchVolunteerReports(reportType, reportBody);
      if (data.error) {
        setReportData(null)
      }
      else {
        setReportData(changeVolunteerGeneralReport(data.item))
      }
    };
    fetchData();
  }, [reportType, reportBody]);
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const classes = useStyles();

  return (
    <Paper
      style={{
        padding: "18px 36px",
        maxWidth: 800,
        margin: "auto",
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <form action="" onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} style={{ padding: "20px 0" }}>
            <Typography variant="h5" align="center" color="primary">
              {t('Reports.General Report')}
            </Typography>
          </Grid>
          <Grid item xs={12} justify="center">
            <SimpleTabs
              reportType={reportType}
              reportBody={reportBody}
              setReportType={setReportType}
              setReportBody={setReportBody}
              endDateAvailable={true}
            />
          </Grid>

          {!reportData ? (
            <Grid
              item
              xs={12}
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <LoadingPage />
            </Grid>
          ) : (
              <>
                <Grid item xs={12} ref={componentRef}>
                  <PDFHeaderImage />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {/* Registered volunteers: */}
                            <Typography
                              variant="h5"
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              {t('Reports.General Summary')}
                            </Typography>
                          </TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {/* Registered volunteers: */}
                            <Typography
                              variant="h6"
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              {t('Reports.General Summary')}: {(`Reports.${reportType}`)}
                            </Typography>
                            {reportType == "DAILY" && <Typography
                              variant="h6"
                              align="right"
                            // style={{ fontWeight: "bold" }}
                            >
                              {t('Reports.From')}: {reportBody[0]} {t('Reports.To')}: {reportBody[1]}
                            </Typography>}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t('Admin.volunteerName')}: {volunteerName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t('EVO.registrationNo')} : {volunteerRegistrationNo}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t('Reports.Volunteer opportunities completed')}:
                        </TableCell>
                          <TableCell align="left">
                            {reportData.completed}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t('Reports.Volunteer hours contributed')}:
                        </TableCell>
                          <TableCell align="left">
                            {reportData.volunteerHours.total}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {reportData.volunteerHours.sectors.length >
                              1 ? (
                                <PieChart
                                  title={t('Reports.By Sectors')}
                                  data={reportData.volunteerHours.sectors}
                                />
                              ) : (
                                t('Reports.By Nationality') + ':' + t('Reports.No data')
                              )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {reportData.volunteerHours.sdgs.length > 1 ? (
                              <BarChart
                                title={t('Reports.By SDGs')}
                                hAxis={t('Reports.Volunteering Hours')}
                                vAxis={t('Reports.SDG')}
                                data={reportData.volunteerHours.sdgs}
                              />
                            ) : (
                                t('Reports.By SDGs') + ':' + t('Reports.No data')
                              )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {reportData.volunteerHours.coreFunctions.length > 1 ? (
                              <BarChart
                                title={t('Reports.By Core Functions')}
                                hAxis={t('Reports.Volunteering Hours')}
                                vAxis={t('Reports.Core Function')}
                                data={reportData.volunteerHours.coreFunctions}
                              />
                            ) : (
                                t('Reports.By Core Functions') + ':' + t('Reports.No data')
                              )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t('Reports.Pending Assignments')}:
                        </TableCell>
                          <TableCell align="left">
                            {reportData.pending}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <PDFFooter />
                </Grid>

                <ReactToPrint
                  trigger={() => (
                    <Button variant="contained" color="primary">
                      {t('Reports.Download as PDF')}
                    </Button>
                  )}
                  content={() => componentRef.current}
                  onBeforePrint={() => (document.title = "report")}
                  onAfterPrint={() =>
                    (document.title = t('Reports.The National Volunteering Platform'))
                  }
                />
              </>
            )}
        </Grid>
      </form>
      <br />
    </Paper>
  );
}