import {serverHost,serverPort} from "../../Constant";
import axios from 'axios';
import {getReport, getOnlineReport} from '../../containers/Reports/utils/constants';

export const dailyReg = async (type, reportType, reportBody) => {
    let report;
    if(type === "REGULAR"){
        report = getReport[reportType];
    } else {
        report = getOnlineReport[reportType];
    }

    if(!report) return null;
    
    var body = {};
    if(typeof(reportBody) === 'object') {
        reportBody.forEach((element, ind) => {
            body[report.BKEY[ind]] = element
        });
    } else {
        body[report.BKEY] = reportBody;
    }
    const fetchUrl = serverHost+":"+serverPort+report.URL;
    
    try {
        const response = await axios.post(fetchUrl, body);
        
        return response.data;
    } catch (error) {
        
        return null;
    }
}
