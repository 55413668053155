import {
  TableCell,
  TableRow,
  Checkbox,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Route } from "react-router-dom";

function EmgVolunteerRow({ labelId, row, isItemSelected, handleClick }) {
  return (
    <Route
      render={({ history }) => (
        <TableRow
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.name}
          selected={isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" padding="none">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item style={{ marginLeft: 16 }}>
                {row.name}
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                {row.status == "Verified" && (
                  <Tooltip title="Verified" placement="top">
                    <VerifiedUserIcon fontSize="small" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>{row.contributeDistrict}</TableCell>
          <TableCell>{row.contributeDivisionalSecretariat}</TableCell>
          <TableCell>{row.contributegnDivision}</TableCell>
          <TableCell>
            <Tooltip title="Show More" placement="top">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  history.push("/volunteer/" + row.id.toString());
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
    />
  );
}

export default EmgVolunteerRow;
