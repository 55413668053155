import React, { useState, useEffect } from 'react'
import { fetchSubvio } from '../../functions/subvio/fetchSubvio';
import ViewSubvioDetails from './SubContainers/ViewSubvioDetails'
import VolunteerSection from './SubContainers/VolunteerSection';
import { addVolunteer, removeVolunteer } from '../../functions/subvio/fetchSubvio'
import { Divider, Grid, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import IconTextField from '../../components/IconTextField';
import SearchIcon from '@material-ui/icons/Search';
import CustomButton from '../../components/CustomButton';
import VolunteerSubVIOTable from '../../components/VIO/VolunteerSubVIOTable';
import { changeSubVioFunc, getOrgVolunteers } from '../../functions/subvio/subVioManagement';
import { getSubVioPublicProfile } from '../../functions/subvio/fetchSubVioPublicProfile';
import MessageCard from '../../components/MessageCard';
import LoadingPage from '../../components/LoadingPage';
import { useTranslation } from 'react-i18next';

function ViewSubvio({ match }) {

    const pageSize = 10;
    const [item, setItem] = useState();
    const [error, setError] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [volunteerFetchList, setVolunteerFetchList] = useState([]);
    const [volunteerList, setVolunteerList] = useState([]);
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(Math.ceil(volunteerList.length / pageSize));
    const {t} = useTranslation()

    const subvioId = match.params.subvioId;

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const updateVolunteerList = (volunteerId, assiged) => {
        var newArr = [...volunteerList]
        for (var i = 0; i < newArr.length; i += 1) {
            if (newArr[i].volunteerId == volunteerId) {
                newArr[i].assigned = assiged
                break
            }
        }
        setVolunteerList(newArr)
    }

    useEffect(async () => {
        let mounted = true;

        let result = await getOrgVolunteers(subvioId);

        if (mounted) {
            if (result.error) {
                setError(true)
            }
            else {
                setError(false)
                setVolunteerFetchList(result.item)
                setVolunteerList(result.item)
                setPageCount(Math.ceil(result.item.length / pageSize))
                setPage(1)
            }
        }

        let itemResult = await getSubVioPublicProfile(subvioId);

        if (mounted) {
            if (itemResult.error) {

            }
            else {
                setItem(itemResult.item)
            }
        }
        return () => mounted = false;
    }, [])

    const onType = (e) => {
        setSearchValue(e.target.value);
        setVolunteerList(volunteerFetchList.filter(item => !searchValue || item.volunteerFName.toLowerCase().includes(searchValue.toLowerCase())));
    }

    const changeSubVio = async (volunteerId, assigned, setError) => {
        let result = await changeSubVioFunc(volunteerId, subvioId, assigned);
        if (result.error) {
            setError(true)
        }
        else {
            setError(false)
            updateVolunteerList(volunteerId, assigned)
        }
    }

    return (
        item ?
            !error ?
                <Paper  style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
                    <ViewSubvioDetails
                        details={item}
                    />
                    <Divider style={{ margin: '20px 0', backgroundColor: '#c0c0c0' }} />
                    {/* <VolunteerSection
                        details={item}
                        onRegister={onRegister}
                        onRemove={onRemove}
                        onType={onType}
                    /> */}
                    <Grid item xs={12} container direction="row" justify="flex-end" style={{ marginBottom: 16 }}>
                        <Grid item>
                            <IconTextField
                                onChange={onType}
                                name="search"
                                placeholder={t('ViewSubvio.searchHere')}
                                defaultValue={""}
                                icon={<SearchIcon />}
                            />
                        </Grid>
                    </Grid>
                    {/* <form onSubmit={handleSubmit(handleVolunteerSearch)} >
                        <Grid item xs={12} container direction="row" justify="flex-end">
                            <Grid item style={{ padding: '0 6px' }}>
                                <IconTextField
                                    name="search"
                                    inputRef={register}
                                    placeholder="Type here…"
                                    defaultValue={""}
                                />
                            </Grid>
                            <Grid item>
                                <CustomButton label="search" type="submit" margin="12px 0" variant="contained" style={{ padding: '15px 20px' }} />
                            </Grid>
                        </Grid>
                    </form> */}
                    <Grid>
                        <VolunteerSubVIOTable
                            rows={volunteerList.slice((page - 1) * pageSize, page * pageSize)}
                            changeSubVio={changeSubVio} count={pageCount}
                            page={page}
                            onChange={handlePageChange}
                            />
                    </Grid>
                </Paper>
                : <MessageCard/>
            : <LoadingPage/>
    )
}

export default ViewSubvio
