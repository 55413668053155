import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

function SubVioElement({ subvio, onDelete, history }) {
    return (
        <Chip
            style={{ margin: 6, padding: 2 }}
            size="medium"
            avatar={<Avatar>{subvio.subvioName.substr(0, 1).toUpperCase()}</Avatar>}
            color="primary"
            label={subvio.subvioName}
            onClick={() => history.push(`/viewSubVio/${subvio.subvioId}`)}
            onDelete={() => { onDelete(subvio.subvioId) }}
        />
    )
}

export default SubVioElement
