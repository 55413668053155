import {makeStyles} from "@material-ui/core/styles";
import landingImage from "../../images/landing-image-wide.webp";


export default makeStyles(() => ({
    root: {
        backgroundColor: 'white',
        padding: '0px',
        width: '100%',
        height: '100%',
    },
    homeSection: {
        width: '100%',
        height: '70vh',
    },
    mockBg: {
        backgroundImage: `url(${landingImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        width: '100%',
        height: '90vh',
        top: '5%', 
        right: 10,
        zIndex: 1,
        position: 'absolute'
    },
    aboutUsSection: {
        width: '100%',
        padding: 48,
    },
    involvementSection: {
        width: '100%',
        padding: '48px 50px 0'
    },
    statisticsSection: {
        width: '100%',
        padding: 48
    },
    sdgSection: {
        width: '100%',
        padding: 48
    },
    volunteerSection: {
        width: '100%',
        padding: 48
    },
    footerSection: {
        width: '100%',
    },
    homeHeading: {
        fontWeight: "600",
        lineHeight: '100%',
        letterSpacing: 0,
        fontSize: 'max(3.7vw, 25pt)',
        position: "absolute",
        top: 0,
    },
    homeParagraph: {
        lineHeight: '100%',
        fontWeight: "500",

    },
    paper: {
        padding: '10%',
        margin: '10px',
        background: "#e1ebff",
    },
    aboutpaper: {
        padding: '5% 10%',
        background: "#e1ebff",
    },
    partnershipSection: {
        width: '100%',
        padding: '48px 50px 60px'
    },
    fadeTransition: {
        transition: "opacity 400ms ease-in"
    },
    hide: {
        opacity: 0,
    },
    show: {
        opacity: 1
    }
}));
