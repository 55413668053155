import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import MessageCard from '../../components/MessageCard';
import { tokenKey } from '../../Constant';
import { getRefereeData } from '../../functions/Referee/apiCalls';

function ViewReferee() {

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [referee, setReferees] = useState({});
  const [refresh, setReferesh] = useState({});

  const onReferesh = () => {
    setReferesh(!refresh)
  }

  useEffect(async () => {
    const setData = async () => {
      const result = await getRefereeData();
      if (result.error) {
        setError(result.error);
        setErrMessage(result.message);
      }
      else {
        setError(result.error);
        setReferees(result.referee);
        if (result.referee.token) {
          localStorage.setItem(tokenKey, result.referee.token)
        }
        
      }
    }
    await setData();
  }, [error, refresh])
  const {t} = useTranslation()
  return (
    !error ?
      referee.refereeId ?
        <Paper style={{ padding: '18px 36px', marginTop: 40, marginBottom: 40 }}>
          <Grid container spacing={3}>
              <Grid
                container
                spacing={2}
                style={{padding: 16}}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Typography variant="h5" color="primary">
                {t('Refree.title')}
          </Typography>
            </Grid>
            <Grid item xs={4}><Typography variant="subtitle2">{t('Common.name')}</Typography></Grid>
            <Grid item xs={8}><Typography variant="body2">{referee.name}</Typography></Grid>
            <Grid item xs={4}><Typography variant="subtitle2">{t('Common.address')}</Typography></Grid>
            <Grid item xs={8}><Typography variant="body2">{referee.address}</Typography></Grid>
            <Grid item xs={4}><Typography variant="subtitle2">{t('Common.email')}</Typography></Grid>
            <Grid item xs={8}><Typography variant="body2">{referee.email}</Typography></Grid>
            <Grid item xs={4}><Typography variant="subtitle2">{t('Common.mobile')}</Typography></Grid>
            <Grid item xs={8}><Typography variant="body2">{referee.phone}</Typography></Grid>
            <Grid item xs={4}><Typography variant="subtitle2">{t('EVO.status')}</Typography></Grid>
            <Grid item xs={8}><Typography variant="body2">{referee.status}</Typography></Grid>
            <CustomButton label={t('Refree.refresh')} onClick={onReferesh} />
            <CustomButton label={t('LandingMenu.home')} component={Link} to="/volunteerHome" margin={'12px 0'}/>
          </Grid>
        </Paper>
        :
        ""
      :
      <MessageCard message={errMessage} />
  )
}

export default ViewReferee
