
import {serverHost,serverPort,tokenKey} from "../Constant"
import axios from 'axios';

/**
* PARAMETER RESOLVERS ---------------------------------------------------------------------------------
*/

/**
*   Returns the base url of backent api
*/
const resolve_baseURL = () => `${serverHost}:${serverPort}` 


/**
*   Returns the access token
*   which will be attached to every request
*/
const resolve_accessToken = () => localStorage.getItem(tokenKey)

/**
* CONFIGURE AXIOS CLIENT ---------------------------------------------------------------------------------
*/
export const axiosClient = axios.create({
    baseURL: resolve_baseURL()
})


axiosClient.interceptors.request.use(function (config) {
    config.headers.token = resolve_accessToken()
    return config;
});


/**
 * Axios Response Resolver
 * will do error handling part
 * @returns [number, {*}]
*           status_code will be http response codes
 */
export async function resolver(axiosResponse) {
    try {
        const response = await axiosResponse
        return [response.status, response.data]
    } catch (e) {
        const response = e.response
        return [response?.status || 408, response?.data || 'Connection error']
    }
}

