import {
  Button,
  IconButton,
  makeStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent, DialogActions, Slider
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import React, {useState} from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import AvatarEditor from 'react-avatar-editor';
import {uploadProfilePicture} from "../../functions/ProfilePicture/uploadProfilePicture";
import {updateProfilePicture} from "../../functions/ProfilePicture/uploadProfilePicture";


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20
  },
  input: {
    display: "none",
  },
  button: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: 6,
  }
}));

export default function RoundImage({ url, fetchDetails, changable = true, size=200, getImageBack, isProfilePicUpdate=true }) {
  const classes = useStyles();
  // const [image, setImage] = useState(fetchDetails.imageURL || url);
  const [image, setImage] = useState(url);
  const [inputImage, setInputImage] = useState();
  const [imageScale, setImageScale] = useState(1);
  const [profilePictureDialog, setProfilePictureDialog] = useState(false);
  const onClickHandler = async() => {
    try {
      const formData = new FormData()
      formData.append('images',inputImage);
      const res = await uploadProfilePicture(formData);
      if (res.error === false){
        
        if(isProfilePicUpdate){
          const res2 = await updateProfilePicture(res.item[0].url);
        }
        else{
          getImageBack(res.item[0].url)
        }        
        setImage(res.item[0].url)
        
      }else {
       
      }
      setProfilePictureDialog(false);
    } catch (e) {
      
    }
  };
  return (
    <React.Fragment>
      <div className={classes.root} style={{width: size}}>
        <img
          src={image}
          style={{ width: size, height: size, borderRadius: "50%" }}
          alt="Profile Picture"
        />
        {changable && (
          <>
            <input
              accept="image/*"
              className={classes.input}
              id="icon-button-file"
              type="file"
            />
            <IconButton
              color="secondary"
              onClick={() => setProfilePictureDialog(true)}
              className={classes.button}
            >
              <PhotoCamera />
            </IconButton>
            <Dialog open={profilePictureDialog} onClose={() => setProfilePictureDialog(false)}>
              <DialogTitle>Update Profile Picture</DialogTitle>
              <DialogContent>
                <DialogContentText>Select and adjust your profile picture</DialogContentText>
                <div>
                  <Card elevation={3} style={{ marginTop: '20px' }}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                          {inputImage && (
                              <AvatarEditor
                                  image={inputImage}
                                  width={250}
                                  height={250}
                                  border={50}
                                  borderRadius={200}
                                  color={[255, 255, 255, 0.6]} // RGBA
                                  scale={imageScale}
                                  rotate={0}
                              />
                          )}
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            style={{ display: 'flex', justifyContent: 'center', margin: '20px auto 0' }}
                        >
                          {inputImage &&
                          <Slider
                            value={imageScale}
                            step={0.1}
                            min={1}
                            max={1.9}
                            onChange={(e, value) => setImageScale(value)}
                            aria-labelledby="continuous-slider"
                          />}
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                              accept="image/*"
                              className={classes.input}
                              id="contained-button-file"
                              multiple
                              name="profilePicture"
                              type="file"
                              onChange={(e) => setInputImage(e.target.files[0])}
                          />
                          <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                              Upload
                            </Button>
                          </label>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  {/*{inputList.length - 1 === i && <Button size="small" onClick={handleAddClick} style={{float: "right", margin: "10px"}}><AddCircleOutlineIcon color="secondary" fontSize='large' /></Button>}*/}
                </div>
              </DialogContent>
              <DialogActions style={{ display: 'block' }}>
                <Button
                    onClick={() => setProfilePictureDialog(false)}
                    color="primary"
                    style={{ marginLeft: '10px' }}
                >
                  Cancel
                </Button>
                <Button
                    type="submit"
                    onClick={onClickHandler}
                    color="primary"
                    style={{ marginRight: '10px', float: 'right' }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
