import React from 'react';
import Grid from '@material-ui/core/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@material-ui/core/Typography';
import 'date-fns';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import CustomButton from '../../CustomButton';
import RoundImage from "../../../containers/Admin/RoundImage";
import PartnerListItem from './PartnerListItem';
import { useTranslation } from 'react-i18next';

export default function Partner({ fetchDetails, deletePartnerFunc }) {

    let history = useHistory();
    const { t } = useTranslation()

    return (
        <div>
            <Grid container justify="space-between">
                <Grid item>
                    <Typography variant="h5" color="primary">
                        {t('Admin.partner')}
                    </Typography>
                </Grid>
                <Grid item>
                    <CustomButton
                        color="primary"
                        label={t('Admin.addPartner')}
                        margin={6}
                        component={Link} to={"/admin/addPartner"}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: '20px 0' }}>
                <Grid container item xs={12} spacing={2} style={{
                    // border: '1px solid #c0c0c0',
                    padding: 16,
                    borderRadius: 4,
                    backgroundColor: '#e1ebff',
                    margin: 0
                }}>
                    {
                        fetchDetails ? fetchDetails.map((partnerRow) => (
                            <PartnerListItem
                                key={Math.random()}
                                partnerRow={partnerRow}
                                deletePartnerFunc={deletePartnerFunc}
                            />
                        )) : ""
                    }
                    {fetchDetails.length == 0 && <Typography>{t('Common.noResults')}</Typography>}
                </Grid>
            </Grid>
        </div>
    );
}
