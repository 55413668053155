import React from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import VOProcess from '../../components/VIO/VOProcess';
import { submitUpdateVOFunc } from '../../functions/VIO/VolunteerOppertunity/submitUpdateVO';
import { fetchVolunteerOpportunity } from '../../functions/VIO/VolunteerOppertunityHome/fetchVolunteerOppertunity';
import { fetchVOFormDataFunc } from '../../functions/VIO/VolunteerOppertunity/fetchVOFormData';
import MessageCard from "../../components/MessageCard";
import { Paper } from "@material-ui/core";

export default function VO({ match }) {

  const id = match.params.id;
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const [fetchErrorFormData, setFetchErrorFormData] = React.useState(false);
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {

    let mounted = true;

    let resultFormData = await fetchVOFormDataFunc();

    if (mounted) {
      if (resultFormData.error) {
        setFetchErrorFormData(true);
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFormDisplayData(resultFormData.item);
        setFetchErrorFormData(false);
      }
    }

    let result = await fetchVolunteerOpportunity(id);

    if (mounted) {
      if (result.error) {
        setError(true);
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFetchDetails({ ...result.item });
        setError(false);
      }
    }

    return () => mounted = false;
  }, [])

  const updateVOFunc = async (voDetails) => {

    var formData = { ...voDetails }
    delete formData.history

    let result = await submitUpdateVOFunc(id, formData);

    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Updated!")
      setPushError(!pushError)
    }
  }
  return (
    <React.Fragment>
      {(fetchDetails && formDisplayData) ?
        (!error && !fetchErrorFormData) ?
          <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40,  marginBottom: 40 }}>
            <VOProcess
              onSubmit={updateVOFunc}
              fetchDetails={fetchDetails}
              isEdit={true}
              formDisplayData={formDisplayData}
            />
          </Paper>
          : <MessageCard />
        : ""
      }
    </React.Fragment>
  );
}