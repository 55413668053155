import axios from 'axios';
import { allOpportunitiesFetchUrl, homeOpportunitiesFetchUrl, userOpportunitiesFetchUrl, viewOpportunityViewFetchUrl, applyURL } from "../../containers/HomePage/Constants";
import { serverHost, serverPort, hostPortDivider, tokenKey } from "../../Constant";


export const fetchOpportunities = async (type = "Online", searchValue = "") => {
    
    const fetchUrl = serverHost + hostPortDivider + serverPort + allOpportunitiesFetchUrl;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                },
                params: {
                    searchValue, type: type.toUpperCase()
                }
            });
        
        if (response.status == 200) {
            const opItems = type.toUpperCase() == "ONLINE" ? {
                onlineItems: response.data,
                error: false
            }
                :
                type.toUpperCase() == "REGULAR" ? {
                    regularItems: response.data,
                    error: false
                }
                    :
                    {
                        error: true
                    }
            const result = opItems
            
            return result;
        }
        else {
            
            const result = {
                message: response.message,
                error: true
            }
            
            return result;
        }
    }

    catch (err) {
        
        const result = {
            message: err.message,
            error: true
        }
        
        return result;
    }


};

export const fetchHomeOpportunities = async (limit) => {
    
    const fetchUrl = serverHost + hostPortDivider + serverPort + homeOpportunitiesFetchUrl;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                },
                params: {
                    limit
                }
            });
        
        if (response.status == 200) {
            const result = {
                onlineItems: response.data.onlineOpportunities,
                regularItems: response.data.regularOpportunities,
                error: false
            }
            
            return result;
        }
        else {
            
            const result = {
                message: response.message,
                error: true
            }
            
            return result;
        }
    }

    catch (err) {
        
        const result = {
            message: err.message,
            error: true
        }
        
        return result;
    }


};




export const fetchUserOpportunities = async (type = "", searchValue = "") => {
    
    const fetchUrl = serverHost + hostPortDivider + serverPort + userOpportunitiesFetchUrl;
    
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                },
                params: {
                    searchValue, type
                }
            });
        
        if (response.status == 200) {
            const result = {
                opportunities: response.data,
                error: false
            }
            
            return result;
        }
        else {
            
            const result = {
                message: response.message,
                error: true
            }
            
            return result;
        }
    }

    catch (err) {
        
        const result = {
            message: err.message,
            error: true
        }
        
        return result;
    }


};



export const fetchOpportunity = async (id) => {
    
    const fetchUrl = serverHost + hostPortDivider + serverPort + viewOpportunityViewFetchUrl + `/${id}`;
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                }
            });
        
        if (response.status == 200) {
            
            // const {id,type} = response.data
            // 
            const result = {
                item: response.data,
                error: false
            }
            
            return result;
        }
        else {
            
            const result = {
                item: response.message,
                error: true
            }
            
            return result;
        }
    }

    catch (err) {
        
        const result = {
            item: err.message,
            error: true
        }
        
        return result;
    }


};
export const applyOpportunity = async (email, opportunityId, dates) => {

    
    const fetchUrl = serverHost + hostPortDivider + serverPort + applyURL;
    
    try {
        const response = await axios.post(fetchUrl, { opportunityId, dates },
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                }
            });
        
        if (response.status == 200) {
            const result = {
                message: response.data.message,
                error: false
            }
            
            return result;
        }
        else {
            
            const result = {
                message: response.message,
                error: true
            }
            
            return result;
        }
    }
    catch (err) {
        
        const result = {
            message: err.message,
            error: true
        }
        
        return result;
    }

}


export const fetchOpportunitiesByAdmin = async () => {
    
    const fetchUrl = serverHost + hostPortDivider + serverPort + "/api/opportunity/list/CREATED";
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                }
            });
        
        if (response.status == 200) {
            const opItems =  {
                onlineItems: response.data,
                error: false
            }
            const result = opItems
            
            return result;
        }
        else {
            
            const result = {
                message: response.message,
                error: true
            }
            
            return result;
        }
    }

    catch (err) {
        
        const result = {
            message: err.message,
            error: true
        }
        
        return result;
    }


};


export const fetchOpportunitiesByAdminToApproveVolunteers = async () => {
    
    const fetchUrl = serverHost + hostPortDivider + serverPort + "/api/opportunity/list/APPROVED";
    try {
        const response = await axios.get(fetchUrl,
            {
                headers: {
                    'token': localStorage.getItem(tokenKey)
                }
            });
        
        if (response.status == 200) {
            const opItems =  {
                onlineItems: response.data,
                error: false
            }
            const result = opItems
            
            return result;
        }
        else {
            
            const result = {
                message: response.message,
                error: true
            }
            
            return result;
        }
    }

    catch (err) {
        
        const result = {
            message: err.message,
            error: true
        }
        
        return result;
    }


};





