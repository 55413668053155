
export class DatePieces {
    static today = new Date()
    static monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

    static instance = new DatePieces()

    constructor(date = DatePieces.today) {
        this.month = date.getMonth() + 1
        this.year = date.getFullYear()
        this.date = date.getDate()
        this.monthName = DatePieces.monthNames[date.getMonth()]
    }

}


export const todayPieces = DatePieces.instance

export function readKeyGenerator(data) {
    return (key, defaultValue) => readKey(data, key, defaultValue)
}


export function suffix(value, suffix) {
    return `${value} ${suffix}`
}

export function prefix(value, prefix) {
    return `${prefix} ${value}`
}

export const SP = {
    LKR: (value) => prefix(value.toFixed(2), "LKR"),
    HOURS: (value) => suffix(value, "HRS")
}

export function readKey(data, key, defaultValue = 0) {
    const value = data? data[key]: null
    return value || defaultValue
}