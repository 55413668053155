import React from "react";
import UserSettings from '../../components/UserSettings'
import { submitChangePassword } from '../../functions/UserCommon/submitChangePassword';
// import { Typography } from "@material-ui/core";

export default UserSettings

// export default function SettingsPage() {
//
//     // const [error, setError] = React.useState(false);
//     // const [success, setSuccess] = React.useState(false);
//     // const [message, setMessage] = React.useState("");
//
//     // const changePasswordFunc = async (voDetails) => {
//     //     const userID = 1
//     //     //
//     //     let result = await submitChangePassword(userID, voDetails);
//     //     if (result.error) {
//     //         setError(true);
//     //         setMessage(result.item)
//     //         setSuccess(false)
//     //     }
//     //     else {
//     //         setError(false)
//     //         setSuccess(true)
//     //     }
//     // }
//
//     return (<UserSettings/>);
// }