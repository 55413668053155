import React from 'react'
import { exportedDetails, exportedDetailsForVolunteerFeedback, exportedDetailsEVO } from './Constant';
import EditableText from './EditableText';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from "react-i18next";

function FetchDetails({ vio, volunteer, opportunity, onClick, isVolunteer,emergencyLogin, isEVO=false }) {

    const state = true;
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {isEVO? exportedDetailsEVO({ emergencyLogin, volunteer, opportunity }).map((item, i) => (
                    <EditableText
                        key={i.toString()}
                        name={t('Feedback.'+item.name)}
                        value={item.value}
                        title={item.type}
                        attribute={item.key}
                        onClick={onClick}
                    />

                ))
                
                :exportedDetails({ vio, volunteer, opportunity }).map((item, i) => (
                    <EditableText
                        key={i.toString()}
                        name={t('Feedback.'+item.name)}
                        value={item.value}
                        title={item.type}
                        attribute={item.key}
                        onClick={onClick}
                        allowEdit={item.allowEdit}
                    />

                ))}
                {isVolunteer ? exportedDetailsForVolunteerFeedback({ vio, volunteer, opportunity }).map((item, i) => (
                    <EditableText
                        key={i.toString()}
                        name={t('Feedback.'+item.name)}
                        value={item.value}
                        title={item.type}
                        attribute={item.key}
                        onClick={onClick}
                        allowEdit={item.allowEdit}
                    />
                ))

                    :
                    ""

                }
            </MuiPickersUtilsProvider>
        </React.Fragment>
    )
}

export default FetchDetails
