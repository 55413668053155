import * as yup from "yup";

const regexPhoneNumber = /^$|(^[0-9]{10}$)/
const regexNIV = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/

export const schema = yup.object().shape({
  title: yup.string().required('Required'),
  category: yup.string().required('Required'),
  noOfVolunteers: yup.number('Should be a number').min(1, 'Should be greater than 0').required('Required'),
  supervisorName: yup.string().required('Required'),
  supervisorLandline: yup.string().required('Required').matches(regexPhoneNumber, 'Phone number is not valid'),
  supervisorMobile: yup.string().required('Required').matches(regexPhoneNumber, 'Phone number is not valid'),
  supervisorFax: yup.string().matches(regexPhoneNumber, 'Phone number is not valid'),
  supervisorEmail: yup.string().required('Required').email('Should be a valid email'),
  // degreeLevelRequired:yup.string().required('Required'),
  startDate:yup.date().required('Required').max(new Date(),"Start date must be a past date"),
  endDate:yup.date().required('Required').min(yup.ref('startDate')).max(new Date(),"End date must be same or after start date"),

  sdgNumbers: yup.object().test('sdgNumbers Test', 'Required',
      (value) => {
        var count = 0
        Object.values(value).forEach((val) => {
          if (val === true) { count += 1 }
        })
        return (count > 0)
      }
  ),

  description: yup.string().required('Required').min(350, 'Minimum 350 characters are allowd'),
  // descriptionEnglish: yup.string().required('Required').max(1500, 'Maximum 1500 characters are allowd'),
  // descriptionTamil: yup.string().required('Required').max(1500, 'Maximum 1500 characters are allowd'),
  // descriptionSMSSinhala: yup.string().required('Required').max(160, 'Maximum 160 characters are allowd'),
  // descriptionSMSEnglish: yup.string().required('Required').max(160, 'Maximum 160 characters are allowd'),
  // descriptionSMSTamil: yup.string().required('Required').max(160, 'Maximum 160 characters are allowd'),
  requiredSkills: yup.object().test('requiredSkills Test', 'Required',
      (value) => {
        var count = 0
        Object.values(value).forEach((val) => {
          if (val === true) { count += 1 }
        })
        return (count > 0)
      }),
  district: yup.string().required('Required'),
  divisionalSecretariat: yup.string().required('Required'),
  gnDivision: yup.string().required('Required'),

  location: yup.string().required('Required'),
  language: yup.string().required('Required'),

  workingHourCount: yup.number().required('Required').min(1, 'Should be greater than 0'),
  averageVolunteerHours: yup.number().required('Required').min(1, 'Should be greater than 0'),
  isFullTime: yup.string().required('Required'),
  // days: yup.object().test('days Test', 'Required',
  //   (value) => {
  //     var count = 0
  //     Object.values(value).forEach((val) => {
  //       if (val === true) { count += 1 }
  //     })
  //     return (count > 0)
  //   }),
  // time: yup.object().test('time Test', 'Required',
  //   (value) => {
  //     var count = 0
  //     Object.values(value).forEach((val) => {
  //       if (val === true) { count += 1 }
  //     })
  //     return (count > 0)
  //   }),
  isTrainingProvided: yup.string().required('Required'),
  trainingDetails: yup.string().when("isTrainingProvided", (data) => {
    if (data == "true") {
      return yup.string().required('Required')
    }
    else {
      return yup.string()
    }
  }),
  isExpenseCovered: yup.string().required('Required'),
  coveredExpenseDetails: yup.string().when("isExpenseCovered", (data) => {
    if (data == "true") {
      return yup.string().required('Required')
    }
    else {
      return yup.string()
    }
  }),
});
