import React from "react";
// import VIOHome from '../../components/VIO/VIOHome';
// import { useEffect } from "react";
// import { fetchVIOHome } from '../../functions/VIO/VIOHome/fetchVIOHome';
// import { fetchVOSearch } from '../../functions/VIO/VIOHome/fetchVOSearch';
// import { fetchVIOHomeCards } from '../../functions/VIO/VIOHome/fetchVIOHomeCards';
// import MessageCard from "../../components/MessageCard";
// import LoadingPage from "../../components/LoadingPage";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import EmergencyUserHome from '../../components/EmergencyUser/EmergencyUserHome';
import { fetchEmgUserHome } from '../../functions/EmergencyUser/EmgUserHome/fetchEmgUserHome';
import MessageCard from "../../components/MessageCard";
import LoadingPage from "../../components/LoadingPage";

export default function EmergencyUserHomePage() {
 
  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [voRows, setVORows] = React.useState([])
  const [error, setError] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])


  React.useEffect(async () => {

    let mounted = true;
    let result = await fetchEmgUserHome();

    if (mounted) {
      if (result.error) {
        setError(true);
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setError(false);
        setPageCount(Math.ceil(result.item.emergencyOpportunities.length / 10))
        setFetchDetails(result.item)
        setVORows(result.item.emergencyOpportunities)
      }
    }
    return () => mounted = false;
  }, [])

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchVO = ({ search }) => {
    if (search) {
      const searchResult = fetchDetails.emergencyOpportunities.filter((item) => (item.title.toLowerCase().search(search.toLowerCase()) >= 0))
      setVORows(searchResult)
      setPageCount(Math.ceil(searchResult.length / 10))
    }
    else {
      setPageCount(Math.ceil(fetchDetails.emergencyOpportunities.length / 10))
      setVORows(fetchDetails.emergencyOpportunities)
    }
    setPage(1)
  }

  return (
    <React.Fragment>
      {fetchDetails ?
        !error ?
          <EmergencyUserHome
            fetchDetails={fetchDetails}
            voRows={voRows}
            page={page}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            handleSearchVO={handleSearchVO}
          />
          : <MessageCard />
        : <LoadingPage />
      }
    </React.Fragment>
  );
}