import { Box, Grid } from "@material-ui/core";
import React from "react";
import asset7 from "../../images/Asset7.png";

function PDFHeaderImage() {
  return (
    <div>
      <Grid
        item
        xs={12}
        container
        alignContent="center"
        justify="center"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Box mb={3}>
          <img
            src={asset7}
            alt="img"
            style={{ width: "75vw", maxWidth: "500px" }}
          />
        </Box>
      </Grid>
    </div>
  );
}

export default PDFHeaderImage;
