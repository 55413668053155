import React, { useEffect } from 'react';
import {useForm} from "react-hook-form";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import IconTextField from './IconTextField'
import CustomButton from './CustomButton'
import {Grid, Paper, Typography} from '@material-ui/core'
import {Link, useHistory} from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

const schema = yup.object().shape({
    email: yup.string().email('Email must be a valid email').required('Required'),
    password: yup.string().required('Required').min(6, 'Password must be at least 6 characters long'),
});

export default function LoginForm({onSubmit, t}) {

    const {register, errors, control, handleSubmit} = useForm({
        resolver: yupResolver(schema)
    });

    const [errorMsg, setErrorMsgUpdate] = React.useState("");
    const [pushError, setPushError] = React.useState(false);

    let history = useHistory();
    const {enqueueSnackbar} = useSnackbar()  

    useEffect(() => {
      if (!errorMsg) return
      enqueueSnackbar(errorMsg, {
        variant: 'error',
        persist: false
      })
    }, [pushError])

    const setErrorMsg = (msg) => {
        setErrorMsgUpdate(msg)
        setPushError(!pushError)
    }
  
    return (
        <Paper style={{padding: '18px 36px', maxWidth: 420, margin: 'auto', marginTop: 40, marginBottom: 40}}>
            <form onSubmit={handleSubmit((data) => onSubmit({...data, history, setErrorMsg}))}>
                <Grid item container xs={12} justify="center">
                    <Grid item style={{padding: '20px 0'}}>
                        <Typography variant="h5" color="primary">{t("LoginPage.logInTitle")}</Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <IconTextField
                            label={t("LoginPage.email")}
                            inputRef={register()}
                            icon={<AccountCircleIcon/>}
                            name="email"
                            error={errors.email}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IconTextField
                            label={t("LoginPage.password")}
                            inputRef={register()}
                            type="password"
                            icon={<LockIcon/>}
                            name="password"
                            error={errors.password}
                            helperText={errors.password?.message}
                        />
                    </Grid>
                    {/* <Grid item>
            <FormControlLabel
              style={{ margin: 0 }}
              control={<Checkbox name="rememberMe" inputRef={register} />}
              label="Remember Me"
            />
          </Grid> */}
                    <Grid item xs={6}>
                        <CustomButton label={t("LoginPage.logInTitle")} type="submit" variant="contained" fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomButton label={t("Register.registerButton")} component={Link} to="/register" variant="outlined" fullWidth/>
                    </Grid>
                    <Grid item xs={12} justify="left">
                        <Typography variant="body2" style={{margin: 12, color: 'blue', textAlign: 'right'}}>
                            <Link to="/forgetPassword">{t("LoginPage.forgetPassword")}</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}
