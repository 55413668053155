import * as yup from "yup";

const regexPhoneNumber = /^$|(^[0-9]{10}$)/
const regexNIV = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/

export const schema = yup.object().shape({
  title: yup.string().required('Required'),
  category: yup.string().required('Required'),
  // type: yup.string().required('Required'),
  noOfVolunteers: yup.number('Should be a number').min(1, 'Should be greater than 0').required('Required'),
  // coreFunctions: yup.array().of(yup.number()),
  supervisorName: yup.string().required('Required'),
  supervisorLandline: yup.string().required('Required').matches(regexPhoneNumber, 'Phone number is not valid'),
  supervisorMobile: yup.string().required('Required').matches(regexPhoneNumber, 'Phone number is not valid'),
  supervisorEmail: yup.string().required('Required').email('Should be a valid email'),

  startDate: yup.date('Enter Date').required('Required').min(new Date(), "Start date must be a future date"),
  endDate: yup.date('Enter Date').required('Required').min(yup.ref('startDate'), "End date must be same or after start date"),

  sdgNumbers: yup.object().test('sdgNumbers Test', 'Required',
    (value) => {
      var count = 0
      Object.values(value).forEach((val) => {
        if (val === true) { count += 1 }
      })
      return (count > 0)
    }
  ),

  descriptionSinhala: yup.string().required('Required').max(1500, 'Maximum 1500 characters are allowd'),
  descriptionEnglish: yup.string().required('Required').max(1500, 'Maximum 1500 characters are allowd'),
  descriptionTamil: yup.string().required('Required').max(1500, 'Maximum 1500 characters are allowd'),
  descriptionSMSSinhala: yup.string().required('Required').max(50, 'Maximum 50 characters are allowd'),
  descriptionSMSEnglish: yup.string().required('Required').max(50, 'Maximum 50 characters are allowd'),
  descriptionSMSTamil: yup.string().required('Required').max(50, 'Maximum 50 characters are allowd'),
  district: yup.string().required('Required'),
  divisionalSecretariat: yup.string().required('Required'),
  gnDivision: yup.string().required('Required'),

  location: yup.string().required('Required'),
});
