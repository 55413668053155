import React from 'react'
import { useTranslation } from 'react-i18next';
import { systemGeneratedDetails } from './Constants';
import DetailCard from './DetailCard';

function DetailsSection({volunteer}) {
    const {t} = useTranslation()

    

    return (
        <React.Fragment>

            {systemGeneratedDetails(volunteer).map((item,i)=>(

                <DetailCard
                    title = {t(`RefreeFeedback.${item.title}`)}
                    value = {item.value}
                />

            ))}

        </React.Fragment>
    )
}

export default DetailsSection
