import React from 'react';
import { Route, Switch} from 'react-router-dom';
import RefereeFeedback from '../../Referees/RefereeFeedback';
import { PUBLIC } from '../Constants';
import CustomRoute from '../CustomRoute';

function RefereeRoutes() {
    return (
        <Switch>
            <CustomRoute exact path="/refereeFeedback/:token" component={RefereeFeedback} routes = {PUBLIC} />
        </Switch>
    )
}

export default RefereeRoutes
