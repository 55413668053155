export const volunteerHomePageOnlineOpportunityImageLink = "https://mopportunities.com/wp-content/uploads/2020/03/onlinevolunteering_mopportunities_jpg-895x430.jpg";
export const volunteerHomePageOnlineOpportunityImageTopic = "Online Volunteering Opportunities";
export const volunteerHomePageOnlineOpportunityCardContentTopic = "Online Volunteering Opportunities";
export const volunteerHomePageOnlineOpportunityCardContent = "Lizards are a widespread group of squamate reptiles, with over 6,000 species, rangingacross all continents except Antarctica";
export const defaultLearnmore = () => {  };
export const defaultShare = () => {  };
export const defualtOnlineCarousalHeading = "Online Volunteering Opportunities";


export const volunteerHomePageRegularOpportunityImageLink = "https://www.unv.org/sites/default/files/styles/threshold-1382/public/UNV_online_volunteering_global_festival_ideas_2017_lizcano.jpg?itok=ZpF8Mp9f";
export const volunteerHomePageRegularOpportunityImageTopic = "Online Volunteering Opportunities";
export const volunteerHomePageRegularOpportunityCardContentTopic = "Online Volunteering Opportunities";
export const volunteerHomePageRegularOpportunityCardContent = "Lizards are a widespread group of squamate reptiles, with over 6,000 species, rangingacross all continents except Antarctica";
export const defualtRegularCarousalHeading = "Regular Volunteering Opportunities";
export const opportunityLimit = 30;

export const onlineItems = {
    imageLink: null,
    id: 2,
    content: "Regular online Test content details Test content details Test content detailsTest content detailsTest content details",//Description of assignment to be displayed on volunteer call-out :
    contentTopic: "online This is the topic of the content",
    skills: ["photography", "ML", "Data Science"]
};
export const regularItems = {
    imageLink: null,
    id: 1,
    content: "Regular online Test content details Test content details Test content detailsTest content detailsTest content details",//Description of assignment to be displayed on volunteer call-out :
    contentTopic: "online This is the topic of the content",
    skills: ["photography", "ML", "Data Science"]
};
export const defaultItems = {
    onlineItems: [onlineItems, onlineItems, onlineItems, onlineItems, onlineItems, onlineItems, onlineItems, onlineItems, onlineItems, onlineItems]
    , regularItems: [regularItems, regularItems, regularItems, regularItems, regularItems]
};
export const onlineOpportunityFetchUrl = "/api/onlineOpportunities";

export const defaultItem = {
    VODescriptionEnglish: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    VODescriptionTamil: "என்பது செம்மொழி இலத்தீனத்தைப் போல் இருப்பதால் ஆர்வத்தைத் தூண்டினாலும், இது எவ்வகையிலும் பொருள் தரக்கூடாது என்ற நோக்கத்துடனேயே உருவாக்கப்பட்டது ஆகும். ஓர் உரை பொருள் தருமானால், ஒரு வடிவமைப்பின் தோற்றக்கூறுகளில் கவனம் செல்லாமல் அந்த உரையைப் படிப்பதிலேயே கவனம் செல்லும். எனவே தான், ஒரு வடிவமைப்பின் தோற்றக்கூறுகளில் கூடுதல் கவனத்தைக் குவிக்கும் பொருட்டு, பதிப்பாளர்கள் இந்த லோரம் இப்சம் உரையைப் பயன்படுத்துகிறார்கள்.",
    VODescriptionxsSEnglish: "",
    VODescriptionxsSSinhala: "",
    VODescriptionxsSTamil: "",
    coveredExpenseDetails: "description",
    degreeLevelRequired: "GCE OL",
    district: "Loc2",
    divisionalSecretariat: "Loc2",
    gnDivision: "Loc3sdfsdfsdfsdf",
    isExpenseCovered: "Yes",
    isFullPartBool: "Part time",
    isTrainingProvided: "Yes",
    languageRequired: "Tamil",
    location: "",
    oppertunityDesignation: "one",
    oppertunityTitle: "new oppo",
    requiredSkills: ["VO Required Skill 1", "VO Required Skill 5"],
    sdgNumbers: ["GOAL 3: Good Health and Well-being", "GOAL 6: Clean Water and Sanitation", "GOAL 9: Industry, Innovation and Infrastructure", "GOAL 10: Reduced Inequality", "GOAL 14: Life Below Water", "GOAL 15: Life on Land"],
    specialRequirnments: "none",
    subjectAreaRequired: "VO Subject Area Required 4",
    supervisorEmail: "abc@def.com",
    supervisorFax: "0412222222",
    supervisorLandline: "0412222222",
    supervisorMobile: "0412222222",
    supervisorName: "supervisor",
    time: ["Afternoon"],
    trainingDetails: "description",
    voCategory: "Vo Category 2",
    voCoreFunctions: ["VO Core Function 3", "VO Core Function 1"],
    voDays: ["Tuesday", "Friday"],
    voEndDate: new Date(2020, 11, 30),
    voStartDate: new Date(2020, 11, 22),
    volunteerCount: "3",
    workingHourCount: "12",
    VODescriptionSinhala: "කපුටා සිංහල යුනිකෝඩ් ( Sinhala Unicode ) අඩවිය වෙත පැමිණි ඔබ සාදරයෙන් පිළිගනිමු. මෙහි සිංහල යුනිකෝඩ් භාවිතයට ඔබට අවශ්‍ය උපාංග මෙවලම්, ලිපි, පර්යේෂණ අඩවි ආදී දෑ ඉදිරිපත් කොට ඇත. අප විසින් කරන ලද සිංහල යුනිකෝඩ් ( Sinhala Unicode ) උපාංග, පර්යේෂණ හා ලිපි වලට අමතරව සිංහල යුනිකේත සම්බන්ධයෙන් අන්තර්ජාලය තුල ඇති විශේෂ වෙබ් අඩවි පිළිබඳව තොරතුරුද මෙම වෙබ් අඩවියේ ඇතුලත් වේ.",
    id: 2,
    type: "Online",
    imageLink: null,
    vioRegNumber: "1234",
    name: "Organization name",
    isAlreadyAgreed: false,
    isAlreadySubmited: false,

}
