import React from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import VOProcess from '../../components/VIO/VOProcess';
import { submitCreateVOFunc } from '../../functions/VIO/VolunteerOppertunity/submitCreateVO';
import { fetchVOFormDataFunc } from '../../functions/VIO/VolunteerOppertunity/fetchVOFormData';
import { Paper } from "@material-ui/core";
import MessageCard from '../../components/MessageCard';
import FormLanguageAlert from "../../components/FormLanguageAlert";

export default function CreateVO() {


  const [errorMsg, setErrorMsg] = React.useState("");
  const [formDisplayData, setFormDisplayData] = React.useState(null);
  const [fetchErrorFormData, setFetchErrorFormData] = React.useState(false);
  const [isError, setIsErrorVariant] = React.useState(true);
  const [pushError, setPushError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: isError ? 'error' : 'success',
      persist: false
    })
  }, [pushError])

  useEffect(async () => {

    let mounted = true;
    let resultFormData = await fetchVOFormDataFunc();

    if (mounted) {
      if (resultFormData.error) {
        setFetchErrorFormData(true);
        result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
        setIsErrorVariant(true)
        setPushError(!pushError)
      }
      else {
        setFormDisplayData(resultFormData.item);
        setFetchErrorFormData(false);
      }
    }
    return () => mounted = false;
  }, [])

  const createVOFunc = async (voDetails) => {

    var formData = { ...voDetails }
    delete formData.history

    let result = await submitCreateVOFunc(formData);
    if (result.error) {
      result.status == 500 ? setErrorMsg("Something went wrong!") : setErrorMsg(result.item)
      setIsErrorVariant(true)
      setPushError(!pushError)
    }
    else {
      setIsErrorVariant(false)
      setErrorMsg("Successfully Created!")
      setPushError(!pushError)
      voDetails.history.push("/volunteerOpportunity/" + result.item.id)
    }
  }
  return (
    <React.Fragment>
      <FormLanguageAlert/>
      {
        (formDisplayData) ?
          (!fetchErrorFormData) ?
            <Paper style={{ padding: '18px 36px', margin: 'auto', marginTop: 40, marginBottom: 40 }}>
              <VOProcess
                onSubmit={createVOFunc}
                formDisplayData={formDisplayData}
              />
            </Paper>
            : <MessageCard message="Error" />
          : ""
      }
    </React.Fragment>

  );
}