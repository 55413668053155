import React from 'react'
import DropdownList from '../../components/DropdownList'

function ReportItem({item}) {
    return (
        <React.Fragment>
        
        <h4>
            {item.title}
        </h4>
        <p>
            {item.date}
        </p>
        {item.list.map((element,i)=>(

        <div key = {i.toString()}>
            {element.title +"  "+element.value}
            {element.needDropDown?
            
            <DropdownList
                menuItemList = {element.dropdownItems}
                isSearch = {false}
            />            
            :""} 
        </div>


        ))}
    
        </React.Fragment>
        
    )
}

export default ReportItem
