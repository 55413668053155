import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import PieChart from "../../components/report/PieChart";
import BarChart from "../../components/report/BarChart";
import { dailyReg } from "../../functions/Reports/fetchDailyRegistration";
import { initPDF, addTable, addText, saveDoc, addTitle } from "./pdf";
import { changeVolDailyReport } from "./utils/restructure";
import SimpleTabs from "../../components/report/DateArea";
import ReactToPrint from "react-to-print";
import PDFHeaderImage from "../../components/report/PDFHeaderImage";
import PDFFooter from "../../components/report/PDFFooter";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  table: {
    minWidth: 250,
  },
  pieChart: {
    marginLeft: "50px",
    // marginRight: "auto",
  },
}));

function OnlineDailyVolRegistration(props) {
  const [reportType, setReportType] = useState("DAILY");
  const [reportBody, setReportBody] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const componentRef = useRef();

  const [reportData, setReportData] = useState({});
  // 
  useEffect(() => {
    const fetchData = async () => {
      const data = await dailyReg("ONLINE", reportType, reportBody);
      if (data) setReportData(changeVolDailyReport(data));
      
    };
    fetchData();
  }, [reportType, reportBody]);
  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  const handleDownload = () => {
    var doc = initPDF();
    doc = addTitle("Daily Volunteers registrations", doc);
    doc = addTable(
      [["Sector", "Count"]],
      reportData.numVolunteersByKeySectors,
      doc
    );
    doc = addTable(
      [["Sector", "Count"]],
      reportData.numVolunteersByKeySectors,
      doc
    );
    saveDoc(`daily_Reg_${rdate}`, doc);
  };
  const classes = useStyles();
  return (
    <>
      <Paper
        style={{
          padding: "18px 36px",
          maxWidth: 800,
          margin: "auto",
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <form action="" onSubmit={handleSubmit}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} style={{ padding: "20px 0" }}>
              <Typography variant="h5" align="center" color="primary">
                Online Volunteers registrations
              </Typography>
            </Grid>
            <Grid item xs={12} justify="center">
              <SimpleTabs
                reportType={reportType}
                reportBody={reportBody}
                setReportType={setReportType}
                setReportBody={setReportBody}
              />
            </Grid>
            {reportData &&
            Object.keys(reportData).length === 0 &&
            reportData.constructor === Object ? (
              <Grid
                item
                xs={12}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                LOADING...
              </Grid>
            ) : (
              <>
                <Grid item xs={12} ref={componentRef}>
                  <PDFHeaderImage />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <Typography
                          variant="h5"
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Online Volunteers registrations : {reportType}
                        </Typography>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Registered volunteers:
                          </TableCell>
                          <TableCell align="left">
                            {reportData.numVolunteers}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {reportData.numVolunteersByNationality.length >
                            1 ? (
                              <PieChart
                                title="By Nationality"
                                data={reportData.numVolunteersByNationality}
                              />
                            ) : (
                              "By Nationality: No data"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {reportData.numVolunteersByKeySectors.length > 1 ? (
                              <PieChart
                                title="By Sectors"
                                data={reportData.numVolunteersByKeySectors}
                              />
                            ) : (
                              "By Sectors: No data"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {reportData.numVoluteersByDistricts.length > 1 ? (
                              <BarChart
                                title="By Districts"
                                hAxis="Count"
                                vAxis="Districts"
                                data={reportData.numVoluteersByDistricts}
                              />
                            ) : (
                              "By Districts: No data"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {reportData.numVolunteersByGender.length > 1 ? (
                              <PieChart
                                title="By Gender"
                                data={reportData.numVolunteersByGender}
                              />
                            ) : (
                              "By Gender: No data"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <PDFFooter />
                </Grid>
              </>
            )}
            {/* <Grid>
                            <Button variant="contained" color="primary" onClick={handleDownload}>Download as PDF</Button>
                        </Grid> */}
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary">
                  Download as PDF
                </Button>
              )}
              content={() => componentRef.current}
              onBeforePrint={() => (document.title = "report")}
              onAfterPrint={() =>
                (document.title = "The National Volunteering Platform")
              }
            />
          </Grid>
        </form>
        <br />
      </Paper>
    </>
  );
}

export default OnlineDailyVolRegistration;
