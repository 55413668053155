import axios from 'axios';
import { serverHost, serverPort, hostPortDivider, tokenKey } from "../../Constant";
import { emgUserSubmitEVOFeedbackUrl, volunteerSubmitEVOFeedbackUrl } from '../../containers/Feedback/Constant';

export const emgUserSubmitEVOFeedback = async (details) => {

    const saveUrl = serverHost + hostPortDivider + serverPort + emgUserSubmitEVOFeedbackUrl;

    try {
        const response = await axios.post(saveUrl, { ...details }, { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }
    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }
};

export const volunteerSubmitEVOFeedback = async (details) => {

    const saveUrl = serverHost + hostPortDivider + serverPort + volunteerSubmitEVOFeedbackUrl;

    try {
        const response = await axios.post(saveUrl, { ...details }, { headers: { 'token': localStorage.getItem(tokenKey) } });

        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }
    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }
};
