import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

const styles = {
    dialog: {
        padding: '20px'
    }
}


export default function DeleteAccountDialog({ onCancel, onDelete, dialog }) {

    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const { t } = useTranslation()

    const onClose = () => {
        setError('')
        onCancel()
    }

    const validate = (password) => {
        if (password && password.length > 0) {
            setError('')
            return true
        } else {
            setError('required')
            return false
        }
    }
    const onPasswordChange = (e) => {
        setPassword(e.target.value)
        validate(e.target.value)
    }

    const onSubmit = () => {
        if (validate(password)) {
            onDelete(password)
        }
    }

    return (
        <Dialog open={dialog} onClose={onClose} aria-labelledby="form-dialog-title" style={styles.dialog}>
            <DialogTitle id="form-dialog-title">{t('Settings.warning')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('Settings.warningDesc')}
                </DialogContentText>
                <TextField
                    name="password"
                    label={t('Settings.password')}
                    type="password"
                    size="small"
                    id="outlined-margin-normal"
                    margin="normal"
                    fullWidth
                    onChange={onPasswordChange}
                    error={error}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions style={styles.dialog}>
                <Button onClick={onClose} color="primary">
                    {t('Common.cancel')}
                </Button>
                <Button disabled={error} onClick={onSubmit} style={{ backgroundColor: error ? '#646464' : '#f52929', color: 'white' }} >
                    {t('Settings.deleteAcc')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
