import Typography from "@material-ui/core/Typography";
import CustomButton from "../../components/CustomButton";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React from "react";
import useStyles from "./_styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import involvement1 from "../../images/involvement-1.png";
import involvement2 from "../../images/involvement-2.png";
import involvement3 from "../../images/involvement-3.png";
import involvement4 from "../../images/involvement-4.png";
import involvement5 from "../../images/involvement-5.png";
import involvement6 from "../../images/involvement-6.png";
import { useTranslation } from "react-i18next";

export default function Home() {
    const classes = useStyles();
    const {t} = useTranslation()
    return(
        <Grid item className={classes.involvementSection}>
            <Divider variant="fullWidth" />
            <Grid container style={{display: 'flex', justifyContent: 'center',marginTop: '50px'}} spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                    {t('LandingPage.involvement.subtitle1')}
                    </Typography>
                    <Typography variant="h4" color='primary' gutterBottom style={{textAlign: "center", fontWeight: 'bold'}} >
                    {t('LandingPage.involvement.subtitle2')}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Paper elevation={3} className={classes.paper}>
                        <div style={{width: '100%'}}>
                            <img src={involvement1} width={'100%'}/>
                        </div>
                    </Paper>
                    <div style={{width: '100%', margin: '5% auto'}}>
                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                        {t('LandingPage.involvement.writingAndEditing')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Paper elevation={3} className={classes.paper}>
                        <div style={{width: '100%'}}>
                            <img src={involvement2} width={'100%'}/>
                        </div>
                    </Paper>
                    <div style={{width: '100%', margin: '5% auto'}}>
                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                        {t('LandingPage.involvement.research')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Paper elevation={3} className={classes.paper}>
                        <div style={{width: '100%'}}>
                            <img src={involvement3} width={'100%'}/>
                        </div>
                    </Paper>
                    <div style={{width: '100%', margin: '5% auto'}}>
                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                        {t('LandingPage.involvement.technologyDevelopment')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Paper elevation={3} className={classes.paper}>
                        <div style={{width: '100%'}}>
                            <img src={involvement4} width={'100%'}/>
                        </div>
                    </Paper>
                    <div style={{width: '100%', margin: '5% auto'}}>
                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                        {t('LandingPage.involvement.outreachAndAdvocacy')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Paper elevation={3} className={classes.paper}>
                        <div style={{width: '100%'}}>
                            <img src={involvement5} width={'100%'}/>
                        </div>
                    </Paper>
                    <div style={{width: '100%', margin: '5% auto'}}>
                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                        {t('LandingPage.involvement.leadershipAndStrategy')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Paper elevation={3} className={classes.paper}>
                        <div style={{width: '100%'}}>
                            <img src={involvement6} width={'100%'}/>
                        </div>
                    </Paper>
                    <div style={{width: '100%', margin: '5% auto'}}>
                        <Typography variant="h6" color='primary' gutterBottom style={{textAlign: "center"}} >
                        {t('LandingPage.involvement.teachingAndTraining')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <CustomButton size="large" className={classes.button} color="primary" component={Link} to="/volunteer-opportunities" label={t('LandingPage.involvement.browseAllOpportunities')} />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}
