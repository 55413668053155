import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import CustomButton from "../../../components/CustomButton";


export default class PopUpTerminateVol extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      selectedDay: undefined,
      open: false,
    };
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOKClick = () => {
    this.props.terminate(this.props.id, this.props.history);
  };

  render() {
    return (
      <Grid container>
        <CustomButton
          label="Terminate"
          color="primary"
          style={{backgroundColor: '#f52929', color: 'white'}}
          onClick={this.handleOpen}
          type="submit"
          variant="contained"
        />
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="simple-dialog-title">
            Are You Sure to Terminate This Volunteer?
          </DialogTitle>
          <DialogContent dividers>
              This action cannot be UNDO!!!
          </DialogContent>
          <DialogActions>
            <CustomButton label="OK" onClick={this.handleOKClick} />
            {/* <CustomButton label="Clear Al" onClick={this.clearDates} /> */}
            <CustomButton label="Cancel" onClick={this.handleClose} />
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}
