import React from "react";
import { Chart } from "react-google-charts";

function PieChart(props) {
  return (
    <Chart
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={props.data}
      options={{
        title: props.title,
        legend: {
          position: "labeled",
        },
      }}
      rootProps={{ "data-testid": "1" }}
    />
  );
}

export default PieChart;
