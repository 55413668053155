import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dataArea: {
    margin: "10px",
    padding: "10px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function SimpleTabs({ reportType, reportBody, setReportType, setReportBody, endDateAvailable = false }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [year, setYear] = useState((new Date()).toISOString().substr(0, 4));
  const [quarter, setQuarter] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    setYear((new Date()).toISOString().substring(0, 4));
    setQuarter(1);
  }, [value]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (type) => {
    var body;
    switch (type) {
      case 'DAILY':
        body = endDateAvailable ? [(new Date()).toISOString().substring(0, 10), (new Date()).toISOString().substring(0, 10)] : (new Date()).toISOString().substring(0, 10);
        break;
      case 'QUARTERLY':
        body = [1, (new Date()).toISOString().substring(0, 4)];
        break;
      case 'ANNUALLY':
        body = (new Date()).toISOString().substring(0, 4);
        break;
    }
    setReportType(type);
    setReportBody(body);
  }

  const handleDropdownQuarter = (event) => {
    const val = event.target.value;
    setQuarter(val);
    setReportBody([val, year]);
  }

  const handleDropdownYear = (event) => {
    const val = event.target.value.toString();
    setYear(val);
    setReportBody([quarter, val]);
  }

  const handleDropdownOnlyYear = (event) => {
    const val = event.target.value.toString();
    setYear(val);
    setReportBody(val);
  }

  const handleDateChange = (event, inx) => {
    if (endDateAvailable) {
      inx == 0 ? setReportBody([event.target.value, reportBody[1]]) : setReportBody([reportBody[0], event.target.value])
      if (reportBody[1] < reportBody[0]) {
        setReportBody([reportBody[0], reportBody[0]])
      }
    }
    else {
      setReportBody(event.target.value)
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" centered>
          <Tab label={t('Reports.DAILY')} {...a11yProps(0)} onClick={() => handleClick('DAILY')} />
          <Tab label={t('Reports.QUARTERLY')} {...a11yProps(1)} onClick={() => handleClick('QUARTERLY')} />
          <Tab label={t('Reports.ANNUALLY')} {...a11yProps(2)} onClick={() => handleClick('ANNUALLY')} />
        </Tabs>
      </AppBar>
      <div className={classes.dataArea} hidden={value !== 0}>
        <TextField
          id="date"
          label={endDateAvailable ? t('Reports.Select the start date') : t('Reports.Select the date')}
          type="date"
          value={endDateAvailable ? reportBody[0] : reportBody}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => handleDateChange(event, 0)}
        />
        {endDateAvailable &&
          <TextField
            id="date"
            label={t('Reports.Select the end date')}
            type="date"
            value={reportBody[1]}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => handleDateChange(event, 1)}
          />
        }
      </div>
      <div className={classes.dataArea} hidden={value !== 1}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('Reports.Year')}</InputLabel>
            <Select
              labelId="drop-down-label1"
              id="drop-down1"
              value={year}
              defaultValue={year}
              onChange={handleDropdownYear}
            >
              {[...Array(10).keys()].map(val =>
                <MenuItem key={val.toString()} value={year - val + 4}>{year - val + 4}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('Reports.Quarter')}</InputLabel>
            <Select
              labelId="drop-down-label2"
              id="drop-down2"
              value={quarter}
              defaultValue={quarter}
              onChange={handleDropdownQuarter}
            >
              <MenuItem value={1}>{t('Reports.1st Quarter')}</MenuItem>
              <MenuItem value={2}>{t('Reports.2nd Quarter')}</MenuItem>
              <MenuItem value={3}>{t('Reports.3rd Quarter')}</MenuItem>
              <MenuItem value={4}>{t('Reports.4th Quarter')}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.dataArea} hidden={value !== 2}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('Reports.Year')}</InputLabel>
            <Select
              labelId="drop-down-label1"
              id="drop-down1"
              value={year}
              defaultValue={year}
              onChange={handleDropdownOnlyYear}
            >
              {[...Array(10).keys()].map(val =>
                <MenuItem key={val.toString()} value={year - val + 4}>{year - val + 4}</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
