import React from "react";
import Grid from "@material-ui/core/Grid";
import ImageCard from "./ImageCard";
import { useTranslation } from "react-i18next";
import OpportunityCard from "../containers/VolunteerOpportunity/OpportunityCard";
import { Link } from "react-router-dom";

export default function NestedGrid({
  defaultImageLink,
  items,
  onClickLearnMore,
  onClickShare,
  onClickCard,
  isAdminView,
  isVolunteerApproveByAdmin,
  isVioView,
  isVolunteerApproveByVIO,
}) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      style={{
        padding: 24,
        borderRadius: 4,
        backgroundColor: "#e1ebff",
      }}
    >
      <Grid container spacing={2}>
        {items && items.length
          ? items.map((item, i) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                {/* <ImageCard
                  id={item.id}
                  url={"/viewOpportunity/"}
                  oppertunityTitle={item.title}
                  voCategory={item.category}
                  type={item.type}
                  volunteerCount={item.volunteerCount}
                  name={item.Vio.name}
                  VODescriptionEnglish={item.descriptionEnglish}
                  requiredSkills={item.requiredSkills}
                  isAdminView={isAdminView}
                  isVolunteerApproveByAdmin={isVolunteerApproveByAdmin}
                  isVioView={isVioView}
                  isVolunteerApproveByVIO={isVolunteerApproveByVIO}
                /> */}

                {/* 1 */}
                {!isAdminView && !isVioView && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/viewOpportunity/${item.id}`}
                    // to={`/viewOpportunity/${item.id}`}
                  >
                    <OpportunityCard
                      sdg={Number(item.sdgNumbers[0]) || 1}
                      // className={cls.card}
                      header={Number(item.category) || 1}
                      title={item.title || ""}
                      vio={item.Vio.name || ""}
                      duration={item.workingHourCount || 0}
                      functions={item.coreFunctions.slice(0, 3) || []}
                      location={item.district || ""}
                      // Props
                      isAdminView={isAdminView}
                      isVolunteerApproveByAdmin={isVolunteerApproveByAdmin}
                      isVioView={isVioView}
                      isVolunteerApproveByVIO={isVolunteerApproveByVIO}
                    />
                  </Link>
                )}

                {/* 2 */}
                {isAdminView && !isVolunteerApproveByAdmin && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/admin/viewOpportunity/${item.id}`}
                    // to={`/viewOpportunity/${item.id}`}
                  >
                    <OpportunityCard
                      sdg={Number(item.sdgNumbers[0]) || 1}
                      // className={cls.card}
                      header={Number(item.category) || 1}
                      title={item.title || ""}
                      // vio={item.Vio.name || ""}
                      duration={item.workingHourCount || 0}
                      functions={item.coreFunctions.slice(0, 3) || []}
                      location={item.district || ""}
                      // Props
                      isAdminView={isAdminView}
                      isVolunteerApproveByAdmin={isVolunteerApproveByAdmin}
                      isVioView={isVioView}
                      isVolunteerApproveByVIO={isVolunteerApproveByVIO}
                    />
                  </Link>
                )}

                {/* 3 */}
                {isAdminView && isVolunteerApproveByAdmin && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/admin/volunteerOpportunity/${item.id}`}
                    // to={`/viewOpportunity/${item.id}`}
                  >
                    <OpportunityCard
                      sdg={Number(item.sdgNumbers[0]) || 1}
                      // className={cls.card}
                      header={Number(item.category) || 1}
                      title={item.title || ""}
                      // vio={item.Vio.name || ""}
                      duration={item.workingHourCount || 0}
                      functions={item.coreFunctions.slice(0, 3) || []}
                      location={item.district || ""}
                      // Props
                      isAdminView={isAdminView}
                      isVolunteerApproveByAdmin={isVolunteerApproveByAdmin}
                      isVioView={isVioView}
                      isVolunteerApproveByVIO={isVolunteerApproveByVIO}
                    />
                  </Link>
                )}

                {/* 4 */}
                {isVioView && isVolunteerApproveByVIO && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/vio/volunteerOpportunity/${item.id}`}
                    // to={`/viewOpportunity/${item.id}`}
                  >
                    <OpportunityCard
                      sdg={Number(item.sdgNumbers[0]) || 1}
                      // className={cls.card}
                      header={Number(item.category) || 1}
                      title={item.title || ""}
                      vio={item.Vio.name || ""}
                      duration={item.workingHourCount || 0}
                      functions={item.coreFunctions.slice(0, 3) || []}
                      location={item.district || ""}
                      // Props
                      isAdminView={isAdminView}
                      isVolunteerApproveByAdmin={isVolunteerApproveByAdmin}
                      isVioView={isVioView}
                      isVolunteerApproveByVIO={isVolunteerApproveByVIO}
                    />
                  </Link>
                )}
              </Grid>
            ))
          : t("Volunteer.No Items")}
      </Grid>
    </Grid>
  );
}
