/**
 1) 1 to 3 digits - display as it is (show only first two decimal values)
 2) 4 to 6 digits(before the decimal point) - hide last 3 digits from the decimal point and the decimal values and display a "K"
 3) 6 to 8 digits(before the decimal point) - hide last 6 digits from the decimal point and the decimal values and display a "Mn"
 4) 6 to 8 digits(before the decimal point) - hide last 9 digits from the decimal point and the decimal values and display a "Bn"
 */
const formatter = new Intl.NumberFormat('en', {maximumFractionDigits: 2})

function if_format(bound, suffix, value) {
    if (value >= bound) {
        return formatter.format(value / bound).toString() + suffix
    }
    return 0
}

export function compact_number(value) {
    return (
        if_format(10**9, 'Bn', value) ||
        if_format(10**6, 'Mn', value) ||
        if_format(10**3, 'K', value) ||
        if_format(1, '', value) ||
            0
    )
}
