import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import EditableText from './EditableText';
import StarFeedback from './StarFeedback';
import CustomButton from '../../components/CustomButton'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { vioStarFeedbackTopics, satisName, review, howGetKnowName, contributionHoursName, vioRecommondName, methodGetKnowName } from './Constant'
import { Select, Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

function VioFeedback({ vioName, onSubmitDetails }) {
    const [error, setError] = useState(false)
    const [errMessage, setErrorMessage] = useState("")
    const [result, setResult] = useState({
        [vioRecommondName]: "No",
        [contributionHoursName]: 0,
        [howGetKnowName]: "",
        [review]: "",
        [methodGetKnowName]: ""
    });
    const {t} = useTranslation();
    useEffect(() => {


    }, [error, result[methodGetKnowName]])


    const onTarget = (e) => {
        const { name, value } = e.target
        if (name == contributionHoursName) {
            setResult({ ...result, [name]: value >= 0 ? value : 0 })
        }
        else {
            setResult({ ...result, [name]: value })
        }
        

    }


    const onChange = (name, value) => {
        result[name] = value
    }

    const validate = () => {

        for (let number = 0; number < vioStarFeedbackTopics.length; number++) {
            let elementList = vioStarFeedbackTopics[number];
            for (let i = 0; i < elementList.items.length; i++) {
                let element = elementList.items[i];
                if (!result[element.name] || (result[element.name] & result[element.name] < 1)) {

                    
                    return false
                }


            }
        }


        if (!result[satisName] || (result[satisName] & result[satisName] == "")) {

            // 
            return false
        }


        if (!result[methodGetKnowName] || (result[methodGetKnowName] & result[methodGetKnowName] == "")) {

            
            return false
        }

        if (!result[methodGetKnowName] == "Other" || (result[howGetKnowName] & result[howGetKnowName] == "")) {

            
            return false
        }


        if (!result[vioRecommondName] || (result[vioRecommondName] & result[vioRecommondName] == "")) {

            
            return false
        }




        if (Number(result[contributionHoursName]) < 0) {
            
            
            return false
        }

        
        return true;

    }
    const onSubmit = () => {
        setError(false);
        setErrorMessage("")
        
        const err = validate()
        
        if (!err) {
            setError(true)
            setErrorMessage("Please Fill the all components")
        }
        else {
            
            onSubmitDetails(result)
        }

    }


    return (
        <React.Fragment>
            <Grid item xs={12}>
                <TextField
                    onChange={onTarget}
                    name={review}
                    defaultValue=""
                    label={t('Feedback.reviewTopic')}
                    variant="outlined"
                    value={result[review]}
                    multiline
                    rows={4}
                    fullWidth
                />
            </Grid>
            {
                vioStarFeedbackTopics.map((item, i) => (
                    <React.Fragment key={i.toString()}>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">{t(`Feedback.${item.title}`)}</Typography>
                        </Grid>
                        {item.items.map((element, j) => (
                            <StarFeedback
                                key={j.toString()}
                                name={element.name}
                                value={t(`Feedback.${element.title}`)}
                                onChange={onChange}
                            />
                        ))}
                    </React.Fragment>
                ))
            }
            <Grid item sx={12} style={{ marginBottom: 24 }}>
                <Typography variant="caption" color="textSecondary">{t('Feedback.ratingAdvice')}</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography variant="body1" component="h2">{t('Feedback.vioSatisfaction').replace('%vioName%',vioName)}</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Select
                    name={satisName}
                    variant="outlined"
                    defaultValue=""
                    onChange={onTarget}
                    fullWidth
                >
                    <MenuItem value="1">{t('Dissatisfied')}</MenuItem>
                    <MenuItem value="2">{t('Neither Satisfied nor Dissatisfied')}</MenuItem>
                    <MenuItem value="3">{t('Satisfied')}</MenuItem>
                    <MenuItem value="4">{t('Extremly Satisfied')}</MenuItem>
                </Select>
                {
                    //error?errMessage:""
                }
            </Grid>
            <Grid item md={8} xs={12}>
                <Typography variant="body1" component="h2">{t('Feedback.howYouHear')}</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Select
                    name={methodGetKnowName}
                    variant="outlined"
                    defaultValue=""
                    onChange={onTarget}
                    fullWidth
                >
                    <MenuItem value="FACEBOOK">Facebook</MenuItem>
                    <MenuItem value="NVS">{t('Feedback.NVS website')}</MenuItem>
                    <MenuItem value="MOUTH">{t('Feedback.Word of mouth')}</MenuItem>
                    <MenuItem value="SMS">{t('SMS Alert')}</MenuItem>
                    <MenuItem value="OTHER">{t('Volunteer.other')}</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12}>
                {
                    result[methodGetKnowName] == "OTHER" &&
                    <TextField
                        label={t('Feedback.howYouHearOther')}
                        name={howGetKnowName}
                        onChange={onTarget}
                        value={result[howGetKnowName]}
                        defaultValue=""
                        fullWidth
                        variant="outlined"
                    />
                }
            </Grid>
            <Grid item md={10} xs={12}>
                <Typography variant="body1" component="h2">{t('Feedback.vioRecommondation')}</Typography>
            </Grid>
            <Grid item md={2} xs={12}>
                <RadioGroup aria-label="recommend" name={vioRecommondName} defaultValue="No" value={result[vioRecommondName]} onChange={onTarget}>
                    <FormControlLabel value="Yes" control={<Radio />} label={t('Common.yes')} />
                    <FormControlLabel value="No" control={<Radio />} label={t('Common.no')} />
                </RadioGroup>
                <Grid>
                    {
                        //error?errMessage:""
                    }

                </Grid>
            </Grid>
            <Grid item md={10} xs={12}>
                <Typography variant="body1" component="h2">{t('Feedback.numOfHour')}</Typography>
            </Grid>
            <Grid item md={2} xs={12}>
                <TextField
                    name={contributionHoursName}
                    variant="outlined"
                    fullWidth
                    onChange={onTarget}
                    value={Number(result[contributionHoursName]) > 0 ? Number(result[contributionHoursName]) : 0}
                    type="number"
                // error= {() => (parseInt(result[numOfHour]) >=0 ) }
                // defaultValue = "0"
                />
            </Grid>
            {
                error ? errMessage : ""
            }
            <Grid item xs={12} container direction="column" justify="center" alignItems="center">
                <CustomButton
                    label={t('Common.submit')}
                    variant="contained"
                    onClick={onSubmit}
                    color="primary"
                    style={{ width: 180 }}
                />
            </Grid>
        </React.Fragment>
    )
}

export default VioFeedback
