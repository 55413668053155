import axios from 'axios';
import { serverHost, serverPort, tokenKey } from "../../../Constant";
import { updateNonFormalVOUrl } from '../../../containers/VIO/Constants';

export const submitUpdateNonFormalVO = async (voID,voDetails) => {

    const saveUrl = serverHost + ":" + serverPort + updateNonFormalVOUrl + '/' + voID.toString();
    try {
        const response = await axios.put(saveUrl,{...voDetails},{headers: {'token': localStorage.getItem(tokenKey)}});
  
        if (response.status === 200) {
            const result = {
                item: response.data,
                error: false,
                status: response.status
            }
            return result;
        }
        else {
            const result = {
                item: response.message,
                error: true,
                status: response.status
            }
            return result;
        }
    }

    catch (err) {
        const result = {
            item: err.response ? err.response.data.message : "Something went wrong!",
            error: true,
            status: err.status ? err.status : 500
        }
        return result;
    }

};

