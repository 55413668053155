import React from "react";
import VIOHome from '../../components/VIO/VIOHome';
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import {Box, Button, Grid} from '@material-ui/core';
import { fetchVIOHome } from '../../functions/VIO/VIOHome/fetchVIOHome';
import { fetchVOSearch } from '../../functions/VIO/VIOHome/fetchVOSearch';
import { fetchVIOHomeCards } from '../../functions/VIO/VIOHome/fetchVIOHomeCards';
import MessageCard from "../../components/MessageCard";
import LoadingPage from "../../components/LoadingPage";
import { Link, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

export default function VIOHomePage({ match }) {
  const id = match.params.id;

  const [fetchDetails, setFetchDetails] = React.useState(null);
  const [voRows, setVORows] = React.useState([])
  const [error, setError] = React.useState(false);
  const [searchError, setSearchError] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [profileIncmoplete, setProfileIncomplete] = React.useState(false);
  const registrationNo = useSelector(state => state.auth.registrationNo);

  const history = useHistory();
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(async () => {
    setProfileIncomplete(localStorage.getItem("PROFILE_INCOMPLETE"));
    let mounted = true;

    let resultCardData = await fetchVIOHomeCards();

    if (mounted) {
      if (resultCardData.error) {
        setError(true);
      }
      else {
        setFetchDetails(resultCardData.item);
        setError(false);
      }
    }

    return () => mounted = false;
  }, [])
  // useEffect(async () => {

  //   let mounted = true;
  //   const userID = 1; // 
  //   // 

  //   // let result = await fetchVIOHome(userID);

  //   // 

  //   // if (mounted) {

  //   //   if (result.error) {
  //   //     setError(true);
  //   //   }
  //   //   else {
  //   //     setFetchDetails(result.item);
  //   //     setError(false);
  //   //   }

  //   // }

  //   // Until api connect
  //   // setFetchDetails(props)
  //   setVORows(voRowsProp)
  //   // setError(false)

  //   // 
  //   return () => mounted = false;
  // }, [error])

  useEffect(async () => {
    let mounted = true;

    let result = await fetchVOSearch(searchValue, page);

    if (mounted) {

      if (result.error) {
        setSearchError(true);
      }
      else {
        setPageCount(Math.ceil(result.item.count / 10))
        setVORows(result.item.rows)
        setSearchError(false);
      }

    }
  }, [page, searchValue])

  const handleSearchVO = ({ search }) => {
    setSearchValue(search)
    setPage(1)
  }

  const handleProfileClick = () => {
    history.push("/vioprofile")
    setProfileIncomplete(false);
    localStorage.removeItem("PROFILE_INCOMPLETE");
  }

  return (
    <React.Fragment>
      {profileIncmoplete &&
      <div>
        <br />
        <Alert variant="filled" severity="warning"
          action={
            <Button color="inherit" size="small" onClick={handleProfileClick}>
              View profile
            </Button>
          }
        >
          Compete your volunteer profile to apply for opportunities
        </Alert>
      </div>
      }
      <Grid container direction="row" justify="flex-end" alignItems="center">
        {
          registrationNo &&  
          <Box m={1}>
            <Chip
              avatar={<Avatar>R</Avatar>}
              label={"Registration No: "+registrationNo}
              color="primary"
              variant="outlined"
            />
          </Box>
        }
      </Grid>
      {fetchDetails ?
        !error ?
          <VIOHome
            fetchDetails={fetchDetails}
            voRows={voRows}
            page={page}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            handleSearchVO={handleSearchVO}
          />
          : <MessageCard />
        : <LoadingPage/>
      }

    </React.Fragment>

  );
}