import React from 'react';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/core/styles';
import menu, {menus} from '../constants/menu';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { check } from '../helper/route_helper';
import { PUBLIC } from '../containers/Router/Constants';
import { useTranslation } from 'react-i18next';
// import { Collapse } from '@material-ui/core';
// import { ExpandLess, ExpandMore, StarBorder } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    // necessary for content to be below app bar
    toolbar: {
        [theme.breakpoints.up('sm')]: {
			height: 120
		},[theme.breakpoints.down('sm')]: {
			height: 70
		},
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    menuItem: {
        '&:hover': {
            backgroundColor: '#E1EBFF'
        }
    }
}));


function getUserTypeMenu(type,t, {refereeFilled}) {
    
    if(type.includes("VIO")){
        return menus(t, {refereeFilled}).vio
      }
      else if(type.includes("VOLUNTEER")){
        return menus(t, {refereeFilled}).volunteers
      }
      else if(type.includes("ADMIN")){
        return menus(t, {refereeFilled}).admin
      }
      else if(type.includes("EMERGENCY_LOGIN")){
        return menus(t, {refereeFilled}).emegencyUser
      }
      else{
        return menus(t, {refereeFilled}).vio
      }
}

export default function CustomDrawer() {
    const classes = useStyles();
    const type = useSelector(state => state.auth.type)
    const access = useSelector(state => state.auth.access);
    const refereeFilled = useSelector(state => state.auth.isRefereeFilled);
    const { t } = useTranslation()

    return (
        <div>
            <div className={classes.toolbar} />
            {/*<Divider />*/}
            <List>
                {getUserTypeMenu(type,t, {refereeFilled}).map((menuItem, index) => (
                    check(menuItem.access, access, menuItem.url) && <ListItem button key={index} component={Link} to={menuItem.url} className={classes.menuItem}>
                        {/* <ListItemIcon><InboxIcon /></ListItemIcon> */}
                        <ListItemText 
                        primary={menuItem.name} 
                        primaryTypographyProps={{
                            variant: 'button',
                            style: {fontWeight: 'bold', fontSize: 16},
                            color: 'primary'
                        }}
                        />
                    </ListItem>
                ))}
            </List>
            {/* <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Inbox" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="Starred" />
                    </ListItem>
                </List>
            </Collapse> */}
        </div>
    );
}