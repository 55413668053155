import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import asset7 from '../../images/Asset7.png';

function MainTitle({children}) {
    return (
        <Typography
            variant="h6"
            color="inherit"
            gutterBottom
            style={{
                fontWeight: "bold",
                backgroundColor: "#14334F",
                color: "white",
                padding: 10,
                textTransform: "uppercase",
            }}
        >
            {children}
        </Typography>
    );
}

function Content({children}) {
    return (
        <Box
            m={1}
            style={{
                padding: "10px 30px",
                color: "#14334F",
                fontFamily: "500",
                textAlign: 'justify',
                fontSize: "16px",
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
}

function TextContent({children}) {
    return (
        <Box
            m={1}
            style={{
                padding: "10px 30px",
                textIndent: 30,
                textAlign: 'justify',
                fontSize: "16px",
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="p"
                        color="inherit"
                        gutterBottom
                        style={{color: "#14334F", fontweight: "500", lineHeight: 1.6,}}
                    >
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

function Section_SocialServices({t}) {
    return (
        <Grid
            style={{
                backgroundColor: "#E1EBFE",
                marginBottom: "30px",
                paddingBottom: "10px",
                lineHeight: 1.6,
            }}
        >
            <MainTitle>{t('AboutUs.socialServiceTitle')}</MainTitle>
            <TextContent>
                {t('AboutUs.socialServiceDesc')}
            </TextContent>
        </Grid>
    );
}

function Section_NationalPolicy({t}) {
    return (
        <Grid
            style={{
                backgroundColor: "#E1EBFE",
                marginBottom: "30px",
                paddingBottom: "10px",
                lineHeight: 1.6,
            }}
        >
            <MainTitle>
                {t('AboutUs.nationalPolicy.Title')}
            </MainTitle>
            <TextContent>
                {t('AboutUs.nationalPolicy.Desc1')}
            </TextContent>
            <Content>
                {t('AboutUs.nationalPolicy.Desc2')}
            </Content>
            <Content>
                {t('AboutUs.nationalPolicy.Desc3')}
                <ul>
                    <li>{t('AboutUs.nationalPolicy.Desc4')} </li>
                    <li>
                        {t('AboutUs.nationalPolicy.Desc5')}
                    </li>
                    <li>
                        {t('AboutUs.nationalPolicy.Desc6')}
                    </li>
                    <li>
                        {t('AboutUs.nationalPolicy.Desc7')}
                    </li>
                </ul>
            </Content>
            <Content>
                {t('AboutUs.nationalPolicy.Desc8')}
            </Content>
            <Content>
                <Typography
                    variant="h6"
                    style={{marginBottom: "20px", fontWeight: "bold", fontSize: "16px"}}
                >
                    {t('AboutUs.nationalPolicy.keyDef1')}
                </Typography>
                <div style={{marginTop: "20px"}}>
                    <p>
                        {t('AboutUs.nationalPolicy.keyDef2')}
                    </p>
                </div>
                <div style={{marginTop: "20px"}}>
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            fontStyle: "italic",
                        }}
                    >
                        {t('AboutUs.nationalPolicy.keyDef3')}
                    </Typography>
                    <p>
                        {t('AboutUs.nationalPolicy.keyDef4')}
                    </p>
                    <p style={{fontStyle: "italic"}}>
                        {t('AboutUs.nationalPolicy.keyDef7')}
                    </p>
                </div>
                <div style={{marginTop: "20px"}}>
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            fontStyle: "italic",
                        }}
                    >
                        {t('AboutUs.nationalPolicy.keyDef5')}
                    </Typography>
                    <p>
                        {t('AboutUs.nationalPolicy.keyDef6')}
                    </p>
                </div>
            </Content>
        </Grid>
    );
}

function Section_NVS({t}) {
    return (
        <Grid
            style={{
                backgroundColor: "#E1EBFE",
                marginBottom: "30px",
                paddingBottom: "10px",
                lineHeight: 1.6,
            }}
        >
            <MainTitle>
                {t('AboutUs.nvs.Title')}
            </MainTitle>
            <TextContent>
                {t('AboutUs.nvs.Desc1')}
            </TextContent>
            <Content>
                {t('AboutUs.nvs.Desc2')}
            </Content>
            <Content>
                {t('AboutUs.nvs.Desc3')}
            </Content>
            <Content>
                {t('AboutUs.nvs.Desc4')}
                <ul>
                    <li>
                        {t('AboutUs.nvs.Desc5')}
                    </li>
                    <li>
                        {t('AboutUs.nvs.Desc6')}
                    </li>
                    <li>
                        {t('AboutUs.nvs.Desc7')}
                    </li>
                    <li>
                        {t('AboutUs.nvs.Desc8')}
                    </li>
                    <li>{t('AboutUs.nvs.Desc9')}</li>
                    <li>{t('AboutUs.nvs.Desc10')}</li>
                    <li>
                        {t('AboutUs.nvs.Desc11')}
                    </li>
                    <li>
                        {t('AboutUs.nvs.Desc12')}
                    </li>
                    <li>
                        {t('AboutUs.nvs.Desc13')}
                    </li>
                </ul>
            </Content>
            <Content>
                {t('AboutUs.nvs.Desc14')}
            </Content>
        </Grid>
    );
}


function Section_VolunteerManagementSystem({t}) {
    return (
        <Grid
            style={{
                backgroundColor: "#E1EBFE",
                marginBottom: "30px",
                paddingBottom: "10px",
            }}
        >
            <MainTitle>{t('AboutUs.vms.title')}</MainTitle>
            <TextContent>{t('AboutUs.vms.Desc')}</TextContent>
        </Grid>
    );
}


function Section_References({t}) {
    const LinkFiles = function(path) {
        return <>(
                <a href={path + "Sinhala.pdf"} target="_blank" >
                    Sinhala</a> | <a href={path + "Tamil.pdf"} target="_blank" >
                        Tamil</a> | <a href={path + "English.pdf"} target="_blank" >
                            English</a>
        )</>
    }

    return (
        <Grid
            style={{
                backgroundColor: "#E1EBFE",
                marginBottom: "30px",
                paddingBottom: "10px",
            }}
        >
            <MainTitle>{t('AboutUs.references.title')}</MainTitle>
            <TextContent>
                <ul>
                    <li>
                        Conditions of Services {LinkFiles("/files/Conditions_of_Services/Condtions_of_Services-")}
                    </li>
                    <li>
                        Copyrights and Terms of Use {LinkFiles("/files/Conditions_of_Services/Condtions_of_Services-")}
                    </li>
                    <li>
                        Privacy Policy {LinkFiles("/files/Privacy_Policy/Privacy_Policy-")}
                    </li>
                    <li> Volunteer Engagement Guidelines </li>
                    <ul>
                        <li>
                            State Entities {LinkFiles("/files/Volunteer_Engagement_Guidelines/VEG-State_Entities/")}
                        </li>
                        <li>
                            Corporate {LinkFiles("/files/Volunteer_Engagement_Guidelines/VEG-Corporate/")}
                        </li>
                        <li>
                            Academia {LinkFiles("/files/Volunteer_Engagement_Guidelines/VEG-Academia/")}
                        </li>
                    </ul>
                </ul>
            </TextContent>
        </Grid>
    );

}



function ContactUs() {
    const {t} = useTranslation()
    return (
        <React.Fragment>
            <Grid
                item
                xs={12}
                style={{
                    padding: "40px",
                    textAlign: "center",
                    backgroundColor: "#1d4971",
                    color: "white",
                    marginBottom: "50px",
                }}
            >
                <Typography variant="h4" style={{fontWeight: "600"}}>
                    {t('AboutUs.title')}
                </Typography>
                <Typography variant="subtitle2">Home / AboutUs</Typography>
            </Grid>
            <Grid item xs={12} container alignContent="center" justify="center" alignItems="center">
                <Box mb={3}>
                    <img src={asset7} alt="img" style={{width: "75vw", maxWidth: "500px"}}/>
                </Box>
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{marginBottom: 20, color: "#14334F"}}
            >
                <Grid item style={{maxWidth: "80%", fontSize: "1.3em"}}>
                    <Section_SocialServices t={t}/>
                    <Section_NationalPolicy t={t}/>
                    <Section_NVS t={t}/>
                    <Section_VolunteerManagementSystem t={t}/>
                    <Section_References t={t} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ContactUs;
