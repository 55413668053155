import {serverHost,serverPort,tokenKey} from "../../Constant";
import axios from 'axios';
import {changePasswordUrl} from '../../containers/UserCommon/Constants'

export const submitChangePassword = async (userId,oldPassword,newPassword)=>{

    const submitUrl = serverHost+":"+serverPort+changePasswordUrl;
    try{
        const response = await axios.post(submitUrl,
            {
            headers: {
                'usertoken': localStorage.getItem(tokenKey)
            },
            params: { 
                userId:userId,
                oldPassword:oldPassword,
                newPassword: newPassword
            } 
        });
       
        if (response.status == 200) {
                const result =  {
                    item:response.data,
                    error:false
                }
               
                return result;
            }
        else {
            
            const result = {
                message:response.message,
                error:true
            }
            
            return result;
        }

}
catch(err){
    
    const result = {
        message:err.message,
        error:true
    }
    
    return result;
} 

};
