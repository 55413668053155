import Axios from "axios";
import { serverHost, serverPort, tokenKey } from "../../../Constant";

export const fetchNonFormalVOFormData = async (id) => {
    const fetchUrl =
      serverHost + ":" + serverPort + "/api/nonformal-opportunity/get/" + id;
//   const fetchUrl =
//     "http://unvms.projects.uom.lk/api/nonformal-opportunity/get/ca7f9ba7-7cc9-4600-bf23-9f65f3a721ba";
  try {
    const response = await Axios.get(fetchUrl, {
      headers: {
        token: localStorage.getItem(tokenKey),
      },
    });

    if (response.status == 200) {
      const result = {
        item: response.data,
        error: false,
        status: response.status,
      };
      return result;
    } else {
      const result = {
        item: response.message,
        error: true,
        status: response.status,
      };
      return result;
    }
  } catch (err) {
    const result = {
      item: err.response ? err.response.data.message : "Something went wrong!",
      error: true,
      status: err.status,
    };
    return result;
  }
};
