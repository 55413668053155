import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as AuthActions from "../../actions/AuthActions";
// import TemporaryDrawer from "../../test/Test";

function HomePage({ authSignIn, auth }) {
  
  return <div>hii</div>;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authSignIn: bindActionCreators(AuthActions.authSignIn, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
