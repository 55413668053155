import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Box,
  Button,
  Paper,
  Grid,
  TextField,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { serverHost, serverPort } from "../../Constant";
import { Alert } from "@material-ui/lab";
import { fetchVio } from '../../functions/public_profiles/fetch_user';
import { useSnackbar } from 'notistack';
import { submitSubVioTransfer, getSubVioDetails } from '../../functions/subvio/subVioTransfer';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import PopupTransfer from "./PopupDetails";
import {useHistory} from "react-router-dom";

const schema = yup.object().shape({
  newRegistrationNumber: yup.string().required('Required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {
    // width: "60vw",
  },
  border: {
    border: "1px solid #c0c0c0",
    padding: 24,
    borderRadius: 4,
    margin: "16px 8px",
  },
}));

export default function SubVioTransfer({ match }) {
  const suvVioId = match.params.subVioId;
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

  const [fetchDetails, setFetchDetails] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [formDisabled, setFormDistabled] = useState(false);
  const [prevVio, setPrevVio] = useState({})
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(async () => {
    let mounted = true;
    let results = await fetchVio(suvVioId);

    if (mounted) {
      if (results.error) {
        setErrorMsg("Error")
      }
      else {
        setFetchDetails(results.vio)
      }
    }
    return () => mounted = false;
  }, [])

  useEffect(() => {
    if (!errorMsg) return
    enqueueSnackbar(errorMsg, {
      variant: 'error',
      persist: false
    })
  }, [errorMsg])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getParent = async (data) => {
    setData(data)
    setFormDistabled(true)
    let results = await getSubVioDetails({
      parentVioId: fetchDetails.parentVio,
      newRegistrationNumber: data.newRegistrationNumber
    })
    
    if (results.error) {
      enqueueSnackbar("Request Failed, Try Again!", {
        variant: 'error',
        persist: false
      })
      setFormDistabled(false)
    }
    else {
      setPrevVio(results.item.newParentVio);
      setOpen(true)
    }
  }

  const submitSubVioTransferFunc = async () => {
    setFormDistabled(true)
    let results = await submitSubVioTransfer({
      parentVioId: fetchDetails.parentVio,
      subVioId: fetchDetails.id,
      newRegistrationNumber: data.newRegistrationNumber
    })
    if (results.error) {
      enqueueSnackbar("Transfer Failed!", {
        variant: 'error',
        persist: false
      })
      setFormDistabled(false)
    }
    else {
      enqueueSnackbar("successfully Transfered", {
        variant: 'success',
        persist: false
      })
      history.push("/admin/VIOlist")
    }
  }

  return (
    fetchDetails ?
      <Paper style={{ padding: '18px 36px', maxWidth: 800, margin: 'auto', marginTop: 40, marginBottom: 40 }}>
        <form action="" onSubmit={handleSubmit(getParent)}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ padding: '20px 0' }} spacing={3}>
              <Typography variant="h5" align="center" color="primary">Sub VIO Transfer</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">
                Sub VIO Name
              </Typography>
              <Typography variant="body2" gutterBottom>
                {fetchDetails.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">
                Registrtation No
              </Typography>
              <Typography variant="body2" gutterBottom>
                {fetchDetails.registrationNo}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">
                Descrition No
              </Typography>
              <Typography variant="body2" gutterBottom>
                {fetchDetails.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="newRegistrationNumber"
                inputRef={register}
                id="outlined-secondary"
                label="Registration Number of the new VIO"
                variant="outlined"
                fullWidth
                disabled={formDisabled}
                className={classes.textField}
                error={errors.newRegistrationNumber}
                helperText={errors.newRegistrationNumber?.message}
              />
            </Grid>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', padding: '8px 0'}}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={formDisabled}
                style={{ width: 180, color: "white" }}
              // onClick={() => handleCheckBoxes()}
              >
                Transfer
              </Button>
              <PopupTransfer
                handleClickOpen = {handleClickOpen}
                handleClose = {handleClose}
                user = {prevVio}
                open = {open}
                onAgree = {submitSubVioTransferFunc}
                setFormDistabled = {setFormDistabled}
                />
            </div>
          </Grid>
        </form>

      </Paper> : <div />
  );
}
